const Backbone = require('backbone');
var FooterView = Backbone.View.extend({
	events: {
		'click .js-feedback': '_launchFeedback',
		'click .js-download-icon': '_goToStore',
		'click .js-privacy-settings': '_openPrivacySettings'
	},

	_launchFeedback: function() {
		window.usabilla_live('click');
	},
	_openPrivacySettings: function(event) {
		event.preventDefault();
		if (window.UC_UI && window.UC_UI.showSecondLayer) {
			window.UC_UI.showSecondLayer();
		}
	},
	_goToStore: function() {
		if (window.BCOM.theme.isBuild) {
			window.location.assign(window.BCOM.mobileAppstoreLink);
		}
	}
});

module.exports = FooterView;
