const querystring = require('querystring'),
	url = require('url'),
	urlUtility = function() {
		/**
		 * @param {*} url
		 */
		const parseQueryString = function(url) {
				if (typeof url.query !== 'string') {
					return;
				}
				url.query = querystring.parse(url.query);
			},
			/**
			 * @param {*} url
			 */
			syncSearchAttributeToQuery = function(url) {
				const queryString = querystring.stringify(url.query);
				if (queryString) {
					url.search = `?${queryString}`;
				} else {
					url.search = '';
				}
			},
			evaluateParamObject = function(path, params) {
				let result = '';
				if (Array.isArray(params)) {
					for (let elem of params) {
						const elemResult = springMvcStringify(path, elem);
						if (elemResult !== null) {
							result += `${elemResult}&`;
						}
					}
				} else {
					for (let key of Object.keys(params)) {
						const newPath = path ? `${path}.${key}` : key,
							elemResult = springMvcStringify(newPath, params[key]);
						if (elemResult !== null) {
							result += `${elemResult}&`;
						}
					}
				}
				return result;
			},
			springMvcStringify = function(path, params) {
				let result;
				switch (typeof params) {
					case 'function':
					case 'undefined':
						return null;
					case 'object':
						if (!params) {
							return null;
						}
						result = evaluateParamObject(path, params);
						if (result.endsWith('&')) {
							result = result.substring(0, result.length - 1);
						}
						return result;
					default:
						result = String(params);
						break;
				}
				if (result === null || result === '') {
					return null;
				}
				result = encodeURIComponent(result);

				if (!path || path === '') {
					return result;
				}

				result = `${encodeURIComponent(path)}=${result}`;
				return result === '=' ? null : result;
			};

		return {
			/**
			 * @param {string} currentUrl
			 * @param {string} key
			 * @return {string|null}
			 */
			getParameter: function(currentUrl, key) {
				let parsedUrl,
					retValue = null;
				if (!currentUrl) {
					return retValue;
				}
				parsedUrl = this.parseUrl(currentUrl);
				if (parsedUrl) {
					return parsedUrl.query[key] || retValue;
				}
			},
			/**
			 * @param {string} currentUrl
			 * @param {string} key
			 * @param {string} value
			 * @return {string}
			 */
			setParameter: function(currentUrl, key, value) {
				const parsedUrl = this.parseUrl(currentUrl);
				parsedUrl.query[key] = value;
				syncSearchAttributeToQuery(parsedUrl);
				return url.format(parsedUrl);
			},
			/**
			 * @param {string} currentUrl
			 * @param {*} parameters
			 * @return {string}
			 */
			setParameters: function(currentUrl, parameters) {
				Object.keys(parameters).forEach((key) => {
					const value = parameters[key];
					currentUrl = this.setParameter(currentUrl, key, value);
				});
				return currentUrl;
			},
			/**
			 * @param {string} currentUrl
			 * @param {string} key
			 * @param {string} [value]
			 * @return {string}
			 */
			unsetParameter: function(currentUrl, key, value) {
				const parsedUrl = this.parseUrl(currentUrl);
				if (Array.isArray(parsedUrl.query[key]) && value !== undefined) {
					parsedUrl.query[key] = parsedUrl.query[key].filter((queryItem) => {
						return queryItem !== value;
					});
					if (!parsedUrl.query[key].length) {
						delete parsedUrl.query[key];
					}
				} else {
					delete parsedUrl.query[key];
				}
				syncSearchAttributeToQuery(parsedUrl);
				return url.format(parsedUrl);
			},
			/**
			 * @param {string} currentUrl
			 * @param {Array} parameters
			 */
			unsetParameters: function(currentUrl, parameters) {
				return parameters.reduce((url, parameter) => {
					return this.unsetParameter(url, parameter);
				}, currentUrl);
			},
			/**
			 * @param {string} currentUrl
			 * @return {string}
			 */
			unsetAllParameters: function(currentUrl) {
				const parsedUrl = this.parseUrl(currentUrl);
				if (parsedUrl.query) {
					delete parsedUrl.query;
				}
				if (parsedUrl.search) {
					delete parsedUrl.search;
				}
				return url.format(parsedUrl);
			},

			/**
			 * filterParameters - filter parameters out of a url
			 *
			 * @param  {string} currentUrl     the current url
			 * @param  {any} filterFunction function to apply to each parameter name
			 * @return {string} filtererd url
			 */
			filterParameters: function(currentUrl, filterFunction) {
				const parsedUrl = this.parseUrl(currentUrl),
					{ query } = parsedUrl;
				if (query && filterFunction) {
					const parameters = Object.keys(query),
						parametersToKeep = parameters.filter(filterFunction),
						filteredQuery = parametersToKeep.reduce((newQuery, parameter) => {
							newQuery[parameter] = query[parameter];
							return newQuery;
						}, {});
					parsedUrl.query = filteredQuery;
				}
				syncSearchAttributeToQuery(parsedUrl);
				return url.format(parsedUrl);
			},
			/**
			 * @param {string} currentUrl
			 * @return {Object}
			 */
			parseUrl: function(currentUrl) {
				const parsedUrl = url.parse(currentUrl);
				if (parsedUrl.query === null) {
					/** @type {object} */ (parsedUrl.query) = {};
				}
				parseQueryString(parsedUrl);
				return parsedUrl;
			},

			format: url.format,

			springMvcStringify(params) {
				return springMvcStringify(null, params) || '';
			}
		};
	};

module.exports = urlUtility();
