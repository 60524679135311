import { NewsletterOriginationContext, cookies } from 'lib/enums';
import Backbone from 'backbone';
import EmailSubscription from 'app/models/store/client/email/email';
import MailCheckView from 'app/views/store/common/mailcheck';
import jsCookie from 'js-cookie';
import $ from 'jquery';
import formValidator from 'formValidator';
import { bannerHelper } from 'src/app/views/store/common/helpers/banner_helper';
import 'customValidationRules';

export const EmailSubscribeView = Backbone.View.extend({
	events: {
		'submit form': '_saveAction',
		'click .js-email-subscribe': '_saveAction',
		'click .js-open-email-subscribe-banner': '_expandSubscribeBanner',
		'click .js-close-banner': '_closeBanner'
	},
	initialize: function(options = {}) {
		// note: el is passed in by instantiator since there is possibility of two on a page (footer and email_subscribe_splash modal)
		if (window.dataLayer.page === 'landing_pages:showroom_landing') {
			window.sessionStorage.isShowRoomVisitor = true;
		}

		// Only show the coupon pop-up if visitor is not showroom and is on one the allowed sites
		const couponPopup = window.dataLayer.couponPopup;
		const siteId = window.dataLayer.siteId;
		const allowedSites = [];
		for (const key of Object.keys(couponPopup)) {
			allowedSites.push(couponPopup[key]);
		}
		const isAllowedSite = allowedSites.indexOf(siteId) > -1;
		if (window.sessionStorage.isShowRoomVisitor || !isAllowedSite) {
			return;
		}

		this.$emailForm = this.$('form[name=emailsub]');
		this.$input = this.$('.js-email-input');
		this.$submitEmailButton = this.$('.js-email-subscribe');
		this.$inputAndButtonRow = this.$('.js-input-and-button-row');
		this.$collapse = this.$('.email-subscribe-splash-collapse');
		this.$expand = this.$('.email-subscribe-splash-expand');
		this.$successTarget = this.$('.js-email-subscribe-content').addBack('.js-email-subscribe-content');
		this.validator = formValidator.init({
			el: this.$el.find('form'),
			validate: {
				rules: {
					email: {
						required: true,
						strictEmail: true
					}
				},
				messages: {
					email: {
						required: 'Please enter your email address.',
						strictEmail: 'Please enter a valid email address.'
					}
				}
			}
		});
		if (options.successMessage) {
			if (options.el.hasClass('js-learning-center-subscription')) {
				this.$successElement = $(
					`<div class="f4">
						<p class="fw6 mb1">${options.successMessage}</p>
						<div class="theme-primary bb"></div>
						<p class="f6 mt1">${options.learningCenterSubscriptionSuccessMessage}</p>
					</div>`
				);
			} else {
				this.$successElement = $(`<p class="f3">${options.successMessage}</p>`);
			}
		} else {
			this.$successElement = $(`<p>To edit your subscription preferences, log in to your account and go to
			<a href="/account/details" class="theme-primary">Account Details</a>.</p>`);
		}

		// set context
		this.isEmailModal = this.$el.hasClass('modal');
		this.isBanner = this.$el.hasClass('banner');
		this.isFooter = !this.isEmailModal && !this.isBanner;
		this.originationContext = this.isFooter ? NewsletterOriginationContext.FOOTER_ENTRY : NewsletterOriginationContext.POPUP_ENTRY;

		if (!window.dataLayer.isLocalUser && bannerHelper.isEligibleForBanner()) {
			if (window.BCOM.device.isMobile && this.isBanner) {
				this._showBanner();
			} else if (this.isEmailModal) {
				this._launchModal.call(this);
			}
		}

		this.isTooltip = this.isEmailModal || this.isBanner;
		this._initMailcheck(MailCheckView, this.$input, this.$submitEmailButton, this.$inputAndButtonRow, this.isTooltip);
	},
	_initMailcheck: function(View, input, submitButton, inputAndButtonRow, isTooltipAlert) {
		this.mailcheck = new View({
			el: input,
			$submitButton: submitButton,
			$insertAfter: inputAndButtonRow,
			isTooltipAlert: isTooltipAlert
		});
	},
	_showBanner: function() {
		if (bannerHelper.shouldShow(bannerHelper.BannerType.EMAIL)) {
			this.$el.removeClass('hidden');
		}
	},
	_expandSubscribeBanner: function() {
		this.$collapse.addClass('hidden');
		this.$expand.removeClass('hidden');
		this._setCookie(cookies.NEWSLETTER_SIGNUP_OFFER);
	},
	_launchModal: function() {
		if (bannerHelper.shouldShow(bannerHelper.BannerType.EMAIL)) {
			const $modal = this.$el;
			this.$el.addClass('new-newsletter');
			$modal.modal('show');
			// same cookie here that CF uses
			this._setCookie(cookies.NEWSLETTER_SIGNUP_OFFER);
		}
	},
	_setCookie(cookieName) {
		jsCookie.set(cookieName, true, {
			path: '/',
			expires: 999999
		});
	},
	_saveAction: function(e) {
		e.preventDefault();
		if (!this.$emailForm.valid()) {
			return;
		}
		this._subscribeToEmails.call(this, {
			email: this.$input.val().trim(),
			originationContext: this.originationContext
		});
	},
	_subscribeToEmails: function(params) {
		const emailSubscription = new EmailSubscription();
		if (typeof emailSubscription.save === 'function') {
			emailSubscription.save(params).then(this._subscribeSuccess.bind(this));
		}
	},
	_subscribeSuccess: function(result) {
		const { success } = result;

		if (!this.isBanner && !this.isEmailModal) {
			this.$successTarget.replaceWith(this.$successElement);
		} else if (this.isBanner) {
			this._successCloseBanner();
		} else if (this.isEmailModal) {
			this._successCloseModal();
		}

		if (success) {
			Backbone.trigger('omniture:emailSignUpTracking', {
				location: this.isEmailModal ? 'emailsignupmodal' : 'footer',
				email: this.$el.find('input[name="email"]').val()
			});
		}
	},
	_successCloseModal: function() {
		this.$('#newsletterPopupEmail').addClass('hide');
		this.$('#submitEmailModal').addClass('hide');
		this.$('.js-invalid-email').addClass('hide');
		this.$('.js-success-wrap').removeClass('hide');
		this.$('.js-email-signup-text').addClass('hide');
		setTimeout(
			function() {
				this.$el.modal('hide');
			}.bind(this),
			4000
		);
	},
	_successCloseBanner: function() {
		this.$('.js-success-wrap').removeClass('hide');
		this.$('.fine-print').addClass('hide');
		this.$('.js-email-signup-text').addClass('hide');
		this.$('#emailSignup').addClass('hide');
		this.$('.privacy-text').addClass('hide');
		setTimeout(
			function() {
				this._closeBanner();
			}.bind(this),
			4000
		);
	},
	_closeBanner: function() {
		this.$el.addClass('hidden');
		this._setCookie(cookies.NEWSLETTER_SIGNUP_OFFER);
	}
});
