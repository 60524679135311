// @ts-nocheck
const Backbone = require('backbone');
Backbone.Model.prototype.defaultSet = Backbone.Model.prototype.set;

const extendBackboneModel = function extendBackboneModel() {
	Object.assign(Backbone.Model.prototype, {

		/**
		 * Adds schema type casting to models before setting attributes
		 * @param {Object|String}    key     The key of the value to set or an object of value pairs
		 * @param {String|Object}    val     The value to set or the options object
		 * @param {Object|undefined} options The options for saving or undefined
		 */
		set: function(key, val, options = {}) {
			var attrs;

			if (typeof key === 'object') {
				attrs = key;
				options = val;
			} else {
				(attrs = {})[key] = val;
			}

			if (this.schema && attrs) {
				Object.keys(attrs).forEach((key) => {
					try {
						const value = attrs[key];
						switch (this.schema[key]) {
							case 'boolean':
								attrs[key] = value === 'true' ? true : value === 'false' ? false : value;
								break;
							case 'int':
								attrs[key] = parseInt(value, 10);
								break;
							case 'float':
								attrs[key] = parseFloat(value);
								break;
							case 'string':
								attrs[key] = value.toString();
								break;
							default:
								attrs[key] = value;
								break;
						}
					} catch (error) {}
				});
			}

			return this.defaultSet.call(this, attrs, options);
		}

	});
};

module.exports = extendBackboneModel();
