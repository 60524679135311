// This file is generated by webpackSVGSpritePlugin.js.
// @ts-ignore
const svgHash = require('../../../public/scripts/svg-symbol-hash');

const cloudinaryHelpers = require('./cloudinary_helpers');
const imageHelpers = require('./image_helpers');
const format = require('../../utilities/lib/format');

const getPropertyFromStackTail = function(tail, property) {
	if (tail && tail.head && tail.head[property]) {
		return tail.head[property];
	} else if (tail && tail.tail) {
		return getPropertyFromStackTail(tail.tail, property);
	}
};

/**
 * Return dust object
 * @type {Object}
 */
module.exports = {
	extend: function(dust) {
		dust.helpers = dust.helpers || {};

		dust.helpers.currentYear = function(chunk) {
			return chunk.write(new Date().getFullYear());
		};

		dust.helpers.formatCurrency = function(chunk, context, bodies, params) {
			let amount = context.resolve(params.amount);
			const allowNegative = context.resolve(params.allowNegative);
			// If amount is a string, remove any commas and convert to number
			if (typeof amount === 'string') {
				amount = Number(amount.replace(/[,]/g, ''));
			}
			return chunk.write(format.currency(amount, allowNegative));
		};

		dust.helpers.formatPhone = function(chunk, context, bodies, params) {
			let phone = context.resolve(params.phone),
				firstNumber = '';
			if (phone) {
				phone = typeof phone !== 'string' ? phone.toString() : phone;
				phone = phone.replace(/[()\s-]/g, '');
				if (phone.length === 11) {
					firstNumber = `${phone.substring(0, 1)} `;
					phone = phone.substring(1);
				}
				phone = `${firstNumber}(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6, 10)}`;
			}
			return chunk.write(phone);
		};

		dust.helpers.hideText = function(chunk, context, bodies, params) {
			let text = context.resolve(params.text),
				regex = context.resolve(params.regex);
			if (typeof text !== 'string') {
				text = '';
			}
			if (typeof regex === 'string') {
				const regExp = new RegExp(regex);
				text = text.replace(regExp, '*');
			} else {
				text = text.replace(/\S/gi, '*');
			}
			return chunk.write(text);
		};

		dust.helpers.replaceText = function(chunk, context, bodies, params) {
			let text = context.resolve(params.text),
				oldText = context.resolve(params.oldText),
				newText = context.resolve(params.newText) || '';
			if (text && oldText) {
				const re = new RegExp(oldText, 'g');
				text = text.replace(re, newText);
			}
			return text;
		};

		dust.helpers.truncate = function(chunk, context, bodies, params) {
			let data = context.resolve(params.data),
				length = context.resolve(params.length),
				separator = context.resolve(params.separator),
				text;
			if (data.length > length) {
				text = chunk.write(data.substr(0, length) + separator);
			} else {
				text = chunk.write(data);
			}
			return text;
		};

		dust.helpers.videoDataAttrs = function(chunk, context, bodies, params) {
			let video = context.resolve(params.video),
				isModal = context.resolve(params.isModal),
				autoPlay = context.resolve(params.autoPlay),
				thumbWidth = context.resolve(params.thumbWidth),
				thumbHeight = context.resolve(params.thumbHeight),
				dataAttrs = '';
			if (video && video.hashKey) {
				// see build-cloud src/app/views/store/common/video.js
				dataAttrs += ` data-title="${video.title}"`;
				dataAttrs += ` data-description="${video.description}"`;
				dataAttrs += ` data-url-hash-id="${video.hashKey}"`;
				dataAttrs += ` data-is-modal="${isModal === 'true' || isModal === true ? 'true' : 'false'}"`;
				dataAttrs += ` data-autoplay="${autoPlay === 'true' || autoPlay === true ? 'true' : 'false'}"`;
				dataAttrs += ` data-provider-id="${video.streamProviderCode}" `;
				if (thumbWidth) {
					dataAttrs += ` data-thumb-width="${thumbWidth}"`;
				}
				if (thumbHeight) {
					dataAttrs += ` data-thumb-height="${thumbHeight}"`;
				}
			}
			return chunk.write(dataAttrs);
		};

		/**
		 * Returns the appropriate class for a div based on the number total elements, index, and the
		 * configured maxColumns (1 to 6). Default maxColumns being 6
		 * This helper is used to create a layout where the last row can have varying number of items
		 * @param {Object} params - parameter object passed in
		 * @param {number} params.itemCount - Total number of items the render in the grid
		 * @param {number} params.itemIndex - current index being built
		 * @param {number} params.maxColumns - optional number of maximum columns (Default: 6)
		 * @param {boolean} params.equalColumns - optional boolean to set equalColumns
		 */
		dust.helpers.dynamicColumn = function(chunk, context, bodies, params) {
			const itemCount = context.resolve(params.itemCount),
				itemIndex = context.resolve(params.itemIndex),
				columnMap = {
					1: 'col-xs-12',
					2: 'col-xs-6',
					3: 'col-xs-4',
					4: 'col-xs-3',
					5: 'col-xs-5ths',
					6: 'col-xs-2'
				};
			let dataAttrs = '',
				itemCountMinusIndex,
				itemCountModuloMaxColumns,
				maxColumns = context.resolve(params.maxColumns),
				equalColumns = Boolean(context.resolve(params.equalColumns));

			if (!itemCount) {
				return chunk.write(dataAttrs);
			}

			if (!maxColumns || maxColumns > 6) {
				maxColumns = 6;
			}

			if (itemCount <= maxColumns) {
				dataAttrs = columnMap[itemCount];
				// if item count is greater than 6 and divides evenly then
				// set the six column class since there is other logic needed
			} else if (itemCount % maxColumns === 0 || equalColumns) {
				dataAttrs = columnMap[maxColumns];
				// Handle case where you have multple rows with different number of columns
			} else {
				// Subtract the itemCount from the maxColumns value
				itemCountModuloMaxColumns = itemCount % maxColumns;
				// Subtract the itemIndex from the itemCount.
				// This is used to determine if this element is in the last row
				itemCountMinusIndex = itemCount - itemIndex;

				if (itemCountModuloMaxColumns === 1 && itemCountMinusIndex === 1) {
					dataAttrs = columnMap[1];
				} else if (itemCountModuloMaxColumns === 2 && itemCountMinusIndex < 3) {
					dataAttrs = columnMap[2];
				} else if (itemCountModuloMaxColumns === 3 && itemCountMinusIndex < 4) {
					dataAttrs = columnMap[3];
				} else if (itemCountModuloMaxColumns === 4 && itemCountMinusIndex < 5) {
					dataAttrs = columnMap[4];
				} else if (itemCountModuloMaxColumns === 5 && itemCountMinusIndex < 6) {
					dataAttrs = columnMap[5];
				} else {
					dataAttrs = columnMap[maxColumns];
				}
			}
			return chunk.write(dataAttrs);
		};

		dust.helpers.autofocus = function(chunk, context) {
			const url = typeof window !== 'undefined' ? window.location.href : context.get('appargument');
			let dataAttrs = url && url.indexOf('contentitemid=') !== -1 ? '' : 'autofocus="autofocus"';
			return chunk.write(dataAttrs);
		};

		/**
		 * Extend helpers functions
		 * @type {Object}
		 */
		dust.helpers.partial = function(chunk, context, bodies, params) {
			const windowData =
					typeof window !== 'undefined' && window.dataLayer && window.dataLayer.dustData ? window.dataLayer.dustData : {},
				prefix =
					context.stack.head.viewPrefix || getPropertyFromStackTail(context.stack.tail, 'viewPrefix') || windowData.viewPrefix,
				layout =
					context.stack.head.siteLayout || getPropertyFromStackTail(context.stack.tail, 'siteLayout') || windowData.siteLayout,
				theme = context.stack.head.theme || getPropertyFromStackTail(context.stack.tail, 'theme') || windowData.theme || 'default',
				paramPath = context.resolve(params.path),
				ext = params.ext ? `.${context.resolve(params.ext)}` : '.dust';
			let themePlatformPath, themeBasePath, platformPath, basePath, path;

			// ex: store/theme/niche/mobile/pages/foo
			themePlatformPath = `${layout}/themes/${theme}/${prefix}/${paramPath}`;
			// ex: store/theme/niche/global/pages/foo
			themeBasePath = `${layout}/themes/${theme}/global/${paramPath}`;
			// ex: store/mobile/pages/foo
			platformPath = `${layout}/${prefix}/${paramPath}`;
			// ex: store/global/pages/foo
			basePath = `${layout}/global/${paramPath}`;

			if (dust.templates.paths.indexOf(themePlatformPath) > -1) {
				path = themePlatformPath;
			} else if (dust.templates.paths.indexOf(themeBasePath) > -1) {
				path = themeBasePath;
			} else if (dust.templates.paths.indexOf(platformPath) > -1) {
				path = platformPath;
			} else if (dust.templates.paths.indexOf(basePath) > -1) {
				path = basePath;
			} else if (dust.templates.paths.indexOf(paramPath) > -1) {
				path = paramPath;
			} else {
				throw new Error(`could not find ${paramPath}`);
			}
			const environment = context.get('environment') || '';
			const isProduction = environment.toLowerCase() === 'production';
			if (environment && !isProduction) {
				chunk = chunk.write(`<!-- templatePath:${path}.dust -->\n`);
			}
			if (params.renderRawHTML) {
				return chunk.partial(path, context, params);
			} else {
				// now that path lookup in templates.json is complete, add dust extension. IDS-19853
				if (!Object.keys(windowData).length && path && ext && path.slice(-ext.length) !== ext) {
					path += ext;
				}

				return chunk.partial(path, context, params);
			}
		};

		/**
		 * Partial to create namespaced css class comming from the content tool
		 * @return {String}        Space separated css class names that are prefixed with the prefix name provided.
		 */
		dust.helpers.namespaceIt = function(chunk, context, bodies, params) {
			const cssClasses = context.resolve(params.data);
			const cssPrefix = context.resolve(params.cssPrefix);
			const nameSpaced = [];
			if (cssClasses && typeof cssClasses !== 'function' && cssPrefix) {
				const classesArray = cssClasses.split(' ');
				if (classesArray.length) {
					classesArray.forEach(function(cssClass) {
						nameSpaced.push(cssPrefix + cssClass);
					});
				}
			}
			return chunk.write(nameSpaced.join(' '));
		};

		dust.helpers.featureIsEnabled = function(chunk, context, bodies, params) {
			const featureName = context.resolve(params.featureName),
				head = context.stack && context.stack.head ? context.stack.head : {},
				enabled = head.features && head.features[featureName] ? head.features[featureName] : false;

			return enabled;
		};

		dust.helpers.featureIsEnabledWithOverride = function(chunk, context, bodies, params) {
			let featureName = context.resolve(params.featureName),
				head = context.stack && context.stack.head ? context.stack.head : {},
				enabled = head.features && head.features[featureName] ? head.features[featureName] : false,
				isInternal = head.isInternal ? head.isInternal : false,
				override = head.featuresOverrides && head.featuresOverrides[featureName] ? head.featuresOverrides[featureName] : false;

			if (!isInternal) {
				enabled = Boolean(override && enabled);
			}
			return enabled;
		};

		dust.helpers.saleEndDay = function() {
			// Note: sale end days don't align whith the week these are as expected
			const saleEndWeekDay = [];
			saleEndWeekDay[0] = 'SUNDAY';
			saleEndWeekDay[1] = 'MONDAY';
			saleEndWeekDay[2] = 'TUESDAY';
			saleEndWeekDay[3] = 'THURSDAY';
			saleEndWeekDay[4] = 'THURSDAY';
			saleEndWeekDay[5] = 'FRIDAY';
			saleEndWeekDay[6] = 'SUNDAY';
			const date = new Date();
			return saleEndWeekDay[date.getDay()];
		};

		dust.helpers.partitionArray = function(chunk, context, bodies, params) {
			const partitionSize = context.resolve(params.size);
			const originalArray = context.resolve(params.array);
			let partitionedArray = [];
			for (let i = 0; i < originalArray.length; i += partitionSize) {
				partitionedArray.push({ items: originalArray.slice(i, i + partitionSize) });
			}
			return chunk.render(bodies.block, context.push({ partitionedArray: partitionedArray }));
		};

		dust.helpers.replace = function(chunk, context, bodies, params) {
			const originalString = context.resolve(params.original);
			const stringToReplace = context.resolve(params.from);
			const replaceString = context.resolve(params.to);

			let updatedString = originalString;
			if (replaceString && originalString) {
				updatedString = originalString.replace(stringToReplace, replaceString);
			}
			return chunk.write(updatedString);
		};

		dust.helpers.icon = function(chunk, context, bodies, params) {
			const name = context.resolve(params.name);
			const inlineStyle = context.resolve(params.style);
			const styleToApply = inlineStyle ? ` style="${inlineStyle}"` : '';
			const inlineClass = context.resolve(params.class);
			const classToApply = inlineClass ? ` class="${inlineClass}"` : '';
			chunk.write(`<svg${styleToApply}${classToApply}><use xlink:href="/public/svg/icons-${svgHash}.svg#${name}"/></svg>`);
		};

		/**
		 * Similar to the icon helper, but this method will take a url param instead of a name
		 * @param chunk
		 * @param context
		 * @param bodies
		 * @param params
		 */
		dust.helpers.svgByUrl = function(chunk, context, bodies, params) {
			const finalUrl = cloudinaryHelpers._resolveUrl(context, bodies, params);
			const inlineStyle = context.resolve(params.style);
			const styleToApply = inlineStyle ? ` style="${inlineStyle}"` : '';
			const inlineClass = context.resolve(params.class);
			const classToApply = inlineClass ? ` class="${inlineClass}"` : '';
			const altToApply = context.resolve(params.alt) || 'SVG Image';
			chunk.write(`<picture${styleToApply}${classToApply}>
				<source type="image/svg+xml" srcset="${finalUrl}.svg">
				<img src="${finalUrl}" alt="${altToApply}" />
			</picture>`);
		};

		/**
		 * Helper method to access all the stacks of a loop except the last two.
		 */
		dust.helpers.sepl = function(chunk, context, bodies) {
			const body = bodies.block;
			if (context.stack.index >= context.stack.of - 2) {
				return chunk;
			}
			if (body) {
				return body(chunk, context);
			} else {
				return chunk;
			}
		};

		/**
		 * This dust helper is used to iterate over an object to get access to it's key
		 * and value attributes.
		 */
		dust.helpers.iterate = function(chunk, context, bodies, params) {
			const obj = context.resolve(params.object);
			const keys = Object.keys(obj);
			for (let i = 0; i < keys.length; i++) {
				chunk = chunk.render(bodies.block, context.push({ key: keys[i], value: obj[keys[i]] }));
			}
			return chunk;
		};

		dust.helpers.renderWithFlatModel = function(chunk, context, bodies, params) {
			const path = context.resolve(params.path);
			const model = context.resolve(params.model);
			const keys = Object.keys(model);
			for (let i = 0; i < keys.length; i++) {
				const obj = {};
				obj[keys[i]] = model[keys[i]];
				context = context.push(obj);
			}
			return chunk.partial(path, context, params);
		};

		dust.helpers.printDataAttributes = function(chunk, context) {
			const parentContext = context.stack.tail && context.stack.tail.head;
			const dataAttrs = [];
			if (parentContext) {
				Object.keys(parentContext).forEach((key) => {
					if (key.startsWith('data-')) {
						dataAttrs.push(`${key}="${context.resolve(parentContext[key])}"`);
					}
				});
			}
			if (dataAttrs.length) {
				return chunk.write(dataAttrs.join(' '));
			}
		};

		/**
		 * CSS Utility used to inject absolute positioning modifiers inside style="" html attribute
		 * based on "position" parameter passed to the context.
		 * Defaults to center if position is not specified.
		 * @example
		 * <pre>
		 *     Use on dust template:
		 *     	<div class="absolute" style="{@CSSAbsPosition position="bottom-left" /}" />
		 *     Output:
		 *     	<div class="absolute" style="bottom: 0; left: 0;" />
		 * </pre>
		 * @param chunk
		 * @param context
		 * @param bodies
		 * @param params
		 * @returns {string}
		 */
		dust.helpers.CSSAbsPosition = function(chunk, context, bodies, params) {
			let output = '';
			const position = context.resolve(params.position);
			switch (position) {
				case 'top-left':
					output = 'top: 0; left: 0;';
					break;
				case 'top-center':
					output = 'top: 0; left: 50%; transform: translate(-50%, 0);';
					break;
				case 'top-right':
					output = 'top: 0; right: 0;';
					break;
				case 'center-left':
					output = 'top: 50%; left: 0; transform: translate(0, -50%);';
					break;
				case 'center-right':
					output = 'top: 50%; right: 0; transform: translate(0, -50%);';
					break;
				case 'bottom-left':
					output = 'bottom: 0; left: 0;';
					break;
				case 'bottom-center':
					output = 'bottom: 0; left: 50%; transform: translate(-50%, 0);';
					break;
				case 'bottom-right':
					output = 'bottom: 0; right: 0;';
					break;
				case 'center':
				default:
					output = 'top: 50%; left: 50%; transform: translate(-50%, -50%);';
					break;
			}
			return chunk.write(output);
		};

		dust.helpers = Object.assign(dust.helpers, cloudinaryHelpers, imageHelpers);
		return dust;
	}
};
