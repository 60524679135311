const Backbone = require('backbone');
var ProductDropModel = Backbone.Model.extend({
	api: 'local',
	idAttribute: 'uniqueId',
	url: function() {
		if (this.get('fetchByUniqueId')) {
			return `/app/api/compare?uniqueIds=${this.get('uniqueId')}`;
		} else {
			return '/app/api/products/:product';
		}
	},

	defaults: {
		uniqueId: undefined,
		productCompositeId: undefined,
		productId: undefined,
		title: undefined,
		imageLink: undefined,
		productLink: undefined
	}
});

module.exports = ProductDropModel;
