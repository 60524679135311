import { COMMUNICATION_METHOD } from 'lib/enums';
import Backbone from 'backbone';
import UniversalLocationView from 'app/views/store/common/universal_location';
import ContactCenterModel from 'models/store/client/contact_center';
import 'bootstrap';
import urlUtility from 'build-utilities/lib/url';
import gtmHelper from 'lib/helpers/gtm_helper';

export const HeaderView = Backbone.View.extend({
	events: {
		'click #search-icon': 'showSearch',
		'click .header-navbar a[data-omniture-info], .site-nav a[data-omniture-info]': '_fireOmnitureNavClick',
		'click .header-icon-wrap .icon-heart': '_favoritesLogin',
		'click .js-account-dropdown': '_toggleAccountDropdown',
		'keyup .js-account-dropdown': '_toggleAccountEnter',
		'mouseenter .js-rep-hover': '_addRepHover',
		'mouseleave .js-rep-hover': '_removeRepHover',
		'mouseenter .js-call-center': '_checkPhoneHours',
		'mouseleave .js-call-center': '_checkPhoneHours',
		'click .js-call-center': '_checkPhoneHours',
		'click .js-onboarding-cancel': '_handleOnboardingCancelClick',
		'click .js-leave-onboarding': '_handleOnboardingLeaveClick',
		'click .js-stay-onboarding': '_handleOnboardingStayClick'
	},

	initialize: function() {
		// project notifications dropdown in header
		if (window.BCOM.theme.isBuild) {
			const initialPostalCode = this.$el.find('.js-location-input').val() || '';
			this.universalLocationView = new UniversalLocationView({
				zip: initialPostalCode
			});

			if (this.$('.js-project-notifications').length) {
				import('app/views/store/common/project_notifications');
			}
			// part of feature: RESPONSIVE_HEADER - touchstart outside of element should close dropdown
			$('body').on('touchstart', (e) => {
				if (!$(e.target).parents('.js-toggle-account-dropdown').length) {
					this._closeAccountDropdown();
				}
			});
		}

		if (window.BCOM.theme.isNiche && window.BCOM.device.isDesktop) {
			$('[data-toggle="tooltip"]').tooltip();
		}

		if (window.BCOM.theme.isLivingDirect) {
			import('app/autocomplete');
		}

		this.$favoritesLoginModal = $('#favoritesLoginModal');

		this.$phoneHours = this.$el.find('.js-phone-hours');
		this.$accountDropdownToggle = this.$el.find('.js-toggle-account-dropdown');
		this.$repHoverToggle = this.$el.find('.js-toggle-rep-hover');
		this.$cancelOnboardingModal = this.$el.find('.js-modal-onboarding-cancel-confirm');

		this.$jsHeaderSearch = this.$el.find('.js-header-search');

		this.listenTo(Backbone, 'search:expanded', this._onSearchExpanded);
		this.listenTo(Backbone, 'search:narrowed', this._onSearchNarrowed);
		this.contactCenterModel = {};
		this.contactCenterModel.phone = new ContactCenterModel({
			communicationMethod: COMMUNICATION_METHOD.PHONE,
			isCheckout: window.dataLayer.isCheckout
		});
		this._attachEvents();
	},
	_checkPhoneHours: function(e) {
		if (e.type === 'mouseleave' && !this.$phoneHours.hasClass('dn')) {
			this._removePhoneHover();
			return;
		}
		const originalEventIsClick = e.type === 'click';
		e.preventDefault();
		this.contactCenterModel.phone
			.fetch()
			.then(() => {
				const isOpen = this.contactCenterModel.phone.get('isOpen');
				if (window.BCOM.device.isDesktop) {
					this._handleDesktopPhoneClick(isOpen, originalEventIsClick);
				} else {
					this._handleMobileTabletPhoneClick(isOpen);
				}
			})
			.catch(window.clientErrorHandler);
	},
	_attachEvents: function() {
		// This need to be global: we are handling the click event on the body element to close the my account dropdown
		$('body').on('click.accountDropdown', this._onBodyClick.bind(this));
		this.$favoritesLoginModal.on('shown.bs.modal', this._onFavoritesModalShown.bind(this));
	},
	_handleDesktopPhoneClick: function(isOpen, originalEventIsClick) {
		if (!isOpen) {
			this._addPhoneHover();
		} else if (originalEventIsClick) {
			window.location.href = `tel://${window.dataLayer.phoneNumber}`;
		}
	},
	_handleMobileTabletPhoneClick: function(isOpen) {
		if (isOpen) {
			$('.callCenterDropdown').addClass('dn');
			$('#callCenterHours').modal('hide');
			window.location.href = `tel://${window.dataLayer.phoneNumber}`;
		} else {
			$('.callCenterDropdown').removeClass('dn');
			$('#callCenterHours').modal('show');
		}
	},
	_onBodyClick: function(e) {
		if (!$(e.target).closest('.js-account-dropdown').length) {
			this._closeAccountDropdown();
		}
	},

	_addPhoneHover: function() {
		this.$phoneHours.removeClass('dn');
	},

	_removePhoneHover: function() {
		this.$phoneHours.addClass('dn');
	},
	_toggleAccountEnter: function(event) {
		if (event.keyCode === 13) {
			this._toggleAccountDropdown();
		}
	},

	_toggleAccountDropdown: function() {
		this.$accountDropdownToggle.hasClass('dn') ? this._openAccountDropdown() : this._closeAccountDropdown();
	},

	_closeAccountDropdown: function() {
		this.$accountDropdownToggle.addClass('dn');
	},

	_openAccountDropdown: function() {
		this.$accountDropdownToggle.removeClass('dn');
	},

	_addRepHover: function() {
		this.$repHoverToggle.removeClass('dn');
	},

	_removeRepHover: function() {
		this.$repHoverToggle.addClass('dn');
	},

	showSearch: function(e) {
		e.preventDefault();
		Backbone.trigger('pubsub:toggleVisibility');
	},

	_favoritesLogin: function(e) {
		e.preventDefault();
		if (window.dataLayer.isLoggedIn) {
			window.location.assign('/favorites/myfavorites');
		} else {
			this.$favoritesLoginModal.modal({ show: true });
		}
	},

	_onFavoritesModalShown: function() {
		// These need to be global
		$('.login-button').attr('href', '/account/login?returnto=/favorites/myfavorites');
	},

	_onSearchExpanded: function() {
		this.$jsHeaderSearch.addClass('wide');
	},

	_onSearchNarrowed: function() {
		this.$jsHeaderSearch.removeClass('wide');
	},

	_fireOmnitureNavClick: function(e) {
		var data = {},
			$target = $(e.currentTarget),
			trackString = $target.data('omnitureInfo').replace(/\s+/g, '-');
		e.preventDefault();
		data.evars = {
			eVar2: trackString
		};
		data.linkName = trackString;
		data.redirect = $target.prop('href');

		gtmHelper.fireEvent({
			event: 'internal-campaign-click',
			linkName: trackString
		});

		Backbone.trigger('track:link', data);
	},

	_handleOnboardingCancelClick: function() {
		this.$cancelOnboardingModal.modal('show');
	},

	_handleOnboardingStayClick: function() {
		this.$cancelOnboardingModal.modal('hide');
	},

	_handleOnboardingLeaveClick() {
		const params = urlUtility.parseUrl(window.location.href).query;
		const projectId = params['project-id'];
		if (projectId) {
			window.location.assign(`/account/projects/${projectId}/feed`);
			return;
		}
		window.location.assign('/showroom');
	}
});
