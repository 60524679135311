export const searchTracking = {
	/**
	 * @param {string} eventId
	 * @return {void}
	 */
	setProductDropVariables: function(eventId = null) {
		const { dataLayer } = window;
		const { pagination = {} } = dataLayer;

		let searchedTerm;
		if (dataLayer.search || (dataLayer.eVar && dataLayer.eVar.eVar1)) {
			searchedTerm = 'search';
		}

		if (eventId) {
			window.dataLayer.events.push(eventId);
		}

		const searchTerm = searchedTerm || '~',
			categoryId = dataLayer.categoryId || '~',
			activePage = pagination.activePage || '~',
			resultsPerPage = pagination.pageSize || '~',
			viewType = dataLayer.viewType || '~',
			sortBy = dataLayer.sortBy.selectedName || '~';
		window.s.eVar82 = `s!${searchTerm}!c${categoryId}!p${activePage}!r${resultsPerPage}!v${viewType}!sb${sortBy}`;
	}
};
