import dustjs from 'dustjs';
import $ from 'jquery';
import Backbone from 'backbone';
import { NavView } from './common/nav';
import NotificationsView from './common/notifications';
import { HeaderView } from './common/header';
import FooterView from 'views/store/common/footer';
import FooterChatButtonView from 'views/store/common/footer_chat_button';
import LiveAgentView from 'views/store/common/live_agent';
import format from 'format';
import _globalModalDust from 'common/_global_modal.dust';
import lazyLoader from 'app/views/store/common/lazy_load';
import GBISAYT from 'app/views/store/common/gbi/sayt/gbi_sayt';
import { GBIRecentAndTrendingView } from 'app/views/store/common/gbi/gbi_recent_and_trending';
import { EmailSubscribeView } from './common/email_subscribe';
import { CompareWidgetView } from 'views/store/common/compare_widget';
import { RecAddToCartView } from 'views/store/common/rec_add_to_cart';
import productTrackingHelper from '../../../lib/helpers/product_tracking_helper';
import { cookies } from 'lib/enums';
import { LIVE_AGENT_EXCLUSION_LIST } from 'config/third_party';
import _compareToggle from 'common/compare/_compare_toggle.dust';
import _dyCurrentProductTemplate from 'common/_dynamic_yield_current_product.dust';
import _quickShipLabel from 'store/global/common/product_tile/_shipping.dust';
import { dynamicYield } from '../../dynamic_yield_utils';
import { UsabillaInlineCampaigns } from '../store/common/usabilla/_usabilla_inline_campaigns';
import jsCookie from 'js-cookie';

// globals
import 'bootstrap';
import 'util';
import 'formValidator';
import 'views/store/common/videos';

const TrackingHelper = require('./tracking_helper');

window.BCOM.modalShown = new Promise((resolve) => {
	$(document).on('shown.bs.modal', resolve);
});
// add and init common views to the page
window.BCOM.notifications = new NotificationsView({
	autoHide: !window.dataLayer.isCheckout
});
/**
 * Create the body view for handling body level view stuff.
 * This will most likely be really thin, basically just emitting messages
 * for other views to consume.
 */
export const BodyView = Backbone.View.extend({
	el: 'body',
	childViews: {},
	events: {
		'click [data-beta]': 'setBetaCookie',
		click: 'emitClick',
		'click #header-phone, #header-fav, #header-cart': 'trackClick',
		'click .js-show-free-shipping': 'showFreeShipping',
		'click .js-open-content-modal': 'openContentModal',
		'show.bs.collapse .panel-collapse.has-icon': 'rotateArrow',
		'hidden.bs.collapse .panel-collapse.has-icon': 'rotateArrow',
		'click .js-dd-set-text a': 'setDropdownDisplayText',
		'click [rel*=popup]': '_popUpWindowClick',
		'click .js-close-covidbanner': 'setCovidCookie'
	},
	initialize: function(options = {}) {
		window.BCOM.data.shouldAjaxDatalayer = window.BCOM.data.shouldAjaxDatalayer || false;
		this.baseView = options.baseView;
		// Attributes
		this.$cartQuantity = $('.js-cart-quantity');
		this.$dpsCartButton = $('.js-dps-cart-button');
		this.$cartEmpty = $('.js-cart-empty');

		// Set-up Listeners
		this.listenTo(Backbone, 'loading:start', this.showLoadingScreen, this);
		this.listenTo(Backbone, 'loading:stop', this.hideLoadingScreen, this);
		this.listenTo(Backbone, 'cart:quantityChanged', this.updateQuantity, this);
		this.listenTo(Backbone, 'cart:totalsChanged', this.updateCartGrandTotal, this);
		this.listenTo(Backbone, 'projectnotifications:updatecount', this._updateNotificationUnreadCount, this);

		// Load DataLayer
		this.loadDataLayer.call(this);

		// Sub-Initializers
		this.initChildViews();
		if (window.BCOM.device.isiOS) {
			this.initIOSModalHack();
		}
		if (window.BCOM.theme.isLightingShowplace) {
			this.initLightingShowplace();
		}
		this.initFormCounter();
		this.initIFrameResizer();

		// Initialize Preview Scroll Keeper for Construct
		this._initConstructPreviewScrollKeeper();
		this._initConstructInlineEditLink();

		this._initLazyLoader();

		this._initPopover();

		this._initSearchBox();
		this._handleCheckoutSessionTimeout();
	},
	_initPopover() {
		// init contact popovers
		const $contactPurchase = $('.contact-purchase');
		if ($contactPurchase.length) {
			// add custom data attribute to sanitizer whitelist, see https://getbootstrap.com/docs/4.3/getting-started/javascript/#sanitizer
			const defaultWhiteList = $.fn.popover.Constructor.DEFAULTS.whiteList;
			if (defaultWhiteList) {
				defaultWhiteList.a.push('data-location');
			}
			$contactPurchase.find('[data-toggle="popover"]').popover();
		}
	},
	_initSearchBox() {
		if (window.dataLayer.enableSearchAsYouType) {
			this.gbiSAYT = new GBISAYT({
				el: '#header .js-gbi-sayt'
			});
			this.gbiRecentAndTrendingView = new GBIRecentAndTrendingView({
				el: '#header .js-gbi-sayt',
				recentSearchTerms: window.dataLayer.recentSearchTerms
			});
		} else {
			$('#header .js-gbi-sayt').on('submit', this._searchBoxSubmit.bind(this));
		}
	},
	_searchBoxSubmit(e) {
		const $currentSearchForm = $(e.target).closest('form');
		const currentSearchValue = String($currentSearchForm.find('.js-site-search-input').val()).trim();
		if (!currentSearchValue) {
			e.preventDefault();
			return;
		}
		Backbone.trigger('search:submit omniture:searchQuery', {
			query: currentSearchValue
		});
		window.BCOM.util.trackKeywordSearch(currentSearchValue);
	},
	_initLazyLoader() {
		lazyLoader.load();
	},
	_initFergusonSurvey() {
		const { usabilla_live } = window;
		if (usabilla_live && typeof usabilla_live === 'function') {
			UsabillaInlineCampaigns.openFergusonSurveyCampaign();
		}
	},
	initIOSModalHack: function() {
		// This is needed for iOS Safari since overflow:hidden doesn't prevent
		// the body from being scrolled when modal is open and position:fixed
		// causing page to scroll to top
		let scrollPos = 0;

		$('.modal')
			.on('show.bs.modal', function() {
				scrollPos = $('body').scrollTop();
				$('body').css({
					position: 'fixed',
					top: -scrollPos
				});
			})
			.on('hide.bs.modal', function() {
				$('body')
					.css({
						position: '',
						top: ''
					})
					.scrollTop(scrollPos);
			});
	},
	initChildViews: function() {
		if (window.location.pathname.substr(0, 8) === '/account') {
			const EcrmNavView = require('views/store/pages/account/ecrm/nav');
			this.baseView.ecrmNavView = new EcrmNavView();
		}

		this.childViews.headerView = new HeaderView({
			el: '#header'
		});

		this.childViews.navView = new NavView();

		this.childViews.emailSubscribeView = new EmailSubscribeView({
			el: '.js-email-subscribe-content'
		});

		this.childViews.footerView = new FooterView({
			el: '.js-footer'
		});

		this.childViews.footerChatButtonView = new FooterChatButtonView({
			el: '#persistent-chat-prompt-minimizable'
		});

		if (!window.dataLayer.siteId || !LIVE_AGENT_EXCLUSION_LIST.SITE_ID_LIST.includes(window.dataLayer.siteId)) {
			// requires `el: 'body'` to detect links anywhere on the page to open the chat
			this.childViews.liveAgentView = new LiveAgentView({
				el: 'body'
			});
		}
		// A/B test
		if ($('.js-modal-email-subscribe-splash').length) {
			this.childViews.emailSubscribeViewModal = new EmailSubscribeView({
				el: '.js-modal-email-subscribe-splash'
			});
		}

		if ($('.email-subscribe-splash-banner-b').length) {
			this.childViews.emailSubscribeViewModal = new EmailSubscribeView({
				el: '.email-subscribe-splash-banner-b'
			});
		}

		if (
			!window.dataLayer.page.includes('checkout:') &&
			window.dataLayer.isCheckout !== true &&
			!(window.dataLayer.model && window.dataLayer.model.excludeCompare)
		) {
			this.baseView.compareWidgetView = new CompareWidgetView();
		}
	},

	_resizeIframes: function(iframeResizer, iframesToResize) {
		if (iframeResizer) {
			Array.prototype.forEach.call(iframesToResize, function(currentIframe) {
				iframeResizer(
					{
						enablePublicMethods: true
					},
					currentIframe
				);
			});
		}
	},

	initIFrameResizer: function() {
		const iframesToResize = document.querySelectorAll('.iframe-resize');
		// This should be eventually refactored to not be in here.
		if (iframesToResize.length) {
			import(/* webpackChunkName: "iframeResizer" */ 'iframe-resizer').then(({ iframeResizer }) => {
				this._resizeIframes(iframeResizer, iframesToResize);
			});
		}
	},

	/**
	 * Initializes functionality to keep scroll position on postbacks
	 * when previewing in Construct.
	 */
	_initConstructPreviewScrollKeeper: function() {
		let previewContentItemId = window.BCOM.util.getQueryStringParameter('contentitemid');

		// If previewContentItemId is defined we are previewing the content in Construct
		if (previewContentItemId) {
			// Subcribe to scroll and store store position on scroll
			$(window).on('scroll', function() {
				window.sessionStorage.scrollTop = `${previewContentItemId}|${$(this).scrollTop()}`;
			});

			// Scroll to last save position
			if (window.sessionStorage.scrollTop && window.sessionStorage.scrollTop.indexOf(previewContentItemId) !== -1) {
				$(window).scrollTop(window.sessionStorage.scrollTop.split('|')[1]);
			} else {
				window.sessionStorage.scrollTop = null;
			}
		}
	},
	_initConstructInlineEditLink: function() {
		if (window.dataLayer.isLocalUser && document.getElementById('toggle-construct-content')) {
			import('views/store/common/construct/construct');
		}
	},
	initLightingShowplace: function() {
		import(/* webpackChunkName: "Masonry" */ 'masonry-layout').then((Masonry) => {
			for (let i = 0; i < 12; i++) {
				if ($(`.grid-${i}`).length > 0) {
					// eslint-disable-next-line no-new
					new Masonry(`.grid-${i}`, {
						itemSelector: `.grid-item-${i}`,
						columnWidth: '.box-item',
						gutter: 10,
						fitWidth: true
					});
				}
			}
			// Adjust the css on the dropdown menu
			$('.dropdown .dropdown-menu')
				.css('width', 'auto')
				.css('min-width', 'auto');

			// To adjust the dropdown menu under the menu within the container
			$.each($('.dropdown-menu'), function(index, dropdownMenu) {
				let $dropdownMenu = $(dropdownMenu);
				let $navBar = $('.navbar');
				let navBarleftOffset = $navBar.offset().left;
				let dropdownRelativeWidth = $dropdownMenu.offset().left - navBarleftOffset + $dropdownMenu.width();
				if (index !== 0 && index !== $('.dropdown-menu').length - 1 && dropdownRelativeWidth > $navBar.width()) {
					let diff = $navBar.width() - dropdownRelativeWidth;
					$dropdownMenu.css('left', diff - 28);
				}
			});
		});
	},
	initFormCounter: function() {
		$('.form-counter').each(function(index, element) {
			let $element = $(element);
			let maxCount = Number($element.attr('maxlength'));
			if (maxCount && maxCount > 1) {
				$element.after(`<span class="char-counter">0/${maxCount}</span>`);
			}

			$element.on('keyup', function(ev) {
				let currentVal = String($(ev.target).val());
				$element
					.parent()
					.find('.char-counter')
					.replaceWith(`<span class="char-counter">${currentVal.length}/${maxCount}</span>`);
			});
		});
	},
	rotateArrow: function(e) {
		if (window.BCOM.device.isMobile && window.dataLayer.product) {
			$('.js-accordion-icon', e.currentTarget.parentNode).toggleClass('ico-chevron-down ico-chevron-up');
		} else {
			$('.js-accordion-icon', e.currentTarget.parentNode).toggleClass('icons-arrow-grey-xsmall-down icons-arrow-grey-xsmall-up');
		}
	},
	setDropdownDisplayText: function(e) {
		const $target = $(e.currentTarget),
			text = $target.data('text') || $target.text();
		$target
			.closest('.js-dd-set-text')
			.find('.js-dd-text-target')
			.text(text);
	},
	showFreeShipping: function(e) {
		let self = this;
		e.preventDefault();
		self.$('.js-modal-free-shipping').modal();
		Backbone.trigger('omniture:clicktracking', 'tabletnav_freeshipping');
	},
	trackClick: function(e) {
		Backbone.trigger('omniture:clicktracking', `mobilenav_${e.currentTarget.id}`);
	},
	emitClick: function(event) {
		Backbone.trigger('body:click', event);
	},
	hideLoadingScreen: function() {
		this.$el.removeClass('loading');
	},
	showLoadingScreen: function() {
		this.$el.addClass('loading');
	},
	updateCartGrandTotal: function(cartData) {
		let price = cartData && cartData.grandTotal ? format.currency(cartData.grandTotal, true) : '$0.00';
		$('.js-cart-grandtotal').text(price || '$0.00');
		const cartSubTotal = cartData.subTotal ? cartData.subTotal.toString() : '0';
		const cartItems = cartData.items || [];
		productTrackingHelper.setSessionStorage(cartSubTotal, null, null, JSON.stringify(cartItems));
	},
	updateQuantity: function(quantity) {
		let $plural = $('.js-cart-item-plural');

		if ($plural.length) {
			$plural.toggleClass('hidden', quantity === 1);
		}

		if (!quantity) {
			quantity = 0;
		}
		if (quantity === 0) {
			productTrackingHelper.setSessionStorage('0', 'false', 'false', 'false');
		}

		this.$cartQuantity.text(quantity);

		if (!window.BCOM.theme.isBuild) {
			if (quantity > 0) {
				this.$cartEmpty.addClass('hidden');
				this.$dpsCartButton.addClass('gradient');
			} else {
				this.$cartEmpty.removeClass('hidden');
				this.$dpsCartButton.removeClass('gradient');
			}
		}
		if (quantity > 99) {
			this.$cartQuantity.text('99+');
		}
	},
	openContentModal: function(event) {
		let self = this,
			id = $(event.currentTarget).data('id');

		if (id) {
			$.ajax({
				url: `/app/api/content/shared?id=${id}`,
				success: function(data) {
					dustjs.render(
						_globalModalDust,
						{
							modalHeader: '',
							modalTitle: (data.metaData && data.metaData.title) || '',
							modalBody: data.top,
							modalFooter: data.bottom
						},
						function(err, out) {
							self.$el.append(out);
							self.$('.js-modal-global').modal('show');
							$('.js-modal-global').on('hidden.bs.modal', function(event) {
								$(event.currentTarget).remove();
							});
						}
					);
				}
			});
		} else {
			console.error('openContentModal: missing data-id attribute');
		}
	},
	setBetaCookie: function(event) {
		let value = $(event.currentTarget).data('beta');
		// default to 2 hours
		let expires = value === '' ? -1 : 2,
			date = new Date();
		date.setTime(date.getTime() + expires * 3600000);
		document.cookie = `BETA=${value}; expires=${date.toUTCString()}; path=/`;
		window.location.reload();
		return false;
	},
	setCovidCookie: function() {
		jsCookie.set(cookies.COVID_19_HEADER_BANNER, true, {
			path: '/',
			expires: 999999
		});

		this.$('.js-covid19-banner').addClass('dn');
		return false;
	},
	_updateNotificationUnreadCount: function(count) {
		this.$('.js-notification-count')
			.text(count || '')
			.parent('.notification-icon')
			.toggleClass('is-active', Boolean(count));
	},
	_processNewDataLayer: function() {
		const { cartQuantity, customer, customerHasProjects, loginComplete } = window.dataLayer;
		if (cartQuantity !== 0) {
			this.updateQuantity(cartQuantity);
		}

		if (customer && customer.userDBID && window.BCOM.theme.isBuild && customerHasProjects) {
			$.ajax(`${window.BCOM.apiPathPrefix}/account/notificationcount`).then((data) => {
				Backbone.trigger('projectnotifications:updatecount', data.unreadCount);
			});
		}

		TrackingHelper.initializeAnalyticsData(() => {
			this.loadThirdParty();
		});

		if (loginComplete) {
			window.BCOM.util.trackLoginComplete({ email: window.dataLayer.email, customerId: customer && customer.userDBID });
		}
	},
	loadThirdParty: function() {
		if (!window.BCOM.util.getQueryStringParameter('disableThirdParty') && !window.dataLayer.isBot) {
			require('omniture');
			require('views/store/common/omniture/omniture_helper');
			import('common/base_thirdparty')
				.then(({ setupThirdParty }) => {
					setupThirdParty();
					this._initFergusonSurvey();
				})
				.catch(window.clientErrorHandler);

			window.BCOM.tracking.isOmnitureLoaded = true;
			$(window).trigger('omnitureLoaded');
		}
	},
	loadDataLayer: function() {
		this._processNewDataLayer.call(this);
	},

	_popUpWindowClick: function(e) {
		if (window.BCOM.device.isDesktop) {
			e.preventDefault();
			let $el = $(e.currentTarget),
				windowUrl = $el.data('windowurl') || $el.attr('href'),
				windowName = $el.data('windowname') || 'LiveChat',
				windowParams = $el.data('windowparams') || 'width=650,height=500';
			window.open(windowUrl, windowName, windowParams);
		}
	},

	recsPlacementLoaded: function(placement) {
		const features = $(placement).data('features');
		const featureMethods = {
			appliance: this._recsAppliancePackagePlacementLoaded.bind(this, placement),
			compare: this._renderCompareTogglesInRecsPlacement.bind(this, placement),
			addToCart: this._initRecAddToCartViews.bind(this, placement),
			dynamicRecsCompare: this._injectDynamicCompareItems.bind(this, placement),
			quickShip: this._injectQuickShipIcon.bind(this, placement),
			numberOfReviews: this._injectNumberOfReviews.bind(this, placement)
		};

		if (features) {
			const featureList = features.split(',');
			featureList.forEach((feature) => {
				const method = featureMethods[feature];
				method && method();
			});
		}
	},
	_renderCompareTogglesInRecsPlacement: function(recsPlacement) {
		const $recsWrapper = $(recsPlacement).closest('.js-recs-placement');
		const recItems = $recsWrapper.find('.rec-item');
		if (recItems.length) {
			recItems.each((i, el) => {
				const $recItem = $(el);
				const $recItemWrapper = $recItem.closest('.rec-item-wrapper');
				const uniqueId = $recItem.data('product-id');

				dustjs.render(_compareToggle, { uniqueId, btnClass: 'pa0 bn f6' }, (err, out) => {
					$recItemWrapper.find('.compare-toggle-container').append(out);
				});
			});

			this.baseView.compareWidgetView.initButtons(recsPlacement);
			$recsWrapper.removeClass('dn');
		}
	},
	_recsAppliancePackagePlacementLoaded: function(placement) {
		this.editMatchingProductsText(placement);
		if (!window.BCOM.device.isMobile) {
			const currentProductData = dynamicYield.getDynamicYieldRenderData();
			if (!window.BCOM.device.isMobile && currentProductData) {
				dustjs.render(_dyCurrentProductTemplate, currentProductData, (err, html) => {
					const $recsContainer = $(placement).find('.recs-container');
					$recsContainer.prepend(html);
				});
			}
		}
	},
	editMatchingProductsText(placement) {
		const $matchingProductTypes = $(placement).find('.rec-matching-type');
		$matchingProductTypes.each((i, typeName) => {
			const prodType = $(typeName).text();
			if (prodType[prodType.length - 1] === 's') {
				$(typeName).text(prodType.slice(0, -1));
			}
		});
	},
	_initRecAddToCartViews: function(recsPlacement) {
		const recItems = $(recsPlacement).find('.rec-item');
		this.baseView.recAddToCartViews = [];

		if (recItems.length) {
			recItems.each((i, el) => {
				const parentDiv = $(el).closest('.rec-item-wrapper');
				const uniqueId = $(el).data('product-id');
				const isConfigurable = $(el).data('is-configurable');
				const productUrl = $(el).attr('href');

				this.baseView.recAddToCartViews.push(new RecAddToCartView({ el: parentDiv, uniqueId, isConfigurable, productUrl }));
			});

			// all views initialized. trigger to remove loading overlay
			Backbone.trigger('dy-add-to-cart:initialized');
		}
	},
	_injectDynamicCompareItems: function(recsPlacement) {
		Backbone.trigger('dynamicRecsCompare:loaded', recsPlacement);
	},
	_injectQuickShipIcon: function(recsPlacement) {
		const $recsPlacement = $(recsPlacement);
		const recItems = $(recsPlacement).find('.rec-item');
		if (recItems.length) {
			recItems.each((i, el) => {
				const $recItem = $(el);
				dustjs.render(
					_quickShipLabel,
					{ isQuickShipEnabled: window.dataLayer.isQuickShipEnabled, quickShipMessage: 'Free 1- or 2-Day Shipping' },
					(err, out) => {
						$recItem.find('.shipping-label-container').append(out);
						$recsPlacement.removeClass('dn');
					}
				);
			});
		}
	},
	_injectNumberOfReviews: function(recsPlacement) {
		const $recsPlacement = $(recsPlacement);
		const recItems = $(recsPlacement).find('.rec-item');

		if (recItems.length) {
			recItems.each((i, el) => {
				const $reviewCountContainer = $(el).find('.review-total');
				const numReviews = Number($reviewCountContainer.data('reviews'));
				if (numReviews > 0) {
					$reviewCountContainer.text(`(${numReviews})`);
				}
			});
			$recsPlacement.removeClass('dn');
		}
	},
	_handleCheckoutSessionTimeout() {
		if (!window.dataLayer.isCheckout) {
			return;
		}

		const timeout = window.dataLayer.sessionTimeout * 60 * 1000;
		window.setTimeout(() => {
			window.location.reload();
		}, timeout);
	}
});
