import Backbone from 'backbone';
import 'jquery.scrollto';
import 'jquery-hoverintent';
import gtmHelper from 'lib/helpers/gtm_helper';

export const NavView = Backbone.View.extend({
	el: '#site-nav',
	events: {
		'click strong': '_toggleSubCategory',
		'click .js-mobile-subcategory': '_toggleSubCategory',
		'click .tabs li': '_handleLeftHandNavTabs',
		'click .js-nav-menu': '_openNav',
		'click .js-close-button': '_closeNav',
		'click .nav-overlay': '_closeNav',
		'click .close-button-column': '_closeNav',
		'touchmove .nav-overlay': '_stopScroll',
		'touch .js-nav-container .tabs': '_stopScroll',
		'touchmove .js-nav-container .tabs': '_stopScroll',
		'touchmove .js-nav-container ul.active': '_preventScrollBubble',
		'click .js-feedback': '_launchFeedback',
		'click .js-top-category': '_toggleTabletMenu',
		'click .js-expand': '_expandSubNav',
		'click .js-nav-collapse': '_collapseSubNav',
		'click .js-toggle-menu, .js-close-menu': '_toggleNicheAndLSPMenu',
		'mouseenter .sub_wrap': '_preventMouseenterBubble',
		'touchstart .js-touch': '_handleTouchNav'
	},
	initialize: function() {
		this.$navList = this.$el.find('.js-nav-item');
		this.$ui = {
			category: this.$navList,
			back: this.$el.find('.js-nav-collapse'),
			shopAllTitle: this.$el.find('h3').eq(0)
		};
		this.$navContainer = this.$('.js-nav-container');
		this.$body = $('body');
		this.$html = $('html');
		this.$navOverlay = $('.nav-overlay');
		this.$navMenus = this.$el.find('.nav ul');
		this.$nicheNavMenu = this.$el.find('.js-nav-links');
		this.$navTabs = this.$el.find('.tabs');
		this.$shopTab = this.$el.find('.shop-tab');
		this.$shopTabPad = this.$shopTab.find('.pad');
		this.$nav = this.$('.nav');
		this.$header = $('#header');
		this.$headerNavbar = this.$header.find('.header-navbar');
		this.$headerMenu = this.$headerNavbar.find('.container');
		this.$categoryNavs = this.$headerNavbar.find('.mega');
		this.$navButtons = this.$headerNavbar.find('.table li');
		/* #region part of feature: RESPONSIVE_HEADER  */
		this.$targetCategories = this.$headerNavbar.find('.header-menu-style').not('#responsiveShopAll');
		this.$responsiveHeaderMenu = this.$headerNavbar.find('#responsiveHeaderMenu');
		/* #endregion */
		this.isTablet = window.BCOM.device.isTablet;
		this.isMobile = window.BCOM.device.isMobile;
		this.state = {
			isMenuOpen: false
		};
		this.expandedClass = 'expanded';
		this._setupHoverIntent();
		/* #region  part of feature: RESPONSIVE_HEADER  - touchstart outside of element should close nav, on navOverlay should close nav */
		this.$body.on('touchstart', (e) => {
			if (!$(e.target).parents('#site-nav').length) {
				this._resetNav(e);
			}
		});
		this.$navOverlay.on('touchstart', (e) => {
			this._resetNav(e);
		});
		/* #endregion */
	},
	_fadeInBackgroundOverlay: function() {
		this.$navOverlay.addClass('active');
	},

	_fadeOutBackgroundOverlay: function() {
		this.$navOverlay.removeClass('active');
	},
	_activateNav: function() {
		this._fadeInBackgroundOverlay.call(this);
		this.$headerNavbar.addClass('subnav-open');
	},
	_deactivateNav: function() {
		this._fadeOutBackgroundOverlay.call(this);
		this.$headerNavbar.removeClass('subnav-open');
	},
	_getVisibleCategoryNav: function() {
		return this.$categoryNavs.filter((index, currentNav) => {
			return $(currentNav).hasClass('active');
		});
	},
	_getCategoryNavByID: function(categoryID) {
		return this.$categoryNavs.filter((index, currentNav) => {
			return $(currentNav).data('id') === categoryID;
		});
	},
	_hideVisibleCategoryNav: function() {
		const $visibleCategoryNav = this._getVisibleCategoryNav();
		$visibleCategoryNav.closest('li').removeClass('active');
		$visibleCategoryNav.removeClass('active');
	},
	_hideNav: function(e) {
		this._hideVisibleCategoryNav.call(this, e);
		this._deactivateNav.call(this);
	},
	_getFirstCategoryNav: function() {
		return this.$categoryNavs.first();
	},
	_showNavItem: function(e) {
		const $currentNavTab = $(e.currentTarget),
			currentCategoryID = $currentNavTab.data('category-id');

		if (currentCategoryID) {
			const $navToShow = this._getCategoryNavByID.call(this, currentCategoryID);

			this._hideVisibleCategoryNav.call(this);
			$navToShow.addClass('active');
			$navToShow.closest('li').addClass('active');
		}
	},
	_setupHoverIntent: function() {
		/**
		 * part of feature: RESPONSIVE_HEADER - bind the hover to only the categories that need it and not the original headerMenu.
		 * The reason for this is the responsive header no longer wants a hover menu for shop all departments but shopAll's li element must
		 * remain in the ul for proper sizing.
		 */
		if (this.$responsiveHeaderMenu.length) {
			this.$targetCategories.hoverIntent({
				over: this._activateNav.bind(this),
				out: this._hideNav.bind(this)
			});
		} else {
			this.$headerMenu.hoverIntent({
				over: this._activateNav.bind(this),
				out: this._hideNav.bind(this)
			});
		}

		this.$navButtons.hoverIntent({
			over: this._showNavItem.bind(this),
			out: $.noop
		});
	},
	_collapseSubNav: function(event) {
		event.preventDefault();
		$(event.currentTarget)
			.closest('.js-nav-item')
			.removeClass(this.expandedClass)
			.find('ul')
			.removeClass(this.expandedClass);
		this.$ui.shopAllTitle.text('All Categories');
		this.$ui.back.hide();
	},
	_expandSubNav: function(event) {
		event.preventDefault();
		event.stopPropagation();
		const expanded = 'expanded',
			$subNav = $(event.currentTarget),
			$li = $subNav.closest('li'),
			$ul = $li.closest('ul'),
			index = $ul.find('li').index($li),
			$uls = $li.closest('.js-nav-item').find('ul');
		$li.closest('.js-nav-item').addClass(expanded);
		$uls.eq(index + 1).addClass(expanded);
		this.$ui.shopAllTitle.text($subNav.text());
		this.$ui.back.show();
	},
	_toggleTabletMenu: function(event) {
		event.preventDefault();
		if (this.isTablet) {
			this.$navList.toggleClass('active is-active');
			this.state.isMenuOpen = !this.state.isMenuOpen;
		}
	},
	_stopScroll: function(e) {
		e.preventDefault();
		e.stopPropagation();
		e.stopImmediatePropagation();
		return false;
	},

	_preventScrollBubble: function(e) {
		e.stopPropagation();
		e.stopImmediatePropagation();
	},

	_toggleSubCategory: function(e) {
		const $currentTarget = $(e.target),
			$parentLI = $currentTarget.closest('li'),
			categoryNavHeight = this.$shopTab.height(),
			categoryName = $parentLI.data('categoryName'),
			isActive = $parentLI.hasClass('active');

		if (isActive) {
			$parentLI.closest('ul').scrollTop(0);

			$parentLI.find('ul').slideUp(() => {
				$parentLI.removeClass('active');
			});
		} else {
			/*
			 * To enable the fancy expando-nav effect, we first need
			 * to force the contents to be tall enough to allow us to
			 * scroll down far enough.
			 */
			this.$shopTabPad.css({
				height: categoryNavHeight * 2,
				display: 'list-item'
			});

			$parentLI.closest('ul').scrollTop($parentLI.get(0).offsetTop);

			// Expand the current group
			$parentLI.find('ul').slideDown(() => {
				$parentLI.addClass('active');
			});

			/*
			 * This runs in tandem with the prior animation - it
			 * prevents strange scrollbar behavior.
			 */
			this.$shopTabPad.slideUp(
				function() {
					this.$shopTabPad.css({
						display: 'none'
					});
				}.bind(this)
			);
		}

		const trackString = `nav2:mainNav~shop:${categoryName}:menu ${isActive ? 'collapse' : 'expand'}`;

		gtmHelper.fireEvent({
			event: 'navigation-click',
			linkName: trackString
		});

		Backbone.trigger('track:link', {
			linkName: trackString
		});
	},

	_handleLeftHandNavTabs: function(e) {
		const $currentTarget = $(e.target),
			menuToOpen = $currentTarget.data('tab'),
			$navSiblingTriggers = this.$navTabs.find('li'),
			$navSiblingMenus = this.$navMenus.not('.tabs'),
			$navActiveMenu = $navSiblingMenus.filter((index, currentElement) => {
				return $(currentElement).data('tab') === menuToOpen;
			}),
			currentTab = $currentTarget.data('tab');

		if (currentTab !== 'home' && currentTab !== 'feedback') {
			$navSiblingTriggers.removeClass('active');
			$navSiblingMenus.removeClass('active');
			$navActiveMenu.addClass('active');
			$currentTarget.addClass('active');
		}

		if (currentTab === 'account') {
			this._populateAccountTab.call(this, window.dataLayer.customer);
		}
	},

	/**
	 * part of feature: RESPONSIVE_HEADER
	 * The responsive header on tablet needs to handle touch events to open the
	 * nav menu (previously opened with hover on desktop via _setupHoverIntent)
	 * @param {Event} e event
	 */
	_handleTouchNav: function(e) {
		e.preventDefault();
		this._activateNav();
		this._showNavItem(e);
	},

	/**
	 * part of feature: RESPONSIVE_HEADER
	 * When the overlay is touched we need to reset nav and overlay back to
	 * initial state
	 * @param {Event} e event
	 */
	_resetNav: function(e) {
		// Prevent default when overlay is showing to stop accidental navigation.
		if (this.$navOverlay.hasClass('active')) {
			e.preventDefault();
		}
		this._hideNav(e);
		const $activeNav = this.$el.find('.js-touch.active');
		$activeNav.removeClass('active');
		$activeNav.closest('div.active').removeClass('active');
	},

	_populateAccountTab: function(data) {
		const $accountTab = this.$nav.find('ul[data-tab="account"]'),
			$userProfileImage = $accountTab.find('.js-user-profile-avatar');

		if (!$accountTab.data('userDataLoaded')) {
			$userProfileImage.css('background-image', `url(${data.avatarImageUrl})`);
			$accountTab.data('userDataLoaded', true);
		}
	},

	_openNav: function(e) {
		e.stopPropagation();

		this.intViewportHeight = window.innerHeight;
		this.intViewportWidth = 0 - window.innerWidth;

		this.$body.addClass('nav-is-open').css({
			height: this.intViewportHeight,
			overflow: 'hidden'
		});

		this.$html.css({
			overflow: 'hidden'
		});

		this.$navContainer.removeClass('moveout').addClass('movein');
		this.$navOverlay.addClass('active');

		gtmHelper.fireEvent({
			event: 'navigation-click',
			linkName: 'nav2:open menu'
		});

		Backbone.trigger('track:link', {
			linkName: 'nav2:open menu'
		});

		$('.js-location-wrapper').addClass('dn');
	},

	_closeNav: function() {
		const navScrollTop = $(window).scrollTop(),
			navElementOffset = $('.js-nav-container .nav').offset().top,
			navDistance = Math.abs(navElementOffset - navScrollTop);

		this.$navOverlay.removeClass('active');

		if (!$('body').hasClass('nav-is-open')) {
			return;
		}

		this.$navTabs.css({
			top: navDistance
		});

		this.$body.removeClass('nav-is-open').css({
			height: '',
			overflow: 'visible'
		});

		$('#add-on-banner')
			.addClass('z-999')
			.css({ 'z-index': '' });

		this.$html.css({
			overflow: 'visible'
		});

		this.$navContainer.addClass('moveout').removeClass('movein');
		this.$navTabs.css({
			top: '0'
		});

		this.$navMenus
			.filter(function() {
				return !$(this).hasClass('tabs');
			})
			.find('li')
			.removeClass('active')
			.find('ul')
			.hide();
	},

	_toggleNicheAndLSPMenu: function(e) {
		if (e) {
			e.preventDefault();
		}

		this.$nicheNavMenu.toggleClass('open');
	},

	_launchFeedback: function() {
		window.usabilla_live('click');
	}
});
