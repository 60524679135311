/**
 *  Backbone Generic Class
 *  @author Patricio Ferreira <patricio.ferreira@build.com>
 **/
const _ = require('underscore');
/**
 * @type {typeof import('backbone/index') & {Generic, initialize}}
 * */
// @ts-ignore
const Backbone = require('backbone');

// @ts-ignore
const Generic = (Backbone.Generic = function() {
	// @ts-ignore
	this.initialize.apply(this, arguments);
});

_.extend(Generic.prototype, Backbone.Events, {
	/**
	 *  Initialize
	 *  @public
	 *  @method initialize
	 *  @return Backbone.Generic
	 **/
	initialize: function() {
		return this;
	},

	/**
	 *  Serializes Backbone.Generic instance into a plain javascript object
	 *  @public
	 *  @method toJSON
	 *  @return Object
	 **/
	toJSON: function() {
		return JSON.parse(JSON.stringify(_.omit(this, _.functions(this))));
	},

	/**
	 *  Default toString Implementation
	 *  @public
	 *  @method toString
	 *  @return String
	 **/
	toString: function() {
		return `[object ${this.constructor.NAME ? this.constructor.NAME : 'Backbone.Generic'}`;
	}
});

Generic['extend'] = Backbone.Model.extend;

module.exports = Generic;
