import Backbone from 'backbone';
import { SAYTSuggestionsModel, SuggestedProductModel } from 'build-data-models/product.interfaces';

export interface SAYTModelOptions {
	fetchProducts?: boolean;
	maxSuggestions?: number;
	minTermLength?: number;
}

export interface SAYTModelData {
	suggestions: string[];
	productDrops: SuggestedProductModel[];
}

export const SAYTModel = Backbone.Model.extend({
	initialize(options: SAYTModelOptions = {}) {
		this.maxSuggestions = options.maxSuggestions || 10;
		this.maxProducts = options.fetchProducts ? 6 : 0;
		this.minTermLength = options.minTermLength || 3;
		this.baseUrl = `${window.BCOM.apiPathPrefix}/search/sayt`;
		this.searchTerm = '';
		this.searchTermChanged = false;
		this.set('results', this._getEmptyResults());
	},

	setSearchTerm(term: string): void {
		const cleanedTerm = this._cleanSearchTerm(term);
		if (cleanedTerm !== this.searchTerm) {
			this.searchTerm = cleanedTerm;
			this.searchTermChanged = true;
		}
	},

	_cleanSearchTerm(term: string): string {
		let cleanedTerm = (term || '').trim().toLowerCase();
		if (/^bci\d+$/.test(cleanedTerm)) {
			cleanedTerm = cleanedTerm.replace('bci', '');
		}
		return cleanedTerm;
	},

	_getEmptyResults(): SAYTModelData {
		return { suggestions: [], productDrops: [] };
	},

	_getUrl(): string {
		return `${this.baseUrl}?term=${encodeURIComponent(this.searchTerm)}&maxSuggestions=${this.maxSuggestions}&maxProductDrops=${
			this.maxProducts
		}`;
	},

	fetch(): Promise<SAYTModelData> {
		// If a second request for the same search term comes in, don't start a new request.
		// Either return the stored results, or the in-progress request promise.
		if (!this.searchTermChanged) {
			if (this.requestPromise) {
				return this.requestPromise;
			}
			return Promise.resolve(this.get('results'));
		}

		// Cancel the current request to prevent out-of-order results.
		if (this.requestXhr) {
			this.requestXhr.abort();
			this.requestXhr = null;
		}

		this.searchTermChanged = false;

		if (!this.searchTerm || this.searchTerm.length < this.minTermLength) {
			this.set('results', this._getEmptyResults());
			return Promise.resolve(this.get('results'));
		}

		const xhr = new window.XMLHttpRequest();
		this.requestXhr = xhr;
		this.requestPromise = new Promise((resolve, reject) => {
			$.ajax({
				url: this._getUrl(),
				xhr: () => xhr
			})
				.done((data) => {
					this.set('results', data ?? this._getEmptyResults());
					resolve(this.get('results'));
				})
				.fail((jqXhr, status, error) => {
					this.set('results', this._getEmptyResults());
					reject(error);
				})
				.always(() => {
					this.requestPromise = null;
					this.requestXhr = null;
				});
		});
		return this.requestPromise;
	},

	fetchProducts(): Promise<SuggestedProductModel[]> {
		return this.fetch().then((results) => results.productDrops);
	},

	fetchProductsAndTerms(): Promise<SAYTSuggestionsModel> {
		return this.fetch();
	}
});
