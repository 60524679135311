interface CartItem {
	quantity: number;
	totalPrice?: number;
	uniqueId: number;
	finish: { price: number };
}

interface Receipt {
	orderNumber: number;
	cart: {
		subTotal: number;
		items: {
			uniqueId: number;
			quantity: number;
			unitPrice: number;
		}[];
	};
}

interface DyCartItem {
	productId: string;
	quantity: number;
	itemPrice: number;
	size?: string;
}

interface SPAPageContext {
	context: {
		type: DY_CONTEXT_TYPE.PRODUCT;
		data: [string];
	};
	url: string;
	countAsPageview: boolean;
}

type DyCart = DyCartItem[];

export const enum DY_SORT_BY {
	PRICE = 'PRICE',
	AGE = 'AGE',
	POPULARITY = 'POPULARITY',
	RATING = 'RATING',
	OTHER = 'OTHER'
}

export const enum DY_SORT_ORDER {
	ASC = 'ASC',
	DESC = 'DESC'
}

export const enum DY_EVENT_TYPE {
	ADD_TO_CART = 'add-to-cart-v1',
	ADD_TO_WISHLIST = 'add-to-wishlist-v1',
	CHANGE_ATTRIBUTE = 'change-attr-v1',
	FILTER_ITEMS = 'filter-items-v1',
	KEYWORD_SEARCH = 'keyword-search-v1',
	LOGIN = 'login-v1',
	MESSAGE_OPT_IN = 'message-optin-v1',
	NEWSLETTER_SUBSCRIPTION = 'newsletter-subscription-v1',
	PROMO_CODE_ENTERED = 'enter-promo-code-v1',
	PURCHASE = 'purchase-v1',
	REMOVE_FROM_CART = 'remove-from-cart-v1',
	SIGNUP = 'signup-v1',
	SORT_ITEMS = 'sort-items-v1',
	SYNC_CART = 'sync-cart-v1',
	VIDEO_WATCH = 'video-watch-v1'
}

export const enum DY_CONTEXT_TYPE {
	HOMEPAGE = 'HOMEPAGE',
	CATEGORY = 'CATEGORY',
	PRODUCT = 'PRODUCT',
	CART = 'CART',
	OTHER = 'OTHER'
}

interface EventData<E extends DY_EVENT_TYPE, P = {}> {
	name: string;
	properties: {
		dyType: E;
	} & P;
}

interface AddRemoveFromCartProps {
	productId: string;
	value: number;
	quantity: number;
	currency?: string;
	cart?: DyCart;
}

type AddToCartEventData = EventData<DY_EVENT_TYPE.ADD_TO_CART, AddRemoveFromCartProps>;

type RemoveFromCartEventData = EventData<DY_EVENT_TYPE.REMOVE_FROM_CART, AddRemoveFromCartProps>;

type AddToWishListEventData = EventData<
	DY_EVENT_TYPE.ADD_TO_WISHLIST,
	{
		productId: string;
		size?: string;
	}
>;

type KeywordSearchEventData = EventData<DY_EVENT_TYPE.KEYWORD_SEARCH, { keywords: string }>;

type PurchaseEventData = EventData<
	DY_EVENT_TYPE.PURCHASE,
	{
		uniqueTransactionId: string;
		value: number;
		currency?: string;
		cart?: DyCart;
	}
>;

interface LoginEmailProps {
	hashedEmail: string;
}

interface LoginCuidProps {
	cuid: string;
	cuidType: string;
}

type LoginEventData = EventData<DY_EVENT_TYPE.LOGIN, LoginEmailProps | LoginCuidProps>;

type PromoCodeEnteredEventData = EventData<DY_EVENT_TYPE.PROMO_CODE_ENTERED, { code: string }>;

interface SortItemsProps {
	sortBy: DY_SORT_BY;
	sortOrder: DY_SORT_ORDER;
}

interface CurrentProductData {
	imageSource: string;
	alt: string;
	productType: string;
}

interface FilterItemsProps {
	filterType: FilterTypes;
	filterStringValue: string;
}

type SortItemsEventData = EventData<DY_EVENT_TYPE.SORT_ITEMS, SortItemsProps>;

type FilterItemsEventData = EventData<DY_EVENT_TYPE.FILTER_ITEMS, FilterItemsProps>;

type EventDataTypes =
	| AddToCartEventData
	| AddToWishListEventData
	| KeywordSearchEventData
	| LoginEventData
	| PromoCodeEnteredEventData
	| PurchaseEventData
	| RemoveFromCartEventData
	| SortItemsEventData
	| FilterItemsEventData;

type FilterTypes = 'manufacturer' | 'finish' | 'masterfinish' | 'theme' | 'type';

interface DYRecommendationContext {
	type: DY_CONTEXT_TYPE;
	data?: string[];
	lng?: string;
}

interface DYRecommendationWidgetConfig {
	maxProducts?: number;
	exclude?: {
		group_id?: string[];
		sku?: string[];
	};
}

interface DYRecommendationWidgetRequestOptions extends DYRecommendationWidgetConfig {
	context: DYRecommendationContext;
}

interface DYRecommendationItem {
	// Base properties
	entity_id: string;
	sku: string;
	group_id: string;
	name: string;
	url: string;
	price: number;
	image_url: string;
	categories: string[];
	keywords: string[];
	dy_display_price: string;

	// Build.com feed
	type: string;
	application: string;
	handletype: string;
	base_category: string;
	business_category: string;
	in_stock: boolean;
	discontinued: boolean;
	manufacturer: string;
	model: string;
	rating: number;
	masterfinish: string;
	finish: string;
}

interface DYRecommendationSlot {
	item: DYRecommendationItem;
	fallback: boolean;
	strId: number;
	md: { [key: string]: any };
}

interface DYRecommendationWidgetResponse {
	wId: number;
	name: string;
	expData: {
		varId: string;
		expId: string;
	};
	fId: number;
	fallback: boolean;
	slots: DYRecommendationSlot[];
}

class TimeoutToken {
	time: number;
	onTimeout: () => void;
	isTimedOut = false;
	private timeoutHandle: NodeJS.Timeout;

	constructor(time: number, onTimeout: () => void) {
		this.time = time;
		this.onTimeout = onTimeout;
	}

	start() {
		this.timeoutHandle = setTimeout(() => this.timeoutComplete(), this.time);
		return this;
	}

	stop() {
		clearTimeout(this.timeoutHandle);
		return this;
	}

	private timeoutComplete() {
		this.isTimedOut = true;
		if (this.onTimeout) {
			this.onTimeout();
		}
	}
}

export const dynamicYield = {
	isEnabled(): boolean {
		return Boolean(window.dataLayer.dynamicYield && window.dataLayer.dynamicYield.enabled);
	},

	eventsEnabled(): boolean {
		return Boolean(window.dataLayer.dynamicYield && window.dataLayer.dynamicYield.eventsEnabled);
	},

	apiAvailable(): boolean {
		return Boolean(window.DY && window.DY.API);
	},

	recommendationsAvailable(): boolean {
		return Boolean(window.DYO && window.DYO.recommendations);
	},

	trackEvent: {
		addToCart(cartItem: CartItem) {
			if (!dynamicYield.eventsEnabled()) {
				return;
			}
			const { quantity, uniqueId, finish } = cartItem;
			if (!finish) {
				return;
			}
			const total = finish.price * quantity;
			dynamicYield._sendEvent({
				name: 'Add to Cart',
				properties: {
					dyType: DY_EVENT_TYPE.ADD_TO_CART,
					productId: uniqueId.toString(),
					value: Number.parseFloat(total.toFixed(2)),
					quantity
				}
			});
		},

		removeFromCart(removedItem: CartItem, remainingCartItems) {
			if (!dynamicYield.eventsEnabled()) {
				return;
			}
			const productId = removedItem.uniqueId.toString(),
				{ quantity, totalPrice } = removedItem,
				cart = remainingCartItems.map(dynamicYield._formatCartItem);

			dynamicYield._sendEvent({
				name: 'Remove from Cart',
				properties: {
					dyType: DY_EVENT_TYPE.REMOVE_FROM_CART,
					value: totalPrice,
					productId,
					quantity,
					cart
				}
			});
		},

		addToProject(productId: number | string) {
			if (!dynamicYield.eventsEnabled()) {
				return;
			}
			productId = productId.toString();

			dynamicYield._sendEvent({
				name: 'Add to Wishlist',
				properties: {
					dyType: DY_EVENT_TYPE.ADD_TO_WISHLIST,
					productId
				}
			});
		},

		keywordSearch(searchTerm: string) {
			if (!dynamicYield.eventsEnabled() || searchTerm === '') {
				return;
			}
			dynamicYield._sendEvent({
				name: 'Keyword Search',
				properties: {
					dyType: DY_EVENT_TYPE.KEYWORD_SEARCH,
					keywords: searchTerm
				}
			});
		},

		purchase(order: Receipt) {
			if (!dynamicYield.eventsEnabled()) {
				return;
			}
			const {
					orderNumber,
					cart: { items, subTotal }
				} = order,
				cart = items.map(dynamicYield._formatCartItem);

			dynamicYield._sendEvent({
				name: 'Purchase',
				properties: {
					uniqueTransactionId: `${orderNumber}`,
					dyType: DY_EVENT_TYPE.PURCHASE,
					value: subTotal,
					cart
				}
			});
		},

		login(customer: { email?: string; customerId?: string }) {
			if (!dynamicYield.eventsEnabled() || !customer) {
				return;
			}
			const { email, customerId } = customer;
			let props: LoginEmailProps | LoginCuidProps;
			if (email && window.DYO) {
				props = {
					hashedEmail: window.DYO.dyhash.sha256(email.toLowerCase())
				};
			} else if (customerId) {
				props = {
					cuid: customerId,
					cuidType: 'customerId'
				};
			} else {
				return;
			}
			dynamicYield._sendEvent({
				name: 'Login',
				properties: {
					dyType: DY_EVENT_TYPE.LOGIN,
					...props
				}
			});
		},

		addCoupon(code: string) {
			if (!dynamicYield.eventsEnabled()) {
				return;
			}
			dynamicYield._sendEvent({
				name: 'Promo Code Entered',
				properties: {
					dyType: DY_EVENT_TYPE.PROMO_CODE_ENTERED,
					code
				}
			});
		},

		sortItems(sortValue: string) {
			if (!dynamicYield.eventsEnabled()) {
				return;
			}
			const sortType = dynamicYield._translateSortValue(sortValue);
			dynamicYield._sendEvent({
				name: 'Sort Items',
				properties: {
					dyType: DY_EVENT_TYPE.SORT_ITEMS,
					...sortType
				}
			});
		},

		reportSPAPageView(uniqueId: number, url: string) {
			const pageContext: SPAPageContext = {
				context: {
					type: DY_CONTEXT_TYPE.PRODUCT,
					data: [`${uniqueId}`]
				},
				url,
				countAsPageview: true
			};

			if (dynamicYield.apiAvailable()) {
				window.DY.API('spa', pageContext);
			}
		},

		filterItems(filterType: FilterTypes, filterStringValue: string) {
			if (!dynamicYield.eventsEnabled()) {
				return;
			}

			dynamicYield._sendEvent({
				name: 'Filter Items',
				properties: {
					dyType: DY_EVENT_TYPE.FILTER_ITEMS,
					filterType,
					filterStringValue
				}
			});
		}
	},

	_sendEvent(eventData: EventDataTypes) {
		if (dynamicYield.apiAvailable()) {
			window.DY.API('event', eventData);
		}
	},

	_formatCartItem(item): DyCartItem {
		return {
			productId: item.uniqueId.toString(),
			quantity: item.quantity,
			itemPrice: item.unitPrice
		};
	},

	_translateSortValue(sortValue: string): SortItemsProps {
		switch (sortValue) {
			case 'PRICE_HIGH':
				return { sortBy: DY_SORT_BY.PRICE, sortOrder: DY_SORT_ORDER.DESC };
			case 'PRICE_LOW':
				return { sortBy: DY_SORT_BY.PRICE, sortOrder: DY_SORT_ORDER.ASC };
			case 'RATING':
				return { sortBy: DY_SORT_BY.RATING, sortOrder: DY_SORT_ORDER.DESC };
			case 'FAVORITE':
				return { sortBy: DY_SORT_BY.POPULARITY, sortOrder: DY_SORT_ORDER.DESC };
			case 'SCORE':
				return { sortBy: DY_SORT_BY.OTHER, sortOrder: DY_SORT_ORDER.DESC };
			default:
				return { sortBy: DY_SORT_BY.OTHER, sortOrder: DY_SORT_ORDER.ASC };
		}
	},

	getDynamicYieldRenderData(): CurrentProductData {
		const { finishes, selectedFinish } = window.dataLayer;
		const currentFinish = finishes.find((f) => f.name === selectedFinish.finish);
		if (currentFinish) {
			const productType =
				currentFinish.type[currentFinish.type.length - 1] === 's' ? currentFinish.type.slice(0, -1) : currentFinish.type;
			return {
				imageSource: currentFinish['image220x220'],
				productType,
				alt: `${currentFinish.manufacturer} ${currentFinish.name} ${productType}`
			};
		}
	},

	getRecommendationData(
		strategyId: number,
		options: DYRecommendationWidgetRequestOptions,
		timeout = 7000
	): Promise<DYRecommendationWidgetResponse> {
		if (!window.DYO || typeof window.DYO.recommendationWidgetData !== 'function') {
			return Promise.reject(new Error('DynamicYield SDK not ready'));
		}

		return new Promise((resolve, reject) => {
			const timeoutToken = new TimeoutToken(timeout, () => reject(new Error('Timeout reached while retrieving recommendations')));
			if (timeout > 0) {
				timeoutToken.start();
			}
			window.DYO.recommendationWidgetData(strategyId, options, (err, data) => {
				timeoutToken.stop();
				if (timeoutToken.isTimedOut) {
					return;
				}

				if (err) {
					reject(err);
				} else {
					resolve(data);
				}
			});
		});
	},

	getProductRecommendationData(
		strategyId: number,
		uniqueId: number | string,
		options: DYRecommendationWidgetConfig,
		timeout = 7000
	): Promise<DYRecommendationWidgetResponse> {
		const requestOptions = {
			context: {
				type: DY_CONTEXT_TYPE.PRODUCT,
				data: [`${uniqueId}`]
			},
			...options
		};
		return dynamicYield.getRecommendationData(strategyId, requestOptions, timeout);
	},

	recommendationSlotToProductTile(slot: DYRecommendationSlot) {
		const { item } = slot;
		return {
			product: {
				productCompositeId: item.group_id,
				uniqueId: item.sku,
				productLink: item.url,
				imageUrl: item.image_url,
				manufacturer: item.manufacturer,
				productId: item.model,
				title: item.name,
				price: {
					min: item.price
				},
				reviewRating: {
					average: item.rating,
					className: item.rating > 0 ? (Math.round(item.rating * 2) / 2).toFixed(1).replace('.', '') : null
				},
				finishCount: 1 // Needed for proper price display.
			},
			recommendationContext: {
				strategyId: slot.strId,
				productId: item.sku
			}
		};
	},

	registerRecommendationPlacement(element): void {
		if (!dynamicYield.recommendationsAvailable()) {
			return;
		}
		window.DYO.recommendations.registerElements(element);
	},

	getLoadedWidgets() {
		if (!dynamicYield.recommendationsAvailable()) {
			return [];
		}
		return window.DYO.recommendations.getLoadedWidgets();
	}
};
