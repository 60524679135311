const Backbone = require('backbone'),
	window = require('window');

module.exports = Backbone.View.extend({
	events: {
		'click .hide-link': '_minimizeChat'
	},

	_minimizeChat: function(e) {
		e.stopPropagation();
		e.preventDefault();
		$.ajax({
			method: 'post',
			url: `${window.BCOM.apiPathPrefix}/minimized_chat/set_status`,
			data: {
				status: true
			}
		});

		this.$el.addClass('minimized');
	}
});
