const Backbone = require('backbone');
const window = require('window');
const dustjs = require('dustjs');
// Have to prefix require with node_modules
// because of our aliases are picking this module up
require('node_modules/mailcheck');
const _mailcheckDust = require('common/_mailcheck.dust');
const TooltipAlerts = require('common/tooltip_alerts');

module.exports = Backbone.View.extend({
	initialize: function(options) {
		if (options.$insertAfter) {
			this.$insertAfter = options.$insertAfter;
		}
		if (options.$submitButton) {
			this.$submitButton = options.$submitButton;
		}
		this.isTooltipAlert = Boolean(options.isTooltipAlert);
		this.disableSubmit = false;
		this._setOnChangeListener(this.isTooltipAlert);
		this._setOnSubmitListener(this.$submitButton);
	},
	_setOnChangeListener: function(isTooltipAlert) {
		this.$el.on('change', () => {
			if (window.BCOM.util.validator.isValidEmail(this.$el.val())) {
				this.$el.mailcheck({
					suggested: (element, suggestion) => {
						this.disableSubmit = true;
						this.email = suggestion.full;
						this._render(suggestion, isTooltipAlert);
					},
					empty: () => {
						this.disableSubmit = false;
					}
				});
			}
		});
	},
	_render: function(suggestion, isTooltipAlert) {
		if (this.$mailcheckAlert) {
			this.$mailcheckAlert.remove();
		}
		dustjs.render(_mailcheckDust, suggestion, (err, out) => {
			if (err) {
				window.clientErrorHandler(err);
				return;
			}
			const $insertAfter = this.$insertAfter || this.$el;

			this.$mailcheckAlert = isTooltipAlert ? new TooltipAlerts({ html: out }).el : $(out);
			$insertAfter.after(this.$mailcheckAlert);
			this._onDustRenderSuccess(this.$mailcheckAlert);
		});
	},
	_onDustRenderSuccess: function($mailcheckAlert) {
		setTimeout(() => {
			this.disableSubmit = false;
		}, 500);
		$('.js-mailcheck-confirm')
			.add($mailcheckAlert)
			.on('click', (e) => {
				this._confirmEmail(e);
			});
	},
	_setOnSubmitListener: function($submitButton) {
		if ($submitButton) {
			$submitButton.on('click', () => {
				if (this.disableSubmit) {
					// tooltip
					this.$submitButton.tooltip({
						title: 'Please verify entered emails before proceeding'
					});
					this.$submitButton.tooltip('enable');
					return false;
				} else {
					if (this.$mailcheckAlert) {
						this.$mailcheckAlert.remove();
					}
					return true;
				}
			});
		}
	},
	_confirmEmail: function(e) {
		e.preventDefault();
		this.$el.val(this.email);
		this.$mailcheckAlert.remove();
	}
});
