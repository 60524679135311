const Backbone = require('backbone');
const dustjs = require('dustjs');
const _optionDust = require('store/global/components/select_box/_select_box_option.dust');

module.exports = Backbone.View.extend(
	/** @type {Partial<BackboneExtensions.SelectBox>} */ ({
		events: {
			'click .js-select-box-button': 'toggleOptions',
			'click .js-select-box-dropdown > li': 'optionClick'
		},
		/**
		 * @param {*} options
		 * @returns {void}
		 */
		initialize: function(options = {}) {
			this.setOptions(options);
			this.bindElements();
			this.val = '';
			this.index = -1;
			this.disabled = false;
			this.resolveSelected();
			this.listenTo(Backbone, 'body:click', this.close);
		},
		/**
		 * @param {*} options
		 * @returns {void}
		 */
		setOptions: function(options) {
			if (!this.options) {
				this.options = {
					title: 'Select option'
				};
			}
			this.options = Object.assign(this.options, options);
			this.options.updatePlaceholder = this.options.updatePlaceholder === undefined ? true : this.options.updatePlaceholder;
		},
		/**
		 * Resolves option selected
		 * @returns {Partial<BackboneExtensions.SelectBox>}
		 */
		resolveSelected() {
			const selectedItemIndex = this.$selectOptions.filter('.js-selected').index();
			if (selectedItemIndex !== -1) {
				this.setSelectedByIndex(selectedItemIndex, false);
			}
			return this;
		},
		/**
		 * @returns {void}
		 */
		bindElements() {
			this.disabled = false;
			this.$placeholder = this.$el.find('.js-select-box-title');
			this.$dropdown = this.$el.find('.js-select-box-dropdown');
			this.$button = this.$el.find('.js-select-box-button');
			this.$selectOptions = this.$dropdown.children('li');
		},
		/**
		 * Clear Options
		 * @returns {Partial<BackboneExtensions.SelectBox>}
		 */
		clear() {
			this.$dropdown.children().remove();
			return this;
		},
		/**
		 * Render Options dynamically
		 * @returns {Partial<BackboneExtensions.SelectBox>}
		 */
		renderOptions(options) {
			options = options || [];
			if (options.length > 0) {
				this.clear();
				options.forEach(this.renderOption, this);
				this.bindElements();
				this.resolveSelected();
			}
			return this.delegateEvents();
		},
		/**
		 * Render a single option
		 * @param option
		 */
		renderOption(option) {
			dustjs.render(_optionDust, option, this.onOptionRendered.bind(this));
		},
		/**
		 * Append result of an option processed by dust into the dropdown list of options
		 * @param err
		 * @param content
		 */
		onOptionRendered(err, content) {
			if (typeof err !== 'undefined' && err !== null) {
				window.clientErrorHandler(err);
				return;
			}
			this.$dropdown.append(content);
		},
		/**
		 * @param {Event} e
		 * @returns {void}
		 */
		optionClick: function(e) {
			if (!this.disabled) {
				const $currentTarget = $(e.currentTarget);
				const url = $currentTarget.data('url');
				const customUrlHandling = $currentTarget.data('custom-url-handling');
				this.setSelectedByElement($(/** @type {HTMLElement} */ (e.currentTarget)));
				if (customUrlHandling) {
					Backbone.trigger('dropdown:option:click', { url });
				} else if (url) {
					window.location.assign(url);
				}
			}
		},
		/**
		 * @returns {ThisType}
		 */
		enable: function() {
			this.$button.removeClass('o-50 not-allowed');
			this.disabled = false;
			return this;
		},
		/**
		 * @returns {ThisType}
		 */
		disable: function() {
			this.$button.addClass('o-50 not-allowed');
			this.disabled = true;
			return this;
		},
		/**
		 * @returns {ThisType}
		 */
		show: function() {
			this.$el.removeClass('dn');
			return this;
		},
		/**
		 * @returns {ThisType}
		 */
		hide: function() {
			this.$el.addClass('dn');
			return this;
		},
		/**
		 * @param {JQuery} $target
		 * @param {boolean} shouldPropagateChange
		 * @returns {void}
		 */
		setSelectedByElement: function($target, shouldPropagateChange = true) {
			this.val = $target.data('value');
			this.index = $target.index();
			if (this.options.updatePlaceholder) {
				this.$placeholder.text($target.text());
			}
			this.$selectOptions
				.removeClass('js-selected')
				.find('.js-checkmark')
				.removeClass('di')
				.addClass('dn');
			$(this.$selectOptions[this.index])
				.addClass('js-selected')
				.find('.js-checkmark')
				.removeClass('dn')
				.addClass('di');
			if (this.options.selectEvent) {
				Backbone.trigger(this.options.selectEvent, { value: this.val, index: this.index, shouldPropagateChange });
			}
			if (typeof this.options.onChange === 'function' && shouldPropagateChange) {
				this.options.onChange(this.val);
			}
		},
		/**
		 * @param {Event} e
		 * @returns {void}
		 */
		close: function(e) {
			this.$dropdown.removeClass('di').addClass('dn');
			if (typeof this.options.onClose === 'function') {
				this.options.onClose(e, this);
			}
		},
		/**
		 * @param {number} index
		 * @param {boolean} shouldPropagateChange
		 */
		setSelectedByIndex: function(index, shouldPropagateChange) {
			const $defaultOption = $(this.$selectOptions[index]);
			this.setSelectedByElement($defaultOption, shouldPropagateChange);
		},
		/**
		 * @returns {false}
		 */
		toggleOptions: function() {
			if (!this.disabled) {
				this.$dropdown.toggleClass('di').toggleClass('dn');
			}
			return false;
		},
		/**
		 * @returns {string}
		 */
		getValue: function() {
			return this.val;
		},
		/**
		 * @returns {number}
		 */
		getIndex: function() {
			return this.index;
		}
	})
);
