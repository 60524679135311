const window = require('window');
const Backbone = require('backbone');
const ZipCodeModel = require('models/store/client/cart/zip_code');
const locationHelper = require('app/views/store/common/helpers/location_helper');
const { ZIP_SOURCE_TYPE } = require('lib/enums');
const gtmHelper = require('lib/helpers/gtm_helper');

const UniversalLocationView = Backbone.View.extend({
	el: '#new-shipping-banner',
	events: {
		submit: 'validateZip',
		'click .js-location-input': '_trackInputFocus',
		'keyup ': '_toggleLocationEnter'
	},

	initialize: function(options = {}) {
		if (!this.$el.length) {
			return; // Don't set this up on pages that don't have the header (receipt print mode for example)
		}
		this._setZipCode(options.zip);
		this.$locationError = $('.js-location-error');
		this.$locationInput = this.$el.find('.js-location-input');
		this.$locationFieldset = $('.js-location-fieldset');
		this.$jsShippingTo = $('.js-shipping-to');
		this.$locationCityStateZip = $('.js-location-city-state-zip');
		this.$locationCityState = $('.js-location-city-state');
		this.$locationZip = $('.js-location-zip');
		this.$navOverlay = $('.nav-overlay');
		this.$locationForm = this.$el.find('.js-location-form');
		this.$dropDownElem = this.$el.find('.js-location-wrapper');
		this.isMobile = window.BCOM.device.isMobile;
		this.isDesktop = window.BCOM.device.isDesktop;

		this.listenTo(Backbone, 'location:changed', this._handleLocationChange.bind(this));
		this.listenTo(Backbone, 'location:cartAddressChanged', this._handleExternalChange.bind(this));
		this._attachEvents();
	},
	_attachEvents: function() {
		$('body').on('mousedown', this._onBodyMouseDown.bind(this));
	},
	_showOverLay: function() {
		if (this.isMobile) {
			// adding 'location-overlay' class so we can differentiate between other overlays that may show on mobile
			$('.nav-overlay').addClass('location-overlay active');
		}
	},
	_hideOverLay: function() {
		const isLocationOverlay = $('.nav-overlay').hasClass('location-overlay');
		if (this.isMobile && isLocationOverlay) {
			$('.nav-overlay.location-overlay').removeClass('location-overlay active');
		}
	},
	_toggleLocationEnter: function(event) {
		if (event.keyCode === 13) {
			this._showLocationDropdown();
		}
	},
	_showLocationDropdown: function() {
		this.$dropDownElem.removeClass('dn');
		this._showOverLay();
	},
	_hideLocationDropdown: function() {
		this.$dropDownElem.addClass('dn');
		this._hideOverLay();
	},
	_handleLocationChange: function(data) {
		this._setZipCode(data.zipCode);
		this._setZipCodeInput(data.zipCode);
		this._setCityStateZipText(data, $('.js-location-toggle'));
	},
	_trackInputFocus: function() {
		gtmHelper.fireEvent({
			event: 'generic-click-tracking',
			linkName: 'zipcode click'
		});

		Backbone.trigger('track:link', { linkName: 'zipcode click' });
		this.$locationInput[0].setSelectionRange(0, 9999);
	},
	validateZip: function(e) {
		e.preventDefault();

		if (!this.$locationForm.valid()) {
			return;
		}

		const zip = this.$locationInput.val();
		this.setZip(zip);

		gtmHelper.fireEvent({
			event: 'generic-click-tracking',
			linkName: 'location update'
		});

		Backbone.trigger('track:link', { linkName: 'location update' });
	},
	setZip: function(zip) {
		if (this.zip === zip) {
			Backbone.trigger('zipCode:noChange', zip);
			return;
		}

		const zipCode = new ZipCodeModel({
			emailId: window.dataLayer.email,
			zipCode: zip,
			zipCodeResolutionType: ZIP_SOURCE_TYPE.CUSTOMER_PROVIDED_UNIVERSAL
		});
		zipCode
			.save(null)
			.then((model, response) => {
				if (response === 'success') {
					if (model.status === 1) {
						this._hideError();

						this._setZipCode(zip);
						this._setZipCodeInput(zip);

						locationHelper.saveLocationCookies(model.data);
						this._setCityStateZipText(model.data);

						this._hideLocationDropdown();

						this._initTriggers(model.data);
					} else {
						this._showError();

						gtmHelper.fireEvent({
							event: 'generic-click-tracking',
							linkName: 'Invalid zip code'
						});

						Backbone.trigger('track:link', { linkName: 'Invalid zip code' });
					}
				}
			})
			.catch(() => {
				this._showError();
			});
	},
	_handleExternalChange: function(zipCode) {
		this.setZip(zipCode);
	},
	_showError: function() {
		this.$locationError.removeClass('dn');
		this.$locationFieldset.addClass('b--theme-error');
	},
	_hideError: function() {
		this.$locationError.addClass('dn');
		this.$locationFieldset.removeClass('b--theme-error');
	},
	_setCityStateZipText: function(data) {
		this.$locationCityState.text(`${data.cityName}, ${data.stateAbbr}`);
		this.$locationZip.text(`${data.zipCode}`);
		this.$jsShippingTo.text('Shipping to:');
	},
	_setZipCode: function(zip) {
		this.zip = zip;
	},
	_setZipCodeInput: function(zip) {
		this.$locationInput.val(zip);
	},
	_initTriggers: function(data) {
		Backbone.trigger('zipCode:changed', data.zipCode);
		Backbone.trigger('cart:changed', { shippingOptionUpdated: true, isZipChange: true });
		Backbone.trigger('availability:locationChange', data.zipCode);
		Backbone.trigger('universalLocation:changed', data.zipCode);
		Backbone.trigger('zipCode:forceupdate', data.zipCode);
		Backbone.trigger('customerLocation:changed', data);
	},
	_onBodyMouseDown: function(event) {
		if (this.isMobile) {
			event.stopPropagation();
		}
		const isTargetInView = $.contains(this.$el[0], event.target);
		const hasDNClass = this.$dropDownElem.hasClass('dn');
		if (!isTargetInView) {
			if (!hasDNClass) {
				this._hideLocationDropdown();
			}
		} else {
			if (hasDNClass) {
				this._showLocationDropdown();

				gtmHelper.fireEvent({
					event: 'generic-click-tracking',
					linkName: 'location click'
				});

				Backbone.trigger('track:link', { linkName: 'location click' });
			}
		}
		return true;
	}
});

module.exports = UniversalLocationView;
