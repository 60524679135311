const Backbone = require('backbone');
const dustjs = require('dustjs');
const _tooltipAlertsDust = require('store/global/common/_tooltip_alerts.dust');

module.exports = Backbone.View.extend({
	initialize: function(options) {
		dustjs.render(_tooltipAlertsDust, {}, (err, out) => {
			if (err) {
				window.clientErrorHandler(err);
				return;
			}
			this.$el.append(out);
			const $contentEl = this.$('.js-tooltip-content');
			if (options.text) {
				$contentEl.text(options.text);
			} else {
				$contentEl.html(options.html);
			}
		});
	}
});
