import Backbone from 'backbone';
import dust from 'dustjs';
import { GBIRecentAndTrendingModel } from 'models/store/client/gbi/gbi_recent_and_trending.js';
import saytProductsDust from 'common/gbi/sayt_products.dust';
import saytTermsDust from 'common/gbi/sayt_terms.dust';
import recentSearchTermsDust from 'common/gbi/recent_search_terms.dust';
import saytResultsDust from 'common/gbi/sayt_results.dust';
import { searchSource } from 'lib/enums';
require('common/_close_menu.dust');

export const GBIRecentAndTrendingView = Backbone.View.extend({
	initialize: function(options) {
		this.$searchInput = this.$el.find('.js-site-search-input');
		this.$saytTerms = this.$el.find('.js-sayt-results');
		this.trendingEnabled = false;
		this.showProducts = window.BCOM.device.isDesktop;

		this.model = new GBIRecentAndTrendingModel({ recentSearchTerms: options.recentSearchTerms, trendingEnabled: this.trendingEnabled });

		this.listenTo(Backbone, 'search:focus suggested-search:emptied', this._onSearchFocus);
	},

	events: {
		'click .js-recent-term': '_searchRecentAndTrending'
	},

	_searchRecentAndTrending: function(e) {
		e.preventDefault();
		const $target = $(e.currentTarget);
		const searchTerm = $target.text();
		const source = searchSource.RECENT_SEARCHES;
		window.location.assign(`/search?term=${searchTerm}&ssrc=${source}`);
	},

	_onSearchFocus: function() {
		const { recentSearchTerms } = this.model.data;

		if (recentSearchTerms && recentSearchTerms.length) {
			this._renderRecentSearchTerms(recentSearchTerms);
		} else if (!this.$searchInput.val()) {
			this._renderTrendingTermsAndProducts();
		}
		this.$saytTerms.fadeIn();
	},

	_renderRecentSearchTerms: function(terms) {
		const currentSearchTerm = this.$searchInput.val();
		// remove duplicates and current search term value
		let recentSearchTerms = terms.reduce((all, current) => {
			const dupTerm = all.find((item) => item === current);
			if (!dupTerm && current !== currentSearchTerm) {
				return [...all, { term: current }];
			} else {
				return all;
			}
		}, []);

		if (recentSearchTerms.length) {
			if (window.BCOM.device.isMobile && recentSearchTerms.length > 6) {
				recentSearchTerms = recentSearchTerms.slice(0, 6);
			}
			dust.render(saytResultsDust, { showProducts: false, BCOM: window.BCOM }, (err, out) => {
				const $out = $(out);

				dust.render(recentSearchTermsDust, { recentSearchTerms }, (err, termsHtml) => {
					$out.find('.js-suggested-search-terms').append(termsHtml);

					this.$saytTerms.html($out);
				});
			});
		}
	},

	_renderTrendingTermsAndProducts: function() {
		const { trendingTerms } = this.model.data;
		// at a minimum we need trendingTerms data to render
		if (trendingTerms) {
			dust.render(
				saytResultsDust,
				{ termsTitle: 'Trending Searches', productsTitle: 'Trending Products', showProducts: this.showProducts, BCOM: window.BCOM },
				this._trendingTermsAndProductDustCallback.bind(this)
			);
		}
	},

	_trendingTermsAndProductDustCallback: function(err, out) {
		const { trendingTerms, trendingProducts } = this.model.data;
		const toRender = [this._renderTrendingTerms(trendingTerms)];
		let $out = $(out);

		if (this.showProducts) {
			toRender.push(this._renderTrendingProducts(trendingProducts));
		}

		return Promise.all(toRender).then((renderedTemplates) => {
			$out.find('.js-suggested-search-terms').append(renderedTemplates[0]);

			if (this.showProducts) {
				$out.find('.js-suggested-products').append(renderedTemplates[1]);
			}

			this.$saytTerms.html($out);
		});
	},

	_renderTrendingTerms: function(suggestedTerms) {
		return new Promise((resolve) => {
			dust.render(saytTermsDust, { suggestedTerms }, (err, out) => {
				resolve(out);
			});
		});
	},

	_renderTrendingProducts: function(suggestedProducts) {
		return new Promise((resolve) => {
			dust.render(saytProductsDust, { suggestedProducts }, (err, out) => {
				resolve(out);
			});
		});
	}
});
