const Backbone = require('backbone');
const PaginationView = Backbone.View.extend(
	/** @type {BackboneExtensions.Pagination} */ ({
		events: {
			'change select': '_handlePageChange',
			'click .js-next-page': 'nextPage',
			'click .js-previous-page': 'previousPage'
		},
		/**
		 * @param {Object} options
		 * @returns {void}
		 */
		initialize: function(options = {}) {
			this.model = new Backbone.Model(window.dataLayer.pagination);
			this.listenTo(this.model, 'change', this._handleModelUpdate);
			this.bindElements();
			this.setOptions(options);
		},
		/**
		 * @param {{isHandledClientSide?: boolean, hidePageSize?: boolean}} [options = {}]
		 * @returns {void}
		 */
		setOptions(options = {}) {
			this.isHandledClientSide = options.isHandledClientSide;
			this.hidePageSize = options.hidePageSize;
			if (this.isHandledClientSide && !this.dustjs) {
				Promise.all([
					import('dustjs'),
					import('store/global/components/pagination/_pagination.dust'),
					import('store/global/components/select_box/_select_box.dust'),
					import('store/global/components/button/button.dust')
				])
					.then((modules) => {
						this.dustjs = modules[0];
						this.paginationDust = modules[1];
					})
					.catch(window.clientErrorHandler);
			}
		},
		/**
		 * @returns {void}
		 */
		bindElements() {
			const $selectBox = this.$el.find('.js-select-box-component');
			if (this.selectBoxView) {
				this.selectBoxView.setElement($selectBox[0]);
				this.selectBoxView.bindElements();
			} else if ($selectBox.length) {
				this.selectBoxView = $selectBox.data('componentView');
				if (this.selectBoxView) {
					this.selectBoxView.setOptions({ onChange: this.setPageViewOption.bind(this) });
				}
			}
		},
		/**
		 * @returns {number}
		 */
		getActivePage: function() {
			return this.model.get('activePage');
		},
		/**
		 * @returns {number}
		 */
		getPageSize: function() {
			return this.model.get('pageSize');
		},
		/**
		 * @returns {void}
		 */
		render: function() {
			const paginationTemplateData = {
				pagination: this.model.toJSON(),
				hidePageSize: this.hidePageSize
			};
			this.dustjs.render(this.paginationDust, paginationTemplateData, (error, paginationMarkup) => {
				if (error) {
					window.clientErrorHandler(error);
				}
				this.$el.html(paginationMarkup);
				this.bindElements();
			});
		},
		/**
		 * @private
		 * @param {Event} e
		 * @returns {void}
		 */
		_handlePageChange(e) {
			this.goToPage(Number($(e.currentTarget).val()), e);
		},
		/**
		 * @param {Event} e
		 * @returns {void}
		 */
		nextPage(e) {
			const activePage = this.getActivePage();
			if (activePage === this.model.get('numberOfPages')) {
				e.preventDefault();
			} else {
				this.goToPage(activePage + 1, e);
			}
		},
		/**
		 * @param {Event} e
		 * @returns {void}
		 */
		previousPage(e) {
			const activePage = this.getActivePage();
			if (activePage > 1) {
				this.goToPage(activePage - 1, e);
			} else {
				e.preventDefault();
			}
		},
		/**
		 * @param {number} page
		 * @param {Event} e
		 * @returns {void}
		 */
		goToPage: function(page, e) {
			this.model.set('activePage', page);
			Backbone.trigger('pagination:changed', this.model.toJSON());
			if (this.isHandledClientSide) {
				e.preventDefault();
				this.render();
			} else {
				this._changePage();
			}
		},
		/**
		 * @param {number} pageSize
		 * @returns {void}
		 */
		setPageViewOption(pageSize) {
			this.model.set('pageSize', pageSize);
			Backbone.trigger('pagination:changed', this.model.toJSON());
			if (!this.isHandledClientSide) {
				window.location.assign(window.BCOM.util.updateURLParameters(window.location.href, 'r', pageSize, true));
			}
		},
		/**
		 * @private
		 * @returns {void}
		 */
		_changePage() {
			window.location.assign(window.BCOM.util.updateURLParameters(window.location.href, 'p', this.model.get('activePage')));
		},
		/**
		 * @private
		 * @param {Backbone.Model & {changed: {activePage: number}}} updatedModel
		 * @returns {void}
		 */
		_handleModelUpdate: function(updatedModel) {
			const { activePage } = updatedModel.changed;
			if (activePage) {
				const updatedPages = this._selectActivePage(activePage, updatedModel.get('pagesCollection'));
				updatedModel.set('pagesCollection', updatedPages);
			}
			if (this.isHandledClientSide) {
				this.render();
			}
		},
		/**
		 * @private
		 * @param {number} pageNumber
		 * @param {BackboneExtensions.Pagination.Page[]} pagesCollection
		 * @returns {BackboneExtensions.Pagination.Page[]}
		 */
		_selectActivePage: function(pageNumber, pagesCollection) {
			if (pagesCollection) {
				return pagesCollection.map((page) => {
					page.isActivePage = Boolean(pageNumber === page.pageNumber);
					return page;
				});
			}
		}
	})
);

module.exports = PaginationView;
