/**
 * @module TrackingHelper The module will inject the onclick event on the clickTrackableElements,
 * which is picked up by ActivityMap tracking in omniture. It follows the ActivityMap rules to get the primary link Id.
 * This will help auto track elements when a new feature is built.
 *
 * @reference: https://marketing.adobe.com/resources/help/en_US/analytics/activitymap/activitymap-link-tracking-methodology.html
 */

'use strict';

const clickTrackableElements = [
	'a',
	'img',
	'button',
	'input[type="button"]',
	'input[type="submit"]',
	'input[type="text"]',
	'input[type="checkbox"]',
	'select'
];
const linkNameMaxLength = 254;
const $ = require('jquery');

function sanitizeLinkName(a) {
	if (!a) {
		return a;
	}
	try {
		return a
			.replace(/\W\s+/g, '')
			.replace(/'/g, "\\'")
			.trim()
			.substring(0, linkNameMaxLength);
	} catch (d) {
		return a.trim().substring(0, linkNameMaxLength);
	}
}

function getTrackingLinkName($element) {
	if (!$element) {
		return '';
	}
	// Giving priority for data-activitymap-name attribute
	const trackLink = sanitizeLinkName($element.data('activitymap-name'));
	if (trackLink) {
		return trackLink;
	}
	// Using the same order that ActivityMap tries to use.
	return (
		sanitizeLinkName($element.text()) ||
		sanitizeLinkName($element.attr('alt')) ||
		sanitizeLinkName($element.attr('title')) ||
		sanitizeLinkName($element.attr('placeholder'))
	);
}

function injectActivityMap(elemType) {
	return new Promise((resolve) => {
		$(`${elemType}:not([data-tracking])`)
			.filter(`${elemType}:not([data-track-navigation])`)
			.filter(`${elemType}:not([data-no-track="true"])`)
			.each((index, elem) => {
				const $targetElem = $(elem);
				const onClick = $targetElem.attr('onclick');
				const linkName = getTrackingLinkName($targetElem);
				if (!onClick && linkName) {
					$targetElem.attr('onclick', `s_objectID='${linkName}';`);
				}
			});
		resolve();
	});
}

/**
 * Check if there is support for performance API.
 */
function performanceAPISupport() {
	return window.performance && typeof window.performance === 'object';
}

function initializeAnalyticsData(callback) {
	let t0;
	const hasPerformanceAPIsupport = performanceAPISupport();
	if (hasPerformanceAPIsupport && window.dataLayer.isLocalUser) {
		t0 = window.performance.now();
	}
	const promises = [];
	clickTrackableElements.forEach((elem) => {
		promises.push(injectActivityMap(elem));
	});
	Promise.all(promises).then(() => {
		if (hasPerformanceAPIsupport && window.dataLayer.isLocalUser) {
			const t1 = window.performance.now();
			console.info(`Call to inject tracking data: (${(t1 - t0).toFixed(2)}) milliseconds.`);
		}
		if (callback && typeof callback === 'function') {
			callback();
		}
	});
}

module.exports = {
	initializeAnalyticsData: initializeAnalyticsData,
	injectActivityMap: injectActivityMap
};
