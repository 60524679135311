interface TrackedField {
	fieldName: string;
	formClassName: string;
	message: string;
	iframeCallback?: Function;
}

const setFieldWithCallback = (trackingData: TrackedField) => ({
	...trackingData,
	iframeCallback: () => {
		parent.postMessage(
			{
				type: 'creditCard:inlineError',
				trackingData
			},
			'*'
		);
	}
});

const shippingForm = 'js-shipping-form';
const paymentForm = 'js-payment-form';
const editForm = 'js-edit-form';
const loginForm = 'js-login-form';

const trackedFields: TrackedField[] = [
	{
		formClassName: shippingForm,
		fieldName: 'address[email]',
		message: 'checkout flow:shipping:email address'
	},
	{
		formClassName: shippingForm,
		fieldName: 'address[phone]',
		message: 'checkout flow:shipping:phone number'
	},
	{
		formClassName: shippingForm,
		fieldName: 'address[fullName]',
		message: 'checkout flow:shipping:full name'
	},
	{
		formClassName: shippingForm,
		fieldName: 'address[address]',
		message: 'checkout flow:shipping:street address'
	},
	{
		formClassName: editForm,
		fieldName: 'address[phone]',
		message: 'checkout flow:shipping:phone number'
	},
	{
		formClassName: editForm,
		fieldName: 'address[fullName]',
		message: 'checkout flow:shipping:full name'
	},
	{
		formClassName: editForm,
		fieldName: 'address[address]',
		message: 'checkout flow:shipping:street address'
	},
	{
		formClassName: editForm,
		fieldName: 'address[zip]',
		message: 'checkout flow:shipping:zip code'
	},
	{
		formClassName: shippingForm,
		fieldName: 'address[zip]',
		message: 'checkout flow:shipping:zip code'
	},
	{
		formClassName: loginForm,
		fieldName: 'username',
		message: 'login:email address'
	},
	{
		formClassName: loginForm,
		fieldName: 'password',
		message: 'login:password'
	},
	setFieldWithCallback({
		formClassName: paymentForm,
		fieldName: 'creditcardname',
		message: 'checkout flow:payment:name on card'
	}),
	setFieldWithCallback({
		formClassName: paymentForm,
		fieldName: 'number',
		message: 'checkout flow:payment:credit card number'
	}),
	setFieldWithCallback({
		formClassName: paymentForm,
		fieldName: 'expiration',
		message: 'checkout flow:payment:exp. date'
	}),
	setFieldWithCallback({
		formClassName: paymentForm,
		fieldName: 'cvv',
		message: 'checkout flow:payment:security code'
	}),
	setFieldWithCallback({
		formClassName: editForm,
		fieldName: 'creditcardname',
		message: 'checkout flow:payment:name on card'
	}),
	setFieldWithCallback({
		formClassName: editForm,
		fieldName: 'number',
		message: 'checkout flow:payment:credit card number'
	}),
	setFieldWithCallback({
		formClassName: editForm,
		fieldName: 'expiration',
		message: 'checkout flow:payment:exp. date'
	}),
	setFieldWithCallback({
		formClassName: editForm,
		fieldName: 'cvv',
		message: 'checkout flow:payment:security code'
	})
];

export function findTrackedField(fieldName: string, className: string): TrackedField {
	return trackedFields.find((field) => field.fieldName === fieldName && className.split(' ').includes(field.formClassName));
}
