const generateCommonAttributes = function(keys, params, context) {
	return keys.map((key) => {
		const resolvedValue = context.resolve(params[key]);
		return `${key}="${resolvedValue}"`;
	});
};
const imageHelpers = {
	/**
	 * Partial to create a custom resized image from the default image
	 * @example {@getImageUrl dimension="resized/x500" default="/imagebase/misenoimages/miseno-mlit155181rt-appshot.jpg" /}
	 * @returns {*}
	 */
	getImageUrl: function(chunk, context, bodies, params) {
		const imagePath = context.resolve(params.default);
		const resizes = context.resolve(params.dimension);
		let resizedImage;
		if (resizes) {
			resizedImage = imagePath.replace('/imagebase/', `/imagebase/${resizes}/`);
		} else {
			resizedImage = imagePath;
		}
		return chunk.write(resizedImage);
	},
	lazyImage: function(chunk, context, bodies, params) {
		const { threshold = 0, classes = '', placeholder = context.get('spacerImage'), alt, src } = params;
		if (!(alt && context.resolve(alt))) {
			throw new Error('alt is a required attributes');
		}
		const index = context.get('$idx') || 0;
		const isLazy = index + 1 > threshold;
		const classList = classes.split(' ');
		if (isLazy) {
			classList.push('js-lazy');
		}
		// filter custom parameters
		const customParameters = ['threshold', 'classes', 'placeholder', 'src'];
		const remainingKeys = Object.keys(params)
			.filter((key) => {
				return !customParameters.includes(key);
			})
			.map((key) => key);
		const commonAttributes = generateCommonAttributes(remainingKeys, params, context);
		if (classList.length) {
			commonAttributes.push(`class="${classList.join(' ')}"`);
		}
		let imageTag;
		if (isLazy) {
			imageTag = `<img src="${placeholder}" data-src="${src}" ${commonAttributes.join(' ')} />`;
		} else {
			imageTag = `<img src="${src}" ${commonAttributes.join(' ')} />`;
		}
		chunk.write(imageTag);
	}
};

module.exports = imageHelpers;
