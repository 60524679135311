/**
 * @param {string} email
 * @returns {boolean}
 */
const checkEmail = (email) => {
	// email should have no spaces
	if (/\s/.test(email)) {
		return false;
	}
	// email should have 1 @ sign
	const emailParts = email.split('@');
	if (emailParts.length !== 2) {
		return false;
	}
	// user part can have + . - _ but cant have them adjacent to each other
	// user part should be made of valid character
	const user = emailParts[0].split(/[._-]/);
	if (user.some((e) => e === '' || !/^[+a-z0-9]*$/i.test(e))) {
		return false;
	}
	// domain part can have . - but cant have them adjacent to each other
	// domain part should be made of valid characters
	// domain part has to have two words separated by a .
	const domain = emailParts[1].split(/[.-]/);
	if (domain.some((e) => e === '' || !/^[a-z0-9]*$/i.test(e)) || emailParts[1].split('.').length < 2) {
		return false;
	}
	// the last part must have 2 or more character
	const parts = user.concat(domain);
	if (parts[parts.length - 1].length < 2) {
		return false;
	}
	return true;
};

module.exports = checkEmail;
