export enum addressType {
	SHIPPING = 2,
	BILLING = 1
}
export const finishStatuses = {
	FILTER_NONE: ['stock'],
	FILTER_ONLY: ['discontinued'],
	FILTER_SKIP: ['stock', 'discontinued'],
	FILTER_NONSTOCK: ['stock', 'nonstock'],
	FILTER_PENDING: ['pending'],
	FILTER_SHOWROOM: ['stock', 'nonstock', 'discontinued']
};
export const contentPageName = {
	NONE: 'none',
	HOME: 'home',
	CATEGORY: 'category',
	BRAND: 'brand',
	ARTICLE: 'article',
	LEARNING_CENTER: 'learning_center',
	VIDEO: 'video',
	ABOUT_US: 'about_us',
	AFFILIATES: 'affiliates',
	BACKORDERS: 'backorders',
	BECOME_VENDOR: 'become_vendor',
	BEST_VALUE: 'best_value',
	CANADA: 'canada',
	CONTACT_US: 'contact_us',
	CONTACT_US_THANKS: 'contact_us_thanks',
	MANUFACTURER_WARRANTY: 'manufacturer_warranty',
	NONSTOCK: 'nonstock',
	PAYMENT_METHODS: 'payment_methods',
	PRO_SERVICES: 'pro_services',
	PRODUCT_AVAILABILITY: 'product_availability',
	RETURNS: 'returns',
	SAFEST: 'safest',
	SALES_TAX: 'sales_tax',
	SECURITY: 'security',
	SHIPPING_METHODS: 'shipping_methods',
	SHIPPING_POLICY: 'shipping_policy',
	SHIPPING_RATES: 'shipping_rates',
	SPECIAL_PRICING: 'special_pricing',
	TERMS: 'terms',
	TRACKING_ORDER: 'tracing_order',
	WHY_SHOP: 'why_shop',
	GEAVAILABILITY: 'geavailability',
	GEDELIVERYINFO: 'gedeliveryinfo',
	SUPPORT: 'support',
	INSPIRATION: 'inspiration',
	GALLERY: 'gallery',
	COUPONS: 'coupons',
	CAREERS: 'careers',
	OUR_LEADERSHIP: 'our_leadership',
	NETWORK_SITES: 'network_sites',
	AFFILIATE_FAQS: 'affiliate_faqs',
	PRESS_RELEASES: 'press_releases',
	MEDIA_MENTIONS: 'media_mentions',
	CMS_TEST: 'cms_test',
	PRESS_MATERIALS: 'press_materials',
	CONNECT_WITH_US: 'connect_with_us',
	AFFILIATE_BLINDS: 'affiliate_blinds',
	AFFILIATE_SMART_HOME: 'affiliate_smart_home',
	INSTALLATION_SERVICES: 'installation_services',
	CREDITCARD: 'creditcard',
	'AFFILIATES:BLINDS': 'affiliates:blinds',
	'AFFILIATES:HOME': 'affiliates:home',
	'AFFILIATES:INSTALLATION_SERVICES': 'affiliates:installation_services',
	'AFFILIATES:SMARTHOME': 'affiliates:smarthome',
	'AFFILIATES:BUILDDIRECT': 'affiliates:builddirect',
	MAIN_REWARDS: 'main_rewards',
	REWARDS_TERMS: 'rewards_terms',
	TERMS_REWARDS: 'terms_rewards',
	REVIEWS: 'reviews',
	'AFFILIATES:ADT': 'affiliates:adt',
	'LANDING_PAGES:PHOTOS': 'landing_pages:photos',
	'AFFILIATES:CHAT_LANDING': 'affiliates:chat_landing',
	'AFFILIATES:PRO_DOT_COM': 'affiliates:pro_dot_com',
	OUR_PROMISE: 'our_promise',
	'LANDING_PAGES:12DAYS': 'landing_pages:12days',
	'FAVORITE:FEATURED': 'favorite:featured',
	'LANDING_PAGES:WELCOMEHOME': 'landing_pages:welcomehome',
	'LANDING_PAGES:AE': 'landing_pages:ae',
	'LANDING_PAGES:AMC': 'landing_pages:amc',
	'LANDING_PAGES:BIOGRAPHY': 'landing_pages:biography',
	'LANDING_PAGES:DIY': 'landing_pages:diy',
	'LANDING_PAGES:E': 'landing_pages:e',
	'LANDING_PAGES:ESQUIRE': 'landing_pages:esquire',
	'LANDING_PAGES:GAMESHOW': 'landing_pages:gameshow',
	'LANDING_PAGES:HALLMARK': 'landing_pages:hallmark',
	'LANDING_PAGES:HALLMARKMOVIE': 'landing_pages:hallmarkmovie',
	'LANDING_PAGES:HGTV': 'landing_pages:hgtv',
	'LANDING_PAGES:LIFETIME': 'landing_pages:lifetime',
	'LANDING_PAGES:OXYGEN': 'landing_pages:oxygen',
	'LANDING_PAGES:VH1': 'landing_pages:vh1',
	'MAIN:PROREWARDS': 'main:prorewards',
	'LANDING_PAGES:DELTASWEEPSTAKES': 'landing_pages:deltasweepstakes',
	'LANDING_PAGES:WINBATH': 'landing_pages:winbath',
	'LANDING_PAGES:REFERRALGIFT': 'landing_pages:referalgift',
	'LANDING_PAGES:BLUETARP': 'landing_pages:bluetarp',
	'REWARDS-PARTNERS': 'rewards-partners',
	SLUG: 'slug'
};
export enum autoPmgRuleDiscountType {
	DOLLAR_OFF_BASE_PRICE = 'DOLLAR_OFF_BASE_PRICE',
	PERCENT_OFF_BASE_PRICE = 'PERCENT_OFF_BASE_PRICE',
	PERCENT_OFF_MSRP = 'PERCENT_OFF_MSRP',
	PERCENT_OF_MSRP = 'PERCENT_OF_MSRP'
}
export enum categoryViewType {
	QUICK_ADD = 'QUICK_ADD',
	SUBCATEGORY = 'SUBCATEGORY'
}
export enum geCartItemError {
	ZIP_CODE_NOT_SERVICEABLE = 'ZIP_CODE_NOT_SERVICEABLE',
	PRODUCT_NOT_AVAILABLE = 'PRODUCT_NOT_AVAILABLE',
	PRODUCT_QUANTITY_NOT_AVAILABLE = 'PRODUCT_QUANTITY_NOT_AVAILABLE',
	INVALID_SERVICES = 'INVALID_SERVICES',
	GE_DOWN = 'GE_DOWN',
	IN_TRANSIT = 'IN_TRANSIT',
	UNKNOWN = 'UNKNOWN'
}
export enum deviceType {
	MOBILE = 'MOBILE',
	TABLET = 'TABLET',
	DESKTOP = 'DESKTOP'
}
// todo: verify this works
export enum sortOptions {
	SCORE = 'SCORE',
	PRICE_HIGH = 'PRICE_HIGH',
	PRODUCT_ID = 'PRODUCT_ID',
	FAVORITE = 'FAVORITE',
	RATING = 'RATING',
	PRICE_LOW = 'PRICE_LOW',
	PRODUCTID = 'PRODUCT_ID'
}
export enum questionnaire {
	COMPANY = 1,
	REWARDS = 2
}
export enum cookies {
	LOTAME_CUSTOMER_ORDERS = 'lotameCustomerOrders',
	LASTSEARCHPAGE = 'LASTSEARCHPAGE',
	COMPARESHOW = 'compareshow',
	PRDUNIQUEID = 'PRDUNIQUEID',
	LAST_PRODUCT = 'LastProduct',
	POSTAL_CODE = 'postalCode',
	POSTAL_CODE_SOURCE = 'postalCodeSource',
	POSTAL_CODE_STATEABBR = 'postalCodeStateAbbr',
	POSTAL_CODE_CITY = 'postalCodeCity',
	EMPLOYEE_USER_NAME = 'EMPLOYEEUSERNAME',
	EMPLOYEE_USER_ID = 'EMPLOYEEUSERID',
	NEWSLETTER_SIGNUP_OFFER = 'newsletterSignupOffer',
	COVID_19_HEADER_BANNER = 'covid19Banner',
	TEMP_SUBSCRIBE_TO_FAVORITE_ON_RETURN = 'temp_subscribeToFavoriteOnReturn',
	TEMP_FAVORITE = 'temp_favorite',
	GRID_VIEW = 'gridView',
	RECENTLY_VIEWED_ITEMS = 'RECENTLYVIEWEDITEMS',
	NICHE_CATEGORY_FLOW_MIGRATION = 'nicheCategoryFlowMigration',
	DYNAMIC_YIELD_ID = '_dyid',
	DYNAMIC_YIELD_ID_SERVER = '_dyid_server',
	DYNAMIC_YIELD_SESSION = '_dyjsession',
	IS_PRO_CUSTOMER = 'isProCustomer',
	IS_SHOWROOM_REP = 'isShowroomRep',
	AB_LIMITED_SCROLL_TO_TOP = 'abLimitedScrollToTop',
	SOLR_KEYWORD_SEARCH = 'solr-keyword-search'
}
export enum feature {
	// example used for docs
	ENABLE_FEATURE_NAME = 'feature-name',
	// location and shipping
	ESTIMATED_DELIVERY = 'estimated-delivery',
	QUICKSHIP = 'quick-ship',
	// search
	ENABLE_SAYT_TERMS_POPULAR = 'sayt-popular-terms',
	ENABLE_ELEVATED_SEARCH_SUGGESTIONS = 'ab-elevated-similar-search-suggestions',
	SEARCH_AS_YOU_TYPE = 'search-as-you-type',
	SOLR_KEYWORD_SEARCH = 'solr-keyword-search',
	FUSION_CONSTRUCT_FACETS = 'fusion-construct-facets',
	// Construct
	CONSTRUCT_HOMEPAGE = 'construct-homepage',
	// live agent
	AB_PROACTIVE_CHAT_CART = 'ab-proactive-chat-cart',
	LIVE_AGENT_PROACTIVE_CHAT = 'proactive-chat',
	DISABLE_PRECHAT_SURVEY = 'disable-prechat-survey',
	// payment
	IN_CONTEXT_PAYPAL = 'in-context-pay-pal',
	PAYPAL_PAYMENT = 'paypal-payment',
	AMAZON_PAYMENT = 'amazon-payment',
	APPLE_PAYMENT = 'apple-payment',
	ENABLE_INJECT_AMAZON_BUTTON = 'inject-amazon-payments-button',
	// Homepage
	ENABLE_AB_HOMEPAGE_LAYOUT = 'ab-homepage-layout',
	ENABLE_AB_HOMEPAGE_DATA_SWAP = 'ab-homepage-data-swap',
	ENABLE_HOMEPAGE_CONSTRUCT = 'home-page-construct',
	// bazaarvoice
	ENABLE_BAZAARVOICE_PIN = 'enableBazaarvoicePin',
	CLOUDINARY_CATEGORY_IMAGES = 'cloudinaryCategoryImages',
	// banners, modals, and popups
	LOGIN_POPUP = 'loginPopup',
	// 3rd party analytics and tracking
	ENABLE_GOOGLE_UNIVERSAL_ANALYTICS = 'google-universal-analytics',
	// cart v2
	ENABLE_CART_V2_SHOPPING_LISTS = 'enableCartV2_ShoppingLists',
	// projects
	SHOW_ASSIGN_EXPERT_TO_PROJECT = 'assign-expert-to-project',
	PROJECT_RECOMMENDATIONS = 'project-recommendations',
	PROJECTS_ERROR_HANDLING = 'projects-error-handling',
	PROJECT_ROOM_CHECKLISTS = 'projects-room-checklists',
	PROJECTS_INSPIRATION = 'projects-inspiration',
	PROJECTS_PMG_TOOL = 'projects-pmg-tool',
	PROJECTS_ITEM_COMMENTS = 'projects-item-comments',
	ADD_FAVORITE_TO_PROJECT = 'add-favorite-to-project',
	PROJECTS_DOWNLOAD_PORTFOLIO = 'download-project-portfolio-pdf',
	PROJECT_ONBOARDING_FLOW = 'project-onboarding-flow',
	EMAIL_PROJECT_CARTS = 'email-project-carts',
	PROJECTS_SAMPLE_PROJECT = 'projects-sample-project',
	PROJECTS_SHOP_BY_LOOK = 'projects-shop-by-look',
	PROJECTS_LANDING_SHOP_BY_LOOK_CAROUSEL = 'projects-landing-shop-by-look-carousel',
	PROJECTS_SHOP_BY_LOOK_CAROUSEL = 'projects-shop-by-look-carousel',
	PROJECTS_QUICK_START_REACTIONS = 'projects-quick-start-reactions',
	PROJECTS_TRENDS_AND_INFLUENCES = 'projects-trends-and-influences',
	PROJECTS_SP_CODE = 'projects-sp-code',
	PROJECTS_BULK_ADD = 'projects-bulk-add',
	PROJECTS_PERMISSIONS = 'projects-permissions',
	PROJECTS_LOCK = 'projects-lock',
	PROJECTS_NEW_PORTFOLIO_SERVICE = 'projects-new-portfolio-service',
	PROJECTS_PORTFOLIO_TERMS_AND_CONDITIONS = 'projects-portfolio-terms-and-conditions',
	PROJECTS_FIND_OPTIMIZATIONS = 'projects-find-optimizations',
	// Product
	PRODUCT_PRICING_BY_UOM = 'product-pricing-by-packaging',
	AB_PRODUCT_EXPANDABLE = 'ab-pdp-expandables',
	// recommendations
	ENABLE_DYNAMIC_YIELD = 'enable-dynamic-yield',
	ENABLE_DYNAMIC_YIELD_EVENTS = 'enable-dynamic-yield-events',
	ENABLE_DYNAMIC_YIELD_PLACEMENTS = 'enable-dynamic-yield-placements',
	ENABLE_DYNAMIC_YIELD_RECENTLY_VIEWED = 'enable-dynamic-yield-recently-viewed',
	ENABLE_CONSTRUCT_DYNAMIC_RECOMMENDATIONS = 'enable-construct-dynamic-recommendations',
	ENABLE_DYNAMIC_YIELD_MAY_WE_SUGGEST = 'enable-dynamic-yield-may-we-suggest',
	ENABLE_APPLIANCE_PACKAGE_TEST = 'dynamic-yield-appliance-package-test',
	DYNAMIC_YIELD_DEFER_LOAD = 'dynamic-yield-defer-load',
	PRODUCT_DROP_INLINE_RECOMMENDATIONS = 'product-drop-inline-recommendations',
	// other
	ADD_CART_TO_PROJECT = 'add-cart-to-project',
	ASYNC_PRODUCT_DROPS = 'asynchronous-product-drops',
	PASSWORD_COMPLEXITY_VIOLATION_CHECK = 'password-complexity-violation-check',
	APPOINTMENT_ONLY_BRANDS = 'appointment-only-brands',
	SERVICE_WORKER = 'service-worker',
	WEB_APP_MANIFEST = 'web-app-manifest',
	FREIGHT_CLAIMS = 'freight-claims',
	ALTERNATE_THIRD_PARTY_CART_BUTTONS = 'alternate-third-party-cart-buttons',
	CSRF_TOKEN = 'csrf-prevention-tokens',
	ENABLE_SALES_RESTRICTION_CTA = 'enable-sales-restriction-cta',
	DELIVERY_ADDRESS_CONFIRMATION_MESSAGING = 'delivery-address-confirmation-messaging',
	SHOW_HOME_PAGE_CATEGORIES = 'show-home-page-categories',
	SHOW_CATEGORY_BOTTOM_DESCRIPTION = 'show-category-bottom-description',
	NAMOGOO_HIJACKING_PREVENTION = 'namogoo-hijacking-prevention',
	ADDRESS_AUTO_COMPLETE = 'address-auto-complete',
	KEY_CODE_VALIDATION = 'key-code-validation',
	ENABLE_TTR_TAX_EXEMPTIONS = 'enable-ttr-tax-exemptions',
	ENABLE_SALE_PRICING = 'enable-sale-pricing',
	AB_PDP_PRO_SAVINGS = 'ab-pdp-pro-savings',
	ENABLE_ECO_REBATES = 'eco-rebates',
	ENABLE_OMC_REBATES = 'omc-rebates',
	PRO_PRICING = 'pro-pricing',
	INVOKE_MARKETING_EMAILS_FOR_NEWSLETTER_SUBSCRIPTIONS = 'invoke-marketing-emails-for-newsletter-subscriptions',
	ONE_PAGE_CHECKOUT_LOGIN = 'one-page-checkout-login',
	INLINE_SEARCH_FEEDBACK = 'inline-search-feedback',
	CHECKOUT_V2 = 'checkout-v2',
	ORDER_HISTORY_BUY_AGAIN = 'order-history-buy-again',
	ORDER_AGAIN = 'order-again',
	ORDER_HISTORY_SEARCH = 'order-history-search',
	ORDER_HISTORY_RELATED_ITEMS = 'order-history-related-items',
	NEW_ORDER_SUMMARY_IMPROVEMENTS = 'new-order-summary-improvements',
	// five9 callback queue
	FIVE9_CALLBACK_QUEUE = 'five-9-callback-que',
	CREATE_CREDIT_CARD_GATEWAY_IFRAME_VIA_CLIENT_SIDE_JS = 'create-credit-card-gateway-iframe-via-client-side-js',
	// Showroom
	SHOWROOM_PRODUCT_REDIRECT = 'showroom-product-redirect',
	SHOWROOM_PRO_PRICING = 'showroom-pro-pricing',
	// Products Top of Funnel
	MANUFACTURER_SALE_MESSAGE = 'manufacturer-sale-message',
	VIDEO_GALLERY = 'video-gallery',
	PLA_TEXT_LINK = 'pla-text-link',
	AB_PDP_BUY_SECTION = 'ab-pdp-buy-section',
	AB_PLA_QUICKSHIP_FREE = 'ab-pla-qs-free',
	AB_HOMEPAGE_PRO_SPLASH = 'ab-homepage-pro-splash',
	BUILD_FERG_CREDITCARD_PROMO = 'build-ferg-creditcard-promo',

	// Prerequisites
	EXPERIMENT_PREREQUISITE = 'experiment-prerequisite',
	// global
	ENABLE_GLOBAL_SITE_WIDE_BANNER = 'enable-global-site-wide-banner',
	ENABLE_COVID_BANNER = 'nodestore-covid-message',
	ENABLE_CA_WILD_FIRES = 'ca-wild-fires',
	ENABLE_EXTREME_WEATHER_CONDITIONS = 'extreme-weather-conditions',
	PRO_PARTICIPATION_DISCOUNT_SAME_AS_CONSUMER = 'pro-participation-discount-same-as-consumer',

	SIGNIFYD_DEVICE_FINGERPRINTING = 'signifyd-device-fingerprinting',

	CALL_CENTER_PURCHASE_ONLY = 'call-center-purchase-only',
	DISABLE_SAFARI_CC_GATEWAY_REDIRECT = 'disable-safari-cc-gateway-redirect',
	TOKENIZE_WITH_DEFAULT_ADDRESS = 'tokenize-with-default-address',
	SHOW_RECENTLY_VIEWED = 'show-recently-viewed-widget',
	AB_SAVE_FOR_LATER = 'ab-save-for-later',
	GOOGLE_TAG_MANAGER = 'google-tag-manager',
	AB_PRO_PROJECT_TOOL_BANNER = 'pro-project-tool-banner',
	ENABLE_ECRM_RETURN_LABEL_MANAGEMENT = 'enable-ecrm-return-label-management',
	ENABLE_ECRM_RETURN_ADVANCE_CREDIT = 'enable-ecrm-return-advance-credit',
	CONTACT_CENTER_NUMBER = 'contact-center-number',
	AB_DEACTIVATE_HEADER_CONTACT_CENTER_NUMBER = 'deactivate-header-contact-center-number',
	SEPARATE_FREIGHT_INSTRUCTIONS = 'separate-freight-instructions-for-remote-vs-in-person',
	MANAGED_PURCHASE_DISPLAY = 'managed-purchase-display',
	ENABLE_VIZURY_OPT_IN_POPUP = 'enable-vizury-opt-in-popup',
	ENABLE_EMAIL_SUBSCRIPTION_POPUP = 'enable-email-subscription-popup',
	RBS_INTERNAL_CART = 'rbs-internal-cart',
	RBS_INTERNAL_CHECKOUT = 'rbs-internal-checkout',
	RBS_ADD_CREDIT_CARD_PAGE = 'rbs-add-credit-card-page',
	RBS_RECEIPT_PAGE = 'rbs-receipt-page',
	OPEN_ACCOUNT = 'open-account'
}

export enum bannerType {
	EMAIL = 'email',
	APP = 'app',
	PWA = 'pwa'
}
export const checkoutType = {
	BLUETARP: 'bluetarp',
	GUEST: 'guest'
};
export enum addressFormFields {
	FIRST_NAME = 'First Name',
	LAST_NAME = 'Last Name',
	FULL_NAME = 'Full Name (First and Last)',
	COMPANY_NAME = 'Company Name',
	ADDRESS_LINE_1 = 'Street Address',
	ADDRESS_LINE_2 = 'Apartment/Suite',
	CITY = 'City',
	STATE = 'State/Province',
	ZIP = 'Zip Code',
	PHONE = 'Phone'
}
export enum friendlyStatuses {
	Amending = 1,
	Approved = 2,
	Cancelled = 3,
	Entered = 4,
	Partialship = 5,
	PaymentHold = 6,
	Processed = 7,
	Reconciled = 8,
	Risk = 9,
	Tied = 10
}
export enum CONSTRUCT_SHARED_HOMEPAGE_SECTIONS {
	DIVIDER = 'divider',
	CENTERED_IMAGE = 'centeredImage',
	DYNAMIC_RECOMMENDATIONS_1 = 'dynamicRecommendations1',
	DYNAMIC_RECOMMENDATIONS_2 = 'dynamicRecommendations2'
}
export enum CONSTRUCT_DESKTOP_HOMEPAGE_SECTIONS {
	FAVORITE_LIST = 'favorites',
	IMAGE_GROUP = 'imagesGroup',
	FAVORITES = 'favorites',
	CLUSTER_IMAGES = 'clusterImageGroup',
	BODY_COPY = 'bodyCopy',
	NATIVE_APP_AD = 'nativeAppAd',
	CLEARANCE_CENTER = 'clearanceCenter',
	FEATURED_BRANDS = 'featuredBrands',
	IMAGE_GRID = 'imageGrid'
}
export enum CONSTRUCT_MOBILE_HOMEPAGE_SECTIONS {
	POPULAR_CATEGORIES = 'popularCategories',
	IMAGE_GROUP = 'mobileImageGroup'
}

export enum productDropViewType {
	grid = 'grid',
	list = 'list',
	gallery = 'gallery'
}

export enum NewsletterOriginationContext {
	FOOTER_ENTRY = 'FOOTER_ENTRY',
	POPUP_ENTRY = 'POPUP_ENTRY',
	SECURE_ACCOUNT_CREATION = 'SECURE_ACCOUNT_CREATION',
	CHECKOUT_AND_SECURE_ACCOUNT_CREATION = 'CHECKOUT_AND_SECURE_ACCOUNT_CREATION',
	CHECKOUT = 'CHECKOUT',
	NONE = 'NONE'
}

export enum CART_TYPE {
	UNKNOWN = 'UNKNOWN',
	SHOPPING = 'SHOPPING',
	QUOTE = 'QUOTE',
	SAVED = 'SAVED',
	PROJECT_SHOPPING_LIST = 'PROJECT_SHOPPING_LIST',
	SAVE_FOR_LATER = 'SAVE_FOR_LATER'
}

export enum CART_STATUS {
	OPEN = 'OPEN',
	LOCKED = 'LOCKED',
	DELETED = 'DELETED'
}

export enum SHIPPING_CLASSIFICATION {
	PARCEL_QUICKSHIP = 'parcel-quickship',
	PARCEL_ESTIMATE = 'parcel-estimate',
	PARCEL_LEADTIME = 'parcel-leadtime',
	PARCEL_NOZIP = 'parcel-nozip',
	PARCEL_FREESHIPPING = 'parcel-freeshipping',
	FREIGHT_QUICKSHIP = 'freight-quickship',
	FREIGHT_ESTIMATE = 'freight-estimate',
	FREIGHT_LEADTIME = 'freight-leadtime',
	FREIGHT_FREESHIPPING = 'freight-freeshipping',
	MADE_TO_ORDER = 'made-to-order',
	GE = 'ge',
	PRE_ORDER = 'pre-order'
}

export enum FREESHIPPING_THRESHOLDS {
	PARCEL = 49,
	FREIGHT = 1499
}

export enum SESSION_STORAGE {
	CART_SUBTOTAL = 'cartSubTotal',
	IS_PREVIOUS_CART_QUICKSHIP = 'isPreviousCartQuickShip',
	PREVIOUS_CART_HAS_QUICKSHIP_ITEM = 'previousCartHasQuickShipItem',
	CART_ITEMS_WITH_LEAD_TIME_INFO = 'cartItemsWithLeadTimeInfo'
}

export enum DELIVERY_METHOD {
	UPS_API = 'UPS_API',
	LEAD_TIME_OFFSET = 'LEAD_TIME_OFFSET',
	VENDOR_SERVICE = 'VENDOR_SERVICE'
}

export enum MERGE_POSTAL_CODE_VALIDATION_BEHAVIOR {
	ALLOW = 'ALLOW',
	DISALLOW = 'DISALLOW',
	IGNORE = 'IGNORE'
}

export const ZIP_SOURCE_TYPE = {
	CUSTOMER_PROVIDED: 'CUSTOMER_PROVIDED',
	CUSTOMER_PROVIDED_UNIVERSAL: 'CUSTOMER_PROVIDED_UNIVERSAL'
};

export enum PRICE_OVERRIDE_REASON {
	EPP = 14
}

export enum LOCATION_ERROR {
	INVALID_ZIP = 'Please enter a valid US or Canadian zip code.'
}

export enum SEARCH_ERROR {
	OUT_OF_BOUNDS = 'Page number out of bounds'
}

export enum ERROR_TYPE {
	VALIDATION = 'VALIDATION'
}

// Expected values for the ssrc query parameter.
export enum searchSource {
	SIMILAR_QUERIES = 'similar_queries',
	SAYT_TERMS = 'sayt_terms',
	TRENDING_TERMS = 'trending_terms',
	RECENT_SEARCHES = 'recent_searches'
}

export enum proType {
	OWNED = 'OWNED',
	VERIFIED = 'VERIFIED',
	SELF_IDENTIFIED = 'SELF_IDENTIFIED',
	SUSPECTED = 'SUSPECTED',
	UNVERIFIED = 'UNVERIFIED',
	UNKNOWN = 'UNKNOWN'
}

export enum COMMUNICATION_METHOD {
	CHAT = 'CHAT',
	PHONE = 'PHONE'
}

export enum LIVE_AGENT_POPUP_TYPE {
	PROACTIVE = 'proactive'
}

export enum KEYCODE_VALIDATION {
	INVALID_KEYCODE = 'INVALID_KEYCODE',
	NO_COMMON_KEYWAY = 'NO_COMMON_KEYWAY',
	PRODUCT_MISSING_KEYWAY = 'PRODUCT_MISSING_KEYWAY'
}

export enum PRODUCT_OPTION_VALIDATION {
	OPTION_TOO_LONG_CODE = 'CART-41',
	OPTION_TOO_LONG_MSG = 'Please enter no more than 24 characters'
}

export enum SHOWROOM {
	URL_PREFIX = 'showroom'
}

export enum PRICEBOOK_IDS {
	TRILOGIE_PRICEBOOK_ID = 999
}

export enum PROOJECTS_ERROR {
	PROJECTID_MISSING = 'Missing param: projectId'
}

export const ADVANCED_CREDIT_REASONS = {
	REFUSED_DELIVERY: {
		id: 1,
		reason: 'refusedDelivery'
	},
	UNDER_35: {
		id: 2,
		reason: 'under35'
	},
	TRACKING_NUMBER: {
		id: 3,
		reason: 'trackingNumber'
	},
	OTHER: {
		id: 4,
		reason: 'other'
	},
	RETURNED_TO_BRANCH: {
		id: 5,
		reason: 'returnedToBranch'
	}
};

export enum PROJECT_IDENTITY_TYPE {
	SESSION = 'SESSION',
	PLATFORM_CUSTOMER = 'PLATFORM_CUSTOMER',
	CUSTOMER = 'CUSTOMER',
	EMPLOYEE = 'EMPLOYEE',
	SESSION_AND_EMPLOYEE = 'SESSION_AND_EMPLOYEE',
	CUSTOMER_AND_EMPLOYEE = 'CUSTOMER_AND_EMPLOYEE'
}
