const Backbone = require('backbone');
require('slick-carousel');
const Carousel = Backbone.View.extend({
	initialize: function() {
		this.$carousel = this.$el.find('.js-carousel-content');
		this.isLarge = this.$el.data('large-carousel');
		this.setupSlick();
	},

	setupSlick: function() {
		this.$carousel.slick({
			arrows: window.BCOM.device.isDesktop,
			centerMode: window.BCOM.device.isMobile,
			centerPadding: '25px',
			slidesToShow: 1,
			slidesToScroll: 1,
			mobileFirst: true,
			prevArrow:
				'<button type="button" class="bg-theme-grey-medium br-100 slick-prev slick-grey-chevron z-5" style="width: 47px; height:47px" />',
			nextArrow:
				'<button type="button" class="bg-theme-grey-medium br-100 slick-next slick-grey-chevron z-5" style="width: 47px; height:47px"/>',
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: this.isLarge ? 3 : 4,
						slidesToScroll: this.isLarge ? 3 : 4
					}
				},
				{
					breakpoint: 769,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3
					}
				}
			]
		});
		this.$carousel.removeClass('hidden');
	}
});

module.exports = Carousel;
