/**
 * Sanitize: to remove illegal characters from data
 * Intended for use both client and server side
 */
import validator from 'validator';
import { String } from 'sugar';
export function stripHtmlFromString(str?: string): string {
	return String(str || '')
		.stripTags()
		.trim().raw;
}
export function stripHtmlFromObjectValues(obj: Object): Object {
	const keys = Object.keys(obj);
	return keys.reduce((result, currentKey) => {
		const currentValue = obj[currentKey];
		if (typeof currentValue === 'string' && currentKey.toLowerCase() !== 'password') {
			result[currentKey] = this.stripHtmlFromString(currentValue);
		} else if (typeof currentValue === 'object' && currentValue !== null) {
			result[currentKey] = this.stripHtmlFromObjectValues(currentValue);
		} else {
			result[currentKey] = currentValue;
		}
		return result;
	}, {});
}
export function sanitizeUniqueId(uniqueId: Array<string | number> | string | number | Object): string | null {
	const validatorOptions = {
		min: 1,
		max: 2147483647
	};
	if (uniqueId || typeof uniqueId !== 'object') {
		if (Array.isArray(uniqueId) && uniqueId.length) {
			uniqueId = uniqueId[0];
		}
		uniqueId = uniqueId.toString().replace(/\D+/gi, '');
		if (validator.isInt(uniqueId as string, validatorOptions)) {
			return uniqueId as string;
		}
	}
	return null;
}
