const { findTrackedField } = require('views/store/common/omniture/track_inline_errors_helper');
const Backbone = require('backbone');
require('validate');
require('jquery.scrollto');

/**
 *
 * @param {HTMLElement} element
 * @param {boolean} isError
 * @returns {void}
 */
function highlightElement(element, isError) {
	let removeClassGroup = 'has-error',
		addClassGroup = 'has-success';

	if (isError) {
		removeClassGroup = 'has-success';
		addClassGroup = 'has-error';
	}

	$(element)
		.closest('.form-group')
		.removeClass(removeClassGroup)
		.addClass(addClassGroup);
}
/**
 * @param {HTMLElement} el
 * @return {void}
 */
function scrollToError(el) {
	$(window).scrollTo($(el).offset().top - 75, 500);
}

const formValidator = function() {
	let alreadyValidated = false;
	const validateDefaults = {
		focusInvalid: false,
		scrollToError: true,
		/**
		 * @param {JQuery} error
		 * @param {JQuery} element
		 * @returns {void}
		 */
		errorPlacement: function(error, element) {
			alreadyValidated = true;
			if (
				$(element)
					.parent()
					.hasClass('button-group') ||
				$(element)
					.parent()
					.hasClass('input-group')
			) {
				error.insertAfter($(element).parent());
			} else {
				error.appendTo($(element).parent());
			}
		},
		/**
		 * @param {JQuery} label
		 * @param {HTMLElement} element
		 * @returns {void}
		 */
		success: function(label, element) {
			$(element)
				.siblings('.error')
				.remove();
		},
		/**
		 * @param {JQueryEventObject} e
		 * @param {JQueryValidation.Validator} validator
		 * @returns {void}
		 */
		invalidHandler: function(e, validator) {
			if (
				validateDefaults.scrollToError &&
				validator.errorList &&
				validator.errorList.length &&
				!$(e.currentTarget).closest('.modal').length
			) {
				scrollToError(validator.errorList[0].element);
			}
		},
		/**
		 * @param {HTMLElement} element
		 * @returns {void}
		 */
		onfocusout: function(element) {
			const trackedField = findTrackedField(element.name, element.form.className);

			if (!$(element).valid() && trackedField) {
				if (trackedField.iframeCallback) {
					trackedField.iframeCallback();
				} else {
					Backbone.trigger('omniture:trackValidationError', {
						message: trackedField.message,
						iframeCallback: trackedField.iframeCallback
					});
				}
			}
		},
		/**
		 * @param {HTMLElement} element
		 * @returns {void}
		 */
		highlight: function(element) {
			highlightElement(element, true);
		},
		/**
		 * @param {HTMLElement} element
		 * @returns {void}
		 */
		unhighlight: function(element) {
			highlightElement(element, false);
		}
	};

	return {
		/**
		 * @typedef {{ $el: JQuery, validator: JQueryValidation.Validator, validateOptions: JQueryValidation.ValidationOptions & { scrollToError?: boolean } }} FormProperties
		 * @param {{ el: string | JQuery, validate?: JQueryValidation.ValidationOptions }} options
		 * @returns {ThisType<JQueryValidation.ValidationOptions & FormProperties>}
		 */
		constructor: function(options) {
			this.$el = typeof options.el === 'string' ? $(options.el) : options.el;
			this.options = options;
			this.validateOptions = window.Object.assign(validateDefaults, options.validate);
			this.validator = this.$el.validate(this.validateOptions);
			return this;
		},
		/**
		 * @returns {boolean}
		 */
		hasBeenValidated: function() {
			return alreadyValidated;
		},
		/**
		 * @returns {void}
		 */
		hide: function() {
			this.$el.find('.error').remove();
		},
		/**
		 * @returns {void}
		 */
		removeValidationStates: function() {
			this.$el.find('.has-success').removeClass('has-success');
			this.$el.find('.has-warning').removeClass('has-warning');
			this.$el.find('.has-error').removeClass('has-error');
		},
		/**
		 * @returns {void}
		 */
		reset: function() {
			this.hide();
			this.removeValidationStates();
		}
	};
};

module.exports = {
	/**
	 * @param {{ el: string | JQuery, validate?: JQueryValidation.ValidationOptions & { scrollToError?: boolean } }} options
	 */
	init: function(options) {
		return formValidator().constructor(options);
	}
};
