var cbs = [], 
	data;
module.exports = function(cb) {
	if(cbs) cbs.push(cb);
	else cb(data);
}
require.ensure([], function(require) {
	data = require("!!../../../../../../node_modules/babel-loader/lib/index.js??ref--6-0!./browse.page.js");
	var callbacks = cbs;
	cbs = null;
	for(var i = 0, l = callbacks.length; i < l; i++) {
		callbacks[i](data);
	}
}, "categories.browse.page");