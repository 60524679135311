// self invoking module handles collapsible boxes
const Backbone = require('backbone');
const CollapsibleBox = Backbone.View.extend({
	el: 'body',

	events: {
		'click [data-collapsible-toggle]': '_toggleCollapsible'
	},

	_toggleCollapsible: function(e) {
		const $toggleElem = $(e.target),
			box = $toggleElem.closest('[data-collapsible]'),
			expanded = box && box.hasClass('collapsed');
		if (box) {
			const text = expanded ? $toggleElem.data('collapse-text') : $toggleElem.data('expand-text');
			box.toggleClass('collapsed');
			$toggleElem.text(text);
		}
	}
});

module.exports = new CollapsibleBox();
