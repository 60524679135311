const Backbone = require('backbone');
const window = require('window');
const formValidator = require('formValidator');
const customValidationRules = require('customValidationRules');
const gtmHelper = require('lib/helpers/gtm_helper');

require('views/store/common/collapsible_box');
require('serializeObject');

const JAVA_MAX_INT = 2147483647;

module.exports = Backbone.View.extend(
	/** @type {BackboneExtensions.FindGuestOrderForm} */ ({
		el: '.js-find-order-form',
		events: {
			submit: 'handleSubmit',
			'blur input': '_trimWhiteSpace'
		},
		/**
		 * @returns {void}
		 */
		initialize: function() {
			this.$orderStatusError = this.$('.js-order-status-error');
			this.$orderStatusWarning = this.$('.js-order-status-warning');
			this.$referenceIdInput = this.$('.js-reference-id-input');
			this.findGuestOrderMethod = this.$el.data('findGuestOrderMethod') || 'order_receipt';
			this.initValidators();
			this.validator = formValidator.init({
				el: this.$el,
				validate: {
					rules: {
						referenceId: {
							required: true,
							number: true,
							// Prevent old orders from being entered (orders before they were uniquely identified). The backend only
							// supports return one order and numbers below 25883 will return an error.
							range: [25883, JAVA_MAX_INT]
						},
						zip: {
							required: true,
							'validate-zip': true
						}
					},
					messages: {
						referenceId: {
							required: 'Please enter your Order # or PO #.',
							range: 'Please enter a valid Order # or PO #',
							number: 'Your input must contain numbers only. Ignore anything before the dash in a PO number.'
						},
						zip: {
							required: 'Please enter your billing postal code.'
						}
					}
				}
			});

			$('[data-toggle="popover"]').popover({
				trigger: 'hover',
				placement: 'top',
				title: 'How to find..',
				html: true,
				sanitize: true
			});

			if (this.$el.hasClass('js-autofocus') && !this.$referenceIdInput.is(':focus')) {
				this.$referenceIdInput.focus();
			}
		},
		/**
		 * @private
		 * @param {Event} e
		 * @returns {void}
		 */
		_trimWhiteSpace: function(e) {
			const target = /** @type {HTMLInputElement} */ (e.currentTarget);
			this.$(target).val(target.value.trim());
		},
		/**
		 * @param {Event} e
		 * @returns {void}
		 */
		handleSubmit: function(e) {
			e.preventDefault();

			if (!this.$el.valid()) {
				this.handleTracking('formInvalid');
				return;
			}
			const { referenceId, zip } = this.$el.serializeObject();
			this.$el.addClass('is-loading');
			this.hideError();
			this.hideWarning();
			this.handleTracking('submit');
			$.ajax({
				url: `/app/api/orders/find?referenceid=${referenceId}&zip=${zip}`,
				success: (response) => {
					if (response && response.status && response.orderNumber) {
						if (response.isMarketplaceOrder) {
							this.showWarning();
						} else {
							this.handleTracking(
								'success',
								`/order/${this.findGuestOrderMethod === 'track_order' ? 'shipments' : 'receipt'}/${
									response.orderNumber
								}?zip=${zip}`
							);
						}
					} else {
						this.handleTracking('failure');
						window.BCOM.notifications.render(response.message, 'danger');
						this.showError();
					}
					this.$el.removeClass('is-loading');
				},
				error: () => {
					this.handleTracking('failure');
					this.$el.removeClass('is-loading');
					window.BCOM.notifications.render('An error has occurred, please try again or call in for help.', 'danger');
					this.showError();
				}
			});
		},
		/**
		 * @returns {void}
		 */
		initValidators: function() {
			customValidationRules.add('zip');
		},
		/**
		 * @returns {void}
		 */
		showError() {
			this.$orderStatusError.removeClass('hide');
		},
		/**
		 * @returns {void}
		 */
		hideError() {
			this.$orderStatusError.addClass('hide');
		},
		/**
		 * @returns {void}
		 */
		showWarning() {
			this.$orderStatusWarning.removeClass('hide');
		},
		/**
		 * @returns {void}
		 */
		hideWarning() {
			this.$orderStatusWarning.addClass('hide');
		},
		/**
		 * @param {string} type
		 * @param {*} redirect
		 */
		handleTracking(type, redirect) {
			const linkName = `FindGuestOrder:${type}:${window.dataLayer.page}`;

			gtmHelper.fireEvent({
				event: 'generic-click-tracking',
				linkName
			});

			Backbone.trigger('track:link', {
				linkName,
				redirect
			});
		}
	})
);
