const Backbone = require('backbone'),
	{ SAYTModel } = require('models/store/client/sayt/sayt'),
	dust = require('dustjs'),
	saytResultsDust = require('common/gbi/sayt_results.dust'),
	saytTermsDust = require('common/gbi/sayt_terms.dust'),
	saytProductsDust = require('common/gbi/sayt_products.dust'),
	{ searchSource } = require('lib/enums'),
	_ = require('underscore');

require('common/gbi/sayt_products.dust');
require('common/_close_menu.dust');

module.exports = Backbone.View.extend({
	keycodes: {
		UPARROW: 38,
		DOWNARROW: 40,
		LEFTARROW: 37,
		RIGHTARROW: 39,
		ENTER: 13,
		TAB: 9,
		ESCAPE: 27
	},
	events: {
		'focus .js-site-search-input': '_expandInput',
		'input .js-site-search-input': '_doSAYTSearch',
		'click .js-suggested-term': '_searchSuggestedTerm',
		'click .js-sayt-close': '_hideSAYT',
		'keydown .js-sayt-navigation': '_saytNavigation',
		'mouseenter .js-suggested-term, .js-trending-term': '_handleTermHover',
		'mouseleave .js-suggested-term, .js-trending-term': '_handleTermMouseLeave',
		submit: '_submitSearch'
	},

	/**
	 * @returns {void}
	 */
	initialize: function() {
		this.resultShowing = false;
		this.$searchInput = this.$el.find('.js-site-search-input');
		this.$saytTerms = this.$el.find('.js-sayt-results');
		this.$saytProducts = this.$el.find('.js-suggested-products');

		this.isLivingDirect = window.BCOM.theme.isLivingDirect;
		this.device = window.BCOM.device.deviceType;
		this.showProducts = window.BCOM.device.isDesktop;
		if (this.isLivingDirect) {
			this.$navOverlay = $('#headerOverlay');
		} else {
			this.$navOverlay = $('.nav-overlay');
		}

		let maxSuggestions;
		if (this.device === 'desktop') {
			maxSuggestions = 10;
		} else if (this.device === 'tablet') {
			maxSuggestions = 7;
		} else {
			maxSuggestions = 5;
		}

		this.saytModel = new SAYTModel({ maxSuggestions, fetchProducts: this.showProducts });

		this._throttledSAYTCall = _.throttle(() => {
			this._getSAYTSuggestions().then(this._renderSAYT.bind(this));
		}, 750);

		// These need to be global
		$('body').on('mousedown', this._narrowInput.bind(this));
		// This handles cases for users tabbing through the site.
		$('body').on('focus', '*', () => {
			this._narrowInput.bind(this);
			this._removeFocusEffectTermSuggestion($('.sayt-term-selected-term-suggestion'));
			this._removeFocusEffectProductSuggestion($('.sayt-term-selected-product-suggestion'));
		});
	},

	/**
	 * @private
	 * @returns {void}
	 */
	_showNavOverlay: function() {
		if (this.isLivingDirect) {
			this.$navOverlay.stop().fadeIn();
		} else {
			this.$navOverlay.addClass('active');
		}
	},

	/**
	 * @private
	 * @returns {void}
	 */
	_hideNavOverlay: function() {
		if (this.isLivingDirect) {
			this.$navOverlay.stop().fadeOut();
		} else {
			this.$navOverlay.removeClass('active');
		}
	},

	/**
	 * @private
	 * @returns {void}
	 */
	_showSAYTLoadingOverlay: function() {
		this.$el.find('.js-sayt-loading-overlay').removeClass('hide');
	},

	/**
	 * @private
	 * @returns {void}
	 */
	_showSAYTProductsLoadingOverlay: function() {
		this.$el.find('.js-sayt-products-loading-overlay').removeClass('hide');
	},

	/**
	 * @private
	 * @returns {void}
	 */
	_hideAllOverlays: function() {
		this.$el.find('.loading').addClass('hide');
	},

	/**
	 * @private
	 * @returns {void}
	 */
	_hideSuggestedTermsAndProducts: function() {
		this.$saytTerms.html('');
		this.$saytProducts.html('');
	},

	/**
	 * @private
	 * @param {Event} e
	 * @returns {void}
	 */
	_submitSearch: function(e) {
		const $currentSearchForm = $(e.target).closest('form');
		const currentSearchValue = String($currentSearchForm.find('.js-site-search-input').val()).trim();

		if (!currentSearchValue) {
			e.preventDefault();
		}

		Backbone.trigger('search:submit omniture:searchQuery', {
			query: currentSearchValue
		});
		window.BCOM.util.trackKeywordSearch(currentSearchValue);
	},

	/**
	 * @private
	 * @returns {void}
	 */
	_expandInput: function() {
		this.resultShowing = true;
		if (this.device !== 'mobile') {
			const headerSearchIsWide = this.$el.closest('.js-header-search').hasClass('wide');
			if (!headerSearchIsWide) {
				Backbone.trigger('search:expanded');
			}
		}

		Backbone.trigger('search:focus');

		this.$saytTerms.fadeIn();
		this._showNavOverlay();
	},

	/**
	 * @private
	 * @param {Event} e
	 * @returns {void}
	 */
	_narrowInput: function(e) {
		const $clickTarget = $(e.target);

		if (
			!$clickTarget.closest('.js-header-search, .js-nav-container, #new-shipping-banner, .js-location-wrapper, .js-location-form')
				.length ||
			e.keyCode === this.keycodes.ESCAPE ||
			e.keyCode === this.keycodes.TAB
		) {
			Backbone.trigger('search:narrowed');
			this._hideSAYT();
			$('.js-sayt-navigation').removeClass('b--theme-primary ba');
		}
	},

	/**
	 * changing the URL instead of submitting the form so we don't double track via omniture
	 * @private
	 * @param {Event} e
	 * @returns {void}
	 */
	_searchSuggestedTerm: function(e) {
		e.preventDefault();

		const newSearchTerm = $(e.currentTarget).text();
		window.BCOM.util.trackKeywordSearch(newSearchTerm);
		window.location.assign(`/search?term=${newSearchTerm}&ssrc=${searchSource.SAYT_TERMS}`);
	},

	/**
	 * @private
	 * @param {string} currentSearchTerm
	 * @param {string} saytTerm
	 * @returns {string}
	 */
	_boldSearchSubstring: function(currentSearchTerm, saytTerm) {
		return saytTerm.replace(currentSearchTerm, `<b>${currentSearchTerm}</b>`);
	},

	/**
	 * @private
	 * @param {string} term
	 * @returns {{term: string, isRecommendedTerm: boolean}}
	 */
	_nonRecommendedTermsMapping: function(term) {
		return {
			term,
			isRecommendedTerm: false
		};
	},

	/**
	 * @private
	 * @returns {Promise<Array<{term: string, isRecommendedTerm: boolean}>>}
	 */
	_getSAYTSuggestions: function() {
		const searchTerm = this.$searchInput.val();
		this.saytModel.setSearchTerm(searchTerm);

		return new Promise((resolve) => {
			this.saytModel.fetchProductsAndTerms().then(({ productDrops, suggestions }) => {
				let terms = suggestions.map(this._nonRecommendedTermsMapping);

				if (productDrops.length) {
					resolve({ products: productDrops, terms });
				} else {
					this._getSAYTProductsUsingFirstSuggestedTerm(resolve, terms);
				}
			});
		});
	},

	/**
	 * @private
	 * @param {Function} parentResolve
	 * @param {Array<{term: string}>} saytTerms
	 * @returns {void}
	 */
	_getSAYTProductsUsingFirstSuggestedTerm: function(parentResolve, saytTerms) {
		const hasNewSearchTerm = saytTerms && saytTerms.length;
		if (hasNewSearchTerm && this.showProducts) {
			this.saytModel.setSearchTerm(saytTerms[0].term);
			return this.saytModel.fetchProducts().then((productsData) => parentResolve({ products: productsData, terms: saytTerms }));
		} else {
			return parentResolve({ products: [], terms: saytTerms });
		}
	},

	/**
	 * @private
	 * @param {Array<any>} termsData
	 * @returns {Promise}
	 */
	_renderSAYTTerms: function(termsData) {
		return new Promise((resolve) => {
			const suggestedTerms = termsData
				? termsData.map((currentTerm) => {
						return {
							suggestedTerm: this._boldSearchSubstring(
								this.$searchInput
									.val()
									.trim()
									.toLowerCase(),
								currentTerm.term.trim().toLowerCase()
							),
							isRecommendedTerm: currentTerm.isRecommendedTerm,
							isDesktop: this.device === 'desktop'
						};
				  })
				: [];

			dust.render(saytTermsDust, { suggestedTerms }, (err, out) => {
				resolve(out);
			});
		});
	},

	/**
	 * @private
	 * @param {Array} productData
	 * @returns {Promise}
	 */
	_renderSAYTProducts: function(productData) {
		return new Promise((resolve) => {
			dust.render(saytProductsDust, { suggestedProducts: productData }, (err, out) => {
				resolve(out);
			});
		});
	},

	/**
	 * @private
	 * @param {Array} data
	 */
	_renderSAYT: function({ terms, products }) {
		const renderProducts = this.showProducts && products && products.length;
		dust.render(saytResultsDust, { showProducts: Boolean(renderProducts), BCOM: window.BCOM }, (err, out) => {
			let $out = $(out);
			const termsExist = terms && terms.length;

			if (termsExist || renderProducts) {
				const toRender = [this._renderSAYTTerms(terms)];

				if (renderProducts) {
					toRender.push(this._renderSAYTProducts(products));
				}

				Promise.all(toRender).then((renderedTemplates) => {
					const [renderedTerms, renderedProducts] = renderedTemplates;
					$out.find('.js-suggested-search-terms').append(renderedTerms);

					if (renderProducts) {
						$out.find('.js-suggested-products').append(renderedProducts);
					}

					this.$saytTerms.html($out);

					this._hideAllOverlays();
				});
			} else {
				this._hideSuggestedTermsAndProducts();
			}
		});
	},

	/**
	 * @private
	 * @returns {void}
	 */
	_doSAYTSearch: function() {
		const searchTerm = this.$searchInput.val().trim();

		if (!searchTerm) {
			this._hideSuggestedTermsAndProducts();
			Backbone.trigger('suggested-search:emptied');
		} else {
			if (!this.resultShowing) {
				this._expandInput();
			}
			if (this.showProducts) {
				this._showSAYTLoadingOverlay();
			}
			this._throttledSAYTCall();
		}
	},

	/**
	 * @private
	 * @param {Event} e
	 * @returns {void}
	 */

	_handleTermHover: function(e) {
		this._addTermSuggestionHoverEffect(e);
	},

	/**
	 * @private
	 * @returns {void}
	 */
	_hideSAYT: function() {
		const headerSearchIsWide = this.$el.closest('.js-header-search').hasClass('wide');
		if (headerSearchIsWide) {
			Backbone.trigger('search:narrowed');
		}
		this.resultShowing = false;
		this.$saytTerms.fadeOut();
		this.$navOverlay.removeClass('active');
	},

	/**
	 * @param {Event} e
	 * @returns {void}
	 */
	_addTermSuggestionHoverEffect: function(e) {
		this._addFocusEffectTermSuggestion($(e.currentTarget), false);
	},

	/**
	 * @private
	 * @param {Event} e
	 * @returns {void}
	 */
	_handleTermMouseLeave: function(e) {
		this._removeFocusEffectTermSuggestion($(e.currentTarget));
	},

	/**
	 * @private
	 * @param {HTMLElement} $elem
	 * @param {Boolean} isKeyboardEvent
	 * @returns {void}
	 */
	_addFocusEffectTermSuggestion($elem, isKeyboardEvent) {
		if (isKeyboardEvent) {
			$elem.css('outline', '-webkit-focus-ring-color auto 3px');
		}
		$elem
			.find('.js-sayt-navigation')
			.removeClass('theme-grey-dark')
			.addClass('theme-primary');
		$elem.addClass('bg-theme-grey-light theme-primary sayt-term-selected-term-suggestion');
	},

	/**
	 * @private
	 * @param {HTMLElement} $elem
	 * @returns {void}
	 */

	_removeFocusEffectTermSuggestion($elem) {
		$elem.css('outline', 'none');
		$elem.removeClass('bg-theme-grey-light theme-primary sayt-term-selected-term-suggestion b--theme-primary ba');
		$elem
			.find('.js-sayt-navigation')
			.removeClass('theme-primary')
			.addClass('theme-grey-dark');
	},

	/**
	 * @private
	 * @param {HTMLElement} $elem
	 * @returns {void}
	 */

	_addFocusEffectProductSuggestion($elem) {
		$elem
			.css('outline', '-webkit-focus-ring-color auto 3px')
			.addClass('z-max sayt-term-selected-product-suggestion')
			.find('.js-product-suggestion-details')
			.addClass('bg-theme-grey-light');
	},

	/**
	 * @private
	 * @param {HTMLElement} $elem
	 * @returns {void}
	 */

	_removeFocusEffectProductSuggestion($elem) {
		$elem
			.css('outline', 'none')
			.removeClass('z-max sayt-term-selected-product-suggestion')
			.find('.js-product-suggestion-details')
			.removeClass('bg-theme-grey-light');
	},

	/**
	 * @private
	 * @param {Event} e
	 * @param {HTMLElement} $currentElem
	 * @returns {void}
	 */

	_handleArrowkeyPress: function(e, $currentElem) {
		e.preventDefault();
		$currentElem.focus().css('outline', 'none');
		if ($currentElem.hasClass('js-term-suggestion')) {
			this._removeFocusEffectTermSuggestion($(e.target).closest('li'));
			this._addFocusEffectTermSuggestion($currentElem.closest('li'), true);
		} else {
			this._removeFocusEffectProductSuggestion($(e.target).closest('.js-product-suggestion-wrapper'));
			this._addFocusEffectProductSuggestion($currentElem.closest('.js-product-suggestion-wrapper'));
		}
	},

	/**
	 * @private
	 * @param {Event} e
	 * @returns {void}
	 */

	_saytNavigation: function(e) {
		const key = e.keyCode;
		if (key === this.keycodes.ESCAPE || key === this.keycodes.DOWNARROW || key === this.keycodes.TAB || key === this.keycodes.UPARROW) {
			const $list = $('.js-sayt-navigation');
			const $target = $(e.target);
			const index = $list.index($target);
			if (key === this.keycodes.ESCAPE || (key === this.keycodes.TAB && !e.shiftKey && index === $list.length - 1)) {
				this._narrowInput(e);
				this._hideSuggestedTermsAndProducts();
			}
			if (index < $list.length - 1 && (key === this.keycodes.DOWNARROW || (key === this.keycodes.TAB && !e.shiftKey))) {
				const $currentElem = $list.eq(index + 1);
				this._handleArrowkeyPress(e, $currentElem);
			} else if ((key === this.keycodes.UPARROW || (key === this.keycodes.TAB && e.shiftKey)) && index > 0) {
				const $currentElem = $list.eq(index - 1);
				this._handleArrowkeyPress(e, $currentElem);
			}
		}
	}
});
