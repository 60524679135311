import { Model, EventsHash, ViewOptions } from 'backbone';
import { Collection } from 'src/app/views/store/common/utility/adt/collection';
import { ExtendedView } from 'common/utility/view/view';
import { isScreenMedium, isScreenLarge, isScreenXLarge, debounce } from 'common/utility';

export type CarouselItem = {
	shouldTrack: boolean;
	name: string;
	itemId?: string | number;
	url?: string;
	event?: string;
};

export interface ICarouselSlider<T extends Model> {
	readonly debouncedResize: EventListener;
	attachEvents: () => CarouselSlider<T>;
	detachEvents: () => CarouselSlider<T>;
	onPrevClick: (e: JQuery.ClickEvent) => CarouselSlider<T>;
	onNextClick: (e: JQuery.ClickEvent) => CarouselSlider<T>;
	onItemAnyLinkClick: (e: JQuery.ClickEvent) => CarouselSlider<T>;
}

/**
 * Carousel Slider Component
 * Important Note:
 * 	This component relies heavily on the html elements dimensions to run the internal calculations (template).
 * 	They should be visible in the DOM at the moment of "bootstrapping" the component, in order to behave properly.
 * 	This component will be registered in the factory inside the component_helpers for lazy-bootstrapping by using an attribute called `autoRender`.
 * 	If you know ahead of building your feature, that the template will be present in the DOM at the moment of initializing the component, set it to `true`.
 * 	Otherwise, to defer the initialization process, set it this attribute to false and later, you can call the render method manually
 * 	via javascript.
 * @class CarouselSlider
 * @extends ExtendedView
 * @implements ICarouselSlider
 */
export class CarouselSlider<T extends Model> extends ExtendedView<T> implements ICarouselSlider<T> {
	/**
	 * @property {JQuery} $prevArrow
	 */
	$prevArrow: JQuery = null;

	/**
	 * @property {JQuery<HTMLElement>} $nextArrow
	 */
	$nextArrow: JQuery = null;

	/**
	 * @property {JQuery} $items
	 */
	$items: JQuery = null;

	/**
	 * @property {JQuery} $item
	 */
	$item: JQuery = null;

	/**
	 * @property {JQuery} $displayElements
	 */
	$displayElements: JQuery = null;

	/**
	 * @private
	 * @property {JQuery} $item
	 */
	debouncedResize: EventListener;

	/**
	 * @static
	 * @property EVENTS
	 */
	static EVENTS = {
		prevClick: 'carousel-slider:left-arrow:click',
		nextClick: 'carousel-slider:right-arrow:click',
		itemLinkClick: 'carousel-slider:item:link:click',
		displayChange: 'carousel-slider:display:change'
	};

	/**
	 * @static
	 * @property DIRECTION
	 */
	static DIRECTION = {
		left: 'LEFT',
		right: 'RIGHT'
	};

	/**
	 * @static
	 * @property CONFIG
	 */
	static CONFIG = {
		maxDisplayForContainerGap: 4,
		maxDisplayForItemGap: 3
	};

	/**
	 * Pre initialize
	 * @override
	 * @param {ViewOptions} options
	 */
	preinitialize(options?: ViewOptions<T>): void {
		super.preinitialize(options);
		this.el = '.js-component[data-component-type="carouselSlider"]';
	}

	/**
	 * Events
	 * @override
	 * @returns EventsHash
	 */
	events(): EventsHash {
		return {
			//  Arrows
			'click a.js-arrow-prev': this.onPrevClick,
			'click a.js-arrow-next': this.onNextClick,
			// Scroll Track pad Laptops and Releasing touch on mobile (calculates visibility of arrows)
			'touchend ul.js-items': this._update,
			// Link clicks inside carousel items
			'click li.js-item a[href]': this.onItemAnyLinkClick,
			'click li.js-item button': this.onItemAnyLinkClick
		};
	}

	/**
	 * Hack to detect Internet Explorer 11 and change layout accordingly in the template.
	 * Flexbox and Grid Issues on IE11:
	 * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Backwards_Compatibility_of_Flexbox
	 * End of life for IE11 support is projected to occur on August 2021 in order to remove this temporary hack.
	 * @returns boolean
	 */
	isIE11(): boolean {
		const ua = navigator.userAgent;
		return ua.indexOf('Trident/7.0') !== -1 && ua.indexOf('rv:11') !== -1;
	}

	/**
	 * Component Defaults
	 * @override
	 * @returns object
	 */
	getDefaults(): object {
		return { model: new Model(), debouncedResize: debounce(this._onResize.bind(this), 120) };
	}

	/**
	 * Attach Events
	 * @returns CarouselSlider
	 */
	attachEvents(): CarouselSlider<T> {
		this.listenTo(this.model, 'change:display', this._onDisplayChange);
		this.listenTo(this.model, 'change:viewport', this._onViewportChange);
		window.addEventListener('resize', this.debouncedResize);
		this.$items.on('scroll', this._update.bind(this));
		return this;
	}

	/**
	 * Detaches Events
	 * @returns CarouselSlider
	 */
	detachEvents(): CarouselSlider<T> {
		window.removeEventListener('resize', this.debouncedResize);
		this.$items.off('scroll');
		return this;
	}

	/**
	 * Cache DOM elements
	 * @returns CarouselSlider
	 */
	cache() {
		this.$prevArrow = this.$el.find('.js-arrow-prev');
		this.$nextArrow = this.$el.find('.js-arrow-next');
		this.$items = this.$el.find('.js-items');
		this.$item = this.$items.children('.js-item').first();
		this.$displayElements = this.$items.find('*[data-viewport]');
		return this;
	}

	/**
	 * Parse Items and returns them
	 * @private
	 * @param {JQuery<HTMLElement>[]} items
	 * @returns Collection
	 */
	_parseItems(items) {
		return new Collection(items.map((item, index) => ({ id: $(item).data('id'), index })));
	}

	/**
	 * Binds and initializes properties in the model silently.
	 * @private
	 * @param {object} [overrides = {}]
	 * @returns CarouselSlider
	 */
	_bindModel(overrides = {}) {
		const display = parseInt(this.$el.data('display'), 10);
		this.model.set(
			{
				display,
				name: this.$el.data('name'),
				viewport: this._resolveViewport(),
				items: this._parseItems(this.$items.children(`li[data-id*="${this.$el.data('name')}"]`).toArray()),
				masterPadding: parseInt(this.$el.data('padding-px')),
				padding: parseInt(this.$el.data('padding-px')),
				containerWidth: this.$items.outerWidth(true),
				shouldTrack: Boolean(this.$el.data('should-track')),
				...overrides
			},
			{ silent: true }
		);
		return this;
	}

	/**
	 * Returns event name by direction
	 * @private
	 * @param {string} direction
	 * @returns string
	 */
	_getEventByDirection(direction) {
		return direction === CarouselSlider.DIRECTION.right ? CarouselSlider.EVENTS.nextClick : CarouselSlider.EVENTS.prevClick;
	}

	/**
	 * Format Columns into a string to feed into the grid layout.
	 * @param items
	 * @param percentage
	 * @param containerGap
	 * @param paddingAsString
	 * @returns string
	 */
	_formatColumns(items, percentage, containerGap, paddingAsString): string {
		percentage = items.size() > this.model.get('display') || this.isIE11() ? `${percentage}%` : 'auto';
		const columns = items.reduce(
			(memo, item, index) => {
				memo.push(`${percentage} ${this.isIE11() && index < items.size() - 1 ? paddingAsString : ''}`.trim());
				return memo;
			},
			[containerGap]
		);
		columns.push(containerGap);
		return columns.join(' ');
	}

	/**
	 * Generate Grid Columns width sizes in percentage based on the amount of items.
	 * For IE11, we will need the extra padding at the beginning, at the end and in between columns.
	 * See the explanation down below in _setIE11Grid() method.
	 * @private
	 * @param {Collection} items
	 * @param {number} percentage
	 * @param {number} display
	 * @param {number} padding - in pixels
	 * @returns string
	 */
	_generateColumns(items, percentage, display, padding) {
		const containerGapCalculation = padding / (CarouselSlider.CONFIG.maxDisplayForContainerGap / display);
		const paddingAsString = `${padding}px`;
		const containerGap = `${containerGapCalculation}px ${this.isIE11() ? paddingAsString : ''}`.trim();
		return this._formatColumns(items, percentage, containerGap, paddingAsString);
	}

	/**
	 * Resolves and returns item gap percentage.
	 * This percentage is also used to leave an extra "space" on the right or the left side of the carousel (depending on the direction),
	 * to give the user a hint that there are more items to slide to, horizontally.
	 * @private
	 * @param {number} padding - in pixels
	 * @returns number
	 */
	_getItemGapPercentage(padding) {
		return (padding * 100) / this.model.get('containerWidth');
	}

	/**
	 * Calculates and resolve item width dimensions in percentage
	 * @private
	 * @param {Collection }items
	 * @param {number} display
	 * @param {number} padding
	 * @returns number
	 */
	_resolveItemWidthPercentage(items, display, padding) {
		const gapPercentage = this._getItemGapPercentage(padding);
		const globalGap = (gapPercentage * 2) / display;
		if (items.size() >= display) {
			// IE11 Hack.
			const factor = this.isIE11() && display < CarouselSlider.CONFIG.maxDisplayForItemGap ? items.size() : display;
			return 100 / factor - (gapPercentage * 2 + globalGap);
		}
		return 100 / items.size();
	}

	/**
	 * Hack to set grid columns for IE11.
	 * In IE11, grid columns has to be set using a number manually (Lack of support for grid-column-gap).
	 * In Chrome/Firefox this is supported as well and work well, but technically not necessary because the support for column-gap.
	 * We will be wrapping this in IE11 detection anyways.
	 * @returns CarouselSlider<T>
	 */
	_setIE11Grid(): CarouselSlider<T> {
		if (this.isIE11()) {
			this.$items.children().each((index, item) => {
				$(item).css({ 'grid-row': 1, 'grid-column': index + 1 + index });
			});
		}
		return this;
	}

	/**
	 * Returns true if items number is greater than display allowed for a given viewport.
	 * @returns boolean
	 */
	_areItemsGreaterThanDisplay(): boolean {
		const { items, display } = this.model.toJSON();
		return items.size() > display;
	}

	/**
	 * Initializes Slider setting up the grid via css based on model settings.
	 * @private
	 * @returns CarouselSlider
	 */
	_initSlider() {
		const { items, display, padding } = this.model.toJSON();
		const columns = this._generateColumns(items, this._resolveItemWidthPercentage(items, display, padding), display, padding);
		this.$items.css(
			Object.assign(
				{
					'grid-columns': columns, // IE11 (jquery will set it as '-ms-grid-columns'
					'grid-template-columns': columns
				}, // IE11 doesn't support column gaps, so we conditionally exclude it.
				!this.isIE11() ? { 'grid-column-gap': `${padding}px` } : {}
			)
		);
		items.size() <= display
			? this.$items.removeClass('justify-start').addClass('justify-center')
			: this.$items.removeClass('justify-center').addClass('justify-start');
		return this._setIE11Grid();
	}

	/**
	 * Update Arrow buttons
	 * @private
	 * @returns CarouselSlider
	 */
	_update(): CarouselSlider<T> {
		if (this.model.get('display') > 2) {
			this._hasReachedLeft() ? this.$prevArrow.addClass('hide') : this.$prevArrow.removeClass('hide');
			this._hasReachedRight() ? this.$nextArrow.addClass('hide') : this.$nextArrow.removeClass('hide');
		} else {
			this.$prevArrow.addClass('hide');
			this.$nextArrow.addClass('hide');
		}
		return this._updateBackground(!this._areItemsGreaterThanDisplay())._adjustViewport(true);
	}

	/**
	 * Update Background Gradient
	 * @private
	 * @param {boolean} [reset = true]
	 * @returns CarouselSlider
	 */
	_updateBackground(reset = true): CarouselSlider<T> {
		const $prevContainer = this.$prevArrow.parent();
		const $nextContainer = this.$nextArrow.parent();
		if (reset) {
			$prevContainer.css({ background: 'none' });
			$nextContainer.css({ background: 'none' });
		} else if (this._hasReachedLeft()) {
			$prevContainer.css({ background: 'none' });
			$nextContainer.css({ background: 'linear-gradient(to right, transparent 0%, white 100%)' });
		} else if (this._hasReachedRight()) {
			$nextContainer.css({ background: 'none' });
			$prevContainer.css({ background: 'linear-gradient(to left, transparent 0%, white 100%)' });
		} else {
			$prevContainer.css({ background: 'linear-gradient(to left, transparent 0%, white 100%)' });
			$nextContainer.css({ background: 'linear-gradient(to right, transparent 0%, white 100%)' });
		}
		return this;
	}

	/**
	 * Returns true if the carousel slider is positioned at the first element of the list, false otherwise.
	 * @private
	 * @returns boolean
	 */
	_hasReachedLeft() {
		return Math.floor(this.$items.scrollLeft()) <= this.model.get('padding');
	}

	/**
	 * Returns true if the carousel slider is positioned in the last item of the list, false otherwise.
	 * @private
	 * @returns boolean
	 */
	_hasReachedRight() {
		return Math.ceil(this.$items.scrollLeft() + this.$items.outerWidth(true)) >= this.$items[0].scrollWidth - this.model.get('padding');
	}

	/**
	 * Calculates and returns distance of movement based on direction passed by parameter.
	 * @private
	 * @param {string} direction
	 * @returns number
	 */
	_calculateDistance(direction = CarouselSlider.DIRECTION.right) {
		const data = this.model.toJSON();
		const padding = Number(data.padding);
		const currentLeft = this.$items.scrollLeft();
		const itemFullWidth = this.$item.outerWidth(true) + padding;
		const distance: number =
			this._hasReachedLeft() || this._hasReachedRight()
				? // extra container padding (referring to first element in the most left or last item in the most right).
				  itemFullWidth - (padding + padding / 2)
				: itemFullWidth;
		return direction === CarouselSlider.DIRECTION.right ? currentLeft + distance : currentLeft - distance;
	}

	/**
	 * Move scrolling to the next position
	 * @private
	 * @param {string} direction - direction where to move (left or right)
	 * @param {number} [scrollLeft] - optional specific scroll left position in pixels
	 * @param {Function} [callback] - optional callback
	 * @returns CarouselSlider
	 */
	_move(direction, scrollLeft, callback) {
		this.$items.animate({ scrollLeft: scrollLeft ? scrollLeft : this._calculateDistance(direction) }, 250, 'swing', callback);
		return this;
	}

	/**
	 * Window Resize Handler
	 * @returns CarouselSlider
	 */
	_onResize() {
		return this._adjustPosition()
			._adjustViewport()
			._adjustDisplay();
	}

	/**
	 * Auto Adjust Position of scroll based on window size
	 * @private
	 * @returns CarouselSlider
	 */
	_adjustPosition() {
		const newWidth = this.$items.outerWidth(true);
		const currentLeft = this.$items.scrollLeft();
		const newScrollLeft = (newWidth * currentLeft) / this.model.get('containerWidth');
		this.$items.animate({ scrollLeft: newScrollLeft }, 0);
		this.model.set({ containerWidth: newWidth }, { silent: true });
		return this;
	}

	/**
	 * Change number of items to display within the container based on screen width.
	 * The original display value will always apply to the largest screen width.
	 * The scaling factor is calculated by subtracting 1 to the current display while scaling down.
	 * The minimum value (and default) for displaying is 1.
	 * @private
	 * @param {number} [toDisplay = 1]
	 * @returns CarouselSlider
	 */
	_adjustDisplay(toDisplay = 1) {
		let toUpdate = { display: this.model.get('display'), padding: this.model.get('padding') };
		const originalDisplay = parseInt(this.$el.data('display'), 10);
		if (isScreenXLarge()) {
			toDisplay = originalDisplay;
		} else if (isScreenLarge()) {
			toDisplay = originalDisplay - 1;
		} else if (isScreenMedium()) {
			toDisplay = originalDisplay - 2;
		}
		if (this.model.get('display') !== toDisplay) {
			toDisplay = toDisplay > 0 ? toDisplay : 1;
			toUpdate = { display: toDisplay, padding: this.calculateNewPadding(this.model, originalDisplay, toDisplay) };
			this.model.set(toUpdate);
		}
		return this.trigger(CarouselSlider.EVENTS.displayChange, { ...toUpdate, originalDisplay }, this);
	}

	/**
	 * Updates viewport if current is different from the previous
	 * @returns CarouselSlider<T>
	 */
	_adjustViewport(force = false) {
		if (this.$displayElements.length) {
			if (force) {
				return this._onViewportChange();
			}
			const previous = this.model.get('viewport'),
				current = this._resolveViewport();
			if (previous !== current) {
				this.model.set('viewport', current);
			}
		}
		return this;
	}

	/**
	 * Show/Hide elements based on the values specified in data attribute 'viewport'.
	 * The attribute viewport can contain: small,medium,large,xlarge (or multiple comma-separated)
	 * If the current viewport matches any of this identifiers, the elements will be displayed.
	 * Otherwise hidden.
	 * regarding responsive capabilities.
	 * @returns CarouselSlider
	 */
	_onViewportChange(): CarouselSlider<T> {
		const viewport = this.model.get('viewport');
		this.$displayElements.addClass('hide');
		this.$displayElements.each((index, element) => {
			this.$(element)
				.data('viewport')
				.split(',')
				.includes(viewport)
				? this.$(element).removeClass('hide')
				: null;
		});
		return this;
	}

	/**
	 * Resolve viewport identifier by media queries
	 * @returns string;
	 */
	_resolveViewport() {
		if (isScreenXLarge()) {
			return 'xlarge';
		} else if (isScreenLarge()) {
			return 'large';
		} else if (isScreenMedium()) {
			return 'medium';
		}
		return 'small';
	}

	/**
	 * Slide carousel component, updates the current step and notifies what direction the carousel slided to.
	 * @private
	 * @param {ClickEvent} event
	 * @param {string} direction
	 * @returns CarouselSlider
	 */
	_slide(event, direction) {
		event.preventDefault();
		return this._move(direction, null, this._update.bind(this))._notify(this._getEventByDirection(direction), {
			name: this.model.get('name'),
			shouldTrack: this.model.get('shouldTrack')
		});
	}

	/**
	 * Send Event Notification
	 * @private
	 * @param {string} event
	 * @param {object} [parameters = {}]
	 * @returns CarouselSlider
	 */
	_notify(event: string, parameters = {}): CarouselSlider<T> {
		return this.trigger(event, { ...parameters, event });
	}

	/**
	 * Render Component
	 * @override
	 * @returns CarouselSlider
	 */
	render() {
		super.render();
		return this.cache()
			.attachEvents()
			._bindModel()
			._initSlider()
			._adjustDisplay()
			._update();
	}

	/**
	 * Remove Component
	 * @override
	 * @returns CarouselSlider
	 */
	remove(): CarouselSlider<T> {
		this.detachEvents();
		super.remove();
		return this;
	}

	/**
	 * Display Change
	 * Improvement: If current is not 0, we should recalculate scrollLeft for all display values dynamically.
	 * Right now, it's resetting the slider back to position 0 and scrollLeft 0 for simplicity.
	 * @private
	 * @param {Backbone.Model} model
	 * @returns CarouselSlider
	 */
	_onDisplayChange(model): CarouselSlider<T> {
		this._bindModel(model.toJSON())._initSlider();
		this.$items.scrollLeft(0);
		return this._update();
	}

	/**
	 * Calculates new carousel padding
	 * @param {T} model
	 * @param {number} originalDisplay
	 * @param {number} newDisplay
	 * @returns number
	 */
	calculateNewPadding(model, originalDisplay, newDisplay) {
		return model.get('masterPadding') / (originalDisplay / Math.max(newDisplay, CarouselSlider.CONFIG.maxDisplayForItemGap));
	}

	/**
	 * Previous Arrow Click
	 * @param {ClickEvent} e
	 * @returns CarouselSlider
	 */
	onPrevClick(e): CarouselSlider<T> {
		return this._slide(e, CarouselSlider.DIRECTION.left);
	}

	/**
	 * Next Arrow Click
	 * @param {ClickEvent} e
	 * @returns CarouselSlider
	 */
	onNextClick(e): CarouselSlider<T> {
		return this._slide(e, CarouselSlider.DIRECTION.right);
	}

	/**
	 * Carousel Any Item Link Click
	 * This function will get called on any link inside each carousel tile
	 * @param {ClickEvent} e
	 * @returns CarouselSlider
	 */
	onItemAnyLinkClick(e): CarouselSlider<T> {
		const name = this.model.get('name');
		const itemId = $(e.currentTarget)
			.closest('li.js-item')
			.data('id');
		const url = $(e.currentTarget).attr('href') || $(e.currentTarget).data('href');
		const shouldTrack = this.model.get('shouldTrack');
		if (shouldTrack) {
			e.preventDefault();
		}
		return this._notify(CarouselSlider.EVENTS.itemLinkClick, { name, itemId, url, shouldTrack });
	}
}
