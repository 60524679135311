const cloudinary = require('cloudinary');

const cloudinaryHelpers = {
	url: null,

	/**
	 * Resolves Url utility function from cloudinary package based on environment.
	 * @private
	 * @returns {Function}
	 */
	_getUtilUrl() {
		if (!cloudinaryHelpers.url) {
			if (typeof module !== 'undefined' && module.exports && typeof window === 'undefined') {
				// server side
				cloudinaryHelpers.url = cloudinary.url;
			} else if (window.$.cloudinary) {
				// client side
				window.$.cloudinary.config({
					cloud_name: 'build',
					secure: true,
					private_cdn: true,
					secure_distribution: 's3.img-b.com',
					cname: 's3.img-b.com'
				});

				cloudinaryHelpers.url = window.$.cloudinary.url.bind(window.$.cloudinary);
			}
		}
		return cloudinaryHelpers.url;
	},

	/**
	 * Resolves url
	 * @private
	 * @returns {*}
	 */
	_resolveUrl: function(context, bodies, params) {
		const publicId = context.resolve(params['public-id']);
		const type = context.resolve(params['type']);
		const transformation = context.resolve(params['transformation']);
		const width = context.resolve(params['width']);
		const height = context.resolve(params['height']);
		const aspectRatio = context.resolve(params['aspect-ratio']);
		const crop = context.resolve(params['crop']) || 'lpad';
		const gravity = context.resolve(params['gravity']);
		const fetchFormat = context.resolve(params['fetch-format']);
		const flags = context.resolve(params['flags']);
		const background = context.resolve(params['background']);
		const dpr = context.resolve(params['dpr']);
		const opacity = context.resolve(params['opacity']);
		const options = {
			type: type || 'private',
			crop,
			transformation: transformation || 'base',
			width,
			height,
			aspect_ratio: aspectRatio,
			secure: true,
			gravity,
			fetch_format: fetchFormat,
			flags,
			background,
			dpr,
			opacity
		};

		return cloudinaryHelpers._getUtilUrl()(publicId, options);
	},

	/**
	 * generateCloudinaryUrl - dust helper to generate a cloudinary url based on the public id for the image.
	 *
	 * @param  {Object} chunk   the chunk being operated on
	 * @param  {Object} context the context of the helper
	 * @param  {Object} bodies  any bodies of the helper
	 * @param  {Object} params  parameters passed when calling the helper
	 * @return {Object} returns the chunk being operated on
	 */
	generateCloudinaryUrl: function(chunk, context, bodies, params) {
		return chunk.write(cloudinaryHelpers._resolveUrl(context, bodies, params));
	},
	/**
	 * Partial to create a custom cloudinary resized image from the default image
	 * @example {@getImageUrl transforms="w_100,h_200" default="/product/private/misenoimages/miseno-mlit155181rt-appshot.jpg" /}
	 * @returns {*}
	 */
	getCloudinaryUrl: function(chunk, context, bodies, params) {
		const imagePath = context.resolve(params.default);
		const transforms = context.resolve(params.transforms);
		const removetransforms = context.resolve(params.removetransforms);
		let resizedImage;
		if (transforms) {
			if (imagePath.indexOf('/private/') !== -1) {
				resizedImage = imagePath.replace('/private/', `/private/${transforms},`);
			} else {
				resizedImage = imagePath.replace('/upload/', `/upload/${transforms},`);
			}
		} else {
			resizedImage = imagePath;
		}

		if (removetransforms) {
			const transformsToRemove = removetransforms.split(',');
			transformsToRemove.forEach((transRem) => {
				const regex = new RegExp(`${transRem}(,)?`);
				resizedImage = resizedImage.replace(regex, '');
			});
		}
		return chunk.write(resizedImage);
	}
};
module.exports = cloudinaryHelpers;
