const Backbone = require('backbone');
const VideoView = require('app/views/store/common/video');
const VideoModal = require('app/templates/common/videos/_video_bootstrap_modal.dust');
const dust = require('dustjs');

export const VideosView = Backbone.View.extend({
	initialize: function() {
		// configure omniture video tracking
		if (window.s && window.s.Media) {
			window.s.Media.trackVars = 'events,eVar47,eVar48,prop10';
			window.s.Media.trackEvents = 'event63,event64,event65,event66';
			window.s.Media.autoTrack = false;
			window.s.Media.playerName = null;
			window.s.Media.trackMilestones = '25,50,75,98';
			window.s.Media.segmentByMilestones = true;
			window.s.Media.trackWhilePlaying = true;
			window.s.Media.trackUsingContextData = true;
			window.s.Media.contextDataMapping = {
				'a.media.name': 'eVar47,prop10',
				'a.media.segment': 'eVar48',
				'a.media.timePlayed': 'event63',
				'a.media.view': 'event64',
				'a.media.segmentView': 'event66',
				'a.media.complete': 'event65'
			};
		}
		// video views
		this.videoViews = [];

		$(document).ready(this._onReady.bind(this));
		// escape hatch to pause all wistia videos
		this.listenTo(Backbone, 'videos:pause', () => {
			window._wq = window._wq || [];
			window._wq.push({
				id: '_all',
				onReady: function(video) {
					video.pause();
				}
			});
		});
	},

	_onReady: function() {
		$('.video')
			.not('.js-deferable-video')
			.each((index, videoElement) => {
				const $videoElement = $(videoElement),
					urlHashId = $videoElement.data('url-hash-id'),
					providerId = $videoElement.data('provider-id'),
					thumbWidth = $videoElement.data('thumb-width'),
					thumbHeight = $videoElement.data('thumb-height');

				if (urlHashId) {
					if ($videoElement.hasClass('inlineVideo')) {
						dust.render(
							VideoModal,
							{
								title: $videoElement.data('title'),
								description: $videoElement.data('description')
							},
							(error, markup) => {
								if (error) {
									window.clientErrorHandler(error);
								} else {
									$videoElement.append(markup);
								}
							}
						);
					}
					// Fetch the thumbnail from wistia
					const thumbnailOptions = { thumbWidth, thumbHeight };
					this.resolveWistiaVideoThumbnail(urlHashId, $videoElement.find('.js-video-bg-image')[0], providerId, thumbnailOptions);
					var videoView = new VideoView({
						el: videoElement
					});
					this.videoViews.push(videoView);
					// IDS-24419 re-delegate events for each video view (src/app/views/store/common/video.js),
					// since recent slick-slider version 1.8.1 caused events to unbind from initial video views.
					this.listenTo(Backbone, 'mediagallery:rebindEvents', function() {
						videoView.delegateEvents();
					});
				}
			});
	},

	/**
	 * Fetches thumbnail from wistia and sets backgroundImage on the video div container
	 * @param {String} urlHashId - the hash id of the wistia video.
	 * @param {Element} videoElement - the element to update the backgroundImage with the wistia thumbnail.
	 * @param {Number} providerId - the type of video provider 1=Wistia and 2=YouTube
	 */
	resolveWistiaVideoThumbnail: function resolveWistiaVideoThumbnail(urlHashId, videoElement, providerId, options = {}) {
		if (!urlHashId || !videoElement || providerId !== 1) {
			return;
		}

		const { thumbWidth, thumbHeight } = options;
		const queryParams = {
			width: thumbWidth,
			height: thumbHeight
		};

		$.ajax({
			url: `https://fast.wistia.net/oembed?url=https://home.wistia.com/medias/${urlHashId}`,
			success: this._thumbnailCallBack.bind(this, videoElement),
			data: queryParams
		});
	},

	/**
	 * Sets the image background for wistia video with thumbnail url returned from wistia.
	 * @param {Element} videoBackgroundDiv - the element to update the backgroundImage with the wistia thumbnail.
	 * @param {Object} data - the data returned from the call to wistia
	 */
	_thumbnailCallBack: function _thumbnailCallBack(videoBackgroundDiv, data) {
		const $videoBackgroundDiv = $(videoBackgroundDiv);
		if ($videoBackgroundDiv && $videoBackgroundDiv.length) {
			$videoBackgroundDiv.css('backgroundImage', `url(${data.thumbnail_url})`);
		}
	}
});

export default new VideosView();
