// @ts-nocheck
const _ = require('underscore');
const { Events } = require('backbone');
const Utils = require('views/store/common/utility');

/**
 * Class Collection
 * @class
 */
class Collection {
	static events = {
		added: 'util:adt:collection:added',
		updated: 'util:add:collection:updated',
		removed: 'util:adt:collection:removed',
		addedAll: 'util:adt:collection:addedAll',
		removedAll: 'util:adt:collection:removedAll',
		replacedAll: 'util:adt:collection:replacedAll',
		sorted: 'util:adt:collection:sorted',
		reset: 'util:adt:collection:reset'
	};

	static properties = ['_parent', '_interface', 'matcher'];

	constructor(initial = [], options = {}) {
		return this.initialize.apply(this, [initial, options]);
	}

	get parent() {
		return this._parent;
	}

	get collection() {
		return this._collection;
	}

	get Interface() {
		return this._interface;
	}

	_getArguments(args) {
		return [this.collection].concat(args);
	}

	_fire(eventName, eventParams = {}, options = {}) {
		return options.silent ? this : this.trigger(eventName, eventParams, { eventName, options });
	}

	_json(element) {
		return Utils.defined(element.toJSON) ? element.toJSON() : element;
	}

	_createElement(element, options) {
		return Utils.defined(this.Interface) ? new this.Interface(element, options) : element;
	}

	_newByMethod(element, options = {}) {
		return Utils.defined(options.new) ? options.new(element, options) : this._createElement(element, options);
	}

	_newElement(element, options = {}) {
		const newElement = this._newByMethod(element, options);
		if (Utils.defined(this.Interface)) {
			newElement.collection = this;
		}
		return newElement;
	}

	_generate(elements = [], options = {}) {
		return _.compact(_.map(elements, (element) => this.add(element, options)));
	}

	_getPrevNext(element, ix, elements) {
		const prevIndex = ix - 1,
			nextIndex = ix + 1;
		const prev = prevIndex >= 0 && elements[prevIndex] ? elements[prevIndex] : element;
		const next = nextIndex < elements.length && elements[nextIndex] ? elements[nextIndex] : element;
		const isFirst = ix === 0,
			isLast = ix === elements.length - 1;
		return { prev, next, isFirst, isLast };
	}

	_add(element, at = this.size()) {
		if (at < 0 || at > this.size()) {
			at = this.size();
		}
		this._collection.splice(at, 0, element);
		return this;
	}

	_predicate(func, context, element, ix, elements) {
		return func.call(context, element, ix, elements, this._getPrevNext(element, ix, elements));
	}

	initialize(initial = [], options = {}) {
		this._parent = null;
		this._interface = null;
		this._collection = null;
		return Object.assign(this, Utils.accept(options, this.constructor.properties, this.getDefaults())).set(initial, { silent: true });
	}

	getDefaults() {
		return { _collection: [] };
	}

	matcher(given, element) {
		return _.isEqual(this._json(given), this._json(element));
	}

	all() {
		return this.collection;
	}

	reset(options = {}) {
		this._collection = [];
		return this._fire(Collection.events.reset, this, options);
	}

	set(elements = [], options = {}) {
		this.reset({ silent: true });
		this._collection = this._generate(elements, _.defaults({ silent: true }, options));
		return this._fire(Collection.events.replacedAll, this, options);
	}

	add(element, options = {}) {
		const newElement = this._newElement(element, options);
		this._add(newElement, options.at);
		this._fire(Collection.events.added, { changed: newElement, action: 'Add', collection: this, options }, options);
		return newElement;
	}

	addAll(elements = [], options = {}) {
		const newElements = this._generate(elements, options);
		return this._fire(Collection.events.addedAll, { changed: newElements, action: 'Add', collection: this, options }, options);
	}

	remove(element, options = {}) {
		const ix = this.findIndex(this.matcher.bind(this, element));
		const removed = this.removeAt(ix, _.defaults({ silent: true }, options));
		return this._fire(Collection.events.removed, { changed: removed, action: 'Remove', collection: this, options }, options);
	}

	removeAt(ix, options = {}) {
		if (ix < 0 || ix > this.size()) {
			return null;
		}
		const removed = this.collection.splice(ix, 1);
		this._fire(Collection.events.removed, { changed: removed[0], action: 'Remove', collection: this, options }, options);
		return removed[0];
	}

	removeBy(predicate, options = {}) {
		const ix = this.findIndex(predicate, this);
		if (ix < 0) {
			return null;
		}
		const removed = this.removeAt(ix, _.defaults({ silent: true }, options));
		this._fire(Collection.events.removed, { changed: removed, action: 'Remove', collection: this, options }, options);
		return removed;
	}

	removeAll(elements = [], options = {}) {
		const removed = [];
		_.each(elements, (element) => {
			const ix = this.findIndex(this.matcher.bind(this, element));
			if (ix >= 0) {
				removed.push(this.removeAt(ix, _.defaults({ silent: true }, options)));
			}
		});
		return this._fire(Collection.events.removedAll, { changed: removed, action: 'Remove', collection: this, options }, options);
	}

	swap(comparator) {
		if (Utils.defined(comparator) && _.isFunction(comparator)) {
			for (let i = 0; i < this._collection.length; i++) {
				const ix = comparator(this._collection[i], i);
				if (Utils.defined(ix) && ix > -1) {
					const e = this._collection[i];
					this._collection[i] = this._collection[ix];
					this._collection[ix] = e;
				}
			}
		}
		return this;
	}

	at(ix) {
		return this.collection[ix];
	}

	pluck(propertyName) {
		return this.map((element) => {
			return this._json(element)[propertyName];
		});
	}

	pluckAll(...propertyNames) {
		return this.map((element) => _.pick(this._json(element), ...propertyNames), this);
	}

	each(predicate, context) {
		_.each.apply(
			this,
			this._getArguments([
				function(element, ix, elements) {
					predicate.call(context, element, ix, elements, this._getPrevNext(element, ix, elements));
				}.bind(this)
			])
		);
		return this;
	}

	map(predicate, context) {
		return _.map.apply(this, this._getArguments([predicate, context]));
	}

	metaMap(predicate, context) {
		return this.map(this._predicate.bind(this, predicate, context), context);
	}

	flatMap(predicate, context) {
		return _.compact(this.map(predicate, context));
	}

	find(predicate, context) {
		return _.find.apply(this, this._getArguments([predicate, context]));
	}

	findIndex(predicate, context) {
		return _.findIndex.apply(this, this._getArguments([predicate, context]));
	}

	findLastIndex(predicate, context) {
		return _.findLastIndex.apply(this, this._getArguments([predicate, context]));
	}

	reduce(predicate, memo, context) {
		return _.reduce.apply(this, this._getArguments([predicate, memo, context]));
	}

	reduceRight(predicate, memo, context) {
		return _.reduceRight.apply(this, this._getArguments([predicate, memo, context]));
	}

	filter(predicate, context) {
		return _.filter.apply(this, this._getArguments([predicate, context]));
	}

	reject(predicate, context) {
		return _.reject.apply(this, this._getArguments([predicate, context]));
	}

	every(predicate, context) {
		return _.every.apply(this, this._getArguments([predicate, context]));
	}

	some(predicate, context) {
		return _.some.apply(this, this._getArguments([predicate, context]));
	}

	contains(element, matcher) {
		if (!Utils.defined(matcher)) {
			matcher = this.matcher.bind(this);
		}
		return Utils.defined(this.find((e) => matcher(element, e), this));
	}

	invoke(methodName, ...args) {
		_.invoke.apply(this, this._getArguments([methodName, ...args]));
		return this;
	}

	update(predicate, methodName, args, options = {}) {
		const updated = this.filter((element, ix, elements) => {
			if (predicate(element, ix, elements)) {
				element[methodName].apply(element, args);
				return true;
			}
			return false;
		});
		return updated.length > 0 ? this._fire(Collection.events.updated, { updated, args, options }, options) : this;
	}

	max(predicate, context) {
		return _.max.apply(this, this._getArguments([predicate, context]));
	}

	min(predicate, context) {
		return _.min.apply(this, this._getArguments([predicate, context]));
	}

	size() {
		return this.all().length;
	}

	first() {
		return this.at(0);
	}

	last() {
		return this.at(this.size() - 1);
	}

	initial() {
		return _.initial.apply(this, this._getArguments(_.toArray(arguments)));
	}

	rest() {
		return _.rest.apply(this, this._getArguments(_.toArray(arguments)));
	}

	unique(property) {
		return _.unique.apply(this, this._getArguments([property]));
	}

	difference(property) {
		return _.difference.apply(this, this._getArguments([property]));
	}

	indexOf(value) {
		return _.indexOf.apply(this, this._getArguments([value]));
	}

	shuffle() {
		return _.shuffle.apply(this, this._getArguments(_.toArray(arguments)));
	}

	lastIndexOf() {
		return _.lastIndexOf.apply(this, this._getArguments(_.toArray(arguments)));
	}

	isEmpty() {
		return this.size() === 0;
	}

	groupBy(predicate, context) {
		return _.groupBy.apply(this, this._getArguments([predicate, context]));
	}

	containsBy(predicate) {
		return Utils.defined(this.find((element, ix, list) => predicate(element, ix, list)));
	}

	containsAll(elements) {
		return _.every(_.map(elements, (element) => this.contains(element)));
	}

	move(fromIndex, toIndex, options = {}) {
		this._collection.splice(toIndex, 0, this._collection.splice(fromIndex, 1)[0]);
		return this._fire(Collection.events.sorted, this, options);
	}

	sort(property, options = {}) {
		this._collection = _.sortBy.apply(this, this._getArguments([property]));
		return this._fire(Collection.events.sorted, this, options);
	}

	sortBy(predicate, context, options = {}) {
		this._collection = _.sortBy.apply(this, this._getArguments([predicate, context]));
		return this._fire(Collection.events.sorted, this, options);
	}

	toJSON() {
		return this.map((element) => this._json(element), this);
	}

	toString() {
		return `[object ${Collection.name}]`;
	}
}

Object.assign(Collection.prototype, Events);

module.exports = { Collection };
