const yall = require('yall-js');
const fullySuported =
	'IntersectionObserver' in window &&
	'IntersectionObserverEntry' in window &&
	'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
	'isIntersecting' in window.IntersectionObserverEntry.prototype;
const $ = require('jquery');
const lazyClass = 'js-lazy';
const lazyLoadedClass = 'lazy-loaded';
const lazyBackgroundClass = 'js-lazy-bg';
const lazyBackgroundLoadedClass = 'lazy-bg-loaded';
const lazyLoader = {
	_needsPolyfill: () => !fullySuported,
	_polyfill: () => {
		return new Promise((resolve, reject) => {
			if (lazyLoader._needsPolyfill()) {
				import('intersection-observer')
					.then(resolve.bind(this, true))
					.catch(reject);
			} else {
				resolve(false);
			}
		});
	},
	_initializeYall: () => {
		yall({
			lazyClass: lazyClass,
			lazyBackgroundClass: lazyBackgroundClass,
			lazyBackgroundLoaded: lazyBackgroundLoadedClass,
			observeChanges: true,
			idleLoadTimeout: 100,
			events: {
				load: lazyLoader._onLoadImage
			}
		});
	},
	_onLoadImage: (event) => {
		if (!event.target.classList.contains(lazyClass) && event.target.nodeName === 'IMG') {
			event.target.classList.add(lazyLoadedClass);
			event.target.classList.remove('lazy');
		}
	},
	_fallBack: (error) => {
		window.clientErrorHandler && window.clientErrorHandler(error);
		// yall does NOT currently have a fallback for when intersction observer isn't supported
		// if anything goes wrong, including not being able to load the polyfill, load all images
		// if we start using yall for things besides images, we will want a more robust solution
		$(`.${lazyClass}`).each((index, image) => {
			const $image = $(image);
			const source = $image.data('src');
			$image.attr('src', source);
			$image.removeClass(lazyClass);
			$image.addClass(lazyLoadedClass);
		});
		$(`.${lazyBackgroundClass}`).each((index, element) => {
			const $element = $(element);
			$element.removeClass(lazyBackgroundClass);
			$element.addClass(lazyBackgroundLoadedClass);
		});
	},
	load: () => {
		return lazyLoader
			._polyfill()
			.then(() => {
				if (document.readyState === 'loading') {
					document.addEventListener('DOMContentLoaded', lazyLoader._initializeYall);
				} else {
					lazyLoader._initializeYall();
				}
			})
			.catch(lazyLoader._fallBack);
	}
};
module.exports = lazyLoader;
