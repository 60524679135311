const Backbone = require('backbone');

module.exports = Backbone.View.extend(
	/** @type {BackboneExtensions.Accordian} */ ({
		events: {
			'click .js-expand': 'toggleExpand'
		},
		/**
		 * @returns {void}
		 */
		initialize: function() {
			this.$accordionContent = this.$('.js-accordion-content');
			this.$icon = this.$('.js-icon');
		},
		/**
		 * @returns {void}
		 */
		toggleExpand: function() {
			this.$accordionContent.toggleClass('dn');
			this.$icon.toggleClass('dn');
		},
		/**
		 * @returns {void}
		 */
		expand: function() {
			this.$accordionContent.removeClass('dn');
			this.$icon.addClass('dn');
		}
	})
);
