import Backbone, { ObjectHash, View, ViewOptions } from 'backbone';
import FinishesModel, { IFinishesModel } from 'models/store/client/components/finish/finishes';
import dustjs from 'dustjs';
import Utils from 'views/store/common/utility';

import 'store/global/components/finish_selector/_finish_selector.dust';
import _finishDust from 'store/global/components/finish/_finish.dust';

/**
 * @interface FinishSelectorOptions<TModel>
 * @extends ViewOptions<TModel>
 */
export interface FinishSelectorOptions<TModel extends IFinishesModel> extends ViewOptions<TModel> {
	model: TModel;
}

/**
 * @interface IFinishSelectorView<TModel>
 * @extends View<TModel>
 */
export interface IFinishSelectorView<TModel extends IFinishesModel> extends View<TModel> {
	isLoaded: () => boolean;
	load: (payload: any, forceFetch?: boolean, callback?: Function) => IFinishSelectorView<TModel>;
	show: () => IFinishSelectorView<TModel>;
	hide: () => IFinishSelectorView<TModel>;
	clear: () => IFinishSelectorView<TModel>;
	onClose: (e: Event) => IFinishSelectorView<TModel>;
}

/**
 * @class FinishSelectorComponent
 */
export default class FinishSelectorView<TModel extends IFinishesModel = IFinishesModel> extends View<TModel>
	implements IFinishSelectorView<TModel> {
	static events: ObjectHash = {
		change: 'components:finish_selector:change',
		select: 'components:finish_selector:select',
		close: 'components:finish_selector:close'
	};

	events(): Backbone.EventsHash {
		return {
			'click .js-finish-option': 'onFinishOptionClick',
			'mouseenter .js-finish-option': 'onHoverStart',
			'mouseleave .js-finish-option': 'onHoverEnd'
		};
	}

	preinitialize(options?: FinishSelectorOptions<TModel>): void {
		super.preinitialize(Object.assign(options, { model: new FinishesModel() }));
	}

	initialize(options?: FinishSelectorOptions<TModel>): void {
		super.initialize(options);
		this.attachEvents();
	}

	attachEvents(): IFinishSelectorView<TModel> {
		this.listenTo(this.model, 'change:selected', this.onSelectionChange);
		this.listenTo(Backbone, 'body:click', this.onClose);
		return this;
	}

	isLoaded(): boolean {
		return !this.model.get('finishes').isEmpty();
	}

	isOpen(): boolean {
		return !this.$el.hasClass('hide');
	}

	load(payload: any, forceFetch = false, callback?: Function): IFinishSelectorView<TModel> {
		!this.isLoaded() || forceFetch ? this.model.fetchAll(payload, callback, this.onLoadError.bind(this)) : callback(this.model);
		return this;
	}

	render(): IFinishSelectorView<TModel> {
		this.clear();
		const collection = this.model.get('finishes');
		collection.each(this.renderFinish, this);
		return this.delegateEvents();
	}

	show(): IFinishSelectorView<TModel> {
		this.$el.removeClass('hide');
		return this;
	}

	hide(): IFinishSelectorView<TModel> {
		this.$el.addClass('hide');
		return this;
	}

	clear() {
		this.$el.children().remove();
		return this;
	}

	getFinishData(model: any, index: number, models: any[]): object {
		return {
			...model,
			clazz: 'js-finish-option',
			selected: this.model.isSelected(model.uniqueId),
			len: models.length,
			idx: index
		};
	}

	renderFinish(model: any, index: number, models: any[]): IFinishSelectorView<TModel> {
		dustjs.render(_finishDust, this.getFinishData(model, index, models), this.onFinishRender.bind(this));
		return this;
	}

	update(selected: any): IFinishSelectorView<TModel> {
		this.$el.children().each((ix: number, finish: HTMLElement) => {
			const uniqueId = parseInt($(finish).data('uniqueid'), 10);
			$(finish).removeClass('b--theme-black b--theme-grey');
			$(finish).addClass(uniqueId === selected.uniqueId ? 'b--theme-black' : 'b--theme-grey');
		});
		return this;
	}

	onFinishRender(err: Error, content: string): IFinishSelectorView<TModel> {
		if (Utils.defined(err)) {
			window.clientErrorHandler(err.message);
			return this;
		}
		this.$el.append(content);
		if (window.BCOM.device.isDesktop) {
			const uniqueId = $(content).attr('data-uniqueid');
			this.$el.find(`[data-uniqueid="${uniqueId}"]`).tooltip();
		}
		return this;
	}

	onFinishOptionClick(e: JQuery.ClickEvent): IFinishSelectorView<TModel> {
		const uniqueId: number = parseInt($(e.currentTarget).data('uniqueid'), 10),
			finishModel = this.model.findFinishByUniqueId(uniqueId);
		if (Utils.defined(finishModel) && finishModel.status === 'stock') {
			this.trigger(FinishSelectorView.events.select, this.model, this).model.set('selected', finishModel);
		}
		return this;
	}

	onSelectionChange(model: IFinishesModel): IFinishSelectorView<TModel> {
		this.update(model.get('selected'));
		return this.trigger(FinishSelectorView.events.change, model, this);
	}

	onClose(e: Event): IFinishSelectorView<TModel> {
		return this.isOpen() ? this.trigger(FinishSelectorView.events.close, e, this) : this;
	}

	onLoadError(): IFinishSelectorView<TModel> {
		window.BCOM.notifications.render('Error retrieving finishes.', 'danger');
		return this;
	}

	onHoverStart(e: JQuery.MouseEnterEvent): IFinishSelectorView<TModel> {
		if (window.BCOM.device.isDesktop) {
			const uniqueId: number = parseInt($(e.currentTarget).data('uniqueid'), 10);
			const finishModel = this.model.findFinishByUniqueId(uniqueId);
			if (Utils.defined(finishModel) && finishModel.status === 'stock') {
				const image = this.$el.closest('li').find('.js-item-image');
				image.attr('src', finishModel.image);
			}
		}
		return this;
	}

	onHoverEnd(): IFinishSelectorView<TModel> {
		if (window.BCOM.device.isDesktop) {
			const image = this.$el.closest('li').find('.js-item-image');
			image.attr('src', this.model.get('selected').image);
		}
		return this;
	}
}
