const Backbone = require('backbone');
const _ = require('underscore');

module.exports = Backbone.View.extend({
	el: '.js-notifications',
	events: {
		'click .close': 'closeNotification'
	},

	initialize: function(options = {}) {
		this.autoHide = options.autoHide === undefined ? true : options.autoHide;
		this.hiddenClass = 'hidden';
		if (!this.$el.hasClass(this.hiddenClass) && this.autoHide) {
			this.fadeOut();
		}
	},
	render: function(message, type, autoHide = true) {
		this.$el.attr('class', (this.$el.attr('class') || '').replace(/\balert-\S+/g, ''));
		this.$el
			.html(this.formatError(message, type))
			.removeClass(this.hiddenClass)
			.fadeIn(100);
		this.$('> ul').addClass(`bg-theme-${type}-light ma0 items-center pa4 pa5-ns list`);

		this.$('> ul').append(
			'<button type="button" class="close pr3 b--none bg-transparent pointer absolute top-0 right-0" data-dismiss="alert" aria-label="Close">' +
				'<span aria-hidden="true" class="f2">&times;</span></button>'
		);
		if (this.autoHide && autoHide) {
			this.fadeOut();
		}
	},
	fadeOut: function() {
		this.$el
			.stop(true, true)
			.delay(5000)
			.fadeOut(500, () => this.$el.empty());
	},
	formatError: function(texts, type) {
		const classes = `"theme-${type} pt justify-center w-100 f4 ma0 tc"`;
		let html = '<ul>';
		if (_.isString(texts)) {
			html += `<li class=${classes}>${texts}</li>`;
		} else if (Array.isArray(texts)) {
			texts.forEach((text) => {
				html += `<li class=${classes}>${text}</li>`;
			});
		} else {
			// Anything other than string or Array of strings cannot be displayed.
			return '';
		}
		html += '</ul>';
		return html;
	},
	closeNotification: function() {
		this.$el.stop(true, true).empty();
	}
});
