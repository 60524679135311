import { cookies, bannerType } from 'lib/enums';
import jsCookie from 'js-cookie';
import urlUtility from 'build-utilities/lib/url';

let determinedBanner;

export const bannerHelper = {
	BannerType: bannerType,
	/**
	 * Main controller function that determines which banner should fire
	 * @param {string} name
	 * @returns {boolean}
	 */
	shouldShow: (name) => {
		return name === bannerHelper.bannerToBeShown();
	},
	/**
	 * Checks against page, user, and source to determine eligibility for email modal/banners
	 * @returns {boolean}
	 */
	isEligibleForBanner: () => {
		const hasBeenSeenBefore = jsCookie.get(cookies.NEWSLETTER_SIGNUP_OFFER) === 'true';
		// check pages
		const currentPageType = window.dataLayer.page.split(':')[0];
		const denyListPageTypes = ['cart', 'checkout'];
		const eligiblePage = denyListPageTypes.indexOf(currentPageType) === -1;
		// check user
		const eligibleUser = !hasBeenSeenBefore && !window.dataLayer.isLoggedIn;
		// check source
		const fromEmailLink = window.location.href.includes('&source=em~');
		const urlOptOut = window.location.href.includes('emp=false');
		const eligibleSource = !fromEmailLink && !urlOptOut;
		// all
		return eligiblePage && eligibleUser && eligibleSource;
	},
	/**
	 * @returns {boolean}
	 */
	isEmailBannerEnabled: () => {
		const eligibleForBanner = bannerHelper.isEligibleForBanner();
		const { isLocalUser } = window.dataLayer;
		return eligibleForBanner && !isLocalUser;
	},
	/**
	 * @returns {boolean}
	 */
	isAppBannerEnabled: () => {
		const parsedUrl = urlUtility.parseUrl(window.location.href),
			disabledByQueryString = parsedUrl.query['app-banner'] === 'false';
		return !disabledByQueryString;
	},
	/**
	 * determine whether each banner is enabled
	 * @returns {string}
	 */
	bannerToBeShown: () => {
		if (!determinedBanner) {
			if (bannerHelper.isEmailBannerEnabled()) {
				determinedBanner = bannerType.EMAIL;
			} else if (bannerHelper.isAppBannerEnabled()) {
				determinedBanner = bannerType.APP;
			}
		}
		return determinedBanner;
	}
};
