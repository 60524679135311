// @ts-nocheck
const dustExtensions = {
	customHelpers: require('./lib/custom_helpers'),
	customFilters: require('./lib/custom_filters')
};

// hack for making this file requireable by both common.js and require.js
// http://ifandelse.com/its-not-hard-making-your-library-support-amd-and-commonjs/
(function(root, factory) {
	if (typeof define === 'function' && define.amd) {
		define([], function() {
			return (root.dustExtensions = factory()); // NOSONAR
		});
	} else if (typeof module === 'object' && module.exports) {
		module.exports = root.dustExtensions = factory(); // NOSONAR
	} else {
		root.myModule = factory();
	}
})(this, function() {
	return dustExtensions;
});
