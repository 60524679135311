// @ts-nocheck
/**
 * This is custom date-fns module used the client. It's purpose to to only include the pieces
 * of date-fns that are in use client side.
 */
module.exports = {
	format: require('node_modules/date-fns/format').default,
	isValid: require('node_modules/date-fns/isValid').default,
	parse: require('node_modules/date-fns/parse').default,
	addDays: require('node_modules/date-fns/addDays').default,
	differenceInMinutes: require('node_modules/date-fns/differenceInMinutes').default,
	differenceInDays: require('node_modules/date-fns/differenceInDays').default,
	isTomorrow: require('node_modules/date-fns/isTomorrow').default
};
