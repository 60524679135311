const $ = require('jquery');
const { extractHostname } = require('app/url');
const Backbone = require('backbone');

const csrfToken = $('meta[name=csrf-token]').attr('content');
const CSRFInterceptor = Backbone.View.extend({
	el: 'form',
	events: {
		submit: 'addCSRFToken'
	},
	addCSRFToken: submitHandler
});

/**
 * Event handler that adds CSRF tokens to forms from a submit event
 * @param {JQuery.SubmitEvent} event
 * @returns {void}
 */
function submitHandler(event) {
	if (
		event.target.method &&
		event.target.method.toLowerCase() !== 'get' &&
		window.location.hostname === extractHostname(event.target.action)
	) {
		injectCSRFToken(event.target);
	}
}

/**
 * If you overwrite ajax's beforeSend you can use this function to manually add csrf tokens to the request
 * @param {JQuery.jqXHR} xhr
 * @param {JQuery.AjaxSettings} settings
 */
function sendCSRFHeader(xhr, settings) {
	// This ensures CSRF headers don't get set on request that don't need it like GET's and other domains
	if (!/^(GET|HEAD|OPTIONS|TRACE)$/.test(settings.type) && !this.crossDomain) {
		xhr.setRequestHeader('CSRF-Token', csrfToken);
	}
}
/**
 * Sets up ways to automatically intercept submitions and ajax request to append csrf token information
 * @returns {Object}
 */
function setUpAjaxAndFormCSRFTokens() {
	// Add the CSRF token to ajax request
	$.ajaxSetup({
		beforeSend: sendCSRFHeader
	});
	// Add the CSRF token to forms as a hidden field when submitted
	$(document).on('submit', 'form', submitHandler);
	// We instantiate a backbone view to intercept form submits.
	return new CSRFInterceptor();
}
/**
 * If you use native DOM submit function over jQuery.submit or backbone's submit event you can manually add csrf token to the form with this function
 * @param {HTMLElement} form
 */
function injectCSRFToken(form) {
	const $form = $(form);
	if (!$form.find('input[name*="_csrf"]').get(0)) {
		$(form).append(`<input type="hidden" name="_csrf" value="${csrfToken}">`);
	}
}

module.exports = {
	sendCSRFHeader,
	setUpAjaxAndFormCSRFTokens,
	injectCSRFToken
};
