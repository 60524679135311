const Backbone = require('backbone');

module.exports = Backbone.View.extend(
	/** @type {BackboneExtensions.Switch} */ ({
		events: {
			'click .js-switch-toggle': '_updateLabelText'
		},

		initialize: function() {
			this._activeLabelText = this.$el.data('activeLabel');
			this._inactiveLabelText = this.$el.data('inactiveLabel');
			this.$switchToggle = this.$el.find('.js-switch-label');
		},
		_updateLabelText: function(event) {
			if (this._activeLabelText && this._inactiveLabelText) {
				event.currentTarget.checked
					? this.$switchToggle.text(this._activeLabelText)
					: this.$switchToggle.text(this._inactiveLabelText);
			}
		}
	})
);
