// @ts-nocheck
const _ = require('underscore');
const Backbone = require('backbone');
const dustjs = require('dustjs');
const Utils = require('../index');

const RenderMethods = {
	appendTo: 'appendTo',
	prependTo: 'prependTo',
	insertAfter: 'insertAfter',
	insertBefore: 'insertBefore'
};

/**
 * Class ExtendedView
 * @class
 */
class ExtendedView extends Backbone.View {
	static properties = ['_parent', '_method', '_targetEl'];

	/**
	 * Preinitialize
	 * @param options
	 * @returns {ExtendedView}
	 */
	preinitialize(options) {
		super.preinitialize(options);
		this._views = null;
		this._parent = null;
		this._method = RenderMethods.appendTo;
		return Object.assign(this, Utils.accept(options, this.constructor.properties, this.getDefaults()));
	}

	get views() {
		return this._views;
	}

	get parent() {
		return this._parent;
	}

	get method() {
		return this._method;
	}

	_resolveTarget() {
		const { _targetEl } = this;
		if (_targetEl) {
			return _targetEl(this);
		} else if (this.parent) {
			return this.parent._targetEl ? this.parent._targetEl(this) : this.parent.$el;
		}
		return Backbone.$('body');
	}

	_resolveMethod() {
		return this.method && _.has(RenderMethods, this.method) ? this.method : RenderMethods.appendTo;
	}

	_makeElement(content) {
		const el = Utils.defined(content) ? content : this.el;
		this.setElement(Backbone.$(el)[this._resolveMethod()](this._resolveTarget()));
		return this;
	}

	_useTemplate(template, model, callback) {
		dustjs.render(template, model, (err, content) => {
			if (err) {
				window.clientErrorHandler(err);
				this._makeElement();
			} else {
				this._makeElement(content);
			}
			this._onElement(callback);
		});
		return this;
	}

	_onElement(callback) {
		return Utils.defined(callback) && _.isFunction(callback) ? callback() : this;
	}

	_next(predicate) {
		return Utils.defined(this._parent) && Utils.defined(this._parent.lookup) ? this._parent.lookup(predicate) : null;
	}

	getDefaults() {
		return {};
	}

	element(callback, template, model = {}) {
		const $parent = this.$el.parent();
		if (Utils.defined($parent) && $parent.length === 1) {
			this._ensureElement();
			this._onElement(callback);
		} else {
			Utils.defined(template) ? this._useTemplate(template, model, callback) : this._makeElement()._onElement(callback);
		}
		return this;
	}

	lookup(predicate) {
		if (Utils.defined(predicate) && _.isFunction(predicate)) {
			return !predicate(this) ? this._next(predicate) : this;
		}
		return null;
	}

	render() {
		return this;
	}
}

module.exports = { ExtendedView, RenderMethods };
