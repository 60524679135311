const Backbone = require('backbone');
const ContactCenterModel = Backbone.Model.extend({
	api: 'local',
	validate: function(params) {
		if (!params.communicationMethod) {
			return 'communicationMethod is undefined';
		}
		return '';
	},
	url: function() {
		return `/app/api/isContactCenterOpen/${this.get('communicationMethod')}?isCheckout=${this.get('isCheckout')}`;
	}
});

module.exports = ContactCenterModel;
