// @ts-nocheck
/**
 *  Backbone Service
 **/
const _ = require('underscore');
const Backbone = require('backbone');
const $ = require('jquery');

const Generic = require('../backbone/backbone-generic');
const Service = Generic.extend({
	_emulation: false,

	/**
	 *  Proxifies a list of methods of this class into the model passed as the first arguments to the method
	 *  @private
	 *  @method _proxy
	 *  @param model {Backbone.Model} model instance to proxify
	 *  @param {...Function} list of methods to proxify
	 *  @return Backbone.Model
	 **/
	_proxy: function(model) {
		const methods = Array.prototype.slice.call(arguments, 1);
		_.each(methods, (m) => {
			if (this[m]) {
				model[m] = _.bind(this[m], this, model);
			}
		});
		return model;
	},

	/**
	 *  Resolves Url path
	 *  @private
	 *  @method _resolvePath
	 *  @param baseUrl {String} model's current baseUrl
	 *  @param path {String} payload path
	 *  @return String
	 **/
	_resolvePath: function(baseUrl, path) {
		return path ? baseUrl + path : baseUrl;
	},

	/**
	 * Returns emulation property
	 * @returns {boolean}
	 */
	emulation() {
		return this._emulation;
	},

	/**
	 * Makes all requests to behave as mocks (requests will automatically fire the success callback).
	 * @param flag
	 * @return
	 */
	emulate(flag = false) {
		this._emulation = flag;
		return this;
	},

	/**
	 *  Proxify Backbone.Model instance to aggregate Service class functionality
	 *  @public
	 *  @method proxify
	 *  @param model
	 *  @return {Backbone.Model}
	 **/
	proxify: function(model) {
		if (!(model instanceof Backbone.Model)) {
			return model;
		}
		return this._proxy(model, 'request', 'download', 'parse', 'onSuccess', 'onError');
	},

	/**
	 *  Service Parsing Model override
	 *  @public
	 *  @method parse
	 *  @returns {any}
	 **/
	parse: function(model, response, request) {
		if (request.noParse) {
			return {};
		} else if (request.onParse) {
			return request.onParse.call(this, response, request);
		} else if (model.onParse) {
			return model.onParse.call(this, response, request);
		}
		return model.constructor.prototype.parse.call(model, response, request);
	},

	/**
	 *  Makes a Ajax Request
	 *  @public
	 *  @method request
	 *  @param model {Backbone.Model} model proxified reference
	 *  @param payload {Object} request payload
	 *  @param [success] {Function} success handler
	 *  @param [error] {Function} error handler
	 *  @return
	 **/
	request: function(model, payload, success, error) {
		const _request = _.extend(_.omit(payload, 'path', 'type'), {
			url: this._resolvePath(_.result(model, 'url'), payload.path),
			type: payload.type ? payload.type : 'POST',
			success: _.bind(this.onSuccess, this, success),
			error: _.bind(this.onError, this, error)
		});
		if (this.emulation()) {
			this.parse(model, {}, _request);
			this.onSuccess(success, model, {}, {});
		} else {
			model.constructor.prototype.fetch.call(model, _request);
		}
		return model;
	},

	/**
	 *	Default Download strategy that
	 *	@public
	 *	@method download
	 *	@param model {Backbone.Model} model proxified reference
	 *	@param payload {Object} payload reference
	 *	@return Service
	 **/
	download: function(model, payload) {
		let url = this._resolvePath(model.url(), payload.path);
		if (payload.data) {
			url += `?${$.param(payload.data)}`;
		}
		const $element = $(`<a href = ${url}/>`).appendTo($('body'));
		$element[0].click();
		$element.remove();
		return this;
	},

	/**
	 *  Default Success Handler
	 *  @public
	 *	@method onSuccess
	 *	@param success {Function} success callback
	 *	@param model {Backbone.Model} model reference
	 *	@param response {Object} response reference
	 *	@param request {Object} request reference
	 *	@return Service
	 **/
	onSuccess: function(success, model, response, request) {
		if (success && _.isFunction(success)) {
			success(model, response, request);
		}
		return this;
	},

	/**
	 *  Default Error Handler
	 *  @public
	 *  @method onError
	 *	@param error {Function} error callback
	 *	@param model {Backbone.Model} model reference
	 *	@param response {Object} response reference
	 *	@param request {Object} request reference
	 *	@return Service
	 **/
	onError: function(error, model, response, request) {
		if (error && _.isFunction(error)) {
			error(model, response, request);
		}
		return this;
	}
});

// @ts-ignore
module.exports = new Service();
