import jsCookie from 'js-cookie';

/**
 * @module helpers/usabilla_inline_campaigns
 * This module defines helper functions for Usabilla inline campaigns
 */
const USABILLA = require('config/third_party').USABILLA;

export const UsabillaInlineCampaigns = {
	/**
	 * Resets Usabilla campaign
	 * @param {string} campaignId
	 * @returns {void}
	 */
	resetCampaign(campaignId) {
		window.localStorage.removeItem(`usbl.${USABILLA.MAIN_BUTTON_ID}.c.${campaignId}`);
	},
	/**
	 * @returns {void}
	 */
	initInlineCampaigns() {
		const campaignIdList = window.BCOM.util.getValuesFromObjectAsArray(USABILLA.CAMPAIGN_IDS);
		// initially reset campaigns for those who have already seen the forms
		campaignIdList.forEach((id) => {
			UsabillaInlineCampaigns.resetCampaign(id);
		});
		// reset campaigns after they are closed
		window.usabilla_live('setEventCallback', function(category, action, label) {
			if (action === 'Campaign:Close' && campaignIdList.includes(label)) {
				UsabillaInlineCampaigns.resetCampaign(label);
			}
		});
	},
	/**
	 * Calls correct Usabilla campaign
	 * @param {Event} event javascript Event interface
	 * @returns {void}
	 */
	openInlineSearchResultsCampaign(answer) {
		if (!['yes', 'no'].includes(answer)) {
			return;
		}
		window.usabilla_live('trigger', `${USABILLA.INLINE_SEARCH_RESULTS} - ${answer}`);
	},

	/**
	 * Function check to see if user is validated to view survey and
	 * show corresponding rep/customer survey
	 * @returns {void}
	 */
	openFergusonSurveyCampaign() {
		if (!this.showFergusonCampaign()) {
			return;
		}

		const isInternalRep = window.dataLayer.employeeUserId > 0;

		if (isInternalRep) {
			window.usabilla_live('trigger', `${USABILLA.FERGUSON_SURVEY} - rep`);
		} else {
			window.usabilla_live('trigger', `${USABILLA.FERGUSON_SURVEY} - customer`);
		}

		sessionStorage.setItem('pagesViewed', '0');
	},

	/**
	 * Function will verify if user is valid to view the survey.
	 * @returns {Boolean}
	 */
	showFergusonCampaign() {
		const isShowroomRep = jsCookie.get('isShowroomRep') === 'true';

		if (!isShowroomRep) {
			return false;
		}

		if (!this.hasViewedRequiredPages()) {
			return false;
		}

		const cookie = jsCookie.get('trigger_survey');
		const inTwoWeeks = new Date(new Date().getTime() + 3600000 * 24 * 14);

		if (!cookie) {
			jsCookie.set('trigger_survey', 'false', {
				expires: inTwoWeeks
			});
			return true;
		}

		return false;
	},

	/**
	 * Function will check to see if user has viewed > 5 pages
	 * @returns {Boolean}
	 */
	hasViewedRequiredPages() {
		let pagesViewed = Number(sessionStorage.getItem('pagesViewed'));

		if (!pagesViewed) {
			sessionStorage.setItem('pagesViewed', '1');
			return false;
		}

		pagesViewed = pagesViewed + 1;
		sessionStorage.setItem('pagesViewed', pagesViewed.toString());

		if (pagesViewed < 5) {
			return false;
		}

		return true;
	}
};
