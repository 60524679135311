const Backbone = require('backbone');

module.exports = Backbone.View.extend({
	el: '.my-account-box',
	initialize: function() {
		this.initMobileMenu();
		$(window).on('orientationchange', this.initMobileMenu.bind(this));
	},
	events: {
		'click .js-expand-projects': '_toggleNavProjectsList'
	},
	initMobileMenu: function() {
		if (window.innerWidth < 768) {
			this.initMobileEvents();
			this.moveActiveToTop();
			if (this.$el.find('.js-display-menu').length) {
				this.$('li').show();
			}
		} else {
			this.$('li').show();
		}
	},
	moveActiveToTop: function() {
		this.$el.prepend(this.$el.find('li.active'));
	},
	initMobileEvents: function() {
		// These are the events specifically for phones.
		this.events = this.events || {};
		this.events['click li.active'] = 'toggleNav';
		this.delegateEvents();
	},
	toggleNav: function(e) {
		e.preventDefault();
		this.$('li.active')
			.toggleClass('ico-chevron-down ico-chevron-up')
			.siblings()
			.toggle();
	},
	_toggleNavProjectsList: function(e) {
		e.preventDefault();
		this.$('.js-projects-list').toggleClass('hide');
	}
});
