/**
 * Changes to this file will effect instances of videos over all of our sites
 *
 * 1.) Embedded Videos - players instantiated and rendered on the page during page load
 * 2.) Modal Videos - players instantiated in a modal on .video click
 *
 * Test: (Data not guaranteed to be there by the time you read this)
 *     - Construct article pages
 *         https://localproxy.build.com/new-article/a15582
 *     - LDI home page (construct data)
 *         https://localproxy.livingdirect.com/
 *     - LDI product page media gallery modal
 *         http://localproxy.livingdirect.com/allerair-5000-ds-tobacco-smoke-air-purifier-5000ds/A5AS21254140.html
 */

const Backbone = require('backbone');
const window = require('window');
const externalScriptLoader = require('externalScriptLoader');
var VideoView = Backbone.View.extend({
	// element will be a `.video` element passed into the constructor
	events: {
		click: '_handleClick'
	},
	initialize: function() {
		this.attributes = this.$el.data() || {};
		// fallback for content tool videos
		this.attributes.title = this.attributes.title || this.$el.prop('title');
		// ultimate title fallback
		this.attributes.title = this.attributes.title || 'unknown title';
		this.$videoModal = this.$el.find('.js-video-modal');
		this.attributes.playCount = 0;
		this.attributes.playsInModal = Boolean(this.$el.data('is-modal'));

		if (this.attributes.playsInModal) {
			this.attributes.autoPlay = true;
			if (this.$el.data('autoplay')) {
				this.attributes.autoPlay = this.$el.data('autoplay');
			}
		} else {
			// immediately load the wistia/youtube library.
			this._embedVideo();
		}
		this.$videoModal.find('.modal-title').html(this.attributes.title);
		// Backbone's event bus is used so multiple instances of video.js can
		// be notified in the global window.onYouTubeIframeAPIReady handler.
		this.listenTo(Backbone, 'youtube:apiLoaded', this._initYouTubeVideoPlayer);
		// pause video
		this.listenTo(Backbone, 'videos:pause', () => {
			this.stopPlayer();
		});
	},
	_embedWistiaVideo: function() {
		var wistiaCallbackObject = {};
		wistiaCallbackObject[this.attributes.urlHashId] = this._wistiaPlayerCallback.bind(this);
		window._wq = window._wq || [];
		window._wq.push(wistiaCallbackObject);
		// superHackeyHack™ to make sure wistia initialization happens
		window.Wistia.embeds.setup();
	},
	_embedVideo: function() {
		if (this.attributes.providerId === 1) {
			// wistia
			externalScriptLoader('wistia', this._embedWistiaVideo.bind(this));
		} else {
			// youtube
			externalScriptLoader('youtube_iframe_api', this._handleYoutubeLoad.bind(this));
		}
	},
	_handleClick: function(event) {
		event.preventDefault();
		this._loadVideo();
	},
	_handleModalClose: function() {
		this.stopPlayer(true);
		// trash the modal and start over
		this.$videoModal.remove();
		this.$videoModal = this.$el.find('.js-video-modal');
	},
	_handleWistiaLoad: function() {
		var $videoPlayer = this.$videoModal.find('.js-video-player');
		$videoPlayer.addClass('wistia_embed');
		$videoPlayer.addClass(`wistia_async_${this.attributes.urlHashId}`);
		$videoPlayer.addClass('videoFoam=true');
		$videoPlayer.attr('id', this.attributes.urlHashId);
		this._embedWistiaVideo();
	},
	_handleYoutubeLoad: function() {
		var $videoPlayer;
		if (this.attributes.playsInModal) {
			$videoPlayer = this.$videoModal.find('.js-video-player')[0];
		} else {
			$videoPlayer = this.$el.find(`.ytp-${this.attributes.urlHashId}`)[0];
		}
		this.$videoPlayer = $videoPlayer;

		if (window.youtubeAPILoaded) {
			this._initYouTubeVideoPlayer();
		} else {
			window.onYouTubeIframeAPIReady = () => {
				window.youtubeAPILoaded = true;
				Backbone.trigger('youtube:apiLoaded');
			};
		}
	},
	_handleYoutubeStateChange: function(event) {
		if (window.s.Media) {
			var time = event.target.getCurrentTime();
			switch (event.data) {
				case window.YT.PlayerState.PLAYING:
					window.s.Media.play(this.attributes.title, time);
					break;
				case window.YT.PlayerState.PAUSED:
					window.s.Media.stop(this.attributes.title, time);
					break;
				case window.YT.PlayerState.ENDED:
					window.s.Media.stop(this.attributes.title, time);
					window.s.Media.close(this.attributes.title);
					break;
				default:
					// we don't want to track any other sates
					break;
			}
		}
	},
	_handleYOutubeVideoLoaded: function(event) {
		var duration = event.target.getDuration();
		if (window.s.Media) {
			window.s.Media.playerName = 'YouTube';
			window.s.Media.open(this.attributes.title, duration, 'YouTube');
		}
	},
	_loadVideo: function() {
		if (this.attributes.providerId === 1) {
			externalScriptLoader('wistia', this._handleWistiaLoad.bind(this));
		} else {
			externalScriptLoader('youtube_iframe_api', this._handleYoutubeLoad.bind(this));
		}

		if (this.attributes.playsInModal) {
			// superHackeyHack™ because bootstrap attaches modal overlays to the body and breaks the z-index stacking context
			this.$videoModal = this.$videoModal.clone();
			this.$videoModal.appendTo('body');
			// register close callback
			this.$videoModal.on(
				'hide.bs.modal',
				function() {
					this._handleModalClose.call(this);
				}.bind(this)
			);
			this.$videoModal.modal('show');
		}
	},
	_initYouTubeVideoPlayer: function() {
		if (this.$videoPlayer) {
			this.attributes.ytplayer = new window.YT.Player(this.$videoPlayer, {
				videoId: this.attributes.urlHashId,
				width: '100%',
				height: '100%',
				playerVars: {
					autoplay: this.attributes.playsInModal ? 1 : 0,
					rel: 0
				},
				events: {
					onStateChange: this._handleYoutubeStateChange.bind(this),
					onReady: this._handleYOutubeVideoLoaded.bind(this)
				}
			});
		}
	},
	_wistiaPlayerCallback: function(wistiaPlayer) {
		this.attributes.wistiaPlayer = wistiaPlayer;
		this.attributes.wistiaPlayer.on(
			'play',
			function() {
				window.s.Media.playerName = 'Wistia';
				this.attributes.playCount++;
				var time = parseInt(this.attributes.wistiaPlayer.time(), 10);
				// Execute Omniture open call on first play event to begin video tracking
				if (this.attributes.playCount === 1) {
					var duration = parseInt(this.attributes.wistiaPlayer.duration(), 10);
					time = 0;
					if (window.s.Media) {
						window.s.Media.open(this.attributes.title, duration, 'Wistia');
					}
				}
				if (window.s.Media) {
					window.s.Media.play(this.attributes.title, time);
				}
			}.bind(this)
		);

		this.attributes.wistiaPlayer.on(
			'pause',
			function() {
				var time = parseInt(this.attributes.wistiaPlayer.time(), 10);
				if (window.s.Media) {
					window.s.Media.stop(this.attributes.title, time);
				}
			}.bind(this)
		);

		this.attributes.wistiaPlayer.on(
			'end',
			function() {
				var time = parseInt(this.attributes.wistiaPlayer.time(), 10);
				if (window.s.Media) {
					window.s.Media.stop(this.attributes.title, time);
					window.s.Media.close(this.attributes.title);
				}
			}.bind(this)
		);
		// autoPlay
		if (this.attributes.playsInModal && this.attributes.autoPlay) {
			this.attributes.wistiaPlayer.play();
		}
	},
	stopPlayer: function(shouldRemoveVideo = false) {
		var time;

		if (this.attributes.providerId === 1) {
			if (this.attributes.wistiaPlayer) {
				time = parseInt(this.attributes.wistiaPlayer.time(), 10);
				this.attributes.wistiaPlayer.pause();
				if (shouldRemoveVideo) {
					this.attributes.wistiaPlayer.remove();
				}
			}
		} else {
			// Only run this if we found the youtube player.
			if (this.attributes.ytplayer && this.attributes.ytplayer.getCurrentTime) {
				time = parseInt(this.attributes.ytplayer.getCurrentTime(), 10);
				this.attributes.ytplayer.stopVideo();
			}
		}
		// track the close in omniture
		if (window.s && window.s.Media) {
			window.s.Media.stop(this.attributes.title, time);
			window.s.Media.close(this.attributes.title);
		}
	}
});
module.exports = VideoView;
