const window = require('window');
const querystring = require('querystring');
const url = require('url');

/**
 * Returns true if the current window size matches small constrain, false otherwise.
 * @returns {boolean}
 */
const isScreenSmall = () => {
	return window.matchMedia('screen and (max-width: 375px)').matches;
};

/**
 * Returns true if the current window size matches medium constrain, false otherwise.
 * @returns {boolean}
 */
const isScreenMedium = () => {
	return window.matchMedia('screen and (min-width: 375px) and (max-width: 768px)').matches;
};

/**
 * Returns true if the current window size matches large constrain, false otherwise.
 * @returns {boolean}
 */
const isScreenLarge = () => {
	return window.matchMedia('screen and (min-width: 768px) and (max-width: 1024px)').matches;
};

/**
 * Returns true if the current window size matches extra large constrain, false otherwise.
 * @returns {boolean}
 */
const isScreenXLarge = () => {
	return window.matchMedia('screen and (min-width: 1024px)').matches;
};

/**
 * Similar to object destructuring but this will pick from attributes
 * an N number of properties array by key name.
 * @param {object} attributes
 * @param {Array} properties
 * @returns {object}
 */
const pick = (attributes, properties) => {
	return properties.reduce(function(memo, key) {
		if (attributes.hasOwnProperty(key)) {
			memo[key] = attributes[key];
		}
		return memo;
	}, {});
};

/**
 * Similar to object destructuring but this will omit from attributes
 * a N number of properties array by key name
 * @param {object} attributes
 * @param {array} properties
 * @returns {object}
 */
const omit = (attributes, properties) => {
	return Object.keys(attributes).reduce((memo, key) => {
		if (!properties.includes(key)) {
			memo[key] = attributes[key];
		}
		return memo;
	}, {});
};

/**
 * Iterates over all the properties of the given object and checks if at least, one property name
 * is exists based on the object key name.
 * @param obj
 * @param propertyKeys
 * @returns {boolean}
 */
const hasAny = (obj = {}, ...propertyKeys) => {
	let res = false;
	const objKeys = Object.keys(obj);
	objKeys.forEach((key) => {
		if (propertyKeys.includes(key)) {
			res = true;
		}
	});
	return res;
};

/**
 * Aggregate given `options` by picking up only a given list of `properties`
 * This method will also apply default values to those options if `defaults' has been passed.
 * @param {object} options
 * @param {array} properties
 * @param {object} defaults
 */
const accept = (options = {}, properties = [], defaults = {}) => {
	return Object.assign(defaults, pick(options, properties));
};

/**
 * Returns array of words found in provided string:
 *  findWords(' ThisIs a-great. test    ');
 *  // => ["This", "Is", "a", "great", "test"]
 * @param {string} str
 */
const findWords = (str) => {
	return str
		.replace(/([A-Z])/g, ' $1')
		.trim()
		.split(/\s|\.|-/)
		.filter((e) => e !== '');
};

/**
 * kebab cases the provided string
 *  kebabCase(' ThisIs a-great. test    ');
 *  // => 'this-is-a-great-test'
 * @param {string} str
 */
const kebabCase = (str) => {
	return findWords(`${str}`.replace(/['\u2019]/g, '')).reduce((result, word, index) => {
		return result + (index ? '-' : '') + word.toLowerCase();
	}, '');
};

/**
 * Returns true if the argument passed as parameter is neither, null or undefined.
 * @param {any} o
 * @return {boolean}
 */
const defined = (o) => {
	return o !== null && o !== void 0;
};

module.exports = {
	getUrlVars: function() {
		var vars = [],
			hash;
		var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
		for (var i = 0; i < hashes.length; i++) {
			hash = hashes[i].split('=');
			vars.push(hash[0]);
			vars[hash[0]] = hash[1];
		}
		return vars;
	},
	getUrlVar: function(name) {
		return this.getUrlVars()[name];
	},
	getQueryString: function(currentUrl) {
		/** @type {*} */
		const parsedUrl = url.parse(currentUrl);
		if (parsedUrl.query === null) {
			/** @type {object} */ (parsedUrl.query) = {};
		}
		if (typeof parsedUrl.query !== 'string') {
			return;
		}
		parsedUrl.query = querystring.parse(parsedUrl.query);
		return parsedUrl.query;
	},

	/**
	 * Per David Walsh: https://davidwalsh.name/javascript-debounce-function
	 * Returns a function, that, as long as it continues to be invoked, will not be triggered
	 * The function will be called after it stops being called for N milliseconds
	 * @param  {Function} func - The function to fire
	 * @param  {Number} wait - How long to wait before calling func
	 * @param  {Boolean} [immediate] - If true, trigger the function on the leading edge, instead of the trailing.
	 */
	debounce: function(func, wait, immediate) {
		let timeout;
		return function executedFunction() {
			const context = this,
				args = arguments,
				callNow = immediate && !timeout,
				later = function() {
					timeout = null;
					if (!immediate) func.apply(context, args);
				};

			clearTimeout(timeout);
			timeout = setTimeout(later, wait);
			if (callNow) func.apply(context, args);
		};
	},

	defined,
	accept,
	pick,
	omit,
	hasAny,
	kebabCase,
	isScreenSmall,
	isScreenMedium,
	isScreenLarge,
	isScreenXLarge
};
