const Backbone = require('backbone');

const CartItemModel = Backbone.Model.extend({
	url: function() {
		let url = '/app/api/cart/item';

		if (this.get('isProjectsQuickAdd')) {
			return '/app/api/projects/item/quickAdd';
		}

		if (this.id) {
			url += `/${this.id}`;
		}
		if (this.get('parentId')) {
			url += `?parentId=${this.get('parentId')}`;
		}

		if (this.get('useNewProjectAddItemEndpoint')) {
			url = '/app/api/projects/item';
		}

		return url;
	},
	api: 'local',
	idAttribute: 'id',
	defaults: {},
	validate: function(attributes) {
		let errors = '';

		if (attributes.subItems && Array.isArray(attributes.subItems)) {
			attributes.subItems.forEach((item) => {
				if (!parseInt(item.uniqueId, 10)) {
					errors = `${errors}Error: uniqueId must be set and an integer but received ${item.uniqueId} `;
				}
			});
		}

		if (!attributes.uniqueId || !parseInt(attributes.uniqueId, 10)) {
			errors = `${errors}Error: uniqueId must be set and an integer but received ${attributes.uniqueId} `;
		}
		// If falsey validation passes. If truthy errors are passed to error callback.
		return errors;
	}
});

module.exports = CartItemModel;
