const window = require('window');
const Backbone = require('backbone');
const ContactCenterModel = require('app/models/store/client/contact_center');
const { COMMUNICATION_METHOD } = require('../../../../lib/enums');
const omnitureHelper = require('app/views/store/common/omniture/omniture_helper');

// SalesForce removed the "send" button from desktop chat. Old session replays showed that users couldn't figure out how
// to submit the chat box, so we add our own button to do this.
const desktopSendButtonTemplate = `
	<button role="button" class="js-desktop-send-chat-button bg-theme-primary theme-white b--theme-primary hover-bg-theme-primary-dark hover-theme-white hover-b--theme-primary-dark active-bg-theme-primary-darker active-b--theme-primary-darker f5 pv3 ml2 w-20 theme-white br2 lh-solid ba justify-center">
		<div>Send</div>
	</button>
`;

const validationWarningTemplate = `<div class="js-custom-validation-errors f7 theme-error">This field is required.</div>`;

const LiveAgentView = Backbone.View.extend({
	initialize: function() {
		this.contactCenterModel = new ContactCenterModel({
			communicationMethod: COMMUNICATION_METHOD.CHAT,
			isCheckout: window.dataLayer.isCheckout
		});
		this._loadLiveAgentScriptIfNeeded();
	},

	_loadLiveAgentScriptIfNeeded: function() {
		if (!window.embedded_svc) {
			let s = document.createElement('script');
			s.setAttribute('src', window.dataLayer.salesforce.embeddedServiceUrl);
			s.onload = () => {
				this._initESWInvitation();
			};
			document.body.appendChild(s);
		} else {
			this._initESWInvitation();
		}
	},

	_initESWInvitation: function() {
		// Basic settings
		window.embedded_svc.settings.displayHelpButton = true;
		window.embedded_svc.settings.defaultMinimizedText = 'Chat With Us!';
		window.embedded_svc.settings.offlineSupportMinimizedText = window.embedded_svc.settings.defaultMinimizedText;
		window.embedded_svc.settings.language = 'en-US';
		window.embedded_svc.settings.enabledFeatures = ['LiveAgent'];
		window.embedded_svc.settings.entryFeature = 'LiveAgent';
		window.embedded_svc.settings.devMode = window.dataLayer.salesforce.devMode;
		window.embedded_svc.settings.chatbotAvatarImgURL =
			'//s3.img-b.com/image/private/s--Pq1eh3dZ--/v1601401800/siteassets/avatars/tinyhouse-build_1.png';

		if (window.BCOM.device.isMobile) {
			window.embedded_svc.settings.defaultMinimizedText = 'Chat';
		}

		window.embedded_svc.settings.extraPrechatFormDetails = [
			{
				value: window.dataLayer.siteDisplayName,
				transcriptFields: ['Referring_Site__c'],
				displayToAgent: false
			},
			{
				value: window.dataLayer.sessionCartId,
				transcriptFields: ['Session_Cart_Id__c'],
				displayToAgent: false
			},
			{
				value: false,
				transcriptFields: ['proactive_chat__c'],
				displayToAgent: true
			},
			{
				value: window.dataLayer.aes256Email,
				transcriptFields: ['auth_token__c'],
				displayToAgent: false
			}
		];

		let buttonId;

		if (window.dataLayer.disablePrechatSurvey) {
			window.embedded_svc.settings.extraPrechatFormDetails.push({
				value: window.dataLayer.contactCenterHoursInfo.isChatOpen ? 'Yes' : 'No',
				transcriptFields: ['isBusinessHours__c'],
				displayToAgent: false
			});
			window.embedded_svc.settings.fallbackRouting = [window.dataLayer.salesforce.chatButtonIds.chatBot];
			buttonId = window.dataLayer.salesforce.chatButtonIds.chatBot;
		} else {
			window.embedded_svc.settings.fallbackRouting = [
				window.dataLayer.salesforce.chatButtonIds.standard,
				window.dataLayer.salesforce.chatButtonIds.chatBot
			];

			window.embedded_svc.settings.prepopulatedPrechatFields = {
				FirstName: window.dataLayer.firstName,
				LastName: window.dataLayer.lastName,
				Email: window.dataLayer.email,
				Company: 'no-company',
				LeadSource: 'Live Agent'
			};

			// Settings to prevent popping up a lead in a new tab
			window.embedded_svc.settings.extraPrechatInfo = [
				{
					entityName: 'Lead',
					showOnCreate: false,
					entityFieldMaps: [
						{
							isExactMatch: false,
							fieldName: 'LastName',
							doCreate: true,
							doFind: false,
							label: 'Last Name'
						},
						{
							isExactMatch: false,
							fieldName: 'FirstName',
							doCreate: true,
							doFind: false,
							label: 'First Name'
						},
						{
							isExactMatch: false,
							fieldName: 'Email',
							doCreate: true,
							doFind: false,
							label: 'Email'
						}
					]
				}
			];

			// Set up handler to determine the chat id to use.
			window.embedded_svc.settings.directToButtonRouting = this._directToButtonRouting.bind(this);

			buttonId = window.dataLayer.salesforce.chatButtonIds.standard;

			window.embedded_svc.addEventHandler('featureLoaded', (feature) => {
				if (feature === 'Invite') {
					// Intercept clicks on the "Start Chatting" pre-chat submit button, to validate before allowing the form submit.
					// The last param to set capture=true isn't supported in jQuery, so used addEventListener.
					document.addEventListener('click', this._onStartChattingButton.bind(this), true);
				}
			});
		}

		const isSafari = Boolean(window.safari);
		window.embedded_svc.__synchronous_decrement_table = !isSafari;

		// Unused Settings
		window.embedded_svc.settings.avatarImgURL = '';
		window.embedded_svc.settings.prechatBackgroundImgURL = '';
		window.embedded_svc.settings.waitingStateBackgroundImgURL = '';
		window.embedded_svc.settings.smallCompanyLogoImgURL = '';

		// Set up event handlers.
		$(document).on('click', '.js-open-live-agent', this._onOpenChatButton.bind(this));

		// This event is triggered when a customer starts the chat via the "Chat With Us!" button, or when they click a
		// header/footer link that opens the chat via the InviteAPI. Need to re-check if chat hours are open at this
		// time in case the page has been open. This prevents sending new chats to any agents still online during off
		// hours (i.e. agents are finishing up queued or open chats but we don't want to allow more to be queued).
		// After the "Start Chatting" button is clicked in the pre-chat form, the synchronous directToButtonRouting
		// callback function is called. The updated hours must be loaded by that time.
		// This call *should* finish before the customer fills in the pre-chat form and hits the start chat button.
		// At that point, _determineLiveAgentButtonId() will be called, where isChatOpen will be used to determine whether
		// to go directly to the chatbot or to go to a live chat id.
		//
		// Also need to track that the customer initiated this chat (vs. proactive invite).
		window.embedded_svc.addEventHandler('onHelpButtonClick', () => {
			this.contactCenterModel
				.fetch()
				.then(() => {
					window.dataLayer.contactCenterHoursInfo.isChatOpen = this.contactCenterModel.get('isOpen');
				})
				.catch(window.clientErrorHandler);
			this._setChatLastOpenedByCustomerAndTrack();
		});

		if (window.BCOM.device.isDesktop) {
			$(document).on('focus', '.chasitorText', this._addDesktopSendButton.bind(this));
			$(document).on('click', '.js-desktop-send-chat-button', this._onChatSendMessageButton.bind(this));
		}

		window.embedded_svc.init(
			window.dataLayer.salesforce.siteUrl,
			window.dataLayer.salesforce.liveAgentSiteUrl,
			// Both RBS and node-store must use this value or the chat will be lost.
			'https://service.force.com',
			window.dataLayer.salesforce.liveAgentSiteId,
			window.dataLayer.salesforce.liveAgentName,
			{
				baseLiveAgentContentURL: window.dataLayer.salesforce.baseLiveAgentContentUrl,
				deploymentId: window.dataLayer.salesforce.deploymentId,
				buttonId,
				baseLiveAgentURL: window.dataLayer.salesforce.baseLiveAgentUrl,
				eswLiveAgentDevName: window.dataLayer.salesforce.eswLiveAgentDevName
			}
		);

		if (this._hasQueryParam()) {
			this._openChat();
		}
	},

	_addDesktopSendButton: function() {
		if (!$('.js-desktop-send-chat-button').length) {
			$('.chasitorControls').append(desktopSendButtonTemplate);
		}
	},

	_hasQueryParam: function() {
		const { BCOM } = window;
		if (BCOM) {
			return Boolean(BCOM.util.getQueryStringParameter('chat') === 'start');
		} else {
			return false;
		}
	},

	_onOpenChatButton: function(e) {
		e.preventDefault();
		this._openChat();
	},

	_openChat: function() {
		const { embedded_svc: liveAgent } = window;
		return this._waitForInviteAPI(liveAgent).then((inviteAPI) => {
			window.localStorage.setItem('chat_last_opened_by', 'customer');
			try {
				inviteAPI.inviteButton.acceptInvite();
			} catch (error) {
				// Calling inviteAPI always throws an unneeded SF error. This suppresses it.
			}
		});
	},

	_onStartChattingButton: function(e) {
		if (e.target && this._isStartChattingButtonClick(e.target)) {
			this._validatePreChatForm(e);
		}
	},

	/**
	 * Validate all fields. Add error messages to any invalid select inputs since SF doesn't validate these correctly.
	 * Allow the pre-chat form to submit except when all text inputs are valid but not all select inputs are
	 * valid; in this case need to prevent submitting because SF would accept the invalid select values.
	 */
	_validatePreChatForm: function(e) {
		let areTextInputsValid = this._validateTextField('.FirstName.slds-style-inputtext');
		areTextInputsValid = this._validateTextField('.LastName.slds-style-inputtext') && areTextInputsValid;
		areTextInputsValid = this._validateTextField('.Email.slds-style-inputtext') && areTextInputsValid;

		this._clearValidationErrorFromFields();
		let areSelectsValid = this._validateSelectField('.Is_This_Regarding_A_Recent_Order__c');
		areSelectsValid = this._validateSelectField('.Are_You_A_Building_Professional__c') && areSelectsValid;
		areSelectsValid = this._validateSelectField('.isWorkingWithFergusonShowroom__c') && areSelectsValid;

		if (areTextInputsValid && !areSelectsValid) {
			// Don't allow submitting in this case since SF would accept the invalid select values.
			e.stopImmediatePropagation();
		}

		if (areTextInputsValid && areSelectsValid) {
			// Validation was ok - track the event since the pre-chat submit will be successful.
			this._trackStartChatting();
		}
	},

	// Check if the click was on or in the 'Start Chatting' button that submits the pre-chat form.
	_isStartChattingButtonClick: function(element) {
		return (
			element.classList.contains('startButton') || (element.parentElement && element.parentElement.classList.contains('startButton'))
		);
	},

	_clearValidationErrorFromFields: function() {
		$('.js-custom-validation-errors').remove();
	},

	_validateTextField: function(selector) {
		// Force blur event on each text field, so that SF will update the validation status (i.e. add/remove
		// the "This field is required" message). This is required for when auto-fill is used to enter values in a field,
		// because it doesn't trigger blur events on auto-filled fields.
		$(selector)
			.focus()
			.blur();

		return $(selector).val() !== '';
	},

	_validateSelectField: function(selector) {
		const $control = $(selector);
		if ($control.val() === 'Select_Option') {
			$control.closest('.uiInput--select').append(validationWarningTemplate);
			return false;
		}
		return true;
	},

	_onChatSendMessageButton: function(e) {
		e.preventDefault();

		const $chatInput = $('.chasitorText');
		const value = $chatInput.val();

		$chatInput.val('');
		$chatInput.focus();

		if (String(value).trim().length > 0) {
			window.embedded_svc.postMessage('chasitor.sendMessage', value);
		}
	},

	_waitForInviteAPI: function(liveAgent) {
		// recursively check for the presence of the inviteAPI
		const checkForAPI = (resolve) => {
			if (liveAgent.inviteAPI) {
				resolve(liveAgent.inviteAPI);
			} else {
				setTimeout(() => {
					checkForAPI(resolve);
				}, 300);
			}
		};
		return new Promise(checkForAPI);
	},

	/**
	 * Check if chat is open; if closed, the customer will be directed specifically to the chatbot,
	 * else they will go a live chat id with chatbot as fallback.
	 */
	_directToButtonRouting: function(prechatFormData) {
		if (window.dataLayer.contactCenterHoursInfo.isChatOpen) {
			// Configure to route to the chosen chat, with chatbot as a fallback. This requires that the chosen
			// id is specified here and returned below. If the chosen chat id is not available, the fallback in
			// fallbackRouting will be used by SF.
			const chatButtonId = this._determineLiveAgentButtonId(prechatFormData);
			window.embedded_svc.settings.fallbackRouting = [chatButtonId, window.dataLayer.salesforce.chatButtonIds.chatBot];
			return chatButtonId;
		}

		// Route directly to chatbot.
		window.embedded_svc.settings.fallbackRouting = [window.dataLayer.salesforce.chatButtonIds.chatBot];
		return window.dataLayer.salesforce.chatButtonIds.chatBot;
	},

	_determineLiveAgentButtonId: function(prechatFormData) {
		// Choose queue depending on Pro status and if currently on an ECRM page
		const lastOpenedBy = window.localStorage.getItem('chat_last_opened_by');
		window.embedded_svc.settings.extraPrechatFormDetails[2].value = lastOpenedBy === 'invitation';
		const chatButtonIds = window.dataLayer.salesforce.chatButtonIds;
		const isLdi = window.BCOM.theme.isLivingDirect;

		let pro = false;
		let regardingRecentOrder = false;
		let ecrm = false;
		let isWorkingWithShowroom = false;

		if (prechatFormData.length >= 6) {
			// Are you a building professional?
			if (prechatFormData[4].value === 'Yes') {
				pro = true;
			}
			// Is this regarding a recent order?
			if (prechatFormData[3].value === 'Yes') {
				regardingRecentOrder = true;
			}

			// Are you working with a Ferguson Showroom?
			isWorkingWithShowroom = Boolean(prechatFormData[5].value === 'Yes');
		}

		if (window.dataLayer.page.includes('ecrm')) {
			ecrm = true;
		}

		// Being on an ECRM page or selecting Yes for Regarding a Recent Order sends you to Post queues
		const isPostSale = regardingRecentOrder || ecrm;
		// Build a lookup key
		let chatKey = '{isLdi}{proStatus}{isPostSale}';
		chatKey = chatKey
			.replace('{isLdi}', isLdi ? 'ldi' : '')
			.replace('{proStatus}', pro && !isWorkingWithShowroom ? 'Pro' : 'Consumer')
			.replace('{isPostSale}', isPostSale || isWorkingWithShowroom ? 'Post' : 'Pre');

		// ProPre -> proPre
		chatKey = chatKey.charAt(0).toLowerCase() + chatKey.slice(1);

		return chatButtonIds[chatKey];
	},

	_trackStartChatting: function() {
		// Tracking based on if the Pre-Chat was opened automatically (proactive invite) or by the customer
		const lastOpenedType = window.localStorage.getItem('chat_last_opened_by') === 'invitation' ? 'livechatinvitation' : 'livechat';
		Backbone.trigger(
			'omniture:chatTracking',
			`${lastOpenedType}~${omnitureHelper._chatTrackingMessage(
				window.s.pageName,
				window.dataLayer.baseCategory,
				omnitureHelper._getBusinessCategory()
			)}`,
			lastOpenedType,
			window.s.pageName,
			window.dataLayer.baseCategory,
			omnitureHelper._getBusinessCategory()
		);
	},

	_setChatLastOpenedByCustomerAndTrack: function() {
		window.localStorage.setItem('chat_last_opened_by', 'customer');
		Backbone.trigger(
			'omniture:chatTracking',
			`liveChatButtonClick~chatWithAnExpert~${omnitureHelper._chatTrackingMessage(
				window.s.pageName,
				window.dataLayer.baseCategory,
				omnitureHelper._getBusinessCategory()
			)}`,
			'buttonclick-chatWithAnExpert',
			window.s.pageName,
			window.dataLayer.baseCategory,
			omnitureHelper._getBusinessCategory()
		);
	}
});

module.exports = LiveAgentView;
