const window = require('window');
const trackLink = {
	/**
	 * @returns {Promise<void>}
	 */
	promiseToTrack: function() {
		return Promise.resolve();
	},
	/**
	 * @returns {void}
	 */
	cleanOmnitureVariables: function() {
		if (window.s) {
			window.s.events = '';
			window.s.linkTrackEvents = '';
			trackLink.cleanPropsAndEvars();
		}
	},
	/**
	 * @returns {void}
	 */
	cleanPropsAndEvars: function() {
		if (window.s) {
			Object.keys(window.s).forEach((key) => {
				if (key.match(/^(prop|eVar)[0-9]+/)) {
					delete window.s[key];
				}
			});
		}
	}
};

module.exports = trackLink;
