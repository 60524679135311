// @ts-nocheck
const Backbone = require('backbone');
require('vendor/jquery.tooltipster');

const extendBackboneView = function extendBackboneView() {
	return Object.assign(Backbone.View.prototype, {

		/**
		 * Serializes form inputs with name attributes into a simple JavaScript object
		 * where the key is the name and the value is the input value.
		 * @param  {String} selector jQuery style selector string for selecting form
		 * @return {Object}          The serialized form data
		 */
		serializeForm: function(selector) {
			var data = {},
				raw = typeof selector === 'string' ? this.$(selector).serializeArray() : selector.serializeArray();
			raw.forEach((pair) => {
				data[pair.name] = $.trim(pair.value);
			});
			return data;
		},

		bindTooltips: function(options) {
			var selector = '.tooltip',
				defaults = {
					position: 'top-right',
					trigger: 'click',
					maxWidth: 250
				};
			options = Object.assign(defaults, options);
			if (options.selector) {
				selector = options.selector;
				delete options.selector;
			}
			this.$(selector).tooltipster(options).on('click', function(event) {
				event.preventDefault();
			});
		},

		clearForm: function(selector, options) {
			var $form = typeof selector === 'string' ? this.$(selector) : selector,
				exclude = '[type=hidden]';
			options = options || {};
			exclude += Array.isArray(options.exclude) ? ',' + options.exclude.join() : '';
			$form.find('input:not(' + exclude + ')').val('');
		}

	});
};

module.exports = extendBackboneView();
