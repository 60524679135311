/*
 * omniture.js
 * --------------
 * This file is for the aggregation and setting of tracking variables for the omniture suite via the sCode.js file.
 * This file is intended to be included after the declaration of sCode and before the call to app measurement.
 */
const productTrackingHelper = require('../lib/helpers/product_tracking_helper');
const { searchTracking } = require('./views/store/common/omniture/search_tracking');
const enums = require('lib/enums');
const methods = {
	/**
	 * @param {Object} properties
	 * @returns {void}
	 */
	setOnmitureProperties: function(properties) {
		if (properties) {
			Object.keys(properties).forEach((key) => {
				window.s[key] = properties[key];
			});
		}
	},
	/**
	 * @returns {void}
	 */
	setChannel: function(channel) {
		window.s.channel = channel;

		if (window.s.channel && window.s.channel.match(/^www\./)) {
			window.s.channel = window.s.channel.replace('www.', '');
		}
	},
	/**
	 * @returns {object}
	 */
	getTrackingProps: function(estimatedDeliveryInfoForFinish) {
		if (!window.dataLayer.showEstimatedDelivery) {
			const activeFinish =
				(window.dataLayer.finishes && window.dataLayer.finishes.find((finish) => finish.selectedFinish === true)) || {};
			estimatedDeliveryInfoForFinish.estimatedLowDays = activeFinish.estimatedLowDays;
			estimatedDeliveryInfoForFinish.estimatedHighDays = activeFinish.estimatedHighDays;
		}
		const stockPriceAndSaleInfo = productTrackingHelper._getStockPriceAndSaleInfo(
			null,
			window.dataLayer.selectedFinish,
			window.dataLayer.finishes
		);
		const trackingProps = productTrackingHelper._setTrackingProps({
			rootCategoryName: window.dataLayer.rootCategory && window.dataLayer.rootCategory.categoryName,
			freight: window.dataLayer.freight,
			manufacturer: window.dataLayer.manufacturer,
			isFreeShipping: window.dataLayer.selectedFinish.freeShipping,
			unitPrice: stockPriceAndSaleInfo.finishPrice,
			sale: stockPriceAndSaleInfo.sale,
			prop48: stockPriceAndSaleInfo.stock,
			deliveryEstimateForFinish: estimatedDeliveryInfoForFinish,
			totalPrice: null
		});
		if (window.BCOM.util.hasRestrictions(window.dataLayer.zipcodeInfo, window.dataLayer.restrictions)) {
			trackingProps.prop47 = '';
		}
		return trackingProps;
	},
	/**
	 * @returns {void}
	 */
	setOmnitureVariables: function() {
		window.s.server = window.dataLayer.serverName;
		const hasViewAllParam = document.URL.indexOf('view=all') > -1;
		window.s.pageName = window.dataLayer.pageName + (hasViewAllParam ? '|View All' : '');
		this.setChannel(window.dataLayer.channel);
		if (window.dataLayer.pageType) {
			window.s.pageType = window.dataLayer.pageType;
		}
		if (window.dataLayer.page === 'search:browse') {
			searchTracking.setProductDropVariables('event156');
		}
		if (window.dataLayer.events && window.dataLayer.events.length > 0) {
			window.s.events = window.dataLayer.events.join(',');
		}
		if (window.dataLayer.page === 'cart:cart') {
			const zipCodeInfo = window.dataLayer.zipCodeInfo;
			Object.assign(
				window.dataLayer.sProp,
				productTrackingHelper.setCartTrackingProps(window.dataLayer.cartItems, window.dataLayer.subTotal, zipCodeInfo)
			);
		}
		if (window.dataLayer.page === 'checkout:review') {
			const zipCodeInfo = window.dataLayer.cart.zipCodeInfo;
			const subTotal = JSON.parse(window.sessionStorage.getItem(enums.SESSION_STORAGE.CART_SUBTOTAL));
			Object.assign(
				window.dataLayer.sProp,
				productTrackingHelper.setCartTrackingProps(window.dataLayer.cart.items, subTotal, zipCodeInfo)
			);
		}
		if (window.dataLayer.page === 'checkout:ordercomplete') {
			const cartItems = JSON.parse(window.sessionStorage.getItem(enums.SESSION_STORAGE.CART_ITEMS_WITH_LEAD_TIME_INFO));
			Object.assign(window.dataLayer.sProp, productTrackingHelper.setCartTrackingProps(cartItems, window.dataLayer.subTotal, null));
			productTrackingHelper.setSessionStorage('0', null, null, null);
		}
		if (window.dataLayer.page === 'product:display' || window.dataLayer.page === 'product-pla:display') {
			window.s.products = window.dataLayer.selectedFinishId > 0 ? `;${window.dataLayer.selectedFinishId}` : '';
			const estimatedDeliveryInfoForFinish = window.dataLayer.estimatedDeliveryInfoForFinish;
			if (estimatedDeliveryInfoForFinish) {
				const trackingProps = this.getTrackingProps(estimatedDeliveryInfoForFinish);
				Object.assign(window.dataLayer.sProp, trackingProps);
			}
		} else if (window.dataLayer.uniqueIds && window.dataLayer.uniqueIds.length > 0) {
			window.s.products = window.dataLayer.uniqueIds.join();
		}

		methods.setOnmitureProperties(window.dataLayer.eVar);
		methods.setOnmitureProperties(window.dataLayer.sProp);
		methods.setOnmitureProperties(window.dataLayer.hier);
		methods.setOnmitureProperties(window.dataLayer.onmiture);
		methods.setOnmitureProperties(window.dataLayer.lists);

		// Global Settings
		window.s.eVar63 = navigator.userAgent;

		window.s.eVar56 = `${window.dataLayer.page}${window.dataLayer.eVar56Data ? `:${window.dataLayer.eVar56Data}` : ''}`;

		window.s.prop13 = document.URL;
		window.s.prop24 = typeof window.Visitor !== 'undefined' ? 'VisitorAPI Present' : 'VisitorAPI Missing';

		if (window.dataLayer.customer && window.dataLayer.customer.isPro) {
			window.s.eVar54 = 'Yes';
			if (window.dataLayer.customer.proBusinessType) {
				window.s.eVar49 = window.dataLayer.customer.proBusinessType;
			}
		}

		window.s.eVar101 = window.dataLayer.customerTrilogieLink ? 'Trilogie Pricing Applied' : 'Trilogie Pricing Not Applied';

		window.s.list2 = window.BCOM.sitespect;
	}
};
(function() {
	let referringHost, referringQueryParams, campaignTermMatch;

	/**
	 * @param {string} url
	 * @returns {void}
	 */
	function parseUrlData(url) {
		let referringDomainArray;

		// remove the protocol
		if (url.indexOf('https://') > -1) {
			referringDomainArray = url.slice(8).split('/');
		} else {
			referringDomainArray = url.slice(7).split('/');
		}

		// split the host off
		referringHost = referringDomainArray[0];
		referringQueryParams = referringDomainArray.slice(1).join();

		// split the querystring
		if (referringQueryParams.indexOf('?') > -1) {
			referringQueryParams = referringQueryParams
				.split('?')
				.slice(1)
				.join();
		}
		// string split on each param
		if (referringQueryParams.indexOf('&') > -1) {
			referringQueryParams = referringQueryParams.split('&');
		}
		// referringQueryParams needs to always be an array
		if (!Array.isArray(referringQueryParams)) {
			referringQueryParams = [referringQueryParams];
		}
	}
	/**
	 * @param {string} referrer
	 * @returns {boolean}
	 */
	function isDenyList(referrer) {
		const referrerDenyList = [
			'paypal.com',
			'build.com',
			'faucet.com',
			'faucetdirect.com',
			'floormall.com',
			'handlesets.com',
			'lightingdirect.com',
			'lightingshowplace.com',
			'pullsdirect.com',
			'ventingdirect.com',
			'ventingpipe.com',
			'allergyandair.com',
			'livingdirect.com',
			'compactappliance.com',
			'winecoolerdirect.com',
			'kegerator.com',
			'icemakerdirect.com',
			'edgestar.com'
		];
		for (let i = 0; i < referrerDenyList.length; i++) {
			const thisReferrer = referrerDenyList[i];
			// if black list domain set bit true
			if (referrer.indexOf(thisReferrer) !== -1) {
				return true;
			}
		}
		return false;
	}
	/**
	 * @param {string} searchParam
	 * @returns {string | false} false when no param key match
	 */
	function getQueryParamMatch(searchParam) {
		let thisQueryParam;

		// loop all params
		for (let i = 0; i < referringQueryParams.length; i++) {
			thisQueryParam = referringQueryParams[i].split('=');
			// check the current param for key match and return value
			if (thisQueryParam[0] === searchParam) {
				if (typeof thisQueryParam[1] !== 'undefined') {
					return decodeURIComponent(thisQueryParam[1]);
				} else {
					// return false default
					return false;
				}
			}
		}
		return false;
	}
	/**
	 * @returns {boolean}
	 */
	function isPpcReferrer() {
		const isPpcReferrerIndex = [
				{
					name: 'google.com/aclk',
					source: 'google.com/aclk_missing tracking code'
				},
				{
					name: 'googleadservices.com',
					source: 'googleadservices.com_missing tracking code'
				},
				{
					name: 'googleads.g.doubleclick.net',
					source: 'googleads.g.doubleclick.net_missing tracking code'
				}
			],
			referrer = document.referrer;

		for (let i = 0; i < isPpcReferrerIndex.length; i++) {
			if (referrer.indexOf(isPpcReferrerIndex[i].name) > -1) {
				window.referredCampaignTracking.push('paid-search', isPpcReferrerIndex[i].source);
				return true;
			}
		}
		return false;
	}
	/**
	 * @returns {boolean}
	 */
	function organicReferrerLookup() {
		const organicSearchIndex = [
			{
				name: 'aol.com',
				source: 'aolorganic',
				termKey: 'q'
			},
			{
				name: 'ask.com',
				source: 'askorganic',
				termKey: 'q'
			},
			{
				name: 'bing.com/images',
				source: 'bingorganic-image',
				termKey: 'q'
			},
			{
				name: 'bing.com',
				source: 'bingorganic',
				termKey: 'q'
			},
			{
				name: 'google.com/imgres',
				source: 'googleorganic-image',
				termKey: 'q'
			},
			{
				name: 'google.com',
				source: 'googleorganic',
				termKey: 'q'
			},
			{
				name: 'images.search.yahoo.com',
				source: 'yahooorganic-images',
				termKey: 'p'
			},
			{
				name: 'yahoo.com',
				source: 'yahooorganic',
				termKey: 'p'
			},
			{
				name: 'duckduckgo.com',
				source: 'duckduckgoorganic',
				termKey: 'q'
			}
		];

		parseUrlData(document.referrer);
		for (let i = 0; i < organicSearchIndex.length; i++) {
			if (referringHost.indexOf(organicSearchIndex[i].name) > -1) {
				// set the referred source
				window.referredCampaignTracking.push(organicSearchIndex[i].source);
				// return term match or false
				campaignTermMatch = getQueryParamMatch(organicSearchIndex[i].termKey);

				if (campaignTermMatch) {
					window.referredCampaignTracking.push(campaignTermMatch);
				} else {
					window.referredCampaignTracking.push('keyword-unavailable');
				}
				return true;
			}
		}
		return false;
	}
	/**
	 * @returns {void}
	 */
	function setCampaignTracking() {
		const campaignArray = [];

		// tracking internal user
		if (window.dataLayer.isLocalUser) {
			campaignArray.push('employee');
		}
		// tracking organic
		if (window.referredCampaignTracking[0] !== 'paid-search' && window.referredCampaignTracking[0] !== 'no-organic-match') {
			campaignArray.push(window.referredCampaignTracking[0]);
		}
		// tracking term
		campaignArray.push(window.referredCampaignTracking[1].replace(/\+/gi, ' '));
		// set s.campaign tracking
		window.s.campaign = campaignArray.join('_');
		// push s.campaign to eVar10
		window.s.eVar10 = window.s.crossVisitParticipation(window.s.campaign, 's_cpm', '182', '10', '>', 'purchase');
	}
	/**
	 * @returns {void}
	 */
	function referrerTrackingInit() {
		// check for paid marketing, then organic, and if all else fails log the rest
		parseUrlData(document.referrer);
		// check if black list referrer and set bit
		parseUrlData(document.location.href);

		campaignTermMatch = getQueryParamMatch('source');
		if (campaignTermMatch) {
			window.referredCampaignTracking.push('paid-search', campaignTermMatch);
			setCampaignTracking();
		} else if (document.referrer !== '') {
			if (isPpcReferrer()) {
				setCampaignTracking();
			} else if (organicReferrerLookup()) {
				setCampaignTracking();
			} else if (!isDenyList(referringHost)) {
				window.referredCampaignTracking.push('no-organic-match', referringHost);
				setCampaignTracking();
			}
		}
	}
	/**
	 * @returns {void}
	 */
	const omnitureCampaignTracking = function() {
		// window scope the referring source & term
		window.referredCampaignTracking = [];

		// init tracking
		referrerTrackingInit();
	};
	/**
	 * @returns {void}
	 */
	const omnitureVisitorTracking = function() {
		try {
			window.s.visitor = window.Visitor.getInstance('F5FA1253512D2B590A490D45@AdobeOrg');
			let authState = window.Visitor.AuthState.UNKNOWN;
			const visitorId = window.dataLayer.visitorId;

			if (window.dataLayer.isLoggedIn) {
				authState = window.Visitor.AuthState.AUTHENTICATED;
			}

			if (visitorId) {
				// Single ID with a single authentication state
				window.s.visitor.setCustomerIDs({
					visitorId: {
						id: visitorId.toUpperCase(),
						authState: authState
					}
				});
			}
		} catch (e) {
			window.clientErrorHandler(e);
		}
	};
	/**
	 * @returns {void}
	 */
	const setAccount = function() {
		const storeName = window.dataLayer.storeName.toLowerCase();
		window.s.account = window.s_account;

		window.s.linkInternalFilters = `javascript:,mailto:,tel:,s2.img-b.com,s3.img-b.com,${storeName}`;
		window.s.trackingServer = window.sCodeSiteData.trackingUrl;
		window.s.trackingServerSecure = window.sCodeSiteData.sslTrackingUrl;
	};

	try {
		setAccount();
		methods.setOmnitureVariables();

		// call campaign tracking
		omnitureCampaignTracking();
		// set visitor tracking
		if (!window.dataLayer.isLocalUser) {
			omnitureVisitorTracking();
		}
		/* eslint-disable camelcase */
		const s_code = window.s.t();
		if (s_code) {
			document.write(s_code);
		}
		/* eslint-enable camelcase */
		window.omnitureLoaded = true;
	} catch (e) {
		window.clientErrorHandler(e);
	}
})();
module.exports = methods;
