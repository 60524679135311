const _ = require('underscore');
const url = {
	/**
	 * @returns {Object}
	 */
	getParameters: function() {
		return url.deserializeParameters();
	},
	/**
	 * @returns {boolean}
	 */
	isPushStateEnabled: function() {
		return window.history.pushState !== undefined;
	},
	/**
	 * @returns {Object}
	 */
	deserializeParameters: function() {
		const queryString = window.location.search;
		const parameters = {};

		if (queryString === '' || queryString === '?') {
			return parameters;
		}
		// remove first character (?) and split into key value pairs
		const queryStringArray = queryString.substring(1).split('&');

		queryStringArray.forEach((keyValuePair) => {
			const keyValueArray = keyValuePair.split('=');
			const value = keyValueArray[1];
			const key = decodeURIComponent(keyValueArray[0]);

			if (!parameters[key]) {
				// add the key and value to the parameters object
				parameters[key] = decodeURIComponent(value);
			} else if (Array.isArray(parameters[key])) {
				parameters[key].push(value);
			} else {
				parameters[key] = [parameters[key], value];
			}
		});

		return parameters;
	},
	/**
	 * @param {object|array} parameters
	 * @returns {string}
	 */
	serializeParameters: function(parameters) {
		const params = [],
			encode = window.encodeURIComponent;

		_.each(parameters, function(value, key) {
			// Handle multiple params with the same key
			if (Array.isArray(value)) {
				value.forEach((currentValue) => {
					params.push(`${encode(key)}=${encode(currentValue)}`);
				});
			} else {
				params.push(`${encode(key)}=${encode(value)}`);
			}
		});
		return params.length === 0 ? '' : `?${params.join('&')}`;
	},
	/**
	 * @template T
	 * @param {T} parameters
	 * @param {string} keyToRemove
	 * @returns {Partial<T>}
	 */
	removeParameterByKey: function(parameters, keyToRemove) {
		delete parameters[keyToRemove];
		return parameters;
	},
	/**
	 * @param {string} url
	 * @returns {string}
	 */
	extractHostname: function(url) {
		let hostname;
		// find & remove protocol (http, ftp, etc.) and get hostname
		if (url.indexOf('//') > -1) {
			hostname = url.split('/')[2];
		} else {
			hostname = url.split('/')[0];
		}
		// find & remove port number
		hostname = hostname.split(':')[0];
		// find & remove "?"
		hostname = hostname.split('?')[0];
		return hostname;
	}
};
module.exports = url;
