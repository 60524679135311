import 'bootstrap';
import 'util';
import './base_init';
import 'dustjs';
import 'views/store/component_helper';

import { setUpAjaxAndFormCSRFTokens } from 'common/csrf_token';
import { BodyView } from './body';

setUpAjaxAndFormCSRFTokens();

const beforeInstallPrompt = function(event) {
	// do NOT offer add to homescreen
	event.preventDefault();
};

/**
 *
 * @param {*} error - error that occurred
 */
window.clientErrorHandler = function(error) {
	// if error is ommited in favor of a simple message make an error
	if (!(error instanceof Error)) {
		error = new Error(error);
	}

	if (window.dataLayer.environment !== 'production' && console && console.error) {
		console.error(error);
	}
};

// Doing a wholesale capture for now to handle cases like DTM.
window.NREUM = window.NREUM || {};
window.NREUM.noticeError = window.clientErrorHandler;

let baseView = {};

// Load in the path for the page specific module
if (window.BCOM && window.BCOM.requireViewPath) {
	let handler;
	// Split the view path @ 'themes/' if themedRequireViewPath is defined we know we need to load a themedView
	const [requireViewPath, themedRequireViewPath] = window.BCOM.requireViewPath.split('themes/');
	if (themedRequireViewPath) {
		// regExp=themes/(.*?)/ was added as bundler-loader option to add the name of the theme as a prefix to the file that gets generated.
		// e.g. src/app/views/store/themes/livingdirect/pages/home/index.js will become livingdirect.index.page.js
		handler = require(`bundle-loader?regExp=themes/(.*?)/&name=[1].[name]!./themes/${themedRequireViewPath}.page.js`);
	} else {
		handler = require(`bundle-loader?regExp=pages/(.*?)/&name=[1].[name]!./pages/${requireViewPath}.page.js`);
	}

	if (handler) {
		handler((PageView) => {
			if (PageView.default) {
				PageView = PageView.default;
			}

			if (PageView) {
				baseView.pageView = new PageView();
				window.BCOM.views = window.BCOM.views || {};
				window.BCOM.views.baseView = baseView;
			}
		});
	}
}

// Load in the path for the theme specific module if any
if (window.BCOM && window.BCOM.themeBaseViewPath && window.BCOM.themeBaseViewPath !== 'views/store/base') {
	// regExp=themes/(.*?)/ was added as bundler-loader option to add the name of the theme as a prefix to the file that gets generated.
	// e.g. src/app/views/store/themes/livingdirect/base.js will become livingdirect.base.js
	const handler = require(`bundle-loader?regExp=themes/(.*?)/&name=[1].[name]!./themes/${window.BCOM.themeBaseViewPath.substring(
		7
	)}/base.js`);

	handler((ThemeBaseView) => {
		if (ThemeBaseView) {
			if (ThemeBaseView.default) {
				ThemeBaseView = ThemeBaseView.default;
			}
			baseView.themeBaseView = new ThemeBaseView();
		}
	});
}

if ('serviceWorker' in navigator) {
	// register a handler for beforeInstallPrompt so we can track pwa installs
	window.addEventListener('beforeinstallprompt', beforeInstallPrompt);
	// register and/or unregister the service worker depending on if it is isEnabled
	if (window.dataLayer.registerServiceWorker) {
		window.addEventListener('load', function() {
			navigator.serviceWorker.register('/node-store-service-worker.js', {
				scope: '/'
			});
		});
	} else if (navigator.serviceWorker.getRegistrations) {
		// unregister our service worker
		navigator.serviceWorker.getRegistrations().then((registrations) => {
			registrations.forEach((registration) => {
				if (registration.active && registration.active.scriptURL.indexOf('node-store-service-worker.js') > -1) {
					registration.unregister();
				}
			});
		});
	}
}

window.bodyView = new BodyView({
	baseView
});
