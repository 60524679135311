const dateFns = require('date-fns');
require('validate');
require('../lib/customvalidators').extend();
const validator = require('validator');
const gtmHelper = require('../lib/helpers/gtm_helper');
const md5 = require('md5');

const zip5Digits = /^\d{5}$/;

/**
 * @param {string} value
 * @return {boolean}
 */
function validZip(value) {
	return validator.isZip(value);
}

$.validator.setDefaults({
	/**
	 * @param {HTMLInputElement} element
	 * @returns {boolean}
	 */
	onkeyup: function(element) {
		return !(element.name === 'address[email]' || element.name === 'email');
	}
});

// Adding this to override default email validator as it allows emails without TLD
$.validator.addMethod(
	'email',
	/**
	 * @param {string} value
	 * @returns {boolean}
	 */
	function(value) {
		// validates test@example[.com/.edu/.net, etc] format, allowing for no more than one @ symbol
		// used on login and forgot password
		return /^[^@]+@[^@]+\.[^@]+$/i.test(value);
	},
	'Please enter a valid email address.'
);
// More strict custom email form validation for account creation and guest checkout forms
$.validator.addMethod(
	'strictEmail',
	/**
	 * @param {string} email
	 * @returns {boolean}
	 */
	function(email) {
		// validates test@example[.com/.edu/.net, etc] format, allowing for no more than one @ symbol
		// only allow alphabetic characters, numbers, and  ._%+- characters left of @
		const isValid = validator.isValidStrictEmail(email);
		if (!isValid) {
			gtmHelper.fireEvent({
				event: 'track-validation-error',
				message: `Attempted with ${md5(email)}`
			});
		}
		return isValid;
	},
	'Please enter a valid email address.'
);

var rules = {
	'full-name': {
		/**
		 * @param {string} value
		 * @returns {boolean}
		 */
		fn: function(value) {
			return value.trim().split(' ').length >= 2;
		},
		message: 'First and last name are required.'
	},
	'phone-number': {
		/**
		 * @param {string} value
		 * @returns {boolean}
		 */
		fn: function(value) {
			// validates 123-456-7890 or 123.456.7890 or 1234567890 or 123 456 7890 or (123) 456-7890
			return !value || /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value);
		},
		message: 'Invalid phone number. Ex: 123 456 7890'
	},
	'new-password': {
		/**
		 * @returns {boolean}
		 */
		fn: function() {
			var newPassword = String($('#new-password-input').val()),
				newPasswordVerify = String($('#new-password-input-verify').val());

			// user provided a new password or didn't but provided one to verify against
			if (newPassword || newPasswordVerify) {
				// new password is long enough and matches the verify input
				return newPassword.length >= 10 && newPassword.length <= 64 && newPassword === newPasswordVerify;
			} else {
				// No new password provided - nothing to validate, so it's a "valid" case
				return true;
			}
		},
		message: 'Password not long enough or does not match.'
	},
	'no-xss-characters': {
		/**
		 * @param {string} value
		 * @returns {boolean}
		 */
		fn: function(value) {
			return !/["><&]/.test(value);
		},
		message: 'Special characters are not allowed'
	},
	zip: {
		fn: validZip,
		message: 'Please enter a valid US or Canada zip code.'
	},
	'optional-zip': {
		/**
		 * @param {string} value
		 * @returns {boolean}
		 */
		fn: function(value) {
			return !value || validZip(value);
		},
		message: 'Please enter a valid US or Canada zip code.'
	},
	'po-box': {
		/**
		 * @param {string} value
		 * @returns {boolean}
		 */
		fn: function(value) {
			const isPOBox = /(p\.o\.)|(post office)|(po box)|(box\s[0-9].*)|(po box\s[0-9].*)/i.test(value);
			return !isPOBox;
		},
		message: 'Sorry, we cannot ship to PO boxes'
	},
	'zip-5-digits': {
		/**
		 * @param {string} value
		 * @returns {boolean}
		 */
		fn: function(value) {
			return !value || zip5Digits.test(value);
		},
		message: 'Please enter a 5 digit zip code.'
	},
	'multi-emails': {
		/**
		 * @param {string} value
		 * @param {HTMLElement} element
		 * @returns {boolean}
		 */
		fn: function(value, element) {
			var emails = value.split(/[ ,]+/),
				valid = true;
			for (var i = -1, n = emails.length; ++i < n; ) {
				var email = $.trim(emails[i]);
				if (email) {
					valid = valid && $.validator.methods.email.call(this, email, element);
				}
			}
			return valid;
		},
		message: 'Please make sure all emails are valid.'
	},
	date: {
		/**
		 * @param {string} value
		 * @returns {boolean}
		 */
		fn: function(value, element) {
			const validDateFormat = '^[0-9]{2}/[0-9]{2}/[0-9]{4}$';

			return this.optional(element) || (value && value.match(validDateFormat) && dateFns.isValid(new Date(value)));
		},
		message: 'Please enter a valid date with the format MM/DD/YYYY'
	},
	'ldi-order-number': {
		/**
		 * @param {string | number} value
		 * @returns {boolean}
		 */
		fn: function(value) {
			const order = String(value).replace(/[^\d,a-zA-Z]/g, '');
			// either an LDI (letter followed by 7 or 9 numbers) or Build order number (some amount of numbers)
			return /^[a-zA-Z]([0-9]{7}|[0-9]{11})$/.test(order) || /^[0-9]+$/.test(order);
		},
		message: 'Please enter a valid order number'
	},
	'ascii-only': {
		/**
		 * @param {string} value
		 * @returns {boolean}
		 */
		fn: function(value) {
			const normalizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
			// eslint-disable-next-line no-control-regex
			return /^[\x00-\x7F]*$/.test(normalizedValue);
		},
		message: function(params, element) {
			const val = String($(element).val());
			const normalizedVal = val.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

			// eslint-disable-next-line no-control-regex
			let invalidChars = normalizedVal.replace(/[\x00-\x7F]/g, '');

			// Remove duplicates
			invalidChars = Array.from(new Set(invalidChars)).join('');

			return `Sorry, we do not allow special characters: ${invalidChars}.`;
		}
	},
	'name-no-digits': {
		/**
		 * @param {string} value
		 * @returns {boolean}
		 */
		fn: function(value) {
			const trimmedValue = value.replace(/\s/g, '');
			const intValue = parseInt(trimmedValue);
			if (!isNaN(intValue) && trimmedValue.length > 12) {
				return !_.isNumber(intValue);
			}
			return true;
		},
		message: 'Please enter a valid name.'
	}
};

module.exports = {
	/**
	 * @param {string} field
	 * @returns {void}
	 */
	add: function(field) {
		if (rules[field] !== undefined) {
			var rule = rules[field];
			$.validator.addMethod(`validate-${field}`, rule.fn, rule.message);
		}
	},
	getPasswordValidationRules: function() {
		return {
			rules: {
				'new-password-input': {
					required: true,
					minlength: 10,
					maxlength: 64
				},
				'new-password-input-verify': {
					required: true,
					minlength: 10,
					equalTo: '#new-password-input',
					maxlength: 64
				}
			},
			messages: {
				'new-password-input': {
					required: 'Please enter new password',
					minlength: 'Password must be at least {0} characters long.',
					maxlength: 'Password cannot exceed {0} characters.'
				},
				'new-password-input-verify': {
					required: 'Please confirm new password.',
					minlength: 'Password must be at least {0} characters long.',
					maxlength: 'Password cannot exceed {0} characters.',
					equalTo: 'Passwords do not match'
				}
			}
		};
	}
};
