// mapping our names for things to what GTM/GA360 needs them to be
const uniqueId = 'id',
	manufacturerAndProductId = 'name',
	baseCategory = 'category',
	finishName = 'variant',
	manufacturer = 'brand',
	productPrice = 'price',
	quantity = 'quantity',
	businessCategory = 'dimension2',
	collection = 'dimension3',
	type = 'dimension13',
	application = 'dimension14',
	isOutOfStock = 'dimension48',
	hasImage = 'dimension50',
	addToCartMethod = 'dimension53',
	productId = 'dimension54',
	isOnSale = 'dimension58',
	position = 'position';

module.exports = {
	fireEvent(gtmTrackingObject) {
		if (window.dataLayer.enableGoogleTagManager) {
			try {
				window.gtmDataLayer.push(gtmTrackingObject);
			} catch (error) {
				window.clientErrorHandler(error);
			}
		}
	},
	// utility method to handle mapping data from our names to GTM's dimensions
	mapProductData(productObject) {
		return {
			[uniqueId]: productObject.uniqueId,
			[baseCategory]: productObject.baseCategory,
			[finishName]: productObject.finishName,
			[manufacturer]: productObject.manufacturer,
			[productPrice]: productObject.price,
			[quantity]: productObject.quantity,
			[businessCategory]: productObject.businessCategory,
			[collection]: productObject.collection,
			[type]: productObject.type,
			[application]: productObject.application,
			[isOutOfStock]: parseInt(productObject.stockCount, 10) === 0,
			[hasImage]: Boolean(!(productObject.image && productObject.image.indexOf('noimage.gif') >= -1)),
			[addToCartMethod]: productObject.addToCartMethod,
			[productId]: productObject.productId,
			[isOnSale]: Boolean(productObject.saleInformation),
			[manufacturerAndProductId]: `${productObject.manufacturer} ${productObject.productId}`,
			[position]: productObject.position
		};
	},
	formatCartItems(items, addToCartMethod) {
		return items.map((item) => {
			const product = item.product,
				consumerPrice = product.consumerPrice,
				selectedFinish = product.selectedFinish || {},
				priceValue = selectedFinish.priceValue,
				unitPrice = item.unitPrice,
				itemPrice = consumerPrice || priceValue || unitPrice,
				category = product.rootCategory.categoryName,
				finish = selectedFinish.finish || item.finish;

			return this.mapProductData({
				uniqueId: product.uniqueId,
				baseCategory: category,
				finishName: finish,
				manufacturer: product.manufacturer,
				price: itemPrice,
				quantity: item.quantity,
				businessCategory: product.product.businessCategory,
				collection: product.collection,
				type: product.type,
				application: product.application,
				stockCount: selectedFinish.stockCount,
				image: selectedFinish.image,
				addToCartMethod: addToCartMethod,
				productId: product.productId
			});
		});
	},
	// projects needs some special handling because they don't supply the same data in the same format
	formatCartItemsFromProject(items, addToCartMethod) {
		return items.map((item) => {
			return this.mapProductData({
				uniqueId: item.uniqueId,
				baseCategory: item.baseCategory,
				finishName: item.finish,
				manufacturer: item.manufacturer,
				price: item.totalPrice,
				collection: item.collection,
				type: item.type,
				application: item.application,
				stockCount: item.stockCount,
				image: item.image,
				quantity: item.quantity,
				productId: item.productId,
				addToCartMethod: addToCartMethod
			});
		});
	},
	fireCartQuantityChangeEvent(event, addToCartMethod, productTrackingData, allCartItems) {
		// the ecommerce products property lives under either an 'add' or 'remove' property, depending on the event
		const measurementType = event === 'add-to-cart' ? 'add' : 'remove';

		return new Promise((resolve) => {
			if (window.dataLayer.enableGoogleTagManager) {
				try {
					const trackingObject = {
						event: event,
						type: addToCartMethod,
						ecommerce: {
							currencyCode: 'USD',
							[measurementType]: {
								products: productTrackingData
							}
						}
					};

					if (allCartItems) {
						trackingObject.cartSummary = allCartItems.map((item) => {
							return {
								uniqueId: item.uniqueId,
								unitPrice: item.unitPrice,
								productQuantity: item.quantity
							};
						});
					}

					window.gtmDataLayer.push(trackingObject);
					resolve();
				} catch (error) {
					window.clientErrorHandler(error);
					// not ideal - will deprecate this promise-ification in the future.  this is a temp hack while we need to support AA and GA in tandem.
					resolve(error);
				}
			}
		});
	},
	_getCartDataForGTM() {
		return window.dataLayer.cartItems.map((cartItem) => {
			return this.mapProductData({
				uniqueId: cartItem.uniqueId,
				baseCategory: cartItem.baseCategory,
				finishName: cartItem.finish,
				manufacturer: cartItem.manufacturer,
				price: cartItem.unitPrice,
				quantity: cartItem.quantity,
				type: cartItem.itemType,
				application: cartItem.application,
				stockCount: cartItem.stockCount,
				image: cartItem.image,
				productId: cartItem.productId
			});
		});
	},
	fireCheckoutStep(step, option) {
		this.fireEvent({
			event: 'checkout',
			ecommerce: {
				checkout: {
					actionField: {
						step,
						option
					},
					products: this._getCartDataForGTM()
				}
			}
		});
	}
};
