import Backbone from 'backbone';

export const Expandable = Backbone.View.extend(
	/** @type {BackboneExtensions.Expandable} */ {
		events: {
			'click .js-expand': 'toggleExpand'
		},
		/**
		 * @returns {void}
		 */
		initialize() {
			this.$expandableContent = this.$('.js-expandable-content');
			this.$icon = this.$('.js-icon');
		},
		/**
		 * @returns {void}
		 */
		toggleExpand() {
			this.$expandableContent.toggleClass('hide');
			this.$icon.toggleClass('hide');
		},
		/**
		 * @returns {void}
		 */
		expand() {
			this.$expandableContent.removeClass('hide');
			this.$icon.addClass('hide');
		}
	}
);
