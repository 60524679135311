import Backbone from 'backbone';
import CartItemModel from 'app/models/store/client/cart/item';
import dust from 'dustjs';
const ButtonDust = require('store/global/components/button/button.dust');

export const RecAddToCartView = Backbone.View.extend({
	events: {
		'click .js-add-to-cart': '_addToCart'
	},
	initialize({ uniqueId, isConfigurable, productUrl }) {
		if (!isConfigurable) {
			this.model = new CartItemModel({ uniqueId, quantity: 1 });
		}
		this.productUrl = productUrl;
		this.listenTo(Backbone, 'dy-add-to-cart:initialized', this._removeLoadingOverlay);

		this._render(isConfigurable);
	},
	_addToCart(event) {
		event.preventDefault();
		const $btn = $(event.currentTarget);
		$btn.text('loading...');

		this.model.save(null, {
			success: this._saveCartItemSuccess.bind(this),
			error: function(error) {
				window.clientErrorHandler(error);
				$btn.text('Add to Cart');
			}
		});
	},
	_saveCartItemSuccess(model, response) {
		if (model.get('status') === 1) {
			const $button = this.$el.find('.js-add-to-cart');
			const updatedCartQuantity = model.get('cart').quantity;

			window.dataLayer.cartQuantity = updatedCartQuantity;
			window.BCOM.util.trackCartAddition(model.attributes);

			Backbone.trigger('addToCart:productAdded', model);
			Backbone.trigger('omniture:productaddtocart', 'recommended-products', response, model, null);
			Backbone.trigger('cart:quantityChanged', updatedCartQuantity);
			Backbone.trigger('cart-sub-total:update', response.model.selectedFinish.priceValue);

			window.BCOM.notifications.render(`Added to cart. (${updatedCartQuantity} items in cart)`, 'success');
			$button.text('Add Another');
		} else {
			window.BCOM.notifications.render('There was a problem adding this item to your cart. Please try again.', 'danger');
		}
	},
	_removeLoadingOverlay() {
		$('#dy-recs-may-we-suggest-1').removeClass('loading');
	},
	_render(isConfigurable) {
		const $el = this.$el;
		dust.render(
			ButtonDust,
			{
				clazz: isConfigurable ? '' : 'js-add-to-cart',
				size: 'small',
				buttonType: 'secondary',
				text: isConfigurable ? 'Configure' : 'Add to Cart',
				buttonUrl: isConfigurable ? this.productUrl : undefined
			},
			(err, button) => {
				if (err) {
					console.error(err);
				}
				const buttonContainer = $el.find('.js-button-container');
				buttonContainer.append(`<div class="flex justify-center mt2">${button}</div>`);
			}
		);
	}
});
