import Backbone from 'backbone';

export const ConfirmationModalView = Backbone.View.extend(
	/** @type {BackboneExtensions.Expandable} */ {
		events: {
			'click .js-confirmation-modal-cancel': 'hide',
			'click .js-confirmation-modal-confirm': '_handleConfirm'
		},
		initialize() {
			this._cacheSelectors();
		},
		_cacheSelectors() {
			this.$modal = this.$el.find('.js-modal-confirmation-modal');
			this.$modalTitle = this.$modal.find('.js-modal-title');
			this.$modalHeader = this.$modal.find('.js-modal-header');
			this.$fullText = this.$el.find('.js-confirmation-full-text');
			this.$subText = this.$el.find('.js-confirmation-subtext');
			return this;
		},
		show() {
			this.$modal.modal('show');
			this.$modal.one('hidden.bs.modal.confirmation_close_handler', this._onHide);
			return this;
		},
		hide() {
			this.$modal.modal('hide');
			return this;
		},
		_onHide() {
			if (typeof this.cancellationCallback === 'function') {
				this.cancellationCallback();
			}
		},
		_handleConfirm() {
			this.$modal.off('.confirmation_close_handler');
			if (typeof this.confirmationCallback === 'function') {
				this.confirmationCallback();
			}
			this.hide();
		},
		setOptions(
			data: { modalTitle: string; fullText?: string; subText?: string; confirmationCallback?: any; cancellationCallback?: any } = {
				modalTitle: undefined
			}
		) {
			const { modalTitle, fullText, subText, confirmationCallback, cancellationCallback } = data;
			this._setModalTitle(modalTitle);
			if (subText) {
				this._setText(subText);
			}
			if (fullText) {
				this._customizeFullText(fullText);
			}
			if (confirmationCallback) {
				this._setConfirmationCallback(confirmationCallback);
			}
			if (cancellationCallback) {
				this._setCancellationCallback(cancellationCallback);
			}
			return this;
		},
		_setModalTitle(modalTitle) {
			if (modalTitle) {
				this.$modalHeader.removeClass('modal-no-title');
				this.$modalTitle.removeClass('hide').html(modalTitle);
			} else {
				this.$modalHeader.addClass('modal-no-title');
				this.$modalTitle.addClass('hide');
			}
			return this;
		},
		_setText(text) {
			this.$fullText.html('Are you sure you want to&nbsp;');
			this.$subText.html(text);
			this.$subText.removeClass('hide');
			return this;
		},
		_customizeFullText(text) {
			this.$subText.addClass('hide');
			this.$fullText.html(text);
			return this;
		},
		_setConfirmationCallback(confirmationCallback) {
			this.confirmationCallback = confirmationCallback;
			return this;
		},
		_setCancellationCallback(cancellationCallback) {
			this.cancellationCallback = cancellationCallback;
			return this;
		}
	}
);
