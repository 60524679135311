const enums = require('lib/enums');
const dateFns = require('date-fns');

module.exports = {
	/**
	 * Pass-through method for setting session storage related to product tracking
	 * @param  {string | null}  cartSubTotal
	 * @param  {string | null}  isPreviousCartQuickShip
	 * @param  {string | null}  previousCartHasQuickShipItem
	 * @param  {string | null}  cartItemsWithLeadTimeInfo
	 * @returns  {void}
	 */
	setSessionStorage(cartSubTotal, isPreviousCartQuickShip, previousCartHasQuickShipItem, cartItemsWithLeadTimeInfo) {
		if (cartSubTotal) {
			window.sessionStorage.setItem(enums.SESSION_STORAGE.CART_SUBTOTAL, cartSubTotal);
		}
		if (isPreviousCartQuickShip) {
			window.sessionStorage.setItem(enums.SESSION_STORAGE.IS_PREVIOUS_CART_QUICKSHIP, isPreviousCartQuickShip);
		}
		if (previousCartHasQuickShipItem) {
			window.sessionStorage.setItem(enums.SESSION_STORAGE.PREVIOUS_CART_HAS_QUICKSHIP_ITEM, previousCartHasQuickShipItem);
		}
		if (cartItemsWithLeadTimeInfo) {
			window.sessionStorage.setItem(enums.SESSION_STORAGE.CART_ITEMS_WITH_LEAD_TIME_INFO, cartItemsWithLeadTimeInfo);
		}
	},
	_setTracking: function() {
		const stockPriceAndSaleInfo = this._getStockPriceAndSaleInfo(
			window.dataLayer.selectedFinishId,
			window.dataLayer.selectedFinish,
			window.dataLayer.finishes
		);
		const props = this._setTrackingProps({
			rootCategoryName: window.dataLayer.rootCategory && window.dataLayer.rootCategory.categoryName,
			freight: window.dataLayer.freight,
			manufacturer: window.dataLayer.manufacturer,
			unitPrice: stockPriceAndSaleInfo.finishPrice,
			sale: stockPriceAndSaleInfo.sale,
			prop48: stockPriceAndSaleInfo.stock,
			deliveryEstimateForFinish: window.dataLayer.estimatedDeliveryInfoForFinish,
			totalPrice: null,
			isQuickShipCart: false,
			isFreeShipping: window.dataLayer.selectedFinish && window.dataLayer.selectedFinish.freeShipping
		});
		const linkTrackVars = ',prop47,prop48,prop49,prop50,prop51';
		Object.assign(window.s, props);
		window.s.linkTrackVars += linkTrackVars;
	},
	/**
	 * Implements business logic that determines if a product is quickship
	 * @param  {boolean}  hasQuickShipFlag
	 * @param  {boolean}  isFreight
	 * @param  {number}  finishPrice
	 * @param {boolean} isFreeShipping
	 * @returns  {boolean} true if item is quick ship
	 */
	_isQuickShipItem: function(hasQuickShipFlag, isFreight, finishPrice, isFreeShipping) {
		if (!hasQuickShipFlag || isFreight) {
			return false;
		}
		return Boolean(finishPrice > enums.FREESHIPPING_THRESHOLDS.PARCEL || isFreeShipping);
	},
	/**
	 * Implements business logic that determines if a product should morph to quickship
	 * @param  {boolean}  isQuickShipCart
	 * @param  {boolean}  isFreight
	 * @param  {number}  finishPrice
	 * @returns  {string}  freight/Parcel shipping string if item should morph quick ship
	 */
	_morphToQuickShip: function(isQuickShipCart, isFreight, finishPrice) {
		if (!isQuickShipCart) {
			return isFreight ? enums.SHIPPING_CLASSIFICATION.FREIGHT_ESTIMATE : enums.SHIPPING_CLASSIFICATION.PARCEL_ESTIMATE;
		}
		let shippingClassification;
		if (isFreight) {
			if (finishPrice <= enums.FREESHIPPING_THRESHOLDS.FREIGHT) {
				shippingClassification = enums.SHIPPING_CLASSIFICATION.FREIGHT_QUICKSHIP;
			} else {
				shippingClassification = enums.SHIPPING_CLASSIFICATION.FREIGHT_ESTIMATE;
			}
		}
		if (!isFreight) {
			if (finishPrice <= enums.FREESHIPPING_THRESHOLDS.PARCEL) {
				shippingClassification = enums.SHIPPING_CLASSIFICATION.PARCEL_QUICKSHIP;
			} else {
				shippingClassification = enums.SHIPPING_CLASSIFICATION.PARCEL_ESTIMATE;
			}
		}
		return shippingClassification;
	},
	/**
	 * Append freeshipping to shipping Classification
	 * @param  {boolean}  isFreeShipping
	 * @param  {boolean}  isFreight
	 * @param  {string}  shippingClassification
	 * @returns  {string} append freeshipping to string if isFreeShipping is true
	 */
	_addFreeShipping: function(isFreeShipping, isFreight, shippingClassification) {
		if (isFreeShipping) {
			if (isFreight) {
				shippingClassification += `_${enums.SHIPPING_CLASSIFICATION.FREIGHT_FREESHIPPING}`;
			} else {
				shippingClassification += `_${enums.SHIPPING_CLASSIFICATION.PARCEL_FREESHIPPING}`;
			}
		}
		return shippingClassification;
	},
	/**
	 * Determines shipping classification of specific product
	 * @param  {boolean}  isFreight
	 * @param  {string}  manufacturer
	 * @param  {number}  price
	 * @param  {boolean} isFreeShipping
	 * @param  {boolean} isQuickShipCart
	 * @param  {object}  deliveryEstimateForFinish
	 * @returns  {string}
	 */
	_setShippingClassifications: function(isFreight, manufacturer, price, isFreeShipping, isQuickShipCart, deliveryEstimateForFinish = {}) {
		let shippingClassification;
		const deliveryMethod = deliveryEstimateForFinish.deliveryEstimateMethod;
		const isQuickShip = deliveryEstimateForFinish.isQuickShip;
		const isEstimate = Boolean(deliveryMethod === enums.DELIVERY_METHOD.UPS_API);

		if (!isFreight) {
			if (this._isQuickShipItem(isQuickShip, false, price, isFreeShipping)) {
				shippingClassification = enums.SHIPPING_CLASSIFICATION.PARCEL_QUICKSHIP;
			} else if (isEstimate) {
				shippingClassification = this._morphToQuickShip(isQuickShipCart, false, price);
			} else {
				shippingClassification = enums.SHIPPING_CLASSIFICATION.PARCEL_LEADTIME;
			}
			// if no zip, override any existing shipping classification
			if (deliveryEstimateForFinish.noZip) {
				shippingClassification = enums.SHIPPING_CLASSIFICATION.PARCEL_NOZIP;
			}
		} else {
			if (isEstimate) {
				shippingClassification = this._morphToQuickShip(isQuickShipCart, true, price);
			} else {
				shippingClassification = enums.SHIPPING_CLASSIFICATION.FREIGHT_LEADTIME;
			}
		}
		if (deliveryEstimateForFinish.isPreOrder) {
			shippingClassification = enums.SHIPPING_CLASSIFICATION.PRE_ORDER;
		}
		if (deliveryEstimateForFinish.isMadeToOrder) {
			shippingClassification = enums.SHIPPING_CLASSIFICATION.MADE_TO_ORDER;
		}
		if (manufacturer === 'GE' || manufacturer === 'Haier') {
			shippingClassification = enums.SHIPPING_CLASSIFICATION.GE;
		}

		shippingClassification = this._addFreeShipping(isFreeShipping, isFreight, shippingClassification);
		return shippingClassification;
	},
	/**
	 * Sets price range bucket of item
	 * @param  {number}  price
	 * @returns  {string}
	 */
	_setPriceRange: function(price) {
		if (isNaN(price)) {
			return 'NA';
		}
		if (price < 50) {
			return '<50';
		}
		if (price >= 50 && price < 200) {
			return '50-200';
		}
		if (price >= 200 && price < 500) {
			return '200-500';
		}
		if (price >= 500 && price < 1000) {
			return '500-1000';
		}
		if (price >= 1000 && price < 1500) {
			return '1000-1500';
		}
		if (price >= 1500) {
			return '1500+';
		}
	},
	/**
	 * Calculates estimated delivery or lead time days
	 * @param  {object}  deliveryEstimate
	 * @param {boolean} showEstimatedDelivery
	 * @returns  {string}
	 */
	_buildEstDeliveryOrLeadTimeString: function(deliveryEstimate, showEstimatedDelivery) {
		if (showEstimatedDelivery) {
			if (deliveryEstimate.noZip) {
				return 'NoEst';
			}
			// if services doesn't return estimated delivery date, that means the finish shows lead time instead
			if (deliveryEstimate.estimatedDeliveryDate) {
				// estimatedDeliveryDate and estimatedShippingDate from services are always set to midnight
				const currentDate = new Date().setHours(0, 0, 0, 0);
				return `ED:${dateFns.differenceInDays(new Date(deliveryEstimate.estimatedDeliveryDate), currentDate)}`;
			} else {
				return `LT:${this._calculateLeadTime(deliveryEstimate)}`;
			}
		} else {
			return `LT:${this._calculateLeadTime(deliveryEstimate)}`;
		}
	},
	_calculateLeadTime: function(deliveryEstimate) {
		if (deliveryEstimate.estimatedLowDays === deliveryEstimate.estimatedHighDays) {
			return deliveryEstimate.estimatedHighDays;
		} else {
			return `${deliveryEstimate.estimatedLowDays}-${deliveryEstimate.estimatedHighDays}`;
		}
	},
	/**
	 * Key method that sets default product tracking props
	 * @param  {object}  trackingPropsInfo
	 * @returns  {object}
	 */
	_setTrackingProps: function(trackingPropsInfo) {
		let trackingProps = {};
		const isProductPage = Boolean(window.dataLayer.page === 'product:display' || window.dataLayer.page === 'product-pla:display');
		const price = trackingPropsInfo.totalPrice || trackingPropsInfo.unitPrice;
		const isQuickShipCart = Boolean(trackingPropsInfo.isQuickShipCart);
		const isFreeShipping = Boolean(trackingPropsInfo.isFreeShipping);
		const isFreight = Boolean(trackingPropsInfo.freight);
		const appointmentOnlyPricing = window.dataLayer.appointmentOnlyPricing || [];
		// no need to track props for discontinued products
		if (!price) {
			return trackingProps;
		}
		trackingProps.prop47 = this._setShippingClassifications(
			isFreight,
			trackingPropsInfo.manufacturer,
			price,
			isFreeShipping,
			isQuickShipCart,
			trackingPropsInfo.deliveryEstimateForFinish
		);
		if (appointmentOnlyPricing && appointmentOnlyPricing.includes(trackingPropsInfo.manufacturer)) {
			trackingProps.prop22 = 'Is Showroom PDP';
		}
		trackingProps.prop48 = trackingPropsInfo.prop48;
		trackingProps.prop49 = trackingPropsInfo.rootCategoryName || 'NA';
		trackingProps.prop50 = `${this._setPriceRange(price)}|${this._buildEstDeliveryOrLeadTimeString(
			trackingPropsInfo.deliveryEstimateForFinish,
			window.dataLayer.showEstimatedDelivery || !isProductPage
		)}`;
		trackingProps.prop51 = trackingPropsInfo.sale ? `on promo ${trackingPropsInfo.sale.catchLine}` : 'off';
		return trackingProps;
	},
	_getStockPriceAndSaleInfo: function(currentUniqueId, selectedFinish, finishes = []) {
		let currentFinish, stock, price;
		if (currentUniqueId) {
			currentFinish = finishes.find((finish) => finish.uniqueId === currentUniqueId) || {};
		} else {
			currentFinish = selectedFinish || {};
		}
		stock = currentFinish.stock || currentFinish.stockCount || 0;
		price =
			typeof currentFinish.price === 'string'
				? window.BCOM.util.format.currencyStringToNumber(currentFinish.price)
				: currentFinish.price;
		return { sale: currentFinish.sale, finishPrice: price, stock: stock === 0 ? 'OOS' : 'IN-STOCK' };
	},
	setZipChangeTrackingData: function(quickShipPropInfo, selectedFinish, finishes, currentUniqueId, linkName, estimatedDeliveryInfo = []) {
		const uniqueId = currentUniqueId || (selectedFinish && selectedFinish.uniqueId);
		const deliveryEstimateForFinish = estimatedDeliveryInfo.find((info) => info.productUniqueId === uniqueId) || {};
		const stockAndPriceInfo = this._getStockPriceAndSaleInfo(currentUniqueId, selectedFinish, finishes);
		const props = this._setTrackingProps({
			rootCategoryName: quickShipPropInfo.categoryName,
			freight: quickShipPropInfo.freight,
			manufacturer: quickShipPropInfo.manufacturer,
			unitPrice: stockAndPriceInfo.finishPrice,
			isFreeShipping: selectedFinish.freeShipping,
			sale: quickShipPropInfo.sale,
			prop48: stockAndPriceInfo.stock,
			deliveryEstimateForFinish,
			totalPrice: null,
			isQuickShipCart: false
		});
		return {
			props: props,
			linkName: linkName
		};
	},
	setFinishChangeTrackingData: function(
		quickShipPropInfo,
		selectedFinishId,
		finishes,
		selectedFinish,
		finishPrice,
		isPreviousFinishQuickShip,
		estimatedDeliveryInfo = []
	) {
		let deliveryEstimateForFinish;
		if (window.dataLayer.showEstimatedDelivery) {
			// noZip = true accounts for case where finish is selected before zip is set
			deliveryEstimateForFinish = estimatedDeliveryInfo.find((info) => info.productUniqueId === selectedFinishId) || { noZip: true };
		} else {
			deliveryEstimateForFinish =
				window.dataLayer.finishes && window.dataLayer.finishes.find((finish) => finish.uniqueId === selectedFinishId);
		}
		const stockAndPriceInfo = this._getStockPriceAndSaleInfo(null, selectedFinish, finishes);
		const props = this._setTrackingProps({
			rootCategoryName: quickShipPropInfo.categoryName,
			freight: quickShipPropInfo.freight,
			manufacturer: quickShipPropInfo.manufacturer,
			unitPrice: stockAndPriceInfo.finishPrice,
			isFreeShipping: selectedFinish.freeShipping,
			sale: stockAndPriceInfo.sale,
			prop48: stockAndPriceInfo.stock,
			deliveryEstimateForFinish,
			totalPrice: null,
			isQuickShipCart: false
		});
		const isCurrentFinishQuickShip = Boolean(
			this._isQuickShipItem(
				deliveryEstimateForFinish.isQuickShip,
				quickShipPropInfo.freight,
				stockAndPriceInfo.finishPrice,
				selectedFinish.freeShipping
			)
		);
		if (isPreviousFinishQuickShip === false && isCurrentFinishQuickShip === true) {
			props.prop47 += '|QuickShip Change';
		}
		window.dataLayer.isPreviousFinishQuickShip = isCurrentFinishQuickShip;
		return {
			props,
			linkTrackVars: ',prop47,prop48,prop49,prop50,prop51'
		};
	},
	/**
	 * Checks for case where cart doesn't qualify for QS because subtotal < $49,
	 * but then cart changes such that subtotal >= $49 while also containing a quickship-able item,
	 * thus qualifying the cart for QS
	 * @param  {any}  cartItems
	 * @param  {number}  subTotal
	 * @param  {boolean}  previousCartIsQuickShip
	 * @returns  {boolean} true if cart changes to or from quickship
	 */
	isQuickShipCartChange: function(cartItems, subTotal, previousCartIsQuickShip) {
		if (!cartItems) {
			return false;
		}
		const quickShipCartInfo = this.getCartInfo(cartItems, subTotal);
		const isQuickShipCartChange = previousCartIsQuickShip !== quickShipCartInfo.isCurrentCartQuickShip;
		this.setCartChangeSessionStorage(quickShipCartInfo.isCurrentCartQuickShip, quickShipCartInfo.currentCartHasQuickShipItem);
		return isQuickShipCartChange;
	},
	_isQuickShipAddToCartChange: function(
		quantity,
		isQuickShipItem,
		hasQuickShipFlag,
		isFreight,
		previousCartIsQuickShip,
		previousCartHasQuickShipItem,
		newCartSubTotal,
		isFirstItemAddedToCart
	) {
		// only parcel-estimate items can become quickship
		const isQuickShipCartChange =
			previousCartIsQuickShip === false &&
			(previousCartHasQuickShipItem === true || (isFirstItemAddedToCart && !isQuickShipItem && hasQuickShipFlag && quantity > 1)) &&
			!isFreight &&
			newCartSubTotal > 49;
		let quickShipCartInfo;
		if (window.dataLayer.cartItems) {
			quickShipCartInfo = this.getCartInfo(window.dataLayer.cartItems, newCartSubTotal);
		}
		const isCurrentCartQuickShip =
			isQuickShipCartChange || isQuickShipItem || (quickShipCartInfo && quickShipCartInfo.isCurrentCartQuickShip);
		this.setCartChangeSessionStorage(isCurrentCartQuickShip, hasQuickShipFlag || previousCartHasQuickShipItem);
		return Boolean(isQuickShipCartChange);
	},
	/**
	 * @param  {object}  cartItems
	 * @param  {number}  addToCartItemUniqueId
	 * @returns  {boolean} true if item added matches previous items added to cart, or it is the first item added to cart
	 */
	_isQualifiesViaSku: function(cartItems, addToCartItemUniqueId) {
		if (!cartItems) {
			return true;
		}
		const filteredCartItems = cartItems.filter((item) => {
			return item.uniqueId === addToCartItemUniqueId;
		});
		return Boolean(filteredCartItems.length > 0);
	},
	getCartInfo(cartItems, subTotal) {
		const currentCartHasQuickShipItem = cartItems && cartItems.some((item) => item.leadTimeInformation.isQuickShip);
		const isCurrentCartQuickShip =
			cartItems &&
			cartItems.some((item) =>
				this._isQuickShipItem(item.leadTimeInformation.isQuickShip, item.isFreightItem, subTotal, item.hasFreeShipping)
			);
		return { currentCartHasQuickShipItem, isCurrentCartQuickShip };
	},
	setCartChangeSessionStorage(isCurrentCartQuickShip, currentCartHasQuickShipItem) {
		const isPreviousCartQuickShip = isCurrentCartQuickShip ? 'true' : 'false';
		const previousCartHasQuickShipItem = currentCartHasQuickShipItem ? 'true' : 'false';
		this.setSessionStorage(null, isPreviousCartQuickShip, previousCartHasQuickShipItem, null);
	},
	/**
	 * @param  {object}  quickShipPropInfo
	 * @param  {object}  activeFinish
	 * @param  {object}  deliveryEstimateForFinish
	 * @param  {number}  quantity
	 * @returns  {any}
	 */
	setAddToCartTrackingData: function(quickShipPropInfo, activeFinish, deliveryEstimateForFinish, quantity) {
		const props = this._setTrackingProps({
			rootCategoryName: quickShipPropInfo.categoryName,
			freight: quickShipPropInfo.freight,
			isFreeShipping: activeFinish.freeShipping,
			manufacturer: quickShipPropInfo.manufacturer,
			unitPrice: activeFinish.price,
			sale: quickShipPropInfo.sale,
			prop48: `${quantity && quantity.toString()}:${activeFinish.stock === 0 ? 'OOS' : 'IN-STOCK'}`,
			deliveryEstimateForFinish,
			totalPrice: null,
			isQuickShipCart: false
		});
		const cartSubTotal = JSON.parse(window.sessionStorage.getItem(enums.SESSION_STORAGE.CART_SUBTOTAL)) || 0;
		const isFirstItemAddedToCart = !cartSubTotal;
		const newCartSubTotal = activeFinish.price * quantity + cartSubTotal;
		this.setSessionStorage(newCartSubTotal, null, null, null);
		const isQuickShipItem = this._isQuickShipItem(
			deliveryEstimateForFinish.isQuickShip,
			quickShipPropInfo.freight,
			activeFinish.price,
			activeFinish.freeShipping
		);
		const isQuickShipAddToCartChange = this._isQuickShipAddToCartChange(
			quantity,
			isQuickShipItem,
			deliveryEstimateForFinish.isQuickShip,
			Boolean(deliveryEstimateForFinish.freight),
			Boolean(JSON.parse(window.sessionStorage.getItem(enums.SESSION_STORAGE.IS_PREVIOUS_CART_QUICKSHIP))),
			Boolean(JSON.parse(window.sessionStorage.getItem(enums.SESSION_STORAGE.PREVIOUS_CART_HAS_QUICKSHIP_ITEM))),
			newCartSubTotal,
			isFirstItemAddedToCart
		);
		const currentCartItems = JSON.parse(window.sessionStorage.getItem(enums.SESSION_STORAGE.CART_ITEMS_WITH_LEAD_TIME_INFO));
		if (isQuickShipAddToCartChange) {
			props.prop47 += this._isQualifiesViaSku(currentCartItems, activeFinish.uniqueId)
				? '|Qualifies QS via Sku'
				: '|Qualifies QS via Cart Total';
		}
		this._setCartItemsWithLeadTimeInfo(currentCartItems, activeFinish, deliveryEstimateForFinish);
		return {
			props,
			linkTrackVars: ',prop47,prop48,prop49,prop50,prop51'
		};
	},
	_setCartItemsWithLeadTimeInfo(currentCartItems, activeFinish, deliveryEstimateForFinish) {
		activeFinish.leadTimeInformation = deliveryEstimateForFinish;
		let cartItemsWithLeadTimeInfo;
		if (currentCartItems) {
			currentCartItems.push(activeFinish);
			cartItemsWithLeadTimeInfo = currentCartItems;
		} else {
			cartItemsWithLeadTimeInfo = [activeFinish];
		}
		this.setSessionStorage(null, null, null, JSON.stringify(cartItemsWithLeadTimeInfo));
	},
	setCartChangeTrackingProps: function(cartItems, linkName, subTotal, zipcodeInfo) {
		const props = this.setCartTrackingProps(cartItems, subTotal, zipcodeInfo);
		return {
			props,
			linkTrackVars: ',prop47,prop48,prop49,prop50,prop51',
			linkName
		};
	},
	setCartTrackingProps: function(cartItems, subTotal, zipcodeInfo) {
		const quickShipCartInfo = this.getCartInfo(cartItems, subTotal);
		const isQuickShipCart = Boolean(quickShipCartInfo && quickShipCartInfo.isCurrentCartQuickShip);
		const cartItemsWithProps =
			(cartItems &&
				cartItems.map((item) => {
					const propsForItem = this._setTrackingProps({
						rootCategoryName: item.baseCategory,
						freight: item.isFreightItem,
						manufacturer: item.manufacturer,
						unitPrice: item.unitPrice,
						isFreeShipping: item.freeShippingAvailable,
						isQuickShipCart: isQuickShipCart,
						sale: item.productSale,
						prop48: `${item.quantity && item.quantity.toString()}:${item.stockCount === 0 ? 'OOS' : 'IN-STOCK'}`,
						deliveryEstimateForFinish: item.leadTimeInformation,
						totalPrice: item.totalPrice
					});

					if (
						zipcodeInfo &&
						item.productRestrictions &&
						window.BCOM.util.hasRestrictions(zipcodeInfo, item.productRestrictions)
					) {
						delete propsForItem.prop47;
					}
					propsForItem.uniqueId = item.uniqueId;
					return propsForItem;
				})) ||
			[];
		return window.BCOM.util.mergeArrayOfCartItemsByProp(cartItemsWithProps);
	}
};
