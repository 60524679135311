/* global UPS_MAP_API_KEY, PLACES_API_KEY */
const loadjs = require('loadjs');
const externalMap = {
	jobvite: 'https://jobs.jobvite.com/_assets_/scripts/careersite/public/iframe.js',
	datatables: 'https://cdn.datatables.net/1.10.6/js/jquery.dataTables.min.js',
	facebook: 'https://connect.facebook.net/en_US/sdk.js',
	google_places: `https://maps.googleapis.com/maps/api/js?key=${PLACES_API_KEY}&libraries=places`,
	google_maps_ups: `https://maps.googleapis.com/maps/api/js?key=${UPS_MAP_API_KEY}`,
	pinterest: 'https://assets.pinterest.com/js/pinit.js',
	wistia: 'https://fast.wistia.net/static/E-v1.js',
	youtube_iframe_api: 'https://www.youtube.com/iframe_api',
	paypal: 'https://www.paypalobjects.com/api/checkout.js'
};

/**
 * This module is used to load in external scripts asynchronously via scriptjs.
 * @param {string} script - name of the script to load or path begining in // e.g. '//www.youtube.com/iframe_api'
 * @param {function} [successCallback] - function to call once script is loaded
 * @param {function} [errorCallback] - function to call if script failed to load. Otherwise the error is logged
 * @returns {void}
 */
module.exports = (script, successCallback, errorCallback) => {
	if (!script || typeof script !== 'string') {
		throw new Error('Missing required string parameter script');
	}

	const scriptToRequest = script.indexOf('//') !== -1 ? script : externalMap[script];
	if (!scriptToRequest) {
		throw new Error(`Parameter ${script} is not defined as an external module.`);
	}
	successCallback = typeof successCallback === 'function' ? successCallback : function() {};
	loadjs(scriptToRequest, {
		success: successCallback,
		error: (pathNotFound) => {
			// Let caller handle error when error callback is provided
			if (errorCallback && typeof errorCallback === 'function') {
				const error = new Error(`Failed to load - ${pathNotFound}`);
				errorCallback(error);
			}
		}
	});
};
