/* global jQuery */
import checkEmail from './check-email';
import _ from 'underscore';
import { dynamicYield } from './dynamic_yield_utils';

const OMNITURE_TIMEOUT = 10,
	getWindowDimensions = function() {
		return {
			height: window.innerHeight || document.documentElement.clientHeight,
			width: window.innerWidth || document.documentElement.clientWidth
		};
	};
export const clientUtils = {
	validator: {
		/**
		 * @param {string} str
		 * @param {string} chars
		 * @returns {string}
		 */
		whitelist: function(str, chars) {
			return str.replace(new RegExp(`[^${chars}]+`, 'g'), '');
		},
		/**
		 * @param {string} str
		 * @returns {boolean}
		 */
		isNumeric: function(str) {
			return /^-?[0-9]+$/.test(str);
		},
		/**
		 * @param {string} email
		 * @returns {boolean}
		 */
		isValidEmail: function(email) {
			return checkEmail(email);
		},
		/**
		 * @param {string} value
		 * @returns {boolean}
		 */
		isPOBox: function(value) {
			return /(p\.o\.)|(post office)|(po box)|(box\s[0-9].*)|(po box\s[0-9].*)/i.test(value);
		},
		/**
		 * @param {string} zip
		 * @returns {boolean}
		 */
		isCanadaZip: function(zip) {
			return zip && zip.replace(/ /g, '').length === 6;
		}
	},
	_isIntlSupported: function() {
		return typeof Intl !== 'undefined';
	},
	format: {
		/**
		 * @param {string} str
		 * @returns {string}
		 */
		phone: function(str) {
			const phone = str.replace(/[^\d]/g, '');
			if (phone.length === 10) {
				return /(\d{3})(\d{3})(\d{4})/
					.exec(phone)
					.slice(1, 4)
					.join('-');
			}
			if (phone.length === 11) {
				return `${phone[0]}-${/(\d{3})(\d{3})(\d{4})/
					.exec(phone.slice(1))
					.slice(1, 4)
					.join('-')}`;
			}
			return str;
		},
		/**
		 * @param {number} value
		 * @returns {string}
		 */
		numberWithCommas: function(value) {
			if (clientUtils._isIntlSupported()) {
				const withCommas = new Intl.NumberFormat('en-US', { maximumFractionDigits: 7 });
				return withCommas.format(value);
			} else {
				return value.toString();
			}
		},
		/**
		 * @param {number} value
		 * @returns {string}
		 */
		numberAsMoney: function(value) {
			if (clientUtils._isIntlSupported()) {
				const asMoney = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
				return asMoney.format(value);
			} else {
				return value.toString();
			}
		},
		/**
		 * @param {string} str
		 * @returns {number}
		 */
		currencyStringToNumber: function(str) {
			return Number(str.replace(/[^0-9.-]+/g, ''));
		}
	},
	sanitize: {
		/**
		 * @param {string} str
		 * @returns {string}
		 */
		stripHtmlTags: function(str) {
			return (str || '')
				.replace(/(<([^>]+)>)/gi, '')
				.replace(/</g, '&lt;')
				.replace(/>/g, '&gt;')
				.trim();
		}
	},
	getWindowDimensions: getWindowDimensions,
	/**
	 * @param {JQuery | HTMLElement} el
	 * @returns {boolean}
	 */
	isElementInViewport: function(el) {
		if (typeof jQuery === 'function' && el instanceof jQuery) {
			el = el[0];
		}

		var rect = /** @type {HTMLElement} */ (el).getBoundingClientRect(),
			bounds = getWindowDimensions();

		return rect.top >= 0 && rect.left >= 0 && rect.bottom <= bounds.height && rect.right <= bounds.width;
	},
	/**
	 * Will call event.preventDefault on event object passed in if no valid href to follow on e.currentTarget
	 * @param  {Event} e Event object
	 * @return {Boolean} Returns true if e.preventDefault has been called.
	 */
	preventEventIfNoHref: function(e) {
		const isValidLink = (target) => {
			return target.href && target.getAttribute('href') !== '#';
		};

		if (e && e.currentTarget && !isValidLink(e.currentTarget)) {
			e.preventDefault();
			return true;
		}
		return false;
	},
	/**
	 * @param {Function} callback
	 * @returns {void}
	 */
	omnitureLoaded: function(callback) {
		var self = this;

		window.omnitureLoadedCheck = window.omnitureLoadedCheck || 0;

		if ((window.omnitureLoaded && window.omnitureHelperLoaded) || window.omnitureLoadedCheck >= OMNITURE_TIMEOUT) {
			callback();
		} else {
			window.omnitureLoadedCheck++;
			window.setTimeout(function() {
				self.omnitureLoaded(callback);
			}, 1000);
		}
	},
	/**
	 * @param {string} url
	 * @param {string} param
	 * @param {string} paramValue
	 * @param {boolean} combineMultipleParams
	 * @returns {string}
	 */
	updateURLParameters: function(url, param, paramValue, combineMultipleParams) {
		var hash = url.split('#')[1],
			cleanUrl = hash ? url.split('#')[0] : url,
			urlArray = cleanUrl.split('?'),
			pageUrl = urlArray[0],
			queryString = urlArray[1],
			parametersArray = queryString ? queryString.split('&') : [],
			parameters = {},
			queryStringArray = [],
			newUrl;

		for (var i = 0; i < parametersArray.length; i++) {
			var keyValueArray = parametersArray[i].split('=');
			if (combineMultipleParams === true) {
				parameters[keyValueArray[0]] = parameters[keyValueArray[0]]
					? `${parameters[keyValueArray[0]]}&${keyValueArray[0]}=${keyValueArray[1]}`
					: keyValueArray[1];
			} else {
				parameters[keyValueArray[0]] = keyValueArray[1];
			}
		}
		parameters[param] = encodeURIComponent(paramValue);

		for (var key in parameters) {
			if (parameters.hasOwnProperty(key)) {
				var query = `${key}=${parameters[key]}`;
				queryStringArray.push(query);
			}
		}

		newUrl = `${pageUrl}?${queryStringArray.join('&')}${hash ? `#${hash}` : ''}`;
		return newUrl;
	},
	/**
	 * @param {string} field
	 * @param {string} url
	 * @returns {string | undefined}
	 */
	getQueryStringParameter: function(field, url) {
		var href = url || window.location.href,
			reg = new RegExp(`[?&]${field}=([^&#]*)`, 'gi'),
			string = reg.exec(href);
		return string ? decodeURIComponent(string[1]) : undefined;
	},
	/**
	 * @param {Object} options
	 * @param {Function} callback
	 */
	elementLoaded: function(options, callback) {
		var self = this,
			defaults = {
				maxChecks: 10,
				timeout: 500
			};
		options = Object.assign(defaults, options);

		options.numChecks = options.numChecks || 0;

		if ($(options.selector).length || options.numChecks > options.maxChecks) {
			options.numChecks++;
			callback();
		} else {
			setTimeout(function() {
				self.elementLoaded(options, callback);
			}, options.timeout);
		}
	},
	flattenArray: _.flatten,
	/**
	 * @param {Object} object
	 * @returns {Array}
	 */
	getValuesFromObjectAsArray: function(object) {
		return object ? Object.keys(object).map((key) => object[key]) : [];
	},
	/**
	 * @param {string | number} number
	 * @returns {'visa' | 'amex' | 'mastercard' | 'discover' | undefined}
	 */
	getCreditCardType: function(number) {
		if (String(number).match(new RegExp('^4'))) {
			return 'visa';
		}

		if (String(number).match(new RegExp('^3'))) {
			return 'amex';
		}

		if (String(number).match(new RegExp('^(5|2)'))) {
			return 'mastercard';
		}

		if (String(number).match(new RegExp('^6'))) {
			return 'discover';
		}
		return undefined;
	},
	/**
	 *
	 * @param {'visa' | 'amex' | 'mastercard' | 'discover'} cardType
	 * @returns {number}
	 */
	getCreditCardTypeId: function(cardType) {
		const cardMap = {
			visa: 1,
			mastercard: 2,
			amex: 3,
			discover: 4
		};

		return cardMap[cardType];
	},
	/**
	 * @private
	 * @param {Array} finishes
	 * @param {number} uniqueId
	 * @returns {object}
	 */
	_extractSelectedFinish: function(finishes, uniqueId) {
		if (!Array.isArray(finishes)) {
			return undefined;
		}
		return finishes.find((finish) => finish.uniqueId === uniqueId);
	},
	/**
	 * @param {{ uniqueId, quantity, model }} cartItem
	 * @returns {void}
	 */
	trackCartAddition: function(cartItem) {
		const { uniqueId: cartUniqueId, quantity, model } = cartItem;
		const uniqueId = parseInt(cartUniqueId);
		const selectedFinish = model && clientUtils._extractSelectedFinish(model.allFinishes, uniqueId);
		if (window.sessionStorage && window.sessionStorage.setItem) {
			const unitPrice = selectedFinish && selectedFinish.price;
			const serializedItem = JSON.stringify({ uniqueId, quantity, unitPrice });
			window.sessionStorage.setItem('lastItemAddedToCart', serializedItem);
		}
		dynamicYield.trackEvent.addToCart({
			uniqueId,
			quantity,
			finish: selectedFinish
		});
	},
	trackCartSubItemAddition: function(item) {
		dynamicYield.trackEvent.addToCart(item);
	},
	/**
	 * Track login complete.
	 * @param {{ email: string, customerId: number }} customer
	 * @returns {void}
	 */
	trackLoginComplete(customer) {
		const { email, customerId } = customer;
		dynamicYield.trackEvent.login({ email, customerId: customerId ? `${customerId}` : null });
	},
	trackSortBy: function(sortValue) {
		dynamicYield.trackEvent.sortItems(sortValue);
	},
	trackKeywordSearch: function(keyword) {
		dynamicYield.trackEvent.keywordSearch(keyword);
	},
	trackProductFinishChange: function(productId, url) {
		dynamicYield.trackEvent.reportSPAPageView(productId, url);
	},
	trackFilterItems: function(type, facetValue) {
		const facetMap = {
			theme: 'theme',
			manufacturer: 'manufacturer',
			type: 'type',
			finish: 'finish',
			masterfinishfacet: 'masterfinish',
			masterfinish: 'masterfinish'
		};
		const facetType = facetMap[type.toLowerCase()];
		if (facetType && facetValue) {
			dynamicYield.trackEvent.filterItems(facetType, facetValue);
		}
	},
	/**
	 * @private
	 * @param {string} popoverClass
	 * @returns {void}
	 */
	_initPopovers: function(popoverClass) {
		$(popoverClass).popover({
			placement: 'top',
			html: true,
			container: 'body',
			trigger: 'manual'
		});
	},
	/**
	 * to be used for implementing popovers when there are multiple popovers of the same 'type' i.e. having the same class name
	 * @private
	 * @param {string} multiplePopoversClass
	 * @param {HTMLElement} currentTarget
	 * @return {void}
	 */
	_handleMultiplePopoversClick: function(multiplePopoversClass, currentTarget) {
		const id = currentTarget.id;
		const filteredPopovers = $(multiplePopoversClass).filter((i, elem) => {
			return $(elem).attr('id') !== id;
		});
		filteredPopovers.popover('hide');
		$(currentTarget).popover('toggle');
	},
	/**
	 * @private
	 * @param {JQuery} targetElement
	 * @param {string} allPopoverClassesOnPage
	 * @return {void}
	 */
	_handleBodyClick: function(targetElement, allPopoverClassesOnPage) {
		if (!targetElement.parents().hasClass('popover') && !targetElement.hasClass('popover')) {
			$(allPopoverClassesOnPage).popover('hide');
		}
	},
	/**
	 * Used for reformatting cart items with quickship-related tracking props such that omniture can interpret them properly
	 * @param {any} cartItemsWithProps
	 * @return {Object}
	 */
	mergeArrayOfCartItemsByProp: function(cartItemsWithProps) {
		return cartItemsWithProps.reduce((newMap, y) => {
			Object.keys(y).forEach((key) => {
				if (key === 'uniqueId') {
					return;
				}
				if (key in newMap) {
					newMap[key] += '|';
				} else {
					newMap[key] = '';
				}
				newMap[key] += `${y.uniqueId}:${y[key]}`;
			});
			return newMap;
		}, {});
	},
	/**
	 * @param {object} zipcodeInfo
	 * @param {array} restrictions
	 * @returns {boolean}
	 */
	hasRestrictions: function(zipcodeInfo, restrictions) {
		if (!zipcodeInfo) {
			return false;
		}
		const applicableRestrictions = this.getApplicableRestrictions(restrictions, zipcodeInfo.stateAbbr || zipcodeInfo.state);
		return Boolean(applicableRestrictions && applicableRestrictions.length > 0);
	},
	/**
	 * Filters list of product restrictions by specific stateCode
	 * @param {array} restrictions
	 * @param {string} stateCode
	 * @return {array}
	 */
	getApplicableRestrictions(restrictions, stateCode) {
		const restrictionsApplied = [];
		if (stateCode && Array.isArray(restrictions)) {
			restrictions.forEach((restriction) => {
				if (restriction.locales) {
					const applicableLocale = restriction.locales.filter((locale) => {
						if (locale.stateCode) {
							return locale.stateCode.toLowerCase() === stateCode.toLowerCase();
						}
					});
					if (applicableLocale && applicableLocale.length) {
						restrictionsApplied.push(restriction);
					}
				}
			});
		}

		return restrictionsApplied;
	},
	/**
	 * Handle notification that a recs placement has been loaded into the DOM.
	 * @param {*} placement DOM element of the loaded recs placement.
	 */
	recsPlacementLoaded(placement) {
		window.bodyView.recsPlacementLoaded(placement);
	},

	/**
	 * Split a className property string into individual class names.
	 * @param {string} className Full className property string.
	 * @returns {string[]} Array of individual class names filtered by the test function.
	 */
	splitClassName(className) {
		return className ? className.trim().split(/\s+/) : [];
	}
};
window.BCOM.util = clientUtils;
