import { Model, ObjectHash, Silenceable } from 'backbone';
import Service from '../../../../../views/store/common/utility/model/service';
import { Collection } from '../../../../../views/store/common/utility/adt/collection';
import Utils from '../../../../../views/store/common/utility';

export interface IFinishesModel extends Model {
	isSelected: (uniqueId: number) => boolean;
	setSelected: (uniqueId: number, options?: Silenceable) => IFinishesModel;
	fetchAll: (payload: any, success?: Function, error?: Function) => IFinishesModel;
	findFinishByUniqueId: (uniqueId: number) => any;
}

export default class FinishesModel extends Model implements IFinishesModel {
	static events: ObjectHash = {
		progress: 'model:finishes:progress',
		done: 'model:finishes:done'
	};

	defaults(): ObjectHash {
		return {
			selected: null,
			uniqueId: null,
			finishes: new Collection([])
		};
	}

	get url(): any {
		return '/app/api';
	}

	initialize(attributes?: any, options?: any): void {
		super.initialize(attributes, options);
		Service.proxify(this);
	}

	findFinishByUniqueId(uniqueId: number): any {
		return this.get('finishes').find((finish) => finish.uniqueId === uniqueId);
	}

	setSelected(uniqueId, options: Silenceable = {}): IFinishesModel {
		if (Utils.defined(uniqueId) && !this.get('finishes').isEmpty()) {
			this.set('selected', this.findFinishByUniqueId(uniqueId), options);
		}
		return this;
	}

	isSelected(uniqueId: number): boolean {
		return Utils.defined(this.get('selected')) && this.get('selected').uniqueId === uniqueId;
	}

	serializeFinishesAsOptions() {
		return this.get('finishes').map((finish) => {
			return {
				...finish,
				optionProp: 'finish',
				optionValueProp: 'uniqueId',
				optionDataAttributes: { name: 'uniqueid', value: finish.uniqueId },
				selectedValue: this.isSelected(finish.uniqueId) ? finish.uniqueId : null
			};
		});
	}

	fetchAll(payload: any, success?: Function, error?: Function): IFinishesModel {
		this.trigger(FinishesModel.events.progress, this);
		return this.set('uniqueId', payload.uniqueId, { silent: true })['request'](
			{
				path: `${payload.url}/${payload.uniqueId}`,
				type: 'GET',
				onParse: this.onFetch.bind(this)
			},
			success,
			error
		);
	}

	onFetch(response = []): object {
		this.onFetchFinishes(response).trigger(FinishesModel.events.done, this);
		return {};
	}

	onFetchFinishes(response = []) {
		if (Array.isArray(response)) {
			this.get('finishes').set(response);
			this.setSelected(this.get('uniqueId'), { silent: true });
		}
		return this;
	}
}
