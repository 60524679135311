export const USABILLA = {
	MAIN_BUTTON_ID: '6e01a3cfe360',
	CAMPAIGN_IDS: {
		INLINE_PRODUCT_DROP_YES: '25d1064459fa',
		INLINE_PRODUCT_DROP_NO: '3f536a0a327c',
		INLINE_SEARCH_RESULTS_YES: '66ebd69c043a',
		INLINE_SEARCH_RESULTS_NO: '3de95a37dfed',
		FERGUSON_CUSTOMER_SURVEY: 'b215c6083beb',
		FERGUSON_REP_SURVEY: '7c7bcfc2f058'
	},
	FERGUSON_SURVEY: 'Ferguson Survey',
	INLINE_PRODUCT_DROP: 'Inline Product Drop',
	INLINE_SEARCH_RESULTS: 'Inline Search Results'
};

export const LIVE_AGENT_EXCLUSION_LIST = {
	SITE_ID_LIST: [95]
};
