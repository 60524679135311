import Backbone from 'backbone';
const dustjs = require('dustjs');
const _modalConfirmationContentDust = require('store/global/pages/account/projects/_modal_confirmation_overlay_content.dust');

export const ModalConfirmationOverlayView = Backbone.View.extend({
	events() {
		return {
			'click .js-modal-confirmation-overlay-link': 'handleConfirmationLinkClick',
			'click .js-modal-confirmation-overlay-button': 'handleConfirmationButtonClick'
		};
	},

	handleConfirmationLinkClick() {
		if (this.linkCallback && typeof this.linkCallback === 'function') {
			this.linkCallback();
		}
		this.handleHide();
	},

	handleConfirmationButtonClick() {
		if (this.buttonCallback && typeof this.buttonCallback === 'function') {
			this.buttonCallback();
		}
		this.handleHide();
	},

	handleHide() {
		if (this.onHide && typeof this.onHide === 'function') {
			this.onHide();
		}
		this.hide();
	},

	hide() {
		this.$el.hide();
	},

	show() {
		this.$el.show();
	},

	render(data) {
		this.linkCallback = data.linkCallback;
		this.buttonCallback = data.buttonCallback;
		this.onHide = data.onHide;
		dustjs.render(_modalConfirmationContentDust, data, (err, out) => {
			this.$el.html(out);
			this.show();
		});
	}
});
