module.exports = {
	extend: function(dust) {
		dust.filters.lc = function(str) {
			if (typeof str === 'string') {
				return str.toLowerCase();
			}
			return str;
		};
		dust.filters.slug = function(str) {
			if (typeof str === 'string') {
				// yoinked from node utils/index.js
				return (
					str
						.toLowerCase()
						.replace(/\s+/g, '-')
						// eslint-disable-next-line no-useless-escape
						.replace(/([^\w\-]|_)+/g, '-')
						// eslint-disable-next-line no-useless-escape
						.replace(/\-\-+/g, '-')
						.replace(/^-+/, '')
						.replace(/-+$/, '')
				);
			}
			return str;
		};

		dust.filters.secsToMilliSecs = function(seconds) {
			return seconds * 1000;
		};

		dust.filters.msToMin = function(duration) {
			if (!duration || isNaN(parseInt(duration, 10))) {
				return duration;
			}
			let minutes = (duration / (1000 * 60)) % 60;
			if (minutes < 1) {
				return `< 1`;
			} else {
				return Math.floor(minutes).toString();
			}
		};

		dust.filters.msToTime = function(duration) {
			if (!duration || isNaN(parseInt(duration, 10))) {
				return duration;
			}
			const seconds = Math.floor((duration / 1000) % 60);
			const minutes = Math.floor((duration / (1000 * 60)) % 60);
			const mm = minutes < 10 ? `0${minutes}` : minutes;
			const ss = seconds < 10 ? `0${seconds}` : seconds;
			return `${mm}:${ss}`;
		};

		dust.filters.titlecase = function(str) {
			return str.replace(/\w\S*/g, function(txt) {
				return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
			});
		};

		dust.filters.projectName = function(str) {
			return str.replace(/&amp;/g, '&');
		};
		return dust;
	}
};
