const jsCookie = require('js-cookie');
const cookies = require('lib/enums').cookies;

const locationHelper = {
	saveLocationCookies: (locationData) => {
		if (
			locationData &&
			locationData.hasOwnProperty('zipCode') &&
			locationData.hasOwnProperty('cityName') &&
			locationData.hasOwnProperty('stateAbbr')
		) {
			jsCookie.set(cookies.POSTAL_CODE, locationData.zipCode, {
				path: '/'
			});
			jsCookie.set(cookies.POSTAL_CODE_SOURCE, 'confirmed', {
				path: '/'
			});
			jsCookie.set(cookies.POSTAL_CODE_CITY, locationData.cityName, {
				path: '/'
			});
			jsCookie.set(cookies.POSTAL_CODE_STATEABBR, locationData.stateAbbr, {
				path: '/'
			});
		}
	}
};

module.exports = locationHelper;
