const $ = require('jquery');
const Backbone = require('backbone');
const ComponentViews = {
	accordion: require('views/store/common/components/accordion/accordion'),
	carouselSlider: require('views/store/common/components/carousel-slider/carousel-slider').CarouselSlider,
	expandable: require('views/store/common/components/expandable/expandable').Expandable,
	findGuestOrderForm: require('views/store/common/components/find_guest_order_form/find_guest_order_form'),
	pagination: require('views/store/common/components/pagination/pagination'),
	selectBox: require('views/store/common/components/select_box/select_box'),
	finishSelector: require('views/store/common/components/finish_selector/finish_selector').default,
	carousel: require('src/app/views/store/common/components/carousel/carousel'),
	confirmationModal: require('views/store/common/components/confirmation_modal/confirmation_modal').ConfirmationModalView,
	modalConfirmationOverlay: require('views/store/common/components/modal_confirmation_overlay/modal_confirmation_overlay')
		.ModalConfirmationOverlayView,
	switch: require('src/app/views/store/common/components/switch/switch')
};

const ComponentHelper = Backbone.View.extend({
	initialize() {
		const $components = $('.js-component');
		// Initialize nested components first
		$components.find('.js-component').each(this._initializeComponent);
		$components.each(this._initializeComponent);
	},
	_initializeComponent(i, el) {
		const $el = $(el),
			componentType = $(el).data('componentType'),
			autoRender = Boolean($(el).data('auto-render')),
			ComponentViewClass = ComponentViews[componentType];
		if (ComponentViewClass && !$el.data('componentView')) {
			const instance = new ComponentViewClass({ el });
			$el.data('componentView', instance);
			if (autoRender && instance.render) {
				instance.render();
			}
		}
	}
});

module.exports = new ComponentHelper();
