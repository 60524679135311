import { cookies } from 'lib/enums';
import Backbone from 'backbone';
import _ from 'underscore';
import ProductModel from 'app/models/store/client/product/drop';
import CompareProductsCollection from 'app/collections/compare/products';
import dustjs from 'dustjs';
import { sanitizeUniqueId } from 'lib/helpers/sanitize';
import 'store/global/components/button/button.dust';
import _compareWidgetDust from 'common/compare/_compare_widget.dust';
import _compareProductDust from 'common/compare/_compare_product.dust';
import _emptySlot from 'common/compare/_compare_widget_empty.dust';
import jsCookie from 'js-cookie';
import gtmHelper from 'lib/helpers/gtm_helper';

export const CompareWidgetView = Backbone.View.extend({
	events: {
		'click .js-remove': '_handleRemoveClickEvent',
		'click .js-compare-button': '_handleActionButton',
		'click .js-clear-selection': '_handleRemoveAll',
		'click .js-compare-widget-toggle': '_handleWidgetToggle',
		'click .js-dismiss-warning': '_hideInCompatibleWarning',
		'click .js-minimize': '_minimize',
		'click .js-minimized-compare': '_handleActionButton',
		'click .js-compare-widget-scroll-arrow': '_scrollingWithArrows'
	},

	initialize: function(options) {
		this.options = Object.assign({}, options);
		const self = this;
		this.collection = new CompareProductsCollection();
		this.type = 'compare';
		this.productMinimum = 2;
		this.productMaximum = 4;
		this.options.productModels = [];
		this.options.baseCategoryMapping = [];
		this.$chatPrompt = $('#persistent-chat-prompt, #persistent-chat-prompt-minimizable');

		dustjs.render(_compareWidgetDust, {}, function(error, markup) {
			const $pageContent = $('#page-content');
			$pageContent.find('.compare-widget').remove();
			$pageContent.append(markup);
			const $compareWidgetToggle = $('.js-compare-widget-toggle');
			const $compareWidget = $('.js-compare-widget');
			self.setElement('.js-compare-widget');
			$compareWidget.toggleClass('hide', self.collection.length === 0);
			const isWidgetShown = jsCookie.get(cookies.COMPARESHOW) ? jsCookie.get(cookies.COMPARESHOW) === 'true' : true;
			$compareWidget.toggleClass('slide-down', !isWidgetShown);
			$compareWidgetToggle.find('.widget-hidden').toggleClass('hide', isWidgetShown);
			$compareWidgetToggle.find('.widget-shown').toggleClass('hide', !isWidgetShown);
			if ($('.widget-shown').hasClass('hide')) {
				$('.js-compare-widget-toggle').addClass('pa2');
				$('.compare-widget').removeClass('bottom-0');
			} else {
				$('.js-compare-widget-toggle').removeClass('pa2');
				$('.compare-widget').addClass('bottom-0');
			}
		});

		$(document).on('click', '.popover .close', function() {
			$(this)
				.parents('.popover')
				.popover('hide');
		});

		this.$actionBtn = $('.js-compare-button');

		this.initButtons();

		this.collection.on('add', function(productModel) {
			$('.js-compare-widget').toggleClass('hide', self.collection.length === 0);
			self._renderItem(productModel.attributes);
			self._addEmptySlot();
			self._getBaseCategory(productModel.get('uniqueId'), function(err, categoryResponse) {
				self.options.baseCategoryMapping.push({
					baseCategoryId: categoryResponse.baseCategory.id,
					uniqueId: productModel.get('uniqueId')
				});
				const uniqueBaseCategories = _.uniq(self.options.baseCategoryMapping, false, function(model) {
					return model.baseCategoryId;
				});
				if (uniqueBaseCategories.length > 1) {
					self._showInCompatibleWarning();
				} else {
					self._hideInCompatibleWarning();
				}
			});
		});

		this.collection.on('remove', function(productModel) {
			self.options.baseCategoryMapping = self.options.baseCategoryMapping.filter(
				(model) => model.uniqueId !== productModel.get('uniqueId')
			);

			const uniqueBaseCategories = _.uniq(self.options.baseCategoryMapping, false, function(model) {
				return model.baseCategoryId;
			});
			self._addEmptySlot();
			if (uniqueBaseCategories.length > 1) {
				self._showInCompatibleWarning();
			} else {
				self._hideInCompatibleWarning();
			}
		});

		this.options.uniqueIds = this._readCookie();

		self.listenTo(Backbone, 'compare:changeFinish', self._changeFinish);

		this._setInitialInputState();
		if (this.options.uniqueIds.length) {
			this.collection.fetch({
				data: {
					uniqueIds: this.options.uniqueIds.toString()
				},
				success: this._updateUiState()
			});
		}
		$(window).on('resize', this._widgetScrollArrows);
	},
	_widgetScrollArrows: function() {
		if (!window.BCOM.device.isMobile) {
			const $compareWrapper = $('.compare-products-wrapper');
			if ($compareWrapper.prop('scrollWidth') > $compareWrapper.prop('clientWidth')) {
				$('.compare-widget-left-arrow, .compare-widget-right-arrow').removeClass('hide');
			} else {
				$('.compare-widget-left-arrow, .compare-widget-right-arrow').addClass('hide');
			}
		}
	},
	_scrollingWithArrows: function(e) {
		const targetClasses = e.currentTarget.className;
		if (targetClasses.indexOf('right') > -1) {
			const leftPos = $('.compare-products-wrapper').scrollLeft();
			$('.compare-products-wrapper').animate({ scrollLeft: leftPos + 300 }, 800);
		} else {
			const leftPos = $('.compare-products-wrapper').scrollLeft();
			$('.compare-products-wrapper').animate({ scrollLeft: leftPos - 300 }, 800);
		}
	},
	initButtons: function(scopedSelector) {
		let $checkboxBtns;
		if (scopedSelector) {
			$checkboxBtns = $(scopedSelector).find('.js-compare-toggle-btn');
		} else {
			$checkboxBtns = $('.js-compare-toggle-btn');
		}
		$checkboxBtns.tooltip({
			title: `You may only compare ${this.productMaximum} products at once`,
			trigger: 'manual'
		});
		$checkboxBtns.on('click', this._toggleProductHandler.bind(this));
		if (scopedSelector) {
			this._setInitialInputState();
		}
	},
	_hideInCompatibleWarning: function() {
		$('.compare-widget-messages').hide();
	},
	_showInCompatibleWarning: function() {
		$('.compare-widget-messages').show();
	},
	_handleRemoveAll: function(e) {
		e.preventDefault();
		const self = this;
		while (self.collection.length > 0) {
			self._removeFromList(self.collection.at(0).get('uniqueId'));
		}
	},
	_handleWidgetToggle: function(e) {
		e.preventDefault();
		$('.js-compare-widget').toggleClass(
			'slide-down',
			!$(e.currentTarget)
				.find('a.widget-shown')
				.hasClass('hide')
		);
		$(e.currentTarget)
			.find('a')
			.toggleClass('hide');
		jsCookie.set(
			cookies.COMPARESHOW,
			!$(e.currentTarget)
				.find('a.widget-shown')
				.hasClass('hide'),
			{
				path: '/'
			}
		);
		if (
			$(e.currentTarget)
				.find('a.widget-shown')
				.hasClass('hide')
		) {
			$('.js-compare-widget-toggle').addClass('pa2');
			$('.compare-widget').removeClass('bottom-0');
		} else {
			$('.js-compare-widget-toggle').removeClass('pa2');
			$('.compare-widget').addClass('bottom-0');
		}
	},
	_changeFinish: function(oldUniqueId, newUniqueId) {
		let $button = $(`.compare_${oldUniqueId}`),
			addNewItem = false;
		if (_.contains(this.options.uniqueIds, oldUniqueId)) {
			this._removeFromList(oldUniqueId.toString());
			addNewItem = true;
			this._addToList(newUniqueId.toString(), $button, null);
		}

		if ($button.length) {
			$button
				.removeClass(`compare_${oldUniqueId}`)
				.addClass(`compare_${newUniqueId}`)
				.attr('value', Number(newUniqueId));
			if (addNewItem) {
				const $checkedIcon = $button.find('.js-cmp-checked'),
					$uncheckedIcon = $button.find('.js-cmp-unchecked');
				$checkedIcon.removeClass('ico-hide dn');
				$uncheckedIcon.addClass('ico-hide dn');
			}
		}
	},
	_readCookie: function() {
		const cookieValue = jsCookie.get(cookies.PRDUNIQUEID) || '';
		const sanitizedCookieValues = cookieValue.split('_').map((val) => sanitizeUniqueId(val));
		return _.without(_.unique(_.compact(sanitizedCookieValues)), '""');
	},

	_writeCookie: function() {
		const localIds = this.options.uniqueIds;
		jsCookie.set(cookies.PRDUNIQUEID, localIds.join('_'), {
			path: '/'
		});
	},

	_setInitialInputState: function() {
		const type = this.type;
		const selectors = this.options.uniqueIds.map((id) => `.${type}_${id}`).join(',');
		const $buttons = $(selectors);
		$buttons
			.find('.js-cmp-checked')
			.removeClass('ico-hide dn')
			.parent()
			.attr('onclick', `s_objectID='Compare UnCheck';`);
		$buttons.find('.js-cmp-unchecked').addClass('ico-hide dn');
	},

	_currentItemsCount: function() {
		if (this.collection.length > 1) {
			$('.js-minimized-compare').text(`Compare ${this.collection.length} Items`);
		} else if (this.collection.length) {
			$('.js-minimized-compare').text('Add more items to compare');
		}
	},

	_minimize: function() {
		this._currentItemsCount();
		$('.widget-shown').click();
	},

	_toggleProductHandler: function(event) {
		const $input = $(event.target),
			$button = $input.hasClass('js-compare-toggle-btn') ? $input : $input.closest('.js-compare-toggle-btn'),
			uniqueId = $button.val(),
			$checkedIcon = $button.find('.js-cmp-checked'),
			$uncheckedIcon = $button.find('.js-cmp-unchecked');
		if (!uniqueId) {
			window.BCOM.notifications.render('No finish selected, Please select a finish.', 'danger');
			return;
		}
		if ($checkedIcon.hasClass('ico-hide') || $checkedIcon.hasClass('dn')) {
			$checkedIcon.removeClass('ico-hide dn');
			$uncheckedIcon.addClass('ico-hide dn');
			this._addToList(uniqueId, $button, event);
			this._trackCompare(true);
			$button.attr('onclick', `s_objectID='Compare UnCheck';`);
		} else {
			this._removeFromList(uniqueId);
			this._trackCompare(false);
			$button.attr('onclick', `s_objectID='Compare Check';`);
		}
		return false;
	},

	_trackCompare(check) {
		const gtmTrackingObject = {};
		let linkName;

		if (window.BCOM.data.isQuickShip) {
			linkName = check ? 'quick-ship-compare-click' : 'quick-ship-compare-unclick';
			gtmTrackingObject.quickShipCompareClick = linkName;
			Backbone.trigger('track:link', {
				linkName: linkName
			});
		}

		linkName = check ? 'check' : 'uncheck';
		gtmTrackingObject.checkStatus = check;

		gtmHelper.fireEvent({
			event: 'compare-widget',
			...gtmTrackingObject
		});

		Backbone.trigger('track:link', {
			linkName: linkName
		});
	},

	_addToList: function(uniqueId, $input, event) {
		const self = this,
			$checkedIcon = $input.find('.js-cmp-checked'),
			$uncheckedIcon = $input.find('.js-cmp-unchecked');
		if (this.options.uniqueIds.length < this.productMaximum) {
			if (!_.contains(self.options.uniqueIds, uniqueId)) {
				self.options.uniqueIds.push(uniqueId);
				self.options.productModels.push(
					new ProductModel({
						uniqueId: uniqueId,
						fetchByUniqueId: true
					}).fetch({
						success: function(model, response) {
							self.collection.add(response);
							self._updateUiState();
						}
					})
				);

				self._writeCookie();
			}
		} else {
			$checkedIcon.addClass('ico-hide dn');
			$uncheckedIcon.removeClass('ico-hide dn');
			$input.tooltip('show');
			setTimeout(function() {
				$input.tooltip('hide');
			}, 3000);
		}
		if (event) {
			event.preventDefault();
		}
	},

	_addEmptySlot: function() {
		$('.all-empty-slots').remove();
		if (this.collection.length > 0) {
			for (let i = 0; i < 4 - this.collection.length; i++) {
				this._renderItem();
			}
		}
		const emptySlotsTotal = 4 - this.collection.length;
		const emptySlots = this.$el.find('.empty-slot-message');
		let msgToDisplay;
		if (!this.collection.length) {
			return;
		}
		if (this.collection.length === 1) {
			msgToDisplay = 'Select at least 1 more product to compare';
		} else {
			msgToDisplay = `You can compare up to ${emptySlotsTotal} more item${emptySlots.length > 1 ? 's' : ''}`;
		}
		if (emptySlots[0]) {
			emptySlots[0].innerHTML = msgToDisplay;
		}
	},

	_handleRemoveClickEvent: function(event) {
		event.preventDefault();
		this._removeFromList(
			$(event.currentTarget)
				.data('uniqueid')
				.toString()
		);
	},

	_removeFromList: function(id) {
		const selector = `#${this.type}Prd_${id}`,
			$button = $(`.${this.type}_${id}`),
			$checkedIcon = $button.find('.js-cmp-checked'),
			$uncheckedIcon = $button.find('.js-cmp-unchecked'),
			modelToRemove = this.collection.where({
				uniqueId: parseInt(id, 10)
			});

		this.collection.remove(modelToRemove);
		this.options.uniqueIds = _.without(this.options.uniqueIds, id.toString());
		this._writeCookie();
		$(selector).remove();

		$checkedIcon.addClass('ico-hide dn');
		$uncheckedIcon.removeClass('ico-hide dn');
		this._updateUiState();
	},

	_renderItem: function(product) {
		const element = product ? _compareProductDust : _emptySlot;
		dustjs.render(
			element,
			{
				product: product,
				type: this.type
			},
			(err, out) => {
				if (err) {
					window.clientErrorHandler(err);
				} else {
					this.$('.js-products').append($(out));
				}
			}
		);
		this._currentItemsCount();
	},

	_updateUiState: function() {
		const self = this;
		if (this.options.uniqueIds.length === 0) {
			this.$el.hide();
			this.$chatPrompt.removeClass('with-compare-widget');
		} else {
			this.$el.show(function() {
				self.$actionBtn = $('.js-compare-button');
				if (self.options.uniqueIds.length >= self.productMinimum) {
					self._enableActionButton();
				} else {
					self._disableActionButton('Please select two or more products to compare.');
				}
			});
			this.$chatPrompt.addClass('with-compare-widget');
			this.$el.one('transitionend', this._widgetScrollArrows);
		}
	},

	/**
	 * This method will add 'disabled' class to this.$actionBtn.
	 * Optionally if a tooltip message is specified, it will add the message
	 * as a tooltip to this.$actionBtn.
	 *
	 * @param {string} tooltipMessage Message displayed in the tooltip (Optional).
	 */
	_disableActionButton: function(tooltipMessage) {
		this.$actionBtn.addClass('disabled');
		if (tooltipMessage) {
			this.$actionBtn.tooltip({
				title: tooltipMessage
			});
		}
	},

	/**
	 * This method will remove the 'disabled' class added by _disableActionButton
	 * It will also remove any tooltips on this.$actionBtn.
	 */
	_enableActionButton: function() {
		this.$actionBtn.removeClass('disabled');
		// Don't want any tooltip when the button is enabled.
		this.$actionBtn.tooltip('destroy');
	},

	_handleActionButton: function() {
		if (!this.$actionBtn.hasClass('disabled')) {
			this._minimize();
			window.location.assign('/compare');
		}
	},

	_getBaseCategory: function(uniqueId, cb) {
		$.ajax({
			url: `${window.BCOM.apiPathPrefix}/basecategory/product/${uniqueId}`,
			type: 'GET'
		}).done(function(data) {
			cb(null, data);
		});
	}
});
