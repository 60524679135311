import Backbone from 'backbone';

export const GBIRecentAndTrendingModel = Backbone.Model.extend({
	initialize: function({ recentSearchTerms, trendingEnabled }) {
		this.trendingEnabled = trendingEnabled;
		this.data = {};
		if (recentSearchTerms.length) {
			this.data.recentSearchTerms = recentSearchTerms;
		}
	}
});
