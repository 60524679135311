const format = {
	_isIntlSupported: function() {
		return typeof Intl !== 'undefined';
	},
	/**
	 * Converts a number to a dollar amount
	 * @param  {Number}         amount Numeric amount
	 * @return {String|Boolean}        The dollar amount unless we were unable to parse the amount variable
	 */
	toDollar: function(amount) {
		if (typeof amount === 'string') {
			amount = parseFloat(amount);
		}
		if (typeof amount === 'number' && !isNaN(amount)) {
			if (format._isIntlSupported()) {
				const asMoney = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
				return asMoney.format(amount);
			} else {
				return amount.toString();
			}
		} else {
			return '';
		}
	},

	/**
	 * Converts a number to two decimal places
	 * @param  {String | Number} amount Numeric amount
	 * @param  {Number} [decimalPlaces] Numeric amount
	 * @return {Number}        The amount to 2 decimal places or 0 if unable to parse
	 */
	toDecimal: function(amount, decimalPlaces) {
		decimalPlaces = typeof decimalPlaces !== 'undefined' ? decimalPlaces : 2;
		amount = amount ? parseFloat(amount.toString()) : amount;

		if (typeof amount === 'number' && !isNaN(amount)) {
			return parseFloat(amount.toFixed(decimalPlaces));
		} else {
			return 0;
		}
	},
	/**
	 * @param {string} phone
	 * @param {boolean} [clean]
	 * @return {string}
	 */
	formatPhone: function(phone, clean) {
		if (!phone || typeof phone !== 'string') {
			return '';
		}

		if (clean) {
			// Clean up the number first to remove any previous formatting
			phone = String(phone).replace(/[^\d]/g, '');
		}

		if (phone.length === 7) {
			phone = phone.replace(/([0-9]{3})([0-9]{4})/g, '$1-$2');
		} else if (phone.length === 10) {
			phone = phone.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/g, '($1) $2-$3');
		} else if (phone.length === 11) {
			phone = phone.replace(/([0-9]){1}([0-9]{3})([0-9]{3})([0-9]{4})/g, '$1 ($2) $3-$4');
		}
		return phone;
	},
	/**
	 * @param {*} amount
	 * @param {boolean} allowNegative
	 * @return {string|undefined}
	 */
	currency: (amount, allowNegative = false) => {
		if (format._isIntlSupported()) {
			const isNegative = amount < 0,
				asMoney = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

			if (!allowNegative && isNegative) {
				return;
			}

			return asMoney.format(amount);
		} else {
			return amount;
		}
	}
};

module.exports = format;
