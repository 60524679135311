const Backbone = require('backbone'),
	_ = require('underscore'),
	md5 = require('md5'),
	$ = require('jquery'),
	productTrackingHelper = require('../../../../../lib/helpers/product_tracking_helper'),
	gtmHelper = require('../../../../../lib/helpers/gtm_helper'),
	enums = require('lib/enums'),
	trackLink = require('./track_link');

const OmnitureHelper = Backbone.View.extend(
	/** @type {BackboneExtensions.OmnitureHelper} */ ({
		el: 'body',

		events: {
			'click [data-tracking]': '_trackEvent',
			'click a[href^=tel]:not([data-tracking])': '_trackPhone',
			'click .js-chat-tracking': '_trackChat',
			'click [data-track-navigation]': '_trackNavigation',
			'click .rec-item': '_handleDynamicYieldProductClick',
			'click [data-finding-method]': '_findingMethodsTracking',
			'click .js-recently-viewed-item': '_recentlyViewedItemsClickTracking'
		},

		initialize: function() {
			this.listenTo(Backbone, 'track:link', this._trackLink);
			this.listenTo(Backbone, 'omniture:productaddtocart', this.addToCart);
			this.listenTo(Backbone, 'omniture:buyitagainResult', this.buyItAgainResult);
			this.listenTo(Backbone, 'omniture:orderAgain', this.orderAgain);
			this.listenTo(Backbone, 'omniture:orderAgainModal', this.orderAgainModal);
			this.listenTo(Backbone, 'omniture:orderAgainAddToCart', this.orderAgainAddToCart);
			this.listenTo(Backbone, 'omniture:orderHistorySearch', this.orderHistorySearch);
			this.listenTo(Backbone, 'omniture:removefromcart', this.removeFromCart);
			this.listenTo(Backbone, 'omniture:cartmanagement', this.cartManagement);
			this.listenTo(Backbone, 'omniture:trackValidationError', this.trackValidationError);
			this.listenTo(Backbone, 'omniture:trackPaypalClicked', this.trackPaypalClicked);
			this.listenTo(Backbone, 'omniture:trackAmazonPayClicked', this.trackAmazonPayClicked);
			this.listenTo(Backbone, 'omniture:incrementCart', this.incrementCart);
			this.listenTo(Backbone, 'omniture:cloudinary', this.cloudinary);
			this.listenTo(Backbone, 'omniture:checkoutCreateAccount', this.checkoutCreateAccount);
			this.listenTo(Backbone, 'omniture:checkoutAsGuest', this.checkoutGuest);
			this.listenTo(Backbone, 'omniture:checkoutAsReturningUser', this.checkoutAsReturningUser);
			this.listenTo(Backbone, 'omniture:addcoupon', this.addCoupon);
			this.listenTo(Backbone, 'omniture:checkoutStarted', this.checkoutStart);
			this.listenTo(Backbone, 'omniture:finishselected', this.selectFinish);
			this.listenTo(Backbone, 'omniture:clicktracking', this.clickTracking);
			this.listenTo(Backbone, 'omniture:recommendedProductClicked', this.recommendedProductClicked);
			this.listenTo(Backbone, 'omniture:facetChanged', this.facetChanged);
			this.listenTo(Backbone, 'omniture:chatTracking', this.chatTracking);
			this.listenTo(Backbone, 'omniture:emailSignUpTracking', this.emailSignUpTracking);
			this.listenTo(Backbone, 'omniture:emailEntry', this.emailEntry);
			this.listenTo(Backbone, 'omniture:createAccount', this.createAccount);
			this.listenTo(Backbone, 'omniture:phoneClick', this._trackPhone);
			this.listenTo(Backbone, 'omniture:socialshare', this._socialShare);
			this.listenTo(Backbone, 'omniture:plaPageToFullProductPage', this._plaPageToFullProductPage);
			this.listenTo(Backbone, 'omniture:shippingAsyncDropsChange', this._trackShippingAsyncDrops);
			this.listenTo(Backbone, 'omniture:plaConfigureAddToCart', this._plaConfigureAddToCart);
			this.listenTo(Backbone, 'omniture:defaultAddressUnset', this._defaultAddressUnset);
			this.listenTo(Backbone, 'omniture:productAddedToProject', this.productAddedToProject);
			this.listenTo(Backbone, 'omniture:projectModalOpen', this.projectModalOpen);
			this.listenTo(Backbone, 'omniture:cartAddedToProjectFromCartPage', this.cartAddedToProjectFromCartPage);
			this.listenTo(Backbone, 'omniture:searchQuery', this._searchQuery);
			this.listenTo(Backbone, 'omniture:proAccountSignupStepTwo', this._proAccountSignupStepTwo);
			this.listenTo(Backbone, 'omniture:proAccountSignupFinish', this._proAccountSignupFinish);
			this.listenTo(Backbone, 'omniture:projects', this.trackProjectsOmniture);
			this.listenTo(Backbone, 'omniture:productCard', this.trackProductCard);
			this.listenTo(Backbone, 'omniture:exportToTrilogie', this.trackExportToTrilogie);
			this.listenTo(Backbone, 'omniture:addProPrice', this.trackAddTrilogieProPricing);
			this.listenTo(Backbone, 'omniture:removeProPrice', this.trackRemoveTrilogieProPricing);
			this.listenTo(Backbone, 'omniture:orderHistoryRelatedItems', this.orderHistoryRelatedItems);
			window.omnitureHelperLoaded = true;
		},
		/**
		 * @returns {void}
		 */
		checkoutAsReturningUser: function() {
			window.s.prop18 = 'returning user';

			gtmHelper.fireEvent({
				event: 'customer-checkout-type',
				customerCheckoutType: 'returning user'
			});

			trackLink.promiseToTrack('Begin Checkout');
		},
		/**
		 * @returns {void}
		 */
		checkoutGuest: function() {
			window.s.prop18 = 'guest checkout';

			gtmHelper.fireEvent({
				event: 'customer-checkout-type',
				customerCheckoutType: 'guest checkout'
			});

			trackLink.promiseToTrack('Begin Checkout');
		},
		/**
		 * @private
		 * @param {Event} event
		 * @returns {void}
		 */
		_trackEvent: function(event) {
			let shouldRedirect = false;
			const $target = $(event.target),
				$currentTarget = $(event.currentTarget),
				href = $target.attr('href'),
				openNewWindow = $target.attr('target') && $target.attr('target') === '_blank';

			if (href) {
				shouldRedirect = href !== '#' && $target.data('toggle') !== 'dropdown';
				event.preventDefault();
			}

			const linkId = this._shopAllHelper($currentTarget.data('tracking'));

			if (window.s) {
				window.s.linkTrackVars = $currentTarget.data('tracking-vars') || 'server';
				// hack to make sure we aren't sending erroneous events left over from other s.tl calls
				window.s.linkTrackEvents = '';

				if ($currentTarget.data('tracking-event')) {
					event = `event${event}`;
					window.s.linkTrackEvents = event;
					window.s.events = event;
				}

				trackLink.promiseToTrack(linkId).then(() => {
					if (openNewWindow) {
						window.open(href);
					} else if (shouldRedirect) {
						window.location.assign(href);
					}
				});
			}
		},
		/**
		 * @private
		 * @param {Array} linkTrackingVariables
		 * @param {Object} data
		 * @param {boolean?} updateSObject
		 * @returns {void}
		 */
		_handleLinkTrackVars: function(linkTrackingVariables, data, updateSObject) {
			for (const key in data) {
				if (data.hasOwnProperty(key)) {
					const val = data[key];
					if (updateSObject) {
						window.s[key] = val;
					}
					linkTrackingVariables.push(Array.isArray(data) ? val : key);
				}
			}
		},
		/**
		 * @private
		 * @param {Event} event
		 * @returns {void}
		 */
		_handleDynamicYieldProductClick: function(event) {
			event.preventDefault();
			const $link = $(event.currentTarget),
				recsTrackingData = $link.data('recsTracking'),
				device = window.BCOM.device.deviceType,
				eVar76 = `recs~${device}~${recsTrackingData}`,
				trackingProp = $link.data('trackingProp'),
				linkURL = $link.attr('href'),
				trackingData = {
					eVar76,
					linkURL,
					trackingProp,
					device,
					recsTrackingData
				};

			if (linkURL) {
				this.recommendedProductClicked(trackingData);
			}

			$(event.currentTarget).attr({
				'data-finding-method': 'Recommendations',
				'data-finding-method-subtype': 'dynamic-recommendation'
			});

			this._findingMethodsTracking(event);
		},
		/**
		 * @private
		 * @param {{evars?, events?, products?, props?, linkName?, redirect?}} data
		 * @returns {void}
		 */
		_trackLink: function(data) {
			trackLink.cleanOmnitureVariables();
			window.s.linkTrackVars = '';
			let linkTrackingVariables = [];
			// add evars
			if (data.evars) {
				this._handleLinkTrackVars(linkTrackingVariables, data.evars, true);
			}

			// add events
			if (data.events) {
				window.s.events = '';
				this._handleLinkTrackVars(linkTrackingVariables, data.events);
				window.s.events = _.reduce(
					data.events,
					(state, value) => {
						return state.length ? `${state},${value}` : value;
					},
					window.s.events
				);

				window.s.linkTrackEvents = window.s.events;
			}

			// add products
			if (data.products) {
				linkTrackingVariables.push('products');
				const productStrings = _(data.products).map((product) => {
					return this._generateProductString(product, data.evars);
				});
				window.s.products = productStrings.join();
			}

			if (data.props) {
				this._handleLinkTrackVars(linkTrackingVariables, data.props, true);
			}

			// produce linkTrackVars string
			window.s.linkTrackVars = linkTrackingVariables.join();

			// fire tl function
			trackLink.promiseToTrack(data.linkName).then(() => {
				if (data.redirect) {
					window.location.assign(data.redirect);
				} else {
					this._invokeCallBack(data);
				}
			});
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		trackLink: function(data) {
			this._trackLink(data);
		},
		/**
		 * @private
		 * @param {Event} event
		 * @returns {void}
		 */
		_trackNavigation: function(event) {
			event.preventDefault();
			const $navigationElement = $(event.currentTarget),
				redirect = $navigationElement.attr('href'),
				trackingString = $navigationElement.data('track-navigation'),
				linkName = $navigationElement.data('track-link-name') || 'generic link tracking',
				trackingProp = $navigationElement.data('tracking-prop'),
				customPropData = trackingProp && trackingProp.split('-'),
				trackingData = {
					evars: {
						eVar2: trackingString
					},
					props: {},
					redirect,
					linkName
				};
			if (customPropData && customPropData.length === 2) {
				trackingData.props[customPropData[1]] = customPropData[0];
			}
			this._trackLink(trackingData);
		},
		/**
		 * @private
		 * @param {*} product
		 * @param {Array} evars
		 * @returns {string}
		 */
		_generateProductString: function(product, evars) {
			let productDataArray = [],
				evarString;

			// category
			productDataArray.push('');
			// uniqueId
			productDataArray.push(product.uniqueId);
			// quantity
			productDataArray.push('');
			// price
			productDataArray.push('');
			// event_incrementer
			productDataArray.push('');
			// evars
			evarString = this._generateEvarString(evars, '=', '|');
			productDataArray.push(evarString);

			return productDataArray.join(';');
		},
		/**
		 * @private
		 * @param {Array} evars
		 * @param {string} seperator
		 * @param {string} delimiter
		 * @returns {string}
		 */
		_generateEvarString: function(evars, separator, delimiter) {
			return _.reduce(
				evars,
				(string, value, key, callback) => {
					let section = key + separator + value;
					if (callback && typeof callback === 'function') {
						callback(key, value);
					}
					return string.length ? string + delimiter + section : section;
				},
				''
			);
		},
		/**
		 * Need to include some special logic for the "Shop All Departments" tab
		 * @private
		 * @param {string} linkId
		 * @returns {string}
		 */
		_shopAllHelper: function(linkId) {
			const megaWrapperIsActive = $('.mega-wrapper.active').length,
				newLinkId = megaWrapperIsActive ? `alldept:${linkId}` : linkId;
			return newLinkId;
		},
		_formatCartData: function(cartData, quantity) {
			return {
				uniqueId: cartData.uniqueId,
				baseCategory: cartData.baseCategory,
				finishName: cartData.finish,
				manufacturer: cartData.manufacturer,
				price: cartData.unitPrice,
				quantity: Math.abs(quantity),
				type: cartData.itemType,
				application: cartData.application,
				stockCount: cartData.stockCount,
				image: cartData.image,
				productId: cartData.productId
			};
		},
		/**
		 * @param {*} model
		 * @param {number} quantity
		 * @returns {void}
		 */
		incrementCart: function(model, quantity) {
			if (quantity > 0) {
				this.incrementAddCart(model, quantity);
			} else {
				this.incrementRemoveCart(model, quantity);
			}
		},
		/**
		 * @param {string} linkId
		 * @param {*} event
		 * @param {*} vars
		 * @returns {Promise<void>}
		 */
		clickTracking: function(linkId) {
			linkId = this._shopAllHelper(linkId);

			gtmHelper.fireEvent({
				event: 'generic-click-tracking',
				linkName: linkId
			});

			if (window.s) {
				window.s.linkTrackVars = 'server';
				// hack to make sure we aren't sending erroneous events left over from other s.tl calls
				window.s.linkTrackEvents = '';

				return trackLink.promiseToTrack(linkId);
			}
		},
		/**
		 * @param {*} model
		 * @returns {void}
		 */
		removeFromCart: function(model) {
			window.s.products = `;${model.uniqueId}`;
			window.s.linkTrackVars = 'events,server,products,eVar74';
			window.s.linkTrackEvents = 'scRemove,event76';
			window.s.events = 'scRemove,event76';
			if (window.dataLayer.cartItems && Array.isArray(window.dataLayer.cartItems)) {
				const updatedCartItems = window.dataLayer.cartItems.filter((item) => {
					return item.uniqueId !== model.uniqueId;
				});
				const updatedSubtotal = Math.max(window.dataLayer.subTotal - model.totalPrice).toFixed(2);
				this.checkQuickShipDisqualifications(model, model.quantity, true);
				productTrackingHelper.setSessionStorage(updatedSubtotal.toString(), null, null, JSON.stringify(updatedCartItems));
			}

			gtmHelper.fireCartQuantityChangeEvent('remove-from-cart', 'cart-page', [
				gtmHelper.mapProductData({
					uniqueId: model.uniqueId,
					baseCategory: model.baseCategory,
					finishName: model.finish,
					manufacturer: model.manufacturer,
					price: model.unitPrice.toString(),
					quantity: Math.abs(model.quantity),
					application: model.application,
					stockCount: model.stockCount,
					image: model.imagePaths && model.imagePaths['500'],
					productId: model.productId
				})
			]);

			trackLink.promiseToTrack('remove From Cart');
		},

		/**
		 * This method triggers events for when the cart changes from quick-ship to non-quick-ship
		 * when an item is either removed from the cart or it's quantity is decremented.
		 *
		 * @param {*} model - model of cart item updated
		 * @param {number} quantity - new quantity of item updated
		 * @param {boolean} isCartRemoval - if the action was cart removal
		 */
		checkQuickShipDisqualifications(model, quantity, isCartRemoval) {
			const currentCartSubTotal = JSON.parse(window.sessionStorage.getItem(enums.SESSION_STORAGE.CART_SUBTOTAL));
			const itemPrice = isCartRemoval ? model.unitPrice * quantity : model.unitPrice * (quantity * -1);
			const newCartSubtotal = currentCartSubTotal - itemPrice;

			const isQuickShipCartChange = productTrackingHelper.isQuickShipCartChange(
				window.dataLayer.cartItems,
				newCartSubtotal,
				JSON.parse(window.sessionStorage.getItem(enums.SESSION_STORAGE.IS_PREVIOUS_CART_QUICKSHIP))
			);
			if (isQuickShipCartChange) {
				window.s.prop47 = isCartRemoval ? 'Disqualifies QS via Cart Total' : 'Disqualifies QS via Sku';
				window.s.linkTrackVars += ',prop47';
			}
		},
		/**
		 * @param {*} model
		 * @returns {void}
		 */
		cartManagement: function(model) {
			const trackingStringParts = [model.projectName, model.cartName, model.device, model.data, model.quoteNumber, model.action],
				trackingString = trackingStringParts.join('~');
			window.s.linkTrackVars = 'events,eVar78,eVar79';
			window.s.linkTrackEvents = `${model.action},${model.event}`;
			window.s.eVar78 = trackingString;
			window.s.eVar79 = model.email && md5(model.email);
			window.s.events = model.event;
			trackLink.promiseToTrack(model.action);
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		trackValidationError: function(data) {
			gtmHelper.fireEvent({
				event: 'track-validation-error',
				message: data.message
			});
			if (window.s) {
				window.s.linkTrackVars = 'events,eVar92';
				window.s.linkTrackEvents = 'event32';
				window.s.events = 'event32';
				window.s.eVar92 = data.message;
				trackLink.promiseToTrack('Track Validation Error');

				gtmHelper.fireEvent({
					event: 'track-validation-error',
					message: data.message
				});
			} else {
				data.iframeCallback();
			}
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		trackPaypalClicked: function(data) {
			window.s.linkTrackVars = 'events,eVar92';
			window.s.linkTrackEvents = 'event32';
			window.s.events = 'event32';
			window.s.eVar92 = data.message;

			gtmHelper.fireEvent({
				event: 'track-payment-method-clicked',
				type: 'paypal',
				message: data.message
			});

			trackLink.promiseToTrack('Track Paypal Clicked');
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		trackAmazonPayClicked: function(data) {
			window.s.linkTrackVars = 'events,eVar92';
			window.s.linkTrackEvents = 'event32';
			window.s.events = 'event32';
			window.s.eVar92 = data.message;

			gtmHelper.fireEvent({
				event: 'track-payment-method-clicked',
				type: 'amazon',
				message: data.message
			});

			trackLink.promiseToTrack('Track Amazon Pay Clicked');
		},
		/*
		 * @param {*} model
		 * @param {number} quantity
		 * @returns {void}
		 */
		incrementRemoveCart: function(model, quantity) {
			window.s.products = `;${model.uniqueId}`;
			window.s.linkTrackVars = 'events,server,eVar5,eVar74,products';
			window.s.linkTrackEvents = 'scRemove,event76';
			window.s.events = 'scRemove,event76';
			window.s.eVar5 = `${model.manufacturer}:${model.productId}:${model.finish}::${model.uniqueId}`;
			if (window.dataLayer.cartItems && Array.isArray(window.dataLayer.cartItems)) {
				this.checkQuickShipDisqualifications(model, quantity, false);
			}

			gtmHelper.fireCartQuantityChangeEvent(
				'remove-from-cart',
				'cart-page',
				[gtmHelper.mapProductData(this._formatCartData(model, quantity))],
				model.cart.allItems
			);

			trackLink.promiseToTrack('Decrement From Cart');
		},
		/**
		 * @param {*} model
		 * @param {number} quantity
		 * @returns {void}
		 */
		incrementAddCart: function(model, quantity) {
			window.s.products = `;${model.uniqueId}`;
			window.s.linkTrackVars = 'events,server,eVar5,eVar74,products';
			window.s.linkTrackEvents = 'scAdd,event75';
			window.s.events = 'scAdd,event75';
			window.s.eVar5 = `${model.manufacturer}:${model.productId}:${model.finish}::${model.uniqueId}`;
			const newCartSubtotal =
				JSON.parse(window.sessionStorage.getItem(enums.SESSION_STORAGE.CART_SUBTOTAL)) + model.unitPrice * quantity;
			const isQuickShipCartChange = productTrackingHelper.isQuickShipCartChange(
				window.dataLayer.cartItems,
				newCartSubtotal,
				JSON.parse(window.sessionStorage.getItem(enums.SESSION_STORAGE.IS_PREVIOUS_CART_QUICKSHIP))
			);
			if (isQuickShipCartChange) {
				window.s.prop47 = 'Qualifies QS via Sku';
				window.s.linkTrackVars += ',prop47';
			}

			gtmHelper.fireCartQuantityChangeEvent(
				'add-to-cart',
				'cart-page',
				[gtmHelper.mapProductData(this._formatCartData(model, quantity))],
				model.cart.allItems
			);

			trackLink.promiseToTrack('Increment From Cart');
		},
		/**
		 * @param {*} response
		 * @param {*} model
		 * @param {*} activeFinish
		 * @param {Function} callback
		 * @returns {void}
		 */
		addToCart: function(addToCartMethod, response, model, activeFinish, callback) {
			let items = response.sessionCart || response.items,
				uniqueIds,
				isFromProject,
				product,
				selectedFinish,
				page,
				quantity,
				finishName,
				allCartItems;

			if (items.length === 1 && items[0].product) {
				product = items[0].product.productId ? items[0].product : items[0];
			} else if (response.model) {
				product = response.model;
			}

			quantity = (model ? model.get('quantity') : response.quantity) || 0;

			switch (window.dataLayer.page) {
				case 'product-pla:display':
					page = 'Product-PLA';
					break;
				case 'product:display':
					page = 'Product';
					break;
				case 'product:configure':
					page = 'Product Configuration';
					break;
				case 'product:compare':
					page = 'Product Compare';
					break;
				case 'product:maywesuggest':
					page = 'CS';
					break;
				case 'favorite:show':
					page = 'Favorites';
					break;
				case 'search:browse':
					page = 'Category Product Drop';
					break;
				case 'account:projects:feed':
					page = 'Projects Shopping List';
					isFromProject = true;
					break;
				default:
					page = 'undefined';
			}

			uniqueIds = items.map((item) => {
				return `;${item.uniqueId || item.product.uniqueId}`;
			});

			window.s.products = uniqueIds.join(',');

			delete window.s.eVar5;

			if (product) {
				if (product.finish) {
					finishName = product.finish;
				} else if (product.finishes) {
					selectedFinish = product.finishes.find((finish) => {
						return finish.selectedFinish;
					});

					if (selectedFinish) {
						finishName = selectedFinish.name;
					}
				}

				window.s.eVar5 = `${product.manufacturer}:${product.productId}:${finishName}:${page}:${product.uniqueId}`;
			}

			window.s.linkTrackVars = 'events,server,eVar5,products';

			if (response.isNewCart) {
				window.s.linkTrackEvents = 'scAdd,scOpen,event75,event72';
				window.s.events = 'scAdd,scOpen,event75,event72';
			} else {
				window.s.linkTrackEvents = 'scAdd,event75';
				window.s.events = 'scAdd,event75';
			}

			if (isFromProject) {
				window.s.linkTrackEvents += ',event141';
				window.s.events += ',event141';
			}
			if (
				(window.dataLayer.page === 'product:display' ||
					window.dataLayer.page === 'product-pla:display' ||
					window.dataLayer.page === 'product:configure') &&
				activeFinish
			) {
				const trackingData = this._getAddToCartTrackingData(activeFinish, quantity);
				if (trackingData) {
					Object.assign(window.s, trackingData.props);
					window.s.linkTrackVars += trackingData.linkTrackVars;
				}
			}

			let formattedCartItems;

			if (addToCartMethod === 'projects') {
				formattedCartItems = gtmHelper.formatCartItemsFromProject(items, addToCartMethod);
			} else {
				formattedCartItems = gtmHelper.formatCartItems(items, addToCartMethod);
			}

			if (response.cart && response.cart.allItems) {
				allCartItems = response.cart.allItems.map((item) => {
					return {
						uniqueId: item.uniqueId,
						unitPrice: item.unitPrice,
						productQuantity: item.quantity
					};
				});
			}

			Promise.all([
				gtmHelper.fireCartQuantityChangeEvent('add-to-cart', addToCartMethod, formattedCartItems, allCartItems),
				trackLink.promiseToTrack('Add To Cart')
			]).then(() => {
				if (callback) {
					callback(model);
				}
			});
		},
		/** @private
		 * @param {object} activeFinish
		 * @param {number} quantity
		 * @returns {any}
		 */
		_getAddToCartTrackingData: function(activeFinish, quantity) {
			const deliveryEstimateForFinish =
				(window.BCOM.estimatedDeliveryInfo &&
					window.BCOM.estimatedDeliveryInfo.find((info) => info.productUniqueId === activeFinish.uniqueId)) ||
				window.dataLayer.estimatedDeliveryInfoForFinish ||
				{};
			if (!window.dataLayer.showEstimatedDelivery) {
				deliveryEstimateForFinish.estimatedLowDays = activeFinish.estimatedLowDays;
				deliveryEstimateForFinish.estimatedHighDays = activeFinish.estimatedHighDays;
			}
			return productTrackingHelper.setAddToCartTrackingData(
				{
					categoryName: window.dataLayer.rootCategory && window.dataLayer.rootCategory.categoryName,
					freight: window.dataLayer.freight,
					manufacturer: window.dataLayer.manufacturer,
					sale: window.dataLayer.sale || (window.dataLayer.selectedFinish && window.dataLayer.selectedFinish.sale)
				},
				activeFinish,
				deliveryEstimateForFinish,
				quantity
			);
		},
		/**
		 * @returns {void}
		 */
		cartAddedToProjectFromCartPage: function() {
			window.s.linkTrackEvents = 'event139';
			window.s.events = 'event139';

			gtmHelper.fireEvent({
				event: 'cart-added-to-project-from-cart-page'
			});

			trackLink.promiseToTrack('Project-Cart Add');
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		productAddedToProject: function(data) {
			window.s.linkTrackVars = 'events,products';
			window.s.linkTrackEvents = 'event140';
			window.s.events = 'event140';
			window.s.products = `;${data.uniqueId}`;
			trackLink.promiseToTrack(`ShoppingList Product Add From ${data.location}`).then(data.callback);
		},
		_trackShippingAsyncDrops: function(trackingMessage) {
			const linkTrackVars = ',prop50';
			const props = {
				prop50: trackingMessage
			};
			Object.assign(window.s, props);
			window.s.linkTrackVars += linkTrackVars;

			gtmHelper.fireEvent({
				event: 'shipping-async-drops-change',
				trackingMessage: trackingMessage
			});

			trackLink.promiseToTrack('Async Drops Shipping tracking on PLP');
		},
		/**
		 * @returns {void}
		 */
		projectModalOpen: function() {
			window.s.linkTrackVars = 'none';
			window.s.linkTrackEvents = 'none';
			if (window.BCOM.data.isQuickShip) {
				trackLink.promiseToTrack('quick-ship-project-add');
			}
			trackLink.promiseToTrack('ShoppingListModalOpen');
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		cloudinary: function(data) {
			window.s.linkTrackVars = 'events,server,eVar46,prop15,eVar74';
			window.s.linkTrackEvents = 'event30';
			window.s.events = 'event30';
			window.s.eVar46 = ['cloudinary', data.pageType, data.action, data.objectId].join(':');
			window.s.prop15 = window.s.eVar46;

			gtmHelper.fireEvent({
				event: 'cloudinary',
				pageType: data.pageType,
				action: data.action,
				objectId: data.objectId
			});

			trackLink.promiseToTrack('Favorites Internal');
		},
		/**
		 * @returns {void}
		 */
		checkoutCreateAccount: function() {
			window.s.linkTrackEvents = 'event4,event118';
			window.s.events = 'event4,event118';
			window.s.prop18 = 'create account';

			window.s.hier = 'Checkout|Open Account';

			gtmHelper.fireEvent({
				event: 'account-created',
				source: 'checkout'
			});

			trackLink.promiseToTrack('Guest Checkout');
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		createAccount: function(data) {
			let events = 'event118';

			if (data && data.isNewsletterSignup) {
				events += ',event6';
				window.s.linkTrackVars = 'eVar45,eVar79';
				window.s.eVar45 = `${window.s.pageName}=${data.location}`;
			}

			window.s.linkTrackEvents = events;
			window.s.events = events;
			window.s.prop18 = 'create account';
			window.s.hier = 'Open Account';
			window.s.eVar79 = md5(data.email);

			gtmHelper.fireEvent({
				event: 'account-created',
				pageName: window.s.pageName,
				source: data.location,
				hashedEMail: md5(data.email)
			});

			trackLink.promiseToTrack('Create Account').then(this._invokeCallBack(data));
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		addCoupon: function(data) {
			const dl = window.dataLayer,
				{ customer } = dl,
				isPro = customer ? Boolean(customer.isPro) : false;
			window.s.linkTrackVars = 'events,server,eVar55,eVar74';
			window.s.linkTrackEvents = 'event77';
			window.s.events = 'event77';
			window.s.eVar55 = `_id:${data.coupon}_as:${dl.isLoggedIn ? 'logged in' : 'logged out'}_cs:${isPro ? 'pro' : 'consumer'}_cv:${
				dl.cartTotal
			}_error:${data.notification.notification}_status:${data.couponStatus}`;

			gtmHelper.fireEvent({
				event: 'coupon-submission',
				coupon: data.coupon,
				isLoggedIn: dl.isLoggedIn,
				isPro,
				cartTotal: dl.cartTotal,
				error: data.notification.notification,
				status: data.couponStatus
			});

			trackLink.promiseToTrack('Cart Add Coupon');
		},
		/**
		 * @returns {Promise<void>}
		 */
		checkoutStart: function() {
			window.s.linkTrackVars = 'events,server,eVar74';
			window.s.linkTrackEvents = 'scCheckout, event74';
			window.s.events = 'scCheckout, event74';

			gtmHelper.fireEvent({
				event: 'checkout-start'
			});

			return trackLink.promiseToTrack('Checkout Started');
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		selectFinish: function(data) {
			window.s.linkTrackVars = `events,products,server,eVar52,eVar88,eVar91`;
			if (window.dataLayer.page === 'product:display' || window.dataLayer.page === 'product-pla:display') {
				const estimatedDeliveryInfo = window.BCOM.estimatedDeliveryInfo;
				const isPreviousFinishQuickShip = window.dataLayer.isPreviousFinishQuickShip === true;
				const productTrackingData = productTrackingHelper.setFinishChangeTrackingData(
					{
						categoryName: window.dataLayer.rootCategory && window.dataLayer.rootCategory.categoryName,
						freight: window.dataLayer.freight,
						manufacturer: window.dataLayer.manufacturer
					},
					data.uniqueId,
					window.dataLayer.finishes,
					data,
					data.price,
					isPreviousFinishQuickShip,
					estimatedDeliveryInfo
				);
				Object.assign(window.s, productTrackingData.props);
				window.s.linkTrackVars += productTrackingData.linkTrackVars;

				gtmHelper.fireEvent({
					event: 'product-click',
					type: 'finish-click',
					isPreviousFinishQuickShip: window.dataLayer.isPreviousFinishQuickShip === true,
					ecommerce: {
						detail: {
							products: [
								{
									uniqueId: data.uniqueId,
									baseCategory: window.dataLayer.baseCategory,
									finishName: data.finish,
									manufacturer: window.dataLayer.manufacturer,
									price: data.price,
									type: data.type,
									application: data.application,
									stockCount: data.stock,
									image: data.images && data.images['50'],
									productId: data.productId,
									saleInformation: data.sale
								}
							]
						}
					}
				});
			}
			// event60 (out of stock) - Fire when the product page is loaded, if product does not have an "In Stock" status
			// event61 (finish selected) - Fire each time user selects finish
			// event62 (no image) - Fire each time user selects finish, if the selected finish does not have a product image

			window.s.linkTrackEvents = 'event61,prodview';
			window.s.events = 'event61,prodview';

			window.s.products = window.dataLayer.selectedFinishId > 0 ? `;${window.dataLayer.selectedFinishId}` : '';

			if (parseInt(data.stock, 10) === 0) {
				window.s.linkTrackEvents += ',event60';
				window.s.events += ',event60';
			}
			if (data.images && data.images['50'].indexOf('noimage.gif') >= 0) {
				window.s.linkTrackEvents += ',event62';
				window.s.events += ',event62';
			}
			window.s.eVar52 = `${data.manufacturer}:${data.productId}:${data.name}:${data.uniqueId}`;
			if (data.sale) {
				window.s.eVar88 = 'ON-SALE';
			}
			if (data.discount) {
				window.s.eVar91 = 'WAS-NOW';
			}
			window.s.pageName = `${window.dataLayer.baseUrl}:product:${window.s.eVar52}`;

			trackLink.promiseToTrack('Product Finish');
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		recommendedProductClicked: function(data) {
			window.s.linkTrackVars = 'events,eVar76';
			window.s.linkTrackEvents = 'event101';
			window.s.events = 'event101';
			window.s.eVar76 = data.eVar76;
			const customPropData = data.trackingProp && data.trackingProp.split('-');
			if (customPropData && customPropData.length === 2 && /prop\d+/.test(customPropData[1])) {
				window.s[customPropData[1]] = customPropData[0];
				window.s.linkTrackVars = `${window.s.linkTrackVars},${customPropData[1]}`;
			}

			gtmHelper.fireEvent({
				event: 'recs-tracking',
				trackingProp: data.trackingProp,
				recsTrackingData: data.recsTrackingData
			});

			trackLink.promiseToTrack('recs tracking').then(() => {
				window.location.assign(data.linkURL);
			});
		},
		/**
		 * @param {*} data
		 * @param {*} callback
		 * @returns {void}
		 */
		facetChanged: function(data, callback) {
			const prefix = data.searchTerm ? `sb>${data.searchTerm}` : `c${data.categoryId}`;
			window.s.linkTrackVars = 'events,server,eVar26';
			window.s.linkTrackEvents = data.isAdd ? 'event46' : 'event78';
			window.s.eVar26 = `${prefix}:${data.groupName}>${data.facetValue}`;
			window.s.events = data.isAdd ? 'event46' : 'event78';

			gtmHelper.fireEvent({
				event: 'facet-interaction',
				prefix: prefix,
				groupName: data.groupName,
				facetValue: data.facetValue,
				isAdd: data.isAdd
			});

			trackLink.promiseToTrack('Facet Changed').then(callback);
		},
		/**
		 * Used by SF Live Agent button
		 * @param {*} data
		 * @returns {void}
		 */
		chatTracking: function(data, type, pageName, baseCategory, businessCategory) {
			window.s.linkTrackVars = 'eVar16';
			window.s.eVar16 = data;

			gtmHelper.fireEvent({
				event: 'live-chat-interaction',
				interaction: type,
				pageName: pageName,
				baseCategory: baseCategory,
				businessCategory: businessCategory
			});

			trackLink.promiseToTrack('Live Chat');
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		emailSignUpTracking: function(data) {
			window.s.linkTrackVars = 'eVar45,eVar79';
			window.s.linkTrackEvents = 'event6';
			window.s.events = 'event6';
			window.s.eVar45 = `${window.s.pageName}=${data.location}`;
			if (data.email) {
				window.s.eVar79 = md5(data.email);
			}

			gtmHelper.fireEvent({
				event: 'email-entry',
				isSignUpTracking: true,
				location: data.location,
				hashedEmail: data.email ? md5(data.email) : ''
			});

			trackLink.promiseToTrack('Email SignUp');
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		emailEntry: function(data) {
			window.s.linkTrackVars = 'eVar79';
			window.s.eVar79 = md5(data.email);

			if (data.location !== 'login-form') {
				gtmHelper.fireEvent({
					event: 'email-entry',
					isSignUpTracking: false,
					location: data.location,
					hashedEmail: data.email ? md5(data.email) : ''
				});
			}
			trackLink.promiseToTrack('Email Entry').then(() => {
				if (data.callback && typeof data.callback === 'function') {
					data.callback();
				}
			});
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		ecoRebates: function(data) {
			trackLink.promiseToTrack(data);
		},
		/**
		 * @returns {string}
		 */
		_getBusinessCategory: function() {
			return window.dataLayer.product && window.dataLayer.product.businessCategory ? window.dataLayer.product.businessCategory : null;
		},
		/**
		 * @param {string} pageName
		 * @param {string} baseCategory
		 * @param {string} businessCategory
		 * @returns {string}
		 */
		_chatTrackingMessage: function(pageName, baseCategory, businessCategory) {
			let trackingString = pageName;
			if (baseCategory && businessCategory) {
				trackingString += `~baseCategory:${baseCategory}:businessCategory:${businessCategory}`;
			}
			return trackingString;
		},
		/**
		 * Used by link on page to Live Agent
		 * @param {Event} e
		 * @returns {void}
		 */
		_trackChat: function(e) {
			const location = $(e.currentTarget).data('location');
			window.s.linkTrackVars = 'eVar16';
			window.s.eVar16 = `livechat~${location}~${this._chatTrackingMessage(
				window.s.pageName,
				window.dataLayer.baseCategory,
				this._getBusinessCategory()
			)}`;

			gtmHelper.fireEvent({
				event: 'live-chat-interaction',
				interaction: 'chat-click',
				pageName: window.s.pageName,
				baseCategory: window.dataLayer.baseCategory,
				businessCategory: this._getBusinessCategory()
			});

			trackLink.promiseToTrack('Live Chat');
		},
		/**
		 * @param {Event} e
		 * @returns {void}
		 */
		_trackPhone: function(e) {
			let name = $(e.currentTarget).data('phone');

			if (!name || name === 'phone:click') {
				name = 'PhoneNumber Click';
			}

			window.s.linkTrackEvents = 'event48';
			window.s.events = 'event48';

			gtmHelper.fireEvent({
				event: 'phone-call-click',
				type: name
			});

			trackLink.promiseToTrack(name);
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		_socialShare: function(data, pageName, socialName) {
			window.s.linkTrackVars = 'events,eVar27';
			window.s.linkTrackEvents = 'event29';
			window.s.eVar27 = data.trackingString;
			window.s.events = 'event29';

			gtmHelper.fireEvent({
				event: 'social-share-click',
				pageName: pageName,
				socialName: socialName
			});

			trackLink.promiseToTrack('Social Share Complete');
		},
		/**
		 * @returns {void}
		 */
		_plaPageToFullProductPage: function() {
			productTrackingHelper._setTracking();
			trackLink.promiseToTrack('PLA Product Page to Full Product Page');
		},
		/**
		 * @returns {void}
		 */
		_plaConfigureAddToCart: function() {
			productTrackingHelper._setTracking();
			trackLink.promiseToTrack('Configure and Add to Cart click on PLA');
		},
		/**
		 * @param {boolean} isShipping
		 * @param {boolean} isBilling
		 * @returns {void}
		 */
		_defaultAddressUnset: function(isShipping, isBilling) {
			let events = [];
			if (isShipping) {
				events.push('event119');
			}
			if (isBilling) {
				events.push('event120');
			}
			window.s.linkTrackVars = 'events';
			window.s.linkTrackEvents = window.s.events = events.join(',');

			gtmHelper.fireEvent({
				event: 'default-address-unset',
				isShipping: Boolean(isShipping),
				isBilling: Boolean(isBilling)
			});

			trackLink.promiseToTrack('UnCheck Default Address');
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		_searchQuery: function(data) {
			const tracking = `${window.s.pageName}:${data.query}`;
			window.s.linkTrackVars = 'eVar61';
			window.s.eVar61 = tracking;

			gtmHelper.fireEvent({
				event: 'internal-site-search',
				pageName: window.s.pageName,
				query: data.query
			});

			trackLink.promiseToTrack(tracking);
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		_proAccountSignupStepTwo: function(data) {
			const { callback } = data;
			window.s.linkTrackVars = 'events';
			window.s.linkTrackEvents = window.s.events = 'event159';

			gtmHelper.fireEvent({
				event: 'pro-signup',
				step: 2
			});

			trackLink.promiseToTrack('ProAccountSignupStepTwo').then(() => {
				if (callback && typeof callback === 'function') {
					callback();
				}
			});
		},
		/**
		 * @param {*} data
		 * @returns {void}
		 */
		_proAccountSignupFinish: function(data) {
			const { callback } = data;
			window.s.linkTrackVars = 'events';
			window.s.linkTrackEvents = window.s.events = 'event59';

			gtmHelper.fireEvent({
				event: 'pro-signup',
				step: 3
			});

			trackLink.promiseToTrack('ProAccountSignupFinish').then(() => {
				if (callback && typeof callback === 'function') {
					callback();
				}
			});
		},
		/**
		 * @param {*} data
		 */
		_invokeCallBack: function(data) {
			if (data.callback && typeof data.callback === 'function') {
				data.callback();
			}
		},
		/**
		 * @param {String} component - name of component firing tracking
		 * @param {String} action - name of action to track
		 * @param {Object} additionalData - any additional data to fire to gtm
		 * @param {String} redirect - url to redirect to
		 * @returns {void}
		 */
		trackProjectsOmniture: function(component, action, additionalData, redirect) {
			let formattedAction = action;
			additionalData = additionalData || {};

			if (action === 'save_logged_in') {
				formattedAction = this.$('.js-quick-add').length ? 'save_dropdown' : action;
			}
			const trackingString = `${window.dataLayer.page}~projects_scope~${component}~${formattedAction}`;

			gtmHelper.fireEvent({
				event: 'project-interaction',
				page: window.dataLayer.page,
				component: component,
				interaction: formattedAction,
				...additionalData
			});

			this._trackLink({
				linkName: 'Projects Tracking',
				redirect,
				evars: {
					eVar87: trackingString
				}
			});
		},
		/**
		 * @param {Event} event
		 * @returns {void}
		 */
		_recentlyViewedItemsClickTracking: function(event) {
			const isDy = $(event.currentTarget).hasClass('rec-item');
			const sourceString = isDy ? 'DYRecentlyViewed' : 'buildRecentlyViewed';
			const deviceType = window.BCOM.device.deviceType;
			const pageType = window.dataLayer.page;
			window.s.linkTrackVars = 'eVar76';
			const eVar76 = `${sourceString}~${pageType}~${deviceType}`;
			window.s.eVar76 = eVar76;
			trackLink.promiseToTrack('Recently Viewed Product');
		},
		/**
		 * @param {string} region - region of product card
		 * @param {Number} productIterator - product card number in order of appearance on page
		 * @returns {void}
		 */
		trackProductCard: function(region, productIterator) {
			const trackingText = region === 'General' ? 'Product Card' : 'Product Rating';

			gtmHelper.fireEvent({
				event: 'product-card',
				trackingText: trackingText,
				productIterator: productIterator
			});

			trackLink.promiseToTrack(`${trackingText} ${productIterator}`);
		},

		/**
		 * @param {Boolean} success
		 * @returns {void}
		 */
		trackExportToTrilogie: function(success) {
			window.s.linkTrackVars = 'events,eVar100';
			window.s.linkTrackEvents = 'event164';
			window.s.eVar100 = `Export to Trilogie:${success ? 'Success' : 'Form error'}`;
			window.s.events = 'event164';

			gtmHelper.fireEvent({
				event: 'trilogie-interaction',
				interaction: 'export to trilogie',
				trackingText: `${success ? 'Success' : 'Form error'}`
			});

			trackLink.promiseToTrack('Trilogie Pricing - Internal Tool');
		},

		/**
		 * @param {Boolean} success
		 * @returns {void}
		 */
		trackAddTrilogieProPricing: function(success) {
			window.s.linkTrackVars = 'events,eVar100';
			window.s.linkTrackEvents = 'event165';
			window.s.eVar100 = `Add Pro Pricing:${success ? 'Success' : 'Form error'}`;
			window.s.events = 'event165';

			gtmHelper.fireEvent({
				event: 'trilogie-interaction',
				interaction: 'add trilogie pro pricing',
				trackingText: `${success ? 'Success' : 'Form error'}`
			});

			trackLink.promiseToTrack('Add Pro Pricing');
		},

		/**
		 * @param {Boolean} success
		 * @returns {void}
		 */
		trackRemoveTrilogieProPricing: function(success) {
			window.s.linkTrackVars = 'events,eVar100';
			window.s.linkTrackEvents = 'event166';
			window.s.eVar100 = `Remove Pro Pricing:${success ? 'Success' : 'Form error'}`;
			window.s.events = 'event166';

			gtmHelper.fireEvent({
				event: 'trilogie-interaction',
				interaction: 'remove trilogie pro pricing',
				trackingText: `${success ? 'Success' : 'Form error'}`
			});

			trackLink.promiseToTrack('Remove Pro Pricing');
		},

		/**
		 * @param {Event} event
		 * @returns {void}
		 */
		_findingMethodsTracking: function(e) {
			const findingMethod = $(e.currentTarget).data('finding-method');
			const findingSubType = $(e.currentTarget).data('finding-method-subtype');
			window.s.linkTrackVars = 'eVar3,eVar93';
			if (findingMethod && findingSubType) {
				window.s.eVar3 = `${findingMethod}`;
				window.s.eVar93 = `${findingSubType}`;
			}

			gtmHelper.fireEvent({
				event: 'finding-methods-tracking',
				findingMethod: findingMethod,
				findingSubType: findingSubType
			});

			trackLink.promiseToTrack('Finding Method Tracking');
		},
		/**
		 * @param trackingString
		 * @param isSuccess
		 */
		buyItAgainResult: function(trackingString, product, isSuccess, model) {
			window.s.linkTrackVars = 'events,eVar163,eVar102,eVar99';
			window.s.linkTrackEvents = 'event163';
			window.s.events = 'event163';
			window.s.eVar102 = trackingString;
			window.s.eVar99 = isSuccess ? 'success' : 'error';

			gtmHelper.fireCartQuantityChangeEvent('add-to-cart', 'buy-it-again', [
				gtmHelper.mapProductData({
					uniqueId: model.uniqueId,
					finishName: model.selectedFinish.finish,
					manufacturer: model.manufacturer,
					price: model.consumerPrice,
					quantity: '1',
					businessCategory: model.product.businessCategory,
					type: model.type,
					application: model.application,
					stockCount: model.selectedFinish.stockCount,
					image: model.selectedFinish.image,
					productId: model.productId
				})
			]);

			trackLink.promiseToTrack('Buy It Again');
		},
		/**
		 * @param pageView
		 */
		orderAgain: function(pageView) {
			window.s.linkTrackVars = 'events,eVar104,event168';
			window.s.linkTrackEvents = 'event168';
			window.s.events = 'event168';
			window.s.eVar104 = `page view:${pageView}`;

			gtmHelper.fireEvent({
				event: 'order-again',
				interaction: 'show modal',
				pageView: pageView
			});

			trackLink.promiseToTrack('Order Again');
		},
		/**
		 * @param action
		 */
		orderAgainModal: function(action) {
			window.s.linkTrackVars = 'events,eVar104,event169,event170';
			const event = action === 'view' ? 'event169' : 'event170';
			window.s.linkTrackEvents = event;
			window.s.events = event;
			window.s.eVar104 = 'page view:order again modal';

			gtmHelper.fireEvent({
				event: 'order-again',
				interaction: 'show modal',
				action: action
			});

			trackLink.promiseToTrack('Order Again');
		},
		/**
		 * @param trackingString
		 */
		orderAgainAddToCart: function(trackingString, gtmTrackingData) {
			window.s.linkTrackVars = 'events,eVar104,event171';
			window.s.linkTrackEvents = 'event171';
			window.s.events = 'event171';
			window.s.eVar104 = trackingString;

			gtmHelper.fireCartQuantityChangeEvent('add-to-cart', 'order-again', gtmTrackingData.selectedItems);

			trackLink.promiseToTrack('Order Again');
		},

		/**
		 * @param sku
		 */
		orderHistoryRelatedItems: function(sku) {
			window.s.linkTrackVars = 'events,eVar105';
			window.s.eVar105 = `Related Items clicked on SKU: ${sku}`;
			trackLink.promiseToTrack('Order History Related Items');
		},

		/**
		 * @param trackingString
		 */
		orderHistorySearch: function(searchType, searchBy, searchTerm, noOfResults, sortColumn, sortOrder, status) {
			const eventObject = {
				event: 'order-and-return-history-search',
				searchType: searchType,
				searchBy: searchBy,
				searchTerm: searchTerm,
				sortColumn: sortColumn,
				sortOrder: sortOrder,
				status: status,
				numberOfResults: noOfResults
			};
			gtmHelper.fireEvent(eventObject);

			trackLink.promiseToTrack('Order/Return History Search');
		}
	})
);

module.exports = new OmnitureHelper();
