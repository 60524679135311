var validator = require('validator');

function normalizeYear(year) {
	// Century fix
	var YEARS_AHEAD = 20;
	if (year < 100) {
		var nowYear = new Date().getFullYear();
		year += Math.floor(nowYear / 100) * 100;
		if (year > nowYear + YEARS_AHEAD) {
			year -= 100;
		} else if (year <= nowYear - 100 + YEARS_AHEAD) {
			year += 100;
		}
	}
	return year;
}

function isCreditCardExpirationValid(input) {
	var match = input.match(/^\s*(0?[1-9]|1[0-2])\/(\d\d|\d{4})\s*$/);
	if (!match) {
		return false;
	}
	var exp = new Date(normalizeYear(Number(match[2])), Number(match[1]) - 1, 1).valueOf();
	var now = new Date();
	var currMonth = new Date(now.getFullYear(), now.getMonth(), 1).valueOf();
	return exp > currMonth;
}

var customValidators = {
	extend: function() {
		validator.isZip = function(zip) {
			return /^(\d{5}-\d{4}|\d{5}|\d{9})$|^([a-zA-Z]\d[a-zA-Z]( )?\d[a-zA-Z]\d)$/.test(zip);
		};
		validator.isValidStrictEmail = function(email) {
			// eslint-disable-next-line no-useless-escape
			return /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,62}$/i.test(email);
		};
		validator.isPhone = function(phone) {
			if (!phone || typeof phone !== 'string' || phone === '') {
				return false;
			}
			// REGEX: looks for either phone number with leading 1 format, area code include, or w/o area code
			return (
				/^(1|1[ -.])?[0-9]{3}[ -.]?[0-9]{3}[ -.]?[0-9]{4}$/.test(phone) ||
				/^\([0-9]{3}\)[ -.]?[0-9]{3}[ -.]?[0-9]{4}$/.test(phone) ||
				/^[0-9]{3}[ -.]?[0-9]{4}$/.test(phone)
			);
		};
		validator.isCreditCardExpiration = function(expiration) {
			return isCreditCardExpirationValid(expiration);
		};
		validator.isPoBox = function(address) {
			// eslint-disable-next-line no-useless-escape
			return /(p\.o\.)|(post office)|(po box)|(box\s[0-9].*)|(po box\s[0-9].*)/i.test(address);
		};
		validator.isCVV = function(cvv) {
			return /^[0-9]{3,4}$/i.test(cvv);
		};
	}
};

module.exports = customValidators;
